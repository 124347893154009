.nav-slider{
    position: relative;
    border-radius: 0;
    .nav-link{
        &.active {
            color: var(--#{$variable-prefix}white);
            background-color: var(--#{$variable-prefix}primary);
        }
    }
    .nav-item {
        z-index: 3;
    }
    .nav-slider-thumb {
        z-index: 1 !important;
        width: 100%;
        color: var(--#{$variable-prefix}primary);
        background: var(--#{$variable-prefix}primary);
    }
} 