/*---------------------------------------------------------------------
                      Pages Content
-----------------------------------------------------------------------*/
.custom-header-relative {
  .main-content {
    padding-top: var(--header-height);
  }
}

// view all link button
.iq-view-all {
  font-weight: $font-weight-medium;
  font-size: 0.875rem;
}

body {
  overflow-x: hidden;
}

// movie details
.iq-main-slider {
  #my-video {
    height: 70vh;
  }
}

.details-part {
  padding-top: 1.875em;

  .trending-info {
    position: relative;
    padding: 1rem 0 3.75em ;
  }
}

/* ------------------------
Rtl Box & Color Customizer
----------------------------- */
.rtl-box {
  position: fixed;
  max-width: 250px;
  top: 8rem;
  right: 0;
  background-color: var(--bs-gray-900);
  z-index: 1000;
  .live-customizer {
    background: var(--bs-white);
    height: auto;
    top: 8rem;
    bottom: auto;
    width: 20rem;
  }

  .rtl-btn {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .modes {
    margin: 0;
  }

  .modes {
    .cutomizer-button {
      cursor: pointer;
      display: block;
      border: 0.063em solid var(--bs-body-bg);
      color: var(--bs-dark);
      text-align: center;
      padding: 0.3em 1.8em;
      transition: all 400ms ease;

      &.active,
      &:hover {
        background: var(--bs-gray-900);
        color: var(--bs-white);
      }
    }
    .btn-check {
      &:checked {
        + .cutomizer-button {
          background: var(--bs-gray-900);
          color: var(--bs-white);
        }
      }
    }
  }

  .title-customizer {
    color: var(--bs-dark);
  }
}

.reset-tooltip {
  --bs-tooltip-bg: var(--bs-primary);
  --bs-tooltip-color: var(--bs-white);
}

/* ------------------------
Rtl Box & Color Customizer
----------------------------- */

.share-icons.music-play-lists {
  li {
    position: relative;
    display: inline-flex;
    margin-right: 0.438em;
    width: 2.813em;
    height: 2.813em;
    line-height: 2.188em;
    text-align: center;
    background: rgba(var(--bs-white-rgb), 0.3);
    border-radius: 50%;
    margin: 0 0.5rem 0.438em;
    align-items: center;

    span {
      position: relative;
      display: block;
      width: 2em;
      height: 2em;
      line-height: 2.1rem;
      border-radius: 50%;
      font-size: 1em;
      text-align: center;
      background: var(--bs-white);
      color: var(--bs-primary);
      margin: 0 auto;
      transition: all 0.45s ease 0s;
      cursor: pointer;

      i {
        font-size: 0.938em;
      }
    }

    .share-box {
      display: none;
      position: absolute;
      top: 0.2em;
      right: -4.1em;
      background-color: var(--bs-gray-900);
      padding: 0 0 0 0.4375em;
      border-radius: 0em;
      text-align: center;
      z-index: 2;
      animation: side-in 0.5s forwards;
      margin-bottom: 0.625em;
      transition: all 0.45s ease 0s;
      height: 2.5em;

      .share-shape {
        position: absolute;
        transform: rotate(-180deg);
        left: -0.9em;
        right: auto;
        top: 0px;
        width: 0.9375em;
      }

      a {
        background: transparent !important;
        color: var(--bs-body-text) !important;
        margin-right: 0.938em;
      }
    }

    &.share {
      &:hover {
        .share-box {
          display: inline-block;
        }
      }
    }
  }
}

.movie-detail-select {
  background: rgba(var(--bs-white-rgb), 0.3);
  padding: 7px;
  border-radius: 8px;
  width: 150px;

  select {
    background: var(--bs-white);
    color: var(--bs-primary);
    height: 2em;
    padding: 0.2rem 1rem;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: none;

    &:focus {
      background: var(--bs-white);
      color: var(--bs-primary);
    }
  }

  .select2-container {

    .select2-selection--single,
    .select2-selection--multiple {
      background: var(--bs-white);
      color: var(--bs-primary);
      height: 2em;
      padding: 0.2rem 1rem;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      border: none;

      .select2-selection__rendered {
        color: var(--bs-primary);
      }
    }

    &.select2-container--default {

      .select2-selection--single,
      .select2-selection--multiple {
        .select2-selection__arrow {
          b {
            border-color: var(--bs-primary) transparent transparent transparent;
          }
        }
      }
    }

    &.select2-container--open {

      .select2-selection--single,
      .select2-selection--multiple {
        .select2-selection__arrow {
          b {
            border-color: transparent transparent var(--bs-primary) transparent;
          }
        }
      }
    }
  }
}

.iq-blogtag {
  .title {
    color: var(--bs-body-color);
    font-size: var(--bs-body-font-size);
    transition: all 0.3s;
    text-decoration: none;

    &:hover {
      color: var(--bs-primary);
    }
  }
}

.trailor-video {
  .block-images {
    width: auto;
    border-radius: 0.625em;
    overflow: hidden;
    vertical-align: middle;

    &::before {
      background: rgba(var(--bs-black-rgb), 0.5);
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
    }
  }

  .btn-transparant.iq-button {
    position: absolute;
    top: 50%;
    left: 0;
    text-align: center;
    right: 0;
    transform: translateY(-50%);

    span {
      display: block;
      font-size: 1em;
      text-transform: capitalize;
      color: var(--bs-white);
    }
  }
}

// tv-show details

.tv-show-detail {
  .iq-main-slider {
    min-height: 80vh;
    padding: 3.75rem 0 3.75rem 5em;
    z-index: 1;
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg,
          rgba(51, 51, 51, 0),
          rgba(var(--bs-black-rgb), 0.8) 70%,
          var(--bs-black));
    }
  }
}

.play-button {
  position: relative;
  width: 3.125rem;
  height: 3.125rem;
  min-width: 3.125rem;
  line-height: 3.125rem;
  border-radius: 50%;
  text-align: center;
  background: var(--bs-primary);
  color: var(--bs-white);
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 3.125em;
    min-width: 3.125em;
    height: 3.125em;
    border-radius: 50%;
    z-index: -1;
    background: var(--bs-primary);
    animation: pulse-border 1.5s ease-out infinite;
  }
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.shop-contact-one {
  padding: 6.25em 6.25em 6.25em 6.25em;
  background-color: var(bs-gray-900);

  .channel-logo {
    padding: 0.8em 0.625em 0.625em 0.938em;
    width: 15.938em;
  }
}

.logo-width {
  width: 10rem;
}

// page restriction
.iq-restriction_box {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--bs-gray-900);
  padding: 1rem;
  margin-top: calc(var(--header-height) + 2.5rem);

  .iq-button {
    margin: 0.625em 0;
  }
}

@media (max-width: 1200px) {
  .iq-main-slider {
    #my-video {
      height: 60vh;
    }
  }

  .tv-show-detail {
    .iq-main-slider {
      min-height: 70vh;
      padding: 2rem 1rem;
    }
  }

  .iq-restriction_box {
    height: 60vh;
  }
}

@media (max-width: 1024px) {
  .iq-main-slider {
    #my-video {
      height: 50vh;
    }
  }

  .iq-restriction_box {
    height: 50vh;
  }
}

@media (max-width: 767px) {
  .iq-main-slider {
    #my-video {
      height: 30vh;
    }
  }

  .shop-contact-one {
    padding: 2em;
  }

  .iq-restriction_box {
    height: 30vh;
  }

  .iq-restriction_box {
    margin-top: calc(var(--header-height) + 2rem);
  }
}

// image box
.image-box {
  margin: 0;
  padding: 0;
  overflow: hidden;

  img {
    transition: transform 2s ease-in-out;
    transform-origin: center center;

    &:hover {
      transform: scale(1.3);
    }
  }
}

.box-content {
  .desiganation {
    padding: 0.313em;
    position: relative;
    overflow: hidden;
    display: inline-block;
    font-style: italic;
  }

  h4 {
    line-height: 0;
  }
}

.image-box-1 {
  font-size: 9.375rem;

  svg {
    width: 1em;
    height: 1em;
  }
}

// Counter
.counter {
  color: var(--bs-primary);
  font-size: 2.5em;
  font-weight: $font-weight-semibold;
  text-align: center;
}

.counter-1::after {
  content: "+";
  color: var(--bs-primary);
  font-weight: $font-weight-semibold;
}

.counter-title {
  color: var(--bs-body-text);
  font-weight: $font-weight-light;
  text-align: center;
  font-size: 1.1875rem;
  line-height: 2.5;
}

.iq-contact-list {
  border: 0.063rem solid;
  border-image-slice: 1;
  border-width: 0 0 0 0.063rem;
  border-image-source: linear-gradient(to top,
      rgba(var(--bs-secondary-rgb), 0),
      rgba(var(--bs-secondary-rgb), 0.6),
      rgba(var(--bs-secondary-rgb), 0));
}

// contact-box
.contact-box {
  background-color: var(--bs-gray-900);
  padding: 2.1875rem;
  position: relative;

  img {
    z-index: 0;
  }

  .icon-wrapper {
    background-color: var(--bs-body-bg);
    height: 3.75rem;
    width: 3.75rem;
    line-height: 3.75rem;
    text-align: center;
    flex: none;
  }
}

@media (max-width: 1024px) {
  .contact-box {
    padding: 1rem;
  }
}

// coming soon
.mail-box-width {
  width: 50%;
}

@media (max-width: 1024px) {
  .mail-box-width {
    width: 100%;
  }
}

// profile page
.profile-box {
  background-color: var(--bs-gray-900);
  padding: 2rem 0;
  margin-bottom: 1rem;

  .account-logo {
    img {
      width: 5rem;
      height: 5rem;
    }

    i {
      width: 1.125rem;
      height: 1.125rem;
      line-height: 1.125rem;
      background-color: var(--bs-body-bg);
      color: var(--bs-white);
      font-size: 0.75rem;
      text-align: center;
      position: absolute;
      bottom: 0.25rem;
      right: 0.25rem;
    }
  }
}

// user pages

.user-login-card {
  padding: 3.125em;
  margin: 0 auto;
  backdrop-filter: blur(1.5625em);

  .navbar-brand {
    img {
      margin-bottom: 3rem;
    }

    svg {
      height: 40px;
    }
  }

  .full-button {
    .btn {
      width: 100%;

      i {
        display: none;
      }
    }
  }

  .seperator {
    margin: 1.5rem 0 0.875rem;
  }

  .line {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    display: inline-block;
    border-bottom: 0.0625em solid var(--bs-border-color);
    vertical-align: middle;
    min-width: 50px;
    line-height: 0;
  }

  .widget_social_media {
    li {
      a {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        background: var(--bs-gray-900);
      }
    }
  }
}

//person detail
.seperator {
  .circle {
    background: var(--bs-body-color);
    vertical-align: middle;
    height: 0.25rem;
    width: 0.25rem;
    margin: 0 0.625rem;
    border-radius: 50%;

    &.circle-small {
      height: 0.18rem;
      width: 0.18rem;
    }
  }
}

.awards-box {
  padding: 2.5rem 0;
  margin-bottom: 2.5rem;
}

.cast-box {
  img {
    height: 34.375rem;
  }

  .widget_social_media {
    position: absolute;
    bottom: 1.25rem;
    left: 50%;
    transform: translateX(-50%);

    li {
      a {
        background: var(--bs-body-bg);

        &:hover {
          background: var(--bs-primary);
        }
      }
    }
  }
}

.person-img {
  width: 6.875rem;
  height: 7.5rem;
  min-width: 6.875rem;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

// quick view model
#woosq-popup {
  .modal-content {
    background: var(--bs-gray-900);
  }

  .review {
    span {
      font-size: 0.875rem;
    }
  }

  .entry-summary {
    overflow: auto;
    height: 28.75rem;
    padding: 2rem 2rem 2rem 3rem;
  }

  .modal-dialog {
    max-width: 57.5em;
    max-height: 28.75em;

    img {
      max-height: 28.75em;
      width: 28.75em;
    }

    .btn-close {
      height: 30px;
      width: 30px;
      font-size: 12px;
    }

    .row {
      >div {
        max-height: 28.75em;
      }
    }
  }
}

// Model
.modal-content {
  background: var(--bs-gray-900);

  .modal-header {
    padding: 3.125rem 3.125rem 1rem;
  }

  .modal-body {
    padding: 0 3.125rem 3.125rem;

    .form-control {
      background-color: var(--bs-body-bg);
    }
  }

  .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;

    &:focus {
      box-shadow: none;
    }
  }
}

@media (max-width: 767px) {
  .iq-contact-list {
    border: 0;
  }

  .image-box-1 {
    font-size: 6.375rem;
  }

  #woosq-popup {
    .modal-dialog {
      img {
        width: 100%;
      }
    }

    .entry-summary {
      padding: 1rem;
    }
  }

  .modal-content {
    .modal-header {
      padding: 1rem;
    }

    .modal-body {
      padding: 0 1rem 1rem;
    }
  }
}

.height-auto-100 {
  height: 100vh;
}

[data-bs-theme="hulu"] {
  .iq-button {
    .btn {
      color: var(--bs-black);
    }
  }

  #back-to-top {
    .top {
      color: var(--bs-black);
    }
  }
}

@media (max-width:991px) {
  .height-auto-100 {
    height: auto;
  }
}

.containerHeight{
  
  @media (min-width:1025px) and (max-width:1768px) {
    height:420px;
  }
}

.continueWatching{
  height: 320px;
}

.section-padding-top{
  padding-top: 30px;
}