.swiper-nav {
	--active: var(--bs-white);
	--border: rgba(var(--bs-white-rgb), 0.12);
	width: 2.75rem;
	height: 2.75rem;
	position: absolute;
	cursor: pointer;
	top: 50%;
	transform: translateY(-50%);
	z-index: 999;

	&::before,
	&::after {
		content: "";
		display: block;
		position: absolute;
		left: .063rem;
		right: .063rem;
		top: .063rem;
		bottom: .063rem;
		border-radius: 50%;
		border: .063rem solid var(--bs-body-color);
	}

	svg {
		width: 2.75rem;
		height: 2.75rem;
		display: block;
		position: relative;
		z-index: 1;
		color: var(--active);
		stroke-width: 0.125rem;
		stroke-dashoffset: 126;
		stroke-dasharray: 126 126 0;
		transform: rotate(0deg);
	}

	i {
		display: block;
		position: absolute;
		margin: -0.625rem 0 0 -0.625rem;
		width: 1.25rem;
		height: 1.25rem;
		left: 50%;
		top: 50%;

		&::before,
		&::after {
			content: "";
			width: 0.625rem;
			height: 0.125rem;
			border-radius: 0.063rem;
			position: absolute;
			left: 50%;
			top: 50%;
			background: var(--active);
			margin: -0.063rem 0 0 -0.313rem;
			display: block;
			-webkit-transform-origin: 0.563rem 50%;
			transform-origin: 0.563rem 50%;
		}

		&::before {
			transform: rotate(-40deg);
		}

		&::after {
			transform: rotate(40deg);
		}
	}

	&.animate {
		i {
			animation: arrow 1.6s ease forwards;

			&::before {
				animation: arrowUp 1.6s ease forwards;
			}

			&::after {
				animation: arrowDown 1.6s ease forwards;
			}
		}

		svg {
			animation: stroke 1s ease forwards 0.3s;
		}
	}
}

.swiper-banner-button-prev.swiper-nav {
	left: 2%;
	transform: scaleX(-1)translateY(-50%);
}

.swiper-banner-button-next.swiper-nav {
	left: auto;
	right: 2%;
}

.slider-ratting {
	ul {
		li {
			margin-right: 0.313rem;
		}
	}
}


.banner-home-swiper {
	.banner-home-swiper-image{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		img{
			object-fit: cover;
			height: 100%;
			width: 100%;
		}

		&:before {
			background: linear-gradient(90deg,
					rgba(0, 0, 0, 0.9) 0%,
					rgba(20, 20, 20, 0.9) 15%,
					rgba(83, 100, 141, 0) 100%);
			width: 100%;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
		}
	}

	p {
		width: 37.5rem;
		max-width: 100%;
		margin: 2rem 0;
	}

	.trending-list {
		margin-bottom: 1.875rem;
	}
}

#home-banner-slider {
	height: 100vh;
}

@media (max-width:991px) {
	.home-slider {
		.swiper-bg {
			padding: 3.125rem 0;
		}
	}
}

@media (max-width:767px) {
	#home-banner-slider {
		height: 28.125rem;
	}
}

@-webkit-keyframes stroke {
	52% {
		-webkit-transform: rotate(-180deg);
		transform: rotate(-180deg);
		stroke-dashoffset: 0;
	}

	52.1% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
		stroke-dashoffset: 0;
	}

	100% {
		-webkit-transform: rotate(-180deg);
		transform: rotate(-180deg);
		stroke-dashoffset: 126;
	}
}

@keyframes stroke {
	52% {
		-webkit-transform: rotate(-180deg);
		transform: rotate(-180deg);
		stroke-dashoffset: 0;
	}

	52.1% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
		stroke-dashoffset: 0;
	}

	100% {
		-webkit-transform: rotate(-180deg);
		transform: rotate(-180deg);
		stroke-dashoffset: 126;
	}
}

@-webkit-keyframes arrow {

	0%,
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}

	23% {
		-webkit-transform: translateX(17px);
		transform: translateX(17px);
		opacity: 1;
	}

	24%,
	80% {
		-webkit-transform: translateX(-22px);
		transform: translateX(-22px);
		opacity: 0;
	}

	81% {
		opacity: 1;
		-webkit-transform: translateX(-22px);
		transform: translateX(-22px);
	}
}

@keyframes arrow {

	0%,
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}

	23% {
		-webkit-transform: translateX(17px);
		transform: translateX(17px);
		opacity: 1;
	}

	24%,
	80% {
		-webkit-transform: translateX(-22px);
		transform: translateX(-22px);
		opacity: 0;
	}

	81% {
		opacity: 1;
		-webkit-transform: translateX(-22px);
		transform: translateX(-22px);
	}
}

@-webkit-keyframes arrowUp {

	0%,
	100% {
		-webkit-transform: rotate(-40deg) scaleX(1);
		transform: rotate(-40deg) scaleX(1);
	}

	20%,
	80% {
		-webkit-transform: rotate(0deg) scaleX(0.1);
		transform: rotate(0deg) scaleX(0.1);
	}
}

@keyframes arrowUp {

	0%,
	100% {
		-webkit-transform: rotate(-40deg) scaleX(1);
		transform: rotate(-40deg) scaleX(1);
	}

	20%,
	80% {
		-webkit-transform: rotate(0deg) scaleX(0.1);
		transform: rotate(0deg) scaleX(0.1);
	}
}

@-webkit-keyframes arrowDown {

	0%,
	100% {
		-webkit-transform: rotate(40deg) scaleX(1);
		transform: rotate(40deg) scaleX(1);
	}

	20%,
	80% {
		-webkit-transform: rotate(0deg) scaleX(0.1);
		transform: rotate(0deg) scaleX(0.1);
	}
}

@keyframes arrowDown {

	0%,
	100% {
		-webkit-transform: rotate(40deg) scaleX(1);
		transform: rotate(40deg) scaleX(1);
	}

	20%,
	80% {
		-webkit-transform: rotate(0deg) scaleX(0.1);
		transform: rotate(0deg) scaleX(0.1);
	}
}

@media (max-width:991px) {

	.swiper-nav,
	.swiper-nav svg {
		width: 2rem;
		height: 2rem;
	}

	.swiper-banner-button-prev {
		.swiper-nav {
			left: 1%;
		}
	}
}