.navbar-brand {
  img {
    &.logo {
      height: 2.375rem;
      max-height: 2.375rem;
    }
  }

  svg {
    width: 10rem;
  }
}

header {

  &.header-sticky,
  .header-sticky {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    // backdrop-filter: blur(0.625em);
    backdrop-filter: blur(0.025em);
    width: 100%;
    display: inline-block;
    transition: all 0.3s ease-out 0s;
    // background: rgba(var(--bs-black-rgb), 0.5);
    background: #0000000a;

    &.sticky {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      -webkit-animation: header-slide-down 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: header-slide-down 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation-fill-mode: initial;
      transition: all 0.3s ease-out 0s;
    }
  }

  h1.categoryName {
    font-size: 14px;
    margin-bottom: 20px;
    color: grey;
  }

  div.closeIcon {
    position: absolute;
    right: 5px;
    top: 30%;
    display: none;

    @media all and (max-width: 768px) {
      display: block;
    }

    svg {
      width: 16px !important;
      height: 16px !important;
      color: var(--white-color);

    }
  }

  .navbar-toggler-icon {
    background-image: none;
    position: relative;
    background: none;
    text-align: center;
    background: var(--bs-primary);
  }

  div.dropdowns {
    position: absolute;
    top: 91px;
    right: 40px;
    border-radius: 5px;
    z-index: 99999;
    display: none; // none by default, but block on "open" of the menu
    // padding: var(--#{$prefix}dropdown-padding-y) var(--#{$prefix}dropdown-padding-x);
    margin: 0; // Override default margin of ul
    // @include font-size(var(--#{$prefix}dropdown-font-size));
    color: #000;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    list-style: none;
    background-color: #000;
    background-clip: padding-box;
    // border: var(--#{$prefix}dropdown-border-width) solid var(--#{$prefix}dropdown-border-color);
  }

  .mega-menu-content {
    .iq-nav-menu {
      li {
        ul.mega-menu-item {
          width: 1360px;
          padding: 2rem 1rem;

          li {
            width: 16.4%;
            display: inline-block;
            border-bottom: 0;

            .nav-link {
              span {
                font-size: 1rem;
                font-weight: 400;
                // padding-top: 0.9375rem;
                padding: 1.375rem 0.9375rem;
                transition: color 400ms ease, background 400ms ease;
              }

              &:hover {
                span {
                  color: var(--bs-primary);
                }
              }
            }
          }
        }
      }
    }
  }

  &.header-center-home {
    .mega-menu-content {
      .iq-nav-menu {
        li {
          ul.mega-menu-item {
            left: 30%;
            transform: translateX(-30%);
          }
        }
      }
    }
  }

  .navbar {
    .sub-nav.mega-menu-item {

      li:hover>a::before,
      li>a.active::before {
        content: none;
      }
    }
  }
}

/*===================
Merchandise Header
========================*/
.header-merchandise {
  .navbar {
    .horizontal-nav {
      padding-left: 0;
    }

    >.container-fluid,
    >.container {
      justify-content: start;
    }
  }

  .header-top {
    padding: 1rem 0;
    background-color: #141414;
  }

  .header-bottom {
    position: relative;
    background: rgba(var(--bs-black-rgb), 0.5);
    backdrop-filter: blur(0.625em);
    display: inline-block;
    width: 100%;
    z-index: 999;
    transition: all 0.3s ease-out 0s;
  }

  .iq-nav-menu {
    li {
      &:first-child {
        .nav-link {
          padding-left: 0;
        }
      }

      .sub-nav {
        li {
          .nav-link {
            padding-left: var(--bs-navbar-nav-link-padding-x);
          }
        }
      }
    }
  }
}

div.optionsList {
  display: flex;
  flex-direction: column;

  div.languageOption {
    font-size: 14px;
    padding: 8px 12px;
    color: var(--text-color);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;

    &[data-selected="true"] {
      color: var(--gradient-left-color);
      font-weight: bold;

      &:after {
        content: "✔";
        color: white;
        margin-left: auto;
        font-weight: bold;
      }
    }

    &:hover {
      color: var(--gradient-left-color);
    }
  }
}

.iq-meta-menu {
  gap: 1.5625rem;

  li {
    a {
      color: var(--bs-white);
      text-transform: capitalize;
      font-size: 0.875rem;
      font-weight: 600;
      letter-spacing: 0.0625rem;

      :hover {
        color: var(--bs-primary);
      }
    }
  }
}

.search-box-2 {
  position: relative;

  input {
    height: auto;
    background-color: var(--bs-body-bg);
    padding: 0.5rem 1rem 0.5rem 2.5rem;
  }

  .search-btn {
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: 48%;
    left: 1rem;
    transform: translateY(-50%);
  }
}

/*==========
sidebar cart
===============*/
.sidebar-cart {
  .cart-items-list {
    height: 29rem;
    overflow-y: auto;
  }

  .offcanvas-body {
    background-color: var(--bs-gray-900);
  }
}

/*====================
Slide Down Animation
=========================*/
@-webkit-keyframes header-slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes header-slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@media (max-width: 1800px) {
  header {
    .mega-menu-content {
      .iq-nav-menu {
        li {
          ul.mega-menu-item {
            width: 1190px;
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  header {
    .mega-menu-content {
      .iq-nav-menu {
        li {
          ul.mega-menu-item {
            width: 1024px;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  header {
    .mega-menu-content {
      .iq-nav-menu {
        li {
          ul.mega-menu-item {
            width: 980px;
          }
        }
      }
    }

    &.header-center-home {
      .mega-menu-content {
        .iq-nav-menu {
          li {
            ul.mega-menu-item {
              transform: translateX(-25%);
            }
          }
        }
      }
    }

    .navbar {
      .iq-nav-menu.navbar-nav {
        li {
          ul.sub-nav.mega-menu-item {
            max-width: 980px;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  header {
    .mega-menu-content {
      .iq-nav-menu {
        li {
          ul.mega-menu-item {
            width: auto;

            li {
              width: 100%;
              display: inline-block;
            }
          }
        }
      }
    }

    .navbar {
      .iq-nav-menu.navbar-nav {
        li {
          ul.sub-nav.mega-menu-item {
            max-width: inherit;
            box-shadow: none;
            padding: 0;
            max-width: 100%;
            margin-left: 0;
          }
        }
      }
    }

    &.header-center-home {
      .mega-menu-content {
        .iq-nav-menu {
          li {
            ul.mega-menu-item {
              left: 0;
              transform: none;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  header .mega-menu-content .iq-nav-menu li ul.mega-menu-item li .nav-link span {
    padding-bottom: 0;
  }

  header {
    .navbar {
      .iq-nav-menu.navbar-nav {
        li {
          ul.sub-nav {
            &.mega-menu-item {
              max-width: 1360px;

              li {
                a.nav-link {
                  &::after {
                    display: none;
                  }

                  img {
                    box-shadow: 0px 0px 87.3px 2.7px rgba(var(--bs-black-rgb), 0.06);
                    transition: all 0.3s ease-in-out;
                  }

                  text-align: center;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }

    ul.sub-nav.mega-menu-item {
      overflow: auto;
    }

    &.header-center {
      .iq-nav-menu li>ul.mega-menu-item {
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
      }

      .iq-nav-menu>li:first-child {
        position: static;
      }
    }
  }
}

.iq-search-bar {
  &.dropdown-menu {
    border: none;
    min-width: 25em;
    left: auto;
    right: 0;
  }

  .search-input {
    height: 4em;
    padding: 0 1em;
    border: none;
    border-radius: 0;
    color: var(--bs-white);
  }

  .input-group-text {
    padding: 0;
    background-color: transparent;
  }

  .search-submit {
    color: var(--bs-white);
    cursor: pointer;
    background: var(--bs-primary);
    border: none;
    outline: none;
    box-shadow: none;
    height: 3em;
    width: 3em;
    position: absolute;
    background: transparent;
    top: 50%;
    transform: translateY(-50%);
  }
}

.iq-responsive-menu {
  padding: 1.0625rem 0;
}

div.inputContainer-header {
  position: relative;
  width: 10%;
  margin-right: 20px;
  margin-top: 5px;

  // @media all and (max-width:768px){
  //   display: none;
  // }
  input.headerInput {
    border: none;
    outline: none;
    width: 100%;
    margin-right: auto;
    background-color: transparent;
    font-size: 13px;
    color: var(--grey-color);
    caret-color: var(--grey-color);
    display: none;

    // @media all and (max-width: 1200px) {
    //   display: none;
    // }
    &::placeholder {
      color: #cac9c8;
      font-size: 13px;
      transition: 0.3s ease-in-out;
    }
  }

  &.showInput {
    background-color: var(--black-color);
    padding: 8px 6px;
    display: flex;
    align-items: center;
    width: 70%;
    justify-content: space-between;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    border: 1px solid var(--white-color);
    margin-top: 0;

    input {
      display: block;
      width: 200px;
    }

    // @media all and (max-width:768px){
    //   display: none;
    // }
  }

  @media all and (max-width: 1200px) {
    &.showInput {
      margin-top: 10px;
      width: 70%;

      input {
        display: block;
      }
    }
  }

  @media all and (max-width: 980px) {
    width: 12%;
    justify-content: center;
    padding: 10px 8px;

    &.showInput {
      width: 100%;
      justify-content: space-between;
    }
  }

  // @media all and (max-width: 768px) {
  //   display: none;
  // }
  &:hover {
    border-color: #c5c5c8;
  }

  svg {
    width: 20px !important;
    height: 29px !important;
    color: var(--white-color) !important;
    margin-right: 6px;
    cursor: pointer;
    // @media all and (max-width: 1200px) {
    //   margin-right: 0;
    // }
  }

  div.searchSuggestionsContainer {
    position: absolute;
    top: 47px;
    z-index: 9;
    background-color: var(--transparent-color);

    padding: 15px;
    width: 100%;
    left: 0;
    cursor: default;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    @media all and (max-width: 768px) {
      display: none;
    }

    h6.heading {
      font-size: 12px;
      color: var(--white-color);
      margin-bottom: 10px;

      span.count {
        font-weight: normal;
      }
    }

    ul.lists {
      li.list {
        display: flex;
        margin-bottom: 3px;
        cursor: pointer;

        div.left {
          margin-right: 10px;

          div.imageContainer {
            width: 50px;
            height: 50px;

            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
            }
          }
        }

        div.right {
          h1.title {
            font-size: 14px;
            color: var(--white-color);

          }

          span.director {
            font-size: 12px;
            color: var(--grey-color);
          }
        }
      }
    }

    div.seeAll {
      text-align: center;
      border-top: 1px solid #eaeaea;
      padding-top: 10px;
      margin-top: 20px;
      cursor: pointer;

      button {
        background-color: transparent;
        font-size: 14px;
        color: var(--white-color);
        font-weight: 700;
        cursor: pointer;
      }
    }
  }

  div.closeIcon {
    position: absolute;
    right: 5px;

    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }

  div.searchLoading {
    position: absolute;
    right: 30px;
  }
}

ul.max-height-scroll {
  max-height: 550px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}