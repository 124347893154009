div.currentPasswordModal{
    div.overlayModal{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 999999;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    div.currentPasswordContainer{
        z-index: 9999999;
        text-align: center;
        position: fixed;
        left: 50%;
        // right: 0;
        top: 50%;
        // bottom: 0;
        transform: translate(-50%,-50%);
        background-color: var(--bg-color);
        width: 40%;
        margin: 0 auto;
        padding: 60px 60px 60px;
        display: flex;
        flex-direction: column;
        @media all and (max-width:1200px){
            padding: 30px;
        }
        @media all and (max-width:768px){
            width: 60%;
        }
        @media all and (max-width:480px){
            width: 90%;
        }
        label{
            font-size: 16px;
        margin-bottom: 20px;
        color: var(--heading-color);
        text-transform: uppercase;
        font-weight: 700;


        }
        button.bg-button{
            margin: 20px auto 0;
            width: 30%;
        }
        input{
            outline: none;
            font-size: 14px;
            background: var((--black-color));
            text-align: left;
          padding: 18px 15px;
          border: 1px solid var(--text-color);
          caret-color: var(--text-color);
          display: block;
          width: 100%;
          color: var(--text-color);
          &:focus{
              border: 1px solid var(--white-color);
          }
          @media all and (max-width:640px){
              padding: 16px 15px;
          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

/* Firefox */
        input[type=number] {
        -moz-appearance: textfield;
        }
        div.buttonContainer {
            margin-top: 20px;
            button {
              background-color: var(--button-color);
              border-radius: 3px;
              padding: 10px 12px;
              color: var(--white-color);
              font-size: 13px;
              cursor: pointer;
              width: 10rem;
            }
          }
    }
}