.iq-accordian {
    .iq-accordian-block {
        .iq-accordian-title {
            padding: 1.875rem 1.875rem 1.875rem 4.688rem;

            .iq-icon-right {
                left:1.875rem;
                right:auto;
            }
        }
    }
}