div.openAppContainer{
    padding: 15px;
    position: fixed;
    bottom: 10px;
    width: 100%;
    background-color: var(--button-color);
    z-index: 99999999999999999;
    border-radius: 10px;
    width: 98%;
    margin: 0 auto;
    transform: translateX(-50%);
    left: 50%;
    h1{
        font-size: 14px;
        color: #fff;
        margin-bottom: 12px;
    }
    p{
        font-size: 12px;
        color: #fff;
        margin-bottom: 10px;
    }
    div.buttons{
        display: flex;
        justify-content: space-between;
        button.notNow{
            border: 1px solid #fff;
            background: transparent;
            color: #fff;
            padding: 6px 12px;
            border-radius: 30px;
            font-weight: 700;
            font-size: 12px;
        }
        button.openApp{
            border: 1px solid #fff;
            background: #fff;
            color: #000;
            padding: 6px 12px;
            border-radius: 30px;
            font-weight: 700;
            font-size: 12px;
        }
    }
}