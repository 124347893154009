// Swiper Pagination
.swiper-button {
	width: 1.875rem;
	height: 1.875rem;
	text-align: center;
	line-height: 1.875rem;
	color: var(--bs-white);
	background: transparent;
	border-radius: 50%;
	border: 0.0625em solid var(--bs-white);
	z-index: 1;
	transition: all .3s ease-in-out;
	font-size: 1em;

	i {
		vertical-align: middle;
	}

	&::after {
		font-size: .625rem;
		color: var(--bs-white);
	}

	&.swiper-button-prev {
		left: 1em;
	}

	&.swiper-button-next {
		right: 1em;
	}

	&:hover {
		background: var(--bs-primary);
		border: 0.0625em solid var(--bs-primary);
	}
}

.swiper-button-next,
.swiper-button-prev {
	top: 50%;
}

.swiper {
	margin-bottom: 5.8rem;
}

.card-style-slider {
	.swiper {
		overflow: visible;
		margin-bottom: 4rem;
	}
}

.joint-arrows {
	position: absolute;
	top: auto;
	bottom: 5rem;
	left: 5.25em;
	z-index: 9;
	width: 7rem;
}

@media (max-width: 1024px) {
	.swiper {
		margin-bottom: 20px;
		}
}

@media (max-width: 1367px) {
	.joint-arrows {
		left: 1rem;
	}
}

@media (max-width: 767px) {
	.swiper {
		margin-bottom: 30px;
	}

	.joint-arrows {
		bottom: auto;
		top: 2rem;
		left: auto;
		right: 0;
	}

	.swiper-button {
		width: 1.5rem;
		height: 1.5rem;
		line-height: 1.5rem;

		&.swiper-button-next {
			right: .4687rem;
		}

		&.swiper-button-prev {
			left: .4687rem;
		}

		&::after {
			font-size: .6875rem;
		}
	}
}