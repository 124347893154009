div.livePlayerContainer {
    background-color: var(--bg-color);
    position: relative;
    div.topplayerCenter{
      display: flex;
      justify-content: space-between;
      position: relative;
      height: 97vh;
      @media all and (max-width:480px){
        min-height: 508px;
      }
      @media all and (max-width:360px){
        height: 300px;
        margin-top: 15%;
      }
      div.wrapper{
        display: flex;
        justify-content: center;
        padding-top: 5%;    
      }
      div.bgImage{
        padding: 0px 0;
      width: 100%;
      background-size: 100%;
      background-repeat: no-repeat;
      height: 100%;
      max-height: 800px;
      position: absolute;
      z-index: 3;
      top: 0;
     
      }
      div.gradient {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        z-index: 4;
        height: 500px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 94.15%);
      
      }
      div.videoContainer {
        z-index: 9;
        width: 55%;
        top: 90px;
        height: 80%;
        @media all and (max-width:980px){
         width: 100%;
        }
        @media all and (max-width:360px){
          width: 100%;
          height: 52%;
          margin-top: 4%;
        }
        div.video-js{
          border-radius: 15px;
          height: 408px;
          @media all and (max-width:980px){
            height: 300px;
          }  
          @media all and (max-width:480px){
            height: 200px;
          }  
        }
        video {
          border-radius: 15px;
        }
      }
      div.bannerContainer {
        position: relative;
        width: 100%;
        margin-top: 1%;
        margin-left: 3.5%;
        
        div.info {
            display: flex;
          color: var(--text-color);
          position: relative;
          z-index: 9;
         
            div.channelLogo{
                width: 100px;
                height: 100px;
                margin-bottom: 10px;
                img{
                  display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
              }
          
          div.infoContainer {
            padding: 1%;
            width: 100%;
            div.liveLabel {
                background-color: #f52d2d;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 3px 3px;
                width: 3.5rem;
                border-radius: 3px;
                margin-bottom: 10px;
                span {
                  font-weight: 600;
                  font-size: 11px;
                }
                svg {
                  width: 16px !important;
                  height: 16px !important;
                  margin-right: 6px;
                }
              }
             
              h1.title {
                font-size: 30px;
                margin-bottom: 15px;
                @media all and (max-width:480px){
                  font-size: 26px;
                }
                @media all and (max-width:360px){
                  font-size: 22px;
                }

              
              }
              div.additionalInfo {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                h6.timeLeft {
                  color: var(--white-color);
                  font-size: 15px;
                  margin-right: 15px;
                }
                span.caption {
                  background-color: var(--white-color);
                  color: var(--black-color);
                  font-size: 10px;
                  padding: 2px 4px;
                  font-weight: 700;
                  border-radius: 3px;
                  outline: none;
                  border: none;
                  margin-right: 15px;
                }
                span.rating {
                  background-color: hsla(226, 7%, 61%, 0.32);
                  border-radius: 4px;
                  color: var(--white-color);
                  color: var(--text-color);
                  font-size: 12px;
                  font-weight: 700;
                  padding: 1px 8px;
                }
              }
              p.description {
                max-width: 100%;
                font-size: 15px;
                span.readMore,span.readLess{
                  color: var(--button-color);
                  margin-left: 6px;
                  cursor: pointer;
                  &:hover{
                    text-decoration: underline;
                  }
                }
              }
          }
         
        }
      }
    
    }
    div.wrapper {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 1%;
      div.categories {
        margin-bottom: 30px;
        overflow: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; 
        scrollbar-width: none;
        div.buttons{
          width: 1200px;
          button {
            &.active {
              background-color: var(--white-color);
              color: var(--black-color);
            }
            background-color: var(--black-color);
    
            padding: 5px 10px;
            color: var(--white-color);
            margin-right: 10px;
            font-weight: 600;
            font-size: 13px;
            border-radius: 30px;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
  
      div.scheduleTime {
        width: 90%;
        margin: 0 0 6px auto;
        display: flex;
        align-items: center;
        position: relative;
  
        div.time {
          border-left: 2px solid var(--border-color);
          padding-left: 10px;
          width: 100%;
          span {
            color: var(--inner-text-color);
            font-size: 10px;
            font-weight: 700;
          }
        }
      }
  
      div.scheduleContainer {
        position: relative;
        div.rightGradient {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 10rem;
          z-index: 9;
          background: linear-gradient(95deg, rgba(0, 0, 0, -26.99), #000 101.15%);
        }
        div.bottomGradient {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 10rem;
          z-index: 9;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 62.15%);
        }
        div.channel {
          display: flex;
          align-items: center;
          width: 3000px;
          height: 75px;
          margin-bottom: 4px;
          &.showTimeline {
            div.right {
              div.scheduledItem {
                &.active {
                  div.progress {
                    div.currentTime {
                      position: absolute;
                      top: -26px;
                      right: 0;
                      background-color: #f52d2d;
                      display: flex !important;
                      align-items: center;
                      justify-content: center;
                      padding: 3px 3px;
                      width: 5rem;
                      border-radius: 3px;
                      color: var(--white-color);
                      span {
                        font-weight: 600;
                        font-size: 11px;
                      }
                      div.icon {
                        line-height: 0;
                        svg {
                          width: 16px !important;
                          height: 16px !important;
                          margin-right: 6px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          div.left {
            width: 5%;
            margin-right: 4px;
            height: 100%;
            div.currentPlayingCard {
              width: 100%;
              height: 100%;
  
              div.imageContainer {
                width: 100%;
                height: 100%;
  
                img {
                  display: block;
                  width: 100%;
                  border-radius: 4px;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
  
          div.right {
            display: flex;
            align-items: center;
            width: 95%;
            height: 100%;
            position: relative;
  
            div.scheduledItem {
              background-color: rgb(25, 25, 25);
              padding: 25px 20px;
              border-radius: 4px;
              margin-right: 4px;
              height: 100%;
              // display: flex;
              align-items: center;
              position: relative;
              cursor: pointer;
              &.active {
                border: 2px solid var(--text-hover);
                div.progress {
                  display: block;
                  position: absolute;
                
                  background: linear-gradient(
                    95deg,
                    rgba(25, 25, 25, -26.99),
                    #363434 101.15%
                  );
  
                  width: 10%;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  z-index: 9;
                  div.currentTime {
                    display: none;
                  }
                }
              }
              &:hover {
                div.metaData {
                  display: flex;
                }
              }
              div.progress {
                display: none;
              }
  
              h1 {
                font-size: 16px;
                color: var(--white-color);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                position: relative;
                z-index: 9;
              }
              div.metaData {
                display: none;
                justify-content: space-between;
                p.description {
                  position: absolute;
                  z-index: 99;
                  color: var(--inner-text-color);
                  font-size: 14px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 90%;
                }
                div.infoIcon {
                  text-align: right;
                  position: absolute;
                  z-index: 9999;
                  right: 10px;
                  top: 10px;
                  svg{
                    color: var(--inner-text-color);
                    width: 20px !important;
                    height: 20px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
