div.categoriesShowCard{
    position: relative;
    height: 650px;
    margin-top: 30px;
    div.backgroundImageContainer{
        width: 100%;
        display: block;
        height: 100%;
        img.bgImage{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        div.metaInfo{
            position: absolute;
            top: 30px;
            left: 130px;
            z-index: 9;
            @media all and (max-width: 1200px) {
                left: 77px;
              }
              @media all and (max-width: 768px) {
                left: 57px;
              }
              @media all and (max-width: 640px) {
                left: 30px;
              }

            h1{
                color: var(--white-color);
                text-transform: uppercase;
                font-weight: 700;
                font-size: 40px;
                cursor: pointer;
                &::first-line{
                    color: rgb(255, 234, 0);
                }
            }
            p.description{
                font-size: 14px;
                color: var(--white-color);
            }
        }

        div.AllShows{
            position: absolute;
            bottom: 30px;
            width: 90%;
            // margin-left: auto;
            right: 0;
            z-index: 9;
            @media all and (max-width: 640px) {
               width: 84% !important;
               right: 38px ;
              }
            div.shows{
                width: 100%;
            }
            .swiper-button-prev {
                background: transparent !important;
                left: -130px !important;
            }
            .swiper-button-prev.swiper-button-disabled {
               display: none;
               }
               @media all and (max-width:1200px){
                width: 98%;
                .swiper-button-next,.swiper-button-prev{
                    display: none;
                }
            }
        }
    }
    div.bottomGradient {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
        height: 100%;
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
      }
}