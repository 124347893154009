:root {
  --prefix: #{$variable-prefix};
  --#{$variable-prefix}heading-font-family: #{$headings-font-family};

  @media (max-width: 767px) {
    --bs-heading-1: 3.809rem;
    --bs-heading-2: 2.5rem;
    --bs-heading-3: 1.875rem;
    --bs-heading-4: 1.25rem;
  }
}

.container{
  max-width: 1400px;
}