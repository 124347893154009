.iq-banner-thumb-slider {
  .slider {
    .slider-bg {
      height: 100vh;

      .swiper-container {
        width: 100%;
        height: 100%;
        overflow: hidden;

        .swiper-wrapper {
          margin: 0;
        }
      }

      .slider-images {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;

        .slider--image {
          height: 100%;
          overflow: hidden;
          background: linear-gradient(90.3deg, #181616 9.88%, rgba(19, 18, 18, 0) 31.52%), linear-gradient(266.54deg, #181616 13.29%, rgba(19, 18, 18, 0) 98.41%);

          img {
            transition: 3s;
            mix-blend-mode: overlay;
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
          }
        }

        .description {
          position: absolute;
          top: 50%;
          left: 6.25rem;
          z-index: 99;
          transform: translateY(-50%);
          width: 100%;

          >.row {
            padding-top: var(--header-height);
          }

          p {
            margin-top: 0.625rem;
            margin-bottom: 2rem;
            width: 37.5em;
            max-width: 100%;
          }

          .slider-content {
            a {
              // color: rgba(var(--bs-body-color-rgb));
              color: #fff;
              transition: all 0.5s ease-in-out;

              &:hover {
                color: var(--bs-primary);
              }
            }
          }

          .iq-button {
            margin-top: 2.8125rem;
          }
        }
      }

      .horizontal_thumb_slider {
        position: relative;
        margin-top: var(--header-height);
        z-index: 99;
        width: 38em;
        height: 100%;
        background: linear-gradient(-270deg,
            #141414 0%,
            rgba(20, 20, 20, 0) 100%);
        border-radius: 3px;
        padding: 2.1875rem 0 2.1875rem 2.1875rem;
        right: 0;

        .swiper-wrapper {
          .swiper-slide {
            padding: 0;
          }
        }

        .block-images {
          .img-box {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 3px;
            background: linear-gradient(31deg, #341214 -1.66%, rgba(0, 0, 0, 0) 62.6%);

            img {
              height: 20.625rem;
              mix-blend-mode: overlay;
              object-fit: cover;
              width: 100%;
              border-radius: 3px;
              mix-blend-mode: overlay;
            }

            .block-description {
              position: absolute;
              bottom: 1rem;
              top: auto;
              right: auto;
              left: 1rem;

              .fs-12 {
                display: inline-block;
                direction: ltr;
                font-size: 0.75rem;
              }
            }
          }
        }

        .banner-thumb-slider-nav {
          position: relative;
          padding-bottom: 3.4375rem;

          .swiper-button {
            i {
              font-size: 0.75rem;
            }
          }

          .slider-prev {
            position: absolute;
            z-index: 10;
            left: 0;
            bottom: 0;
          }

          .slider-next {
            position: absolute;
            z-index: 10;
            left: 2.8125em;
            bottom: 0;
          }
        }
      }
    }
  }
}

.big-font {
  font-size: 4.375rem;
  font-weight: $font-weight-bold;
}

@media (max-width: 1399.98px) {
  .iq-banner-thumb-slider {
    .slider {
      .slider-bg {
        .horizontal_thumb_slider {
          width: 30em;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .iq-banner-thumb-slider {
    .slider {
      .slider-bg {
        .slider-images {
          .description {
            left: 4rem;
          }
        }

        .horizontal_thumb_slider {
          width: 27em;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .horizontal_thumb_slider {
    display: none;
  }
}

@media (max-width: 767px) {
  .iq-banner-thumb-slider {
    .slider {
      .slider-bg {
        height: 32.125rem;

        .slider-images {
          .description {
            left: 0;
            padding: 0 1rem;
          }
        }
      }
    }
  }
}
@media (max-width: 980px) {
  .iq-banner-thumb-slider {
    .slider {
      .slider-bg {
        height: 42.125rem;
      }
    }
  }
}

// movie tage design
.movie-tag {
  li {
    padding-right: 1.725em;
    font-weight: $font-weight-semibold;
    position: relative;
    white-space: nowrap;

    &::before {
      content: "";
      height: 0.375em;
      width: 0.375em;
      background: var(--bs-primary);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.5625em;
      border-radius: 50%;
    }

    &:last-child {
      padding-right: 0;

      &::before {
        content: none;
      }
    }
  }
}

// Animation
.swiper-slide-active .RightAnimate {
  -webkit-animation: RightAnimate 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s both;
  animation: RightAnimate 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s both;
}

.swiper-slide-active .RightAnimate-two {
  -webkit-animation: RightAnimate 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.4s both;
  animation: RightAnimate 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.4s both;
}

.swiper-slide-active .RightAnimate-three {
  -webkit-animation: RightAnimate 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.6s both;
  animation: RightAnimate 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.6s both;
}

.swiper-slide-active .RightAnimate-four {
  -webkit-animation: RightAnimate 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s both;
  animation: RightAnimate 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s both;
}

@-webkit-keyframes RightAnimate {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes RightAnimate {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}