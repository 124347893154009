/* Demo */
// body {
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: linear-gradient(#ecf0f1, #fff);
// }

/* Animation */

div.loadingContent {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  // flex-direction: column;
  div.loadingOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--bg-color);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div.loading-container {
    width: 256px;
    height: 256px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loader {
    position: relative;
    width: 54px;
    height: 54px;
    display: block;
  }
  
  .loader:before , .loader:after{
    content: '';
    border-radius: 50%;
    position: absolute;
    inset: 0;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2) inset;
  }
  .loader:after {
    box-shadow: 0 2px 0 var(--button-color) inset;
    animation: rotate 0.9s linear infinite;
  }
  
  @keyframes rotate {
    0% {  transform: rotate(0)}
    100% { transform: rotate(360deg)}
  }
}
