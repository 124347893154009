div.billingAndSubscription {
    padding: 80px 0;
    background-color: var(--bg-color);

    @media all and (max-width:1200px) {
        width: 100%;
    }

    h1.heading {
        font-size: 24px;
        color: var(--heading-color);
        margin-bottom: 30px;
    }

    div.billingDetails {
        width: 80%;
        margin: 0 auto;
        margin-bottom: 30px;

        @media all and (max-width:1200px) {
            width: 90%;
        }

        h6.subHeading {
            font-size: 14px;
            color: var(--white-color);
            font-weight: normal;
            letter-spacing: .3px;
            margin-bottom: 20px;
        }

        div.billingDetailsContainer {
            border: 3px solid var(--border-color);
            padding: 20px;
            display: flex;
            justify-content: space-between;

            span.head {
                display: block;
                color: var(--white-color);
                margin-bottom: 10px;
            }

            span.value {
                display: block;
                color: var(--white-color);
                font-size: 14px;

                &.bold {
                    font-weight: 600;
                    margin-bottom: 20px;
                }
            }

            .active-card-details {
                @media all and (max-width:500px) {
                    .left {
                        margin-top: 20px;
                    }
                }

                .right {
                    background-color: #fff;
                    text-align: center;
                    margin-top: 10px;
                    padding: 10px;
                    border-radius: 2px;
                    cursor: pointer;
                    // border: 1px solid #fff;
                    transition: background-color 0.2s ease-in-out;

                    &:hover {
                        background-color: #016cdf;
                        color: #fff;

                    }

                    @media all and (max-width:500px) {
                        margin: 15px;
                    }


                }
            }
        }
    }

    div.subscriptionDetails {


        width: 80%;
        margin: 0 auto;

        @media all and (max-width:1200px) {
            width: 90%;
        }

        ul.subscriptionContainer {

            li.head {
                display: flex;
                color: var(--heading-color);
                font-size: 14px;

                @media all and (max-width:768px) {
                    display: none;
                }

            }

            li {
                border-bottom: 1px solid #c8c8c8;
                display: flex;
                padding: 6px;
                color: var(--white-color);
                font-size: 14px;


                div.left {
                    display: flex;
                    width: 80%;

                    @media all and (max-width:1200px) {
                        width: 100%;
                    }

                    @media all and (max-width:768px) {
                        flex-direction: column;
                    }

                    div.date {
                        width: 17%;

                        @media all and (max-width:768px) {
                            width: 100%;
                            margin-bottom: 10px;
                        }
                    }

                    div.transactionType {
                        width: 25%;

                        @media all and (max-width:768px) {
                            width: 100%;
                        }

                    }

                    div.servicePeriod {
                        width: 25%;

                        @media all and (max-width:768px) {
                            width: 100%;
                            margin-bottom: 10px;
                        }
                    }

                    div.paymentMethod {
                        width: 25%;

                        @media all and (max-width:768px) {
                            width: 100%;
                            margin-bottom: 10px;
                        }
                    }

                    div.subscriptionName {
                        width: 25%;

                        @media all and (max-width:980px) {
                            width: 30%;
                        }

                        @media all and (max-width:768px) {
                            width: 100%;
                        }

                    }
                }

                div.right {
                    width: 20%;

                    @media all and (max-width:980px) {
                        width: 8%;
                    }

                    text-align: right;

                    div.total {}
                }
            }

            li.body {

                div.left {

                    div.date {}

                    div.transactionType {}

                    div.servicePeriod {}

                    div.paymentMethod {}

                    div.subscriptionName {}
                }

                div.right {

                    div.total {}
                }
            }
        }
    }

    div.bottom {
        width: 80%;
        margin: 30px auto 0;

        @media all and (max-width:1200px) {
            width: 90%;
        }

        p {
            color: var(--white-color);
            font-size: 12px;
        }

        div.buttonContainer {
            margin-top: 20px;
            text-align: center;

            button {
                background-color: var(--button-hover);
                padding: 8px 12px;
                color: var(--white-color);
                font-size: 13px;
                cursor: pointer;
                width: 20rem;

                @media all and (max-width:768px) {
                    width: 100%;
                }
            }
        }
    }
}