div.smallLoading{

    div.loading-container {
        // width: 256px;
        // height: 256px;
        position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%,-50%);
        z-index: 99999999;
    
      }
     
      .nf-loading-spinner {
        animation: load_spinner 0.9s linear infinite;
        background-image: url(https://assets.nflxext.com/en_us/pages/wiplayer/site-spinner.png);
        background-repeat: no-repeat;
        background-size: 100%;
        bottom: 1px;
        left: 1px;
        margin: auto;
        position: absolute;
        right: 1px;
        text-align: center;
        top: 1px;
        width: 20px;
        height: 20px;
      }
      @keyframes load_spinner {
        0% {
          transform: rotate(0deg) /*!rtl:ignore*/;
        }
        50% {
          transform: rotate(180deg) /*!rtl:ignore*/;
        }
        100% {
          transform: rotate(1turn) /*!rtl:ignore*/;
        }
      }
      
}