.pricing-plan-wrapper {
    .pricing-details {
        .pricing-plan-description {
            ul {
                li {
                    padding-left: 0;
                    padding-right: 1.625rem;
                    i {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
} 