:focus {
    outline: none;
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--#{$variable-prefix}heading-font-family);
}

h1 {
    @include font-size(var(--bs-heading-1, $h1-font-size));
}

h2 {
    @include font-size(var(--bs-heading-2, $h2-font-size));
}

h3 {
    @include font-size(var(--bs-heading-3, $h3-font-size));
}

h4 {
    @include font-size(var(--bs-heading-4, $h4-font-size));
}

h5 {
    @include font-size(var(--bs-heading-5, $h5-font-size));
}

h6 {
    @include font-size(var(--bs-heading-6, $h6-font-size));
}


a {
    transition: color 400ms ease, background 400ms ease;
    color: var(--#{$variable-prefix}primary);
    text-decoration: none;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit;
} 

img {
    max-width: 100%;
}

.iq-scroller-effect {
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border-radius: 20px;
    }

    /* Scroll Track */
    ::-webkit-scrollbar-track {
        background: $body-bg;
        border-radius: 20px;
    }

    /* Scroll Handle */
    ::-webkit-scrollbar-thumb {
        background: $border-color;
        border-radius: 20px;
        transition: all 400ms ease;
        cursor: pointer;
    }

    /* Scroll Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: tint-color($border-color, 10%);
        border-radius: 20px;
        transition: all 400ms ease
    }
}

@media (max-width:767px) {
    h5 {
        font-size: 1rem;
    }
}