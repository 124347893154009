div.emailForm{
    div.MuiPaper-root{
        background-color: var(--bg-color);
        padding: 30px;
        width: 33% !important;
        @media all and (max-width:1400px){
            width: 38% !important;
        }
        @media all and (max-width:1200px){
            width: 45% !important;
        }
        @media all and (max-width:980px){
            width: 55% !important;
        }
        @media all and (max-width:768px){
            width: 65% !important;
        }
        @media all and (max-width:640px){
            width: 85% !important;
        }
        @media all and (max-width:480px ){
            width: 100% !important;
            padding: 10px 0;
            margin: 20px !important;
        }
        h1{
            width: 110px ;
            margin: 0 auto 10px;
            img{
                display:block;
                width: 100%;
            }
        }
        h2{
            text-align: center;
            color: var(--white-color);
        }
        form{
            label{
                display: block;
                color: var(--white-color);
                font-size: 14px;
                margin-bottom: 6px;
            }
            input{
                display: block;
                padding: 12px 20px;
                outline: none;
                border: none;
                border-radius: 5px;
                width: 100%;
                margin-bottom: 10px;
            }
                div.buttonContainer { 
                    display: flex;
                padding: 15px 20px;
                border-radius: 4px;
                align-items: center;
                margin-bottom: 15px;
                cursor: pointer;
                transition: 0.2s ease-in-out;
                justify-content: center;
                position: relative;
                @media all and (max-width:480px ){
                    padding: 10px 1px;
                 }
                &:hover{
                    div.background{

                        transform: scale(1.0476190476,1.0357142857);
                    }
                }
                    div.background{
                        background: var(--button-color);
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: 4px;
                        transition: 0.1s ease-in-out;

                    }
					button { 
                        background: transparent;
                        font-size: 15px;
                        color: var( --text-color);
                        font-weight: 600;
                        z-index: 9;
                        cursor: pointer;

					}
				}
            

        }
    }
}
div.verifyEmailModal{
    div.MuiPaper-root{
        background-color: var(--bg-color);
        padding: 20px;
        width: 33%;
        @media all and (max-width:1400px){
            width: 38%;
        }
        @media all and (max-width:1200px){
            width: 45%;
        }
        @media all and (max-width:980px){
            width: 55%;
        }
        @media all and (max-width:768px){
            width: 65%;
        }
        @media all and (max-width:640px){
            width: 85%;
        }
        @media all and (max-width:480px ){
            width: 95%;
            padding: 0;
            margin: 20px !important;
        }
        div.top{
            text-align: center;
            svg{
                width: 70px !important;
                height: 70px !important;
                @media all and (max-width:480px ){
                    width: 50px !important;
                    height: 50px !important;
                }
            }
            
        }
        div.bottom{
            color: var(--text-color);
            text-align: center;
            div.heading{
                    h1{
                        font-size: 22px;
                        margin-bottom: 10px;
                        @media all and (max-width:480px ){
                           font-size: 18px;
                        }
                    }
            }
            p{
                font-size: 15px;
                @media all and (max-width:480px ){
                    font-size: 14px;
                 }
                span{
                    color: rgb(100, 180, 241);
                    @media all and (max-width:480px ){
                        font-size: 14px;
                     }
                }
            }
        }
    }
}
div.loginRequestModal{
    text-align: center;
    div.MuiPaper-root{
        background-color: var(--bg-color);
        padding: 30px;
        width: 33%;
        @media all and (max-width:1400px){
            width: 38%;
        }
        @media all and (max-width:1200px){
            width: 45%;
        }
        @media all and (max-width:980px){
            width: 55%;
        }
        @media all and (max-width:768px){
            width: 65%;
        }
        @media all and (max-width:640px){
            width: 85%;
        }
        @media all and (max-width:480px ){
            width: 95%;
            padding: 0;
            margin: 20px !important;
        }
        span.MuiSkeleton-root{
            background-color: rgba(255, 255, 255, 0.11) !important;
            &::after{
                background: linear-gradient( 90deg, transparent, rgba(0, 0, 0, 0.24), transparent ) !important;
            }
        }
    }
    h4{
        font-size: 17px;
        color: var(--white-color);
        margin-bottom: 8px;
    }
    div.requestDetails{
        background-color: var(--secondary-bg-color);
        padding: 15px 70px 15px 10px;
        border-radius:4px;
        margin-bottom: 20px;
        @media all and (max-width:480px ){
            padding: 15px 13px 15px 10px;
        }
        div.oneRow{
            display: flex;
            justify-content: space-between;
            span{
                color: var(--text-color);
                margin-bottom: 8px;
                font-size: 15px;
                &.label{
                font-style: italic;
                }
            }
            
        }
    }
    p{
        color: var(--text-color);
        margin-bottom: 10px;
        font-size: 14px;
    }
    div.buttonContainer { 
        display: flex;
    padding: 15px 20px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    justify-content: center;
    position: relative;
    @media all and (max-width:480px ){
        padding: 10px 1px;
     }
    &:hover{
        div.background{

            transform: scale(1.0476190476,1.0357142857);
        }
    }
        div.background{
            background: var(--button-color);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 4px;
            transition: 0.1s ease-in-out;

        }
        button { 
            background: transparent;
            font-size: 15px;
            color: var( --white-color);
            font-weight: 600;
            z-index: 9;
            cursor: pointer;

        }
    }
}
div.appLoginModal{
    div.MuiPaper-root{
        background-color: var(--bg-color);
        padding: 20px;
        width: 33% !important;
        @media all and (max-width:1400px){
            width: 38% !important;
        }
        @media all and (max-width:1200px){
            width: 45% !important;
        }
        @media all and (max-width:980px){
            width: 55% !important;
        }
        @media all and (max-width:768px){
            width: 65% !important;
        }
        @media all and (max-width:640px){
            width: 85% !important;
        }
        @media all and (max-width:480px ){
            width: 95% !important;
            padding: 0;
            margin: 20px !important;
        }
        p{
            color: var(--text-color);
            text-align: center;
            font-size: 16px;
            margin-bottom: 10px;
            @media all and (max-width:480px ){
                font-size: 14px;
            }
        }
        div.buttonContainer { 
            display: flex;
        padding: 15px 20px;
        border-radius: 4px;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        justify-content: center;
        position: relative;
        @media all and (max-width:480px ){
           padding: 10px 1px;
        }
        &:hover{
            div.background{
    
                transform: scale(1.0476190476,1.0357142857);
            }
        }
            div.background{
                background: var(--button-color);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 4px;
                transition: 0.1s ease-in-out;
    
            }
            button { 
                background: transparent;
                font-size: 15px;
                color: var( --white-color);
                font-weight: 600;
                z-index: 9;
                cursor: pointer;
    
            }
        }
        span{
            color: var(--white-color);
            text-align: center;
            font-size: 14px;
            display: block;
        }
    }

}