div.LayoutsContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    div.routesContainer {
        flex: 1 0 auto;

    }

    div.resubscriptionInfo {
        display: flex;
        z-index: 9;
        width: 100%;
        height: 45px;
        margin-top: 75px;
        background: #fff;


        .resubscribeMessage {
            width: 85%;
            background: #fff;
            color: red;
            padding: 5px;
            text-align: center;
            font-weight: 600;
            margin: 5px;
        }

        .resubscribeButton {
            width: 15%;
            background-color: #000;
            color: #fff;
            padding: 5px;
            text-align: center;
            cursor: pointer;
            font-weight: 600;
            margin: 2px;
            transition: background-color 0.3s ease, filter 0.3s ease;


            &:hover {
                filter: brightness(2.0);
            }
        }
    }

    @media (min-width: 427px) and (max-width: 769px) {
        div.resubscriptionInfo .resubscribeMessage {
            margin: 5px;
            padding: 5px;
        }
    }

    @media (max-width: 426px) {
        div.resubscriptionInfo {
            flex-direction: column;
            font-size: medium;
            height: 110px;
            padding: 5px;

            .resubscribeMessage {
                width: 100%;
                margin: 0px;
            }

            .resubscribeButton {
                width: 100%;
                margin: 5px 0;
            }

            .resubscribeMessage {
                padding: 5px;
                margin: 0px;
                font-size: small;
            }
        }
    }

    @media (max-width: 321px) {
        div.resubscriptionInfo {
            height: 130px;
        }
    }
}