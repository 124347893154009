.parallax-window {
    height: 100%;
    padding: 6.25em 0;
    position: relative;
    background-size: cover;
    background-attachment: fixed;

    &::after {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(var(--bs-black-rgb), 0.8);
    }

    .parallaxt-details {
        z-index: 9;
        position: relative;
    }
}