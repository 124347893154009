div.detailsScreen {
  padding: 50px 0 0;

  div.topBackgroundContainer {
    position: relative;
    background-repeat: no-repeat;
    width: 100%;
    background-size: 100%;
    background-size: cover;
    padding-bottom: 20px;

    div.contents {
      display: flex;
      justify-content: space-between;
    }

    div.bottomGradient {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 1;
      height: 100%;
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      // background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) -0.54%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) -56.56%, rgba(16, 20, 31, 0.4) 6%);
      background-image: linear-gradient(rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.4) 35%,
          rgba(0, 0, 0, 0.6) 70%,
          rgba(0, 0, 0, 0.8) 100%);
    }

    div.left {
      padding-top: 220px;
      width: 23%;
      z-index: 9;
      padding-left: 5px;

      @media all and (max-width: 2500px) {
        padding-left: 80px;
      }

      @media all and (max-width: 1600px) {
        padding-left: 5px;
      }

      @media all and (max-width: 1400px) {
        padding-left: 30px;
      }

      @media all and (max-width: 1200px) {
        width: 24%;
      }

      @media all and (max-width: 980px) {
        width: 100%;
        padding-top: 280px;
        padding-left: 20px;
        padding-right: 20px;
      }

      h1.title {
        color: var(--white-color);
        font-size: 28px;
        text-transform: uppercase;
        margin-bottom: 16px;
      }

      h2.director {
        color: var(--white-color);
        font-size: 13px;
        font-weight: normal;

        @media all and (max-width: 980px) {
          font-size: 14px;
        }

        span.name {
          font-weight: 700;
          font-size: 14px;

          @media all and (max-width: 980px) {
            font-size: 15px;
          }
        }
      }

      span.country {}

      span.year {
        font-size: 13px;
        color: var(--white-color);
        margin-bottom: 10px;
        display: inline-block;
      }

      div.showMoreInfo {
        display: flex;
        color: var(--text-color);
        align-items: center;
        border-bottom: 1px solid var(--text-color);
        padding-bottom: 10px;
        margin-bottom: 20px;

        div.icon {
          margin-right: 5px;
          line-height: 0;

          svg {
            width: 16px !important;
            height: 16px !important;

            path {
              fill: var(--text-color) !important;
            }
          }
        }

        div.duration {
          display: flex;
          align-items: center;
          margin-right: 10px;

          span {
            font-size: 13px;
            font-weight: 500;

            @media all and (max-width: 980px) {
              font-size: 14px;
            }
          }
        }

        div.language {
          display: flex;
          align-items: center;

          span {
            font-size: 13px;
            font-weight: 500;

            @media all and (max-width: 980px) {
              font-size: 14px;
            }
          }
        }
      }

      div.watchListButton {
        cursor: pointer;

        svg {
          width: 25px !important;
          height: 25px !important;
        }
      }
    }

    div.categoryName {
      background-color: #68abff;
      padding: 10px 6px;
      width: 8rem;
      text-transform: uppercase;
      color: var(--white-color);
      font-weight: 500;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      position: absolute;
      right: 0;
      top: 20px;
      z-index: 9;

      @media all and (max-width: 980px) {
        display: none;
      }
    }

    div.top {
      position: absolute;
      right: 0;
      text-align: right;
      padding-top: 60px;
      z-index: 9;

      @media all and (max-width: 2500px) {
        right: 100px;
      }

      @media all and (max-width: 1600px) {
        right: 5px;
      }

      @media all and (max-width: 1400px) {
        right: 60px;
      }

      @media all and (max-width: 980px) {
        padding-top: 20px;
        right: 5px;
      }

      div.ratings {
        display: flex;
        justify-content: right;
        align-items: center;
        padding-right: 20px;
        margin-top: 10px;

        div.icon {
          margin-right: 5px;

          svg {
            width: 18px !important;
            height: 18px !important;
          }
        }

        h1.rating {
          font-size: 22px;
          color: var(--white-color);

          @media all and (max-width: 980px) {
            font-size: 23px;
          }

          span.outOf {
            color: var(--text-color);
            font-size: 14px;
            font-weight: 700;

            @media all and (max-width: 980px) {
              font-size: 15px;
            }
          }
        }
      }

      div.trailer {
        padding-right: 5px;
        padding-top: 90px;

        @media all and (max-width: 980px) {
          display: none;
        }

        button.trailerBtn {
          background-color: transparent;
          border: 1px solid var(--white-color);
          width: 5rem;
          font-size: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
          padding: 6px;
          color: var(--white-color);
          margin-left: auto;
          cursor: pointer;

          &:hover {
            border-color: var(--button-hover);
            background-color: var(--button-hover);
          }

          div.icon {
            line-height: 0;
            margin-left: 8px;

            playicon {}
          }
        }
      }

      span.totalRatings {
        color: var(--text-color);
        font-size: 13px;
        font-weight: 700;
        padding-right: 3px;

        @media all and (max-width: 980px) {
          font-size: 14px;
        }
      }
    }

    div.right {
      width: 73%;
      padding-top: 270px;
      z-index: 8;
      display: flex;

      @media all and (max-width: 2500px) {
        padding-right: 80px;
      }

      @media all and (max-width: 1600px) {
        padding-right: 5px;
      }

      @media all and (max-width: 1400px) {
        padding-right: 30px;
      }

      @media all and (max-width: 1200px) {
        width: 70%;
      }

      @media all and (max-width: 980px) {
        display: none;
      }

      div.synopsis {
        color: var(--white-color);
        margin-right: 40px;
        width: 50%;

        @media all and (max-width: 980px) {
          margin-right: 0;
          margin-bottom: 20px;
        }

        h1.head {
          font-size: 16px;
        }

        p.synopis {
          font-size: 13px;

          @media all and (max-width: 980px) {
            font-size: 14px;
          }
        }
      }

      div.ourTake {
        color: var(--white-color);
        width: 50%;

        h1.head {
          font-size: 16px;
        }

        p.ourTake {
          font-size: 13px;

          @media all and (max-width: 980px) {
            font-size: 14px;
          }
        }
      }
    }

    div.pageDown {
      z-index: 9;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        bottom: -10px;
      }

      @media all and (max-width: 980px) {
        display: none;
      }

      svg {
        width: 35px !important;
        height: 35px !important;
        transform: rotate(-90deg);
        fill: var(--text-color);
      }
    }

    div.playIconContainer {
      position: absolute;
      width: 5rem;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid var(--white-color);
      transition: 0.3s ease-in-out;
      z-index: 9;
      left: 50%;
      top: 23%;
      transform: translateX(-50%);

      @media all and (max-width: 980px) {
        top: 30%;
      }

      cursor: pointer;

      svg {
        width: 30px !important;
        height: 30px !important;
        color: var(--white-color);
      }

      &:hover {
        background-color: var(--button-hover);
        border-color: var(--button-hover);
      }
    }

    div.subscriptions {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      z-index: 9;
      padding-left: 5px;
      padding-bottom: 30px;

      @media all and (max-width: 2500px) {
        padding-left: 80px;
      }

      @media all and (max-width: 1600px) {
        padding-left: 30px;
      }

      div.items {
        z-index: 9;
        cursor: pointer;
        margin-right: 10px;
        background-color: var(--yellow-color);
        padding: 6px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        font-weight: 600;
        color: var(--white-color);
        margin-bottom: 20px;
        border-radius: 30px;

        @media all and (max-width: 980px) {
          font-size: 12px;
        }

        &.free {
          background-color: var(--success-color);
        }
      }
    }

    div.shareContainer {
      position: absolute;
      right: 5px;
      z-index: 9;
      bottom: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        color: var(--white-color);
        font-size: 14px;
        margin-right: 15px;
        transition: 0.2s ease-in-out;

        &.active {
          transform: translateX(-10px);
        }
      }

      div.icon {
        line-height: 0;

        svg {
          width: 20px !important;
          height: 20px !important;
        }

        .closeIcon {
          stroke: var(--white-color) !important;
          display: block;
          width: 11px !important;
          height: 11px !important;
        }
      }

      div.socialIcons {
        line-height: 0;
        animation: fadeIn 0.9s;

        .facebook {
          margin-right: 15px;

          div.icon {
            svg {
              color: var(--white-color);
            }
          }
        }

        .twitter {
          margin-right: 15px;

          div.icon {
            svg {
              color: var(--white-color);
            }
          }
        }

        .clipBoard {
          margin-right: 15px;
          display: inline;

          svg {
            fill: var(--white-color);
            width: 20px !important;
            height: 20px !important;
          }
        }
      }
    }
  }

  div.responsiveContent {
    padding-bottom: 25px;
    display: none;

    @media all and (max-width: 980px) {
      display: block;
    }

    div.categoryNameSM {
      background-color: rgb(242, 89, 89);
      padding: 12px 16px;
      text-transform: uppercase;
      color: var(--white-color);
      font-weight: 700;
      font-size: 15px;
    }

    div.synopsisSM {
      padding: 25px 15px;
      color: var(--text-color);

      h1.heading {
        font-size: 16px;
        margin-bottom: 8px;
      }

      p.synopsis {
        font-size: 16px;
      }
    }

    div.ourTakeSM {
      padding: 0 15px;
      color: var(--text-color);
      margin-bottom: 20px;

      h1.heading {
        font-size: 16px;
        margin-bottom: 8px;
      }

      p.ourTake {
        font-size: 16px;
      }
    }

    div.trailer {
      padding-right: 15px;

      button.trailerBtn {
        background-color: transparent;
        border: 1px solid var(--text-color);
        width: 5.5rem;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        padding: 7px 10px;
        color: var(--text-color);
        margin-left: auto;
        cursor: pointer;

        &:hover {
          border-color: var(--button-hover);
          background-color: var(--button-hover);
        }

        div.icon {
          line-height: 0;
          margin-left: 8px;

          playicon {}
        }
      }
    }
  }

  div.categories {
    padding: 30px 4px 0;
    background-color: var(--secondary-bg-color);

    h1.heading {
      color: var(--text-color);
      font-size: 17px;
      margin-bottom: 20px;
    }

    div.categoryLists {
      ul {
        display: flex;
        flex-wrap: wrap;

        li.list {
          display: flex;
          margin-right: 10px;
          width: 24%;
          background-color: var(--white-color);
          margin-bottom: 10px;
          cursor: pointer;

          &:nth-child(4n) {
            margin-right: 0;
          }

          @media all and (max-width: 1200px) {
            width: 32.6%;

            &:nth-child(3n) {
              margin-right: 0;
            }

            &:nth-child(4n) {
              margin-right: 10px;
            }
          }

          @media all and (max-width: 980px) {
            width: 49%;

            &:nth-child(3n) {
              margin-right: 10px;
            }

            &:nth-child(2n) {
              margin-right: 0;
            }
          }

          @media all and (max-width: 640px) {
            width: 100%;

            &:nth-child(3n) {
              margin-right: 0;
            }

            &:nth-child(2n) {
              margin-right: 0;
            }

            margin-right: 0;
          }

          div.left {
            div.imageContainer {
              width: 70px;

              img {
                display: block;
                width: 100%;
              }
            }
          }

          div.right {
            padding: 10px;

            h1.title {
              font-size: 14px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  div.awardsAndFestivals {
    padding: 30px 4px 0;
    background-color: var(--secondary-bg-color);

    h1.heading {
      color: var(--text-color);
      font-size: 17px;
      margin-bottom: 20px;

    }

    ul.lists {
      display: flex;
      flex-wrap: wrap;

      li.list {
        margin-right: 10px;
        width: 24%;
        margin-bottom: 20px;

        &:nth-child(4n) {
          margin-right: 0;
        }

        @media all and (max-width: 1200px) {
          width: 32.6%;

          &:nth-child(3n) {
            margin-right: 0;
          }

          &:nth-child(4n) {
            margin-right: 10px;
          }
        }

        @media all and (max-width: 980px) {
          width: 49%;

          &:nth-child(3n) {
            margin-right: 10px;
          }

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @media all and (max-width: 640px) {
          width: 100%;

          &:nth-child(3n) {
            margin-right: 0;
          }

          &:nth-child(2n) {
            margin-right: 0;
          }

          margin-right: 0;
        }

        div.imageContainer {
          width: 40px;
          filter: grayscale(100%);
          background: rgb(200, 200, 200);
          margin-bottom: 20px;

          img {
            display: block;
            width: 100%;
          }
        }

        h1.title {
          color: var(--text-color);
          font-size: 14px;
          margin-bottom: 10px;

        }

        div.descriptionAndYear {
          display: flex;

          span.year {
            margin-right: 6px;
            font-size: 13px;
            color: var(--grey-color);
            font-weight: normal;
          }

          p.description {
            font-size: 13px;
            color: var(--grey-color);
            font-weight: normal;
          }
        }
      }
    }
  }

  div.castAndCrew {
    background-color: var(--secondary-bg-color);
    padding: 30px 4px 0;

    h1.heading {
      color: var(--text-color);
      font-size: 17px;
      margin-bottom: 15px;
    }

    ul.lists {
      display: flex;
      flex-wrap: wrap;

      li.list {
        width: 12%;
        background-color: var(--white-color);
        margin-right: 7px;
        margin-bottom: 20px;
        cursor: pointer;

        &:hover {
          div.info {
            h1.name {
              color: var(--grey-color);
            }
          }
        }

        &:nth-child(8n) {
          margin-right: 0;
        }

        &:last-child {
          margin-right: 0 !important;
        }

        @media all and (max-width: 1400px) {
          width: 13.7%;

          &:nth-child(7n) {
            margin-right: 0;
          }

          &:nth-child(8n) {
            margin-right: 7px;
          }
        }

        @media all and (max-width: 1200px) {
          width: 16%;

          &:nth-child(7n) {
            margin-right: 7px;
          }

          &:nth-child(6n) {
            margin-right: 0;
          }
        }

        @media all and (max-width: 980px) {
          width: 24%;

          &:nth-child(4n) {
            margin-right: 0;
          }

          &:nth-child(6n) {
            margin-right: 7px;
          }
        }

        @media all and (max-width: 640px) {
          width: 32%;

          &:nth-child(4n) {
            margin-right: 7px;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @media all and (max-width: 480px) {
          width: 49%;

          &:nth-child(3n) {
            margin-right: 7px;
          }

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @media all and (max-width: 480px) {
          width: 47.8%;
        }

        div.imageContainer {
          filter: grayscale(100%);
          background: rgb(200, 200, 200);
          width: 100%;

          img {
            width: 100%;
            display: block;
          }
        }

        div.info {
          padding: 10px 10px 30px;

          h1.name {
            font-size: 14px;
            text-transform: uppercase;
            color: var(--text-color);
          }

          p.role {
            font-size: 11px;
            color: var(--grey-color);
            text-transform: uppercase;
          }
        }
      }
    }
  }

  div.relatedFilms {
    background-color: var(--secondary-bg-color);
    padding: 30px 4px 0;

    h1.heading {
      color: var(--text-color);
      font-size: 17px;
      margin-bottom: 15px;
    }

    div.films {
      display: flex;
      flex-wrap: wrap;

      div.show {
        margin-right: 10px;
        margin-bottom: 10px;

        &.portrait {
          width: 16%;

          &:nth-child(6n) {
            margin-right: 0;
          }

          @media all and (max-width: 1300px) {
            width: 19%;

            &:nth-child(6n) {
              margin-right: 10px;
            }

            &:nth-child(5n) {
              margin-right: 0;
            }
          }

          @media all and (max-width: 980px) {
            width: 24%;

            &:nth-child(5n) {
              margin-right: 10px;
            }

            &:nth-child(4n) {
              margin-right: 0;
            }
          }

          @media all and (max-width: 768px) {
            width: 32%;

            &:nth-child(4n) {
              margin-right: 10px;
            }

            &:nth-child(3n) {
              margin-right: 0;
            }
          }

          @media all and (max-width: 640px) {
            width: 48%;

            &:nth-child(3n) {
              margin-right: 10px;
            }

            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }

        &.landscape {
          width: 24.3%;

          &:nth-child(4n) {
            margin-right: 0;
          }

          @media all and (max-width: 1200px) {
            width: 32.5%;

            &:nth-child(4n) {
              margin-right: 10px;
            }

            &:nth-child(3n) {
              margin-right: 0;
            }
          }

          @media all and (max-width: 980px) {
            width: 32%;
          }

          @media all and (max-width: 768px) {
            width: 49%;

            &:nth-child(3n) {
              margin-right: 10px;
            }

            &:nth-child(2n) {
              margin-right: 0;
            }
          }

          @media all and (max-width: 640px) {
            width: 48%;
          }

          @media all and (max-width: 480px) {
            width: 100%;

            &:nth-child(3n) {
              margin-right: 0;
            }

            margin-right: 0;
          }
        }

      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

select.season-select {
  max-width: 30%;
  margin-left: 100px;

  @media all and (max-width: 1367px) {
    margin-left: 20px;
  }

  @media all and (max-width: 480px) {
    max-width: 87%;
  }
}

.detail-subscription-container {

  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
  width: 94vw;
  margin-left: -15px;
  margin-bottom: 20px;
  // background: #dddddd12;
  padding: 5px;

  button {
    margin-right: 6px;
    margin-bottom: 6px;
    padding: 6px 8px;
    background-color: var(--secondary-color);
    display: block;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      background-color: var(--secondary-hover-color);
    }

    &.free {
      background-color: var(--freeBtn-color);

      &:hover {
        background-color: var(--freeBtn-hover);

      }

      span {
        color: var(--white-color);
      }
    }

    span {
      color: var(--white-color);
      font-size: 12px;
      display: block;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .package-item {
    padding: 5px 20px;
    background: rgba(204, 204, 204, 0.08);
    border-radius: 3px;
    margin: 10px;
    cursor: pointer;
    z-index: 9999;
    display: inline-block;
    text-align: left;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }


    .heading-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .plan-options-wrapper {
        align-content: center;
        margin-top: 20px;
        cursor: pointer;
      }

      .plan-options {
        width: 20px;
        height: 20px;
        cursor: pointer !important;
      }

      .heading-wrapper {
        display: flex;
        flex-direction: column;
      }

      .heading {
        font-size: large;
        color: var(--text-color);

        @media only screen and (max-width: 768px) {
          font-size: 15px;
        }
      }

      .sub-heading {
        font-size: small;
        color: #afb6bcf5;
      }


    }

    .continue-button {
      width: 100px;
      height: 30px;
      border-radius: 2px;
      font-size: medium;
      background: #2dbebd;
      color: #121212;
      float: right;

      @media only screen and (max-width: 768px) {
        width: 100%;
        height: 40px;
        font-size: 18px;
      }

      &:hover {
        background: #cccccc17;
        color: #2dbebd;
      }
    }

    &:hover {
      background: var(--bs-primary);

      .sub-heading {
        color: #fff;
      }
    }
  }
}