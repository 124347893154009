div.ceyFlixHelpCenter{
    padding: 100px 0 80px;
    color: var(--text-color);

    p{
        color: var(--inner-text-color);
        margin-left: 20px;
        margin-top: 20px;
    }

    h2{
        text-align: center;
        padding-bottom: 60px;
        
    }
    div.helpPagePoints{
        background: rgba(55, 55, 55, 0.160);
        // background: linear-gradient(to bottom, rgba(55, 25, 25, 0.208), rgb(23, 10, 10, 0.208));
        border-radius: 15px;
        padding: 30px 0;
        div.helpPoint{
            padding: 40px 100px;
    
            h3{
                padding-bottom: 10px;
                border-bottom: 1px solid var(--inner-text-color);
            }
    
            ul{
                padding: 20px  ;
                margin-left: 70px;
                li{
                    list-style:square;
                    padding: 10px;
                    color: var(--text-hover);
    
                    &:last-child{
                        padding-bottom: 0px;
                    }
                }
                @media all and (max-width:1024px) {
                    margin-left: 0px;
                   
                }
                
            }
    
            ol{
                padding: 20px  ;
                margin-left: 70px;
                li{
                    list-style:decimal;
                    padding: 10px;
                    color: var(--text-hover);
                }
                @media all and (max-width:1024px) {
                    margin-left: 0px;
                   
                }
            }
            
    
            @media all and (max-width:1024px) {
                padding: 40px 20px ;
               
            }
        }
    }

    .paddingContainer{
        padding: 20px 100px;

        @media all and (max-width:1024px) {
            padding: 10px 20px ;
           
        }
    }

    p.note{
        color: var(--inner-text-color);
        margin-left: 20px;
        margin-top: 0px;
    }
    
}