.navbar {
  > .container-fluid {
    justify-content: center;
  }
}

.horizontal-nav {
  .offcanvas-header {
    display: none;
    pointer-events: none;
  }
}

.logoutAndLogin{
  display:flex;
  justify-content: center;
  cursor: pointer;
  padding: 10px 0;
  border-top: 1px solid white;
}

header {
  .navbar {
    .iq-nav-menu {
      &.navbar-nav {
        li {
          ul {
            &.sub-nav {
              li {
                a {
                  &.nav-link {
                    .menu-icon {
                      position: absolute;
                      left: auto;
                      right: 15px;
                      top: 50%;
                      transform: translateY(-50%);

                      .toggle-menu {
                        top: 50%;
                        transform: translateY(-50%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sub-nav {
      li {
        padding: 0 0.9375em;
        margin-right: 0;

        &:hover > a,
        > a.active {
          color: var(--bs-white) !important;
        }

        > a {
          &::before {
            position: absolute;
            content: "";
            background: var(--bs-primary);
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 0.25em;
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
            transition: all 0.5s ease-in-out;
          }

          &::after {
            position: absolute;
            content: "";
            background: transparent -webkit-gradient(linear, right top, left top, from(rgba(var(--bs-primary-rgb), 0)), to(rgba(var(--bs-primary-rgb), 0.3)));
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
            transition: all 0.5s ease-in-out;
            z-index: -1;
          }
        }

        &:hover > a::before,
        > a.active::before {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition: all 0.5s ease-in-out;
        }

        &:hover > a::after,
        > a.active::after {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition: all 0.5s ease-in-out;
        }
      }
    }

    .right-panel {
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: #fff;
            // color: var(--bs-heading-color);
          }
        }
      }

      div.dropdowns{
        position: absolute;
        top: 91px;
        right: 40px;
        border-radius: 5px;
      z-index: 99999;
      display: none; // none by default, but block on "open" of the menu
      // padding: var(--#{$prefix}dropdown-padding-y) var(--#{$prefix}dropdown-padding-x);
      margin: 0; // Override default margin of ul
      // @include font-size(var(--#{$prefix}dropdown-font-size));
      color: #000;
      text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
      list-style: none;
      background-color: #000;
      background-clip: padding-box;
      // border: var(--#{$prefix}dropdown-border-width) solid var(--#{$prefix}dropdown-border-color);
      }
    }
  }

  .user-icons {
    height: 2.5rem;
    width: 2.5rem;
    background: #000;
    // background: var(--bs-body-bg);
  }

  .dropdown-user {
    .user-info {
      border-bottom: 1px solid var(--bs-border-color);
      padding: 1rem 1.25rem;

      img {
        width: 2.5rem;
        height: 2.5rem;
        object-fit: cover;
      }
    }

    .iq-sub-card {
      padding: 0.9375rem 1.25rem;

      svg,
      i {
        color: var(--bs-white);
        stroke: var(--bs-white);
      }

      &.iq-logout-2 {
        background: var(--bs-gray-900);
      }

      &:hover {
        h6 {
          color: var(--bs-primary);
        }
      }
    }
  }

  .dropdown-search.dropdown-menu[data-bs-popper] {
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    min-width: 20rem;
  }

  .search-box {
    .form-group {
      animation: scale-out-hor-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
        both;
    }

    .iq-search-bar {
      .form-control {
        padding-left: 2.8em;
        padding-right: 1.5em;
        // background: var(--bs-body-bg);
        background:rgb(41, 39, 39);
        z-index: 0;
      }
    }
  }

  .search-box a.show + ul li .form-group {
    animation: scale-in-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

.nav {
  //navbar button
  .navbar-toggler {
    border: none;
    padding: 0;
    outline: none;
    vertical-align: middle;

    .navbar-toggler-btn {
      width: 30px;
      height: 30px;
      display: block;
      position: relative;

      .navbar-toggler-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.7rem;
        height: 2px;
        background-color: var(--bs-white);
        background-image: none;
        display: inline-block;

        &::after,
        &::before {
          content: "";
          position: absolute;
          left: 0;
          transition: all 0.2s;
          width: 1.7rem;
          height: 2px;
          background-color: var(--bs-white);
          display: inline-block;
        }

        &::after {
          top: 0.6rem;
        }

        &::before {
          top: -0.6rem;
        }
      }
    }

    &[aria-expanded="true"] {
      .navbar-toggler-btn {
        .navbar-toggler-icon {
          background-color: transparent;

          &::before {
            top: 0;
            transform: rotate(135deg);
          }

          &::after {
            top: 0;
            transform: rotate(-135deg);
          }
        }
      }
    }
  }
}

.iq-nav-menu {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1599px) {
  header {
    .navbar {
      .horizontal-nav {
        padding-left: 3rem;
      }
    }
  }
}

@media (max-width: 1499px) {
  header {
    .navbar {
      .horizontal-nav {
        padding-left: 2rem;
      }

      .right-panel {
        .navbar-nav {
          .iq-button {
            padding: 1em 1.5em;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .iq-nav-menu {
    flex-direction: row;
    margin-bottom: 0;
    display: inline-flex;
    flex-wrap: wrap;

    li {
      position: relative;
      flex-direction: column;

      > ul {
        position: absolute;
        height: auto !important;
        top: 100%;
        width: 100%;
        min-width: 17.75rem;
        z-index: 999;
        left: auto;
        right: auto;
        padding: 0.5rem 0;
        display: none;
        margin: 0;
        background: $white;
        animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1,
          menu-sub-dropdown-animation-move-up 0.3s ease 1;

        ul {
          left: 100%;
          top: 0;
          box-shadow: 0rem 0.25rem 2rem 0rem rgba($black, 0.1);
        }

        li {
          padding: 0;
          border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.03);

          a {
            transition: var(--sidebar-transition);
            transition-duration: var(--sidebar-transition-duration);
            transition-timing-function: var(
              --sidebar-transition-function-ease-in-out
            );
            padding: 0.5rem 1.25rem 0.5rem 1.25rem;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .iq-header-sub-menu {
        .nav-item {
          > .nav-link {
            &:hover {
              padding-left: 1.5rem;
              font-weight: 500;
              transition: var(--sidebar-transition);
              transition-duration: var(--sidebar-transition-duration);
              transition-timing-function: var(
                --sidebar-transition-function-ease-in-out
              );
            }
          }
        }
      }

      &:hover {
        > ul {
          display: block;
        }
      }

      .nav-link {
        &.active {
          color: var(--#{$variable-prefix}primary);
        }
      }

      &.active {
        > a {
          color: var(--#{$variable-prefix}primary);
        }
      }
    }

    > li {
      > a {
        padding: 0.5rem 1.25rem;
        margin: 0;
        border-radius: 0.5rem;
      }

      flex: 0 0 auto;
      position: relative;
      flex: 0 0 auto;
      position: relative;

      &:hover {
        border-color: transparent;
        padding: 0;
      }
    }

    ul {
      border-left: 0;
    }

    > li.iq-ecomm-active {
      padding: 0;
    }
  }

  header {
    .navbar {
      .right-panel {
        .navbar-nav {
          .nav-item {
            .nav-link {
              padding: 1.0625rem var(--bs-navbar-nav-link-padding-x);
            }
          }
        }
        div.dropdowns{
          position: absolute;
          top: 91px;
          right: 40px;
          border-radius: 5px;
        z-index: 99999;
        display: none; // none by default, but block on "open" of the menu
        // padding: var(--#{$prefix}dropdown-padding-y) var(--#{$prefix}dropdown-padding-x);
        margin: 0; // Override default margin of ul
        // @include font-size(var(--#{$prefix}dropdown-font-size));
        color: #000;
        text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
        list-style: none;
        background-color: #000;
        background-clip: padding-box;
        // border: var(--#{$prefix}dropdown-border-width) solid var(--#{$prefix}dropdown-border-color);
        }
      }
    }
  }

  .dropdown-user {
    background: #000;
    // background: var(--bs-body-bg);
    width: 17.5rem;
  }
}

@include media-breakpoint-down(xl) {
  header {
    .navbar {
      .horizontal-nav {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .search-box {
      position: static;
      text-align: end;

      .form-group {
        animation: none;
      }

      .dropdown-search {
        &.dropdown-menu[data-bs-popper] {
          top: 100%;
          transform: translateY(0);
        }
        .form-group {
          width: 260px;
          margin: 0 0 0 auto;
          background: var(--bs-gray-900);
        }
      }
    }
  }

  .navbar-toggler {
    display: block;
  }

  .iq-nav-menu {
    li {
      &.active {
        color: var(--bs-primary);
      }

      a {
        padding-left: 1rem;

        .right-icon {
          transition: all 100ms ease-in-out;
          display: none;
        }

        &:not(.collapsed) {
          &[aria-expanded="true"] {
            color: var(--bs-primary);

            .right-icon {
              transition: all 100ms ease-in-out;
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }

  .horizontal-nav {
    .offcanvas-header {
      opacity: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      pointer-events: auto;
    }
  }

  .horizontal-nav {
    &.mobile-offcanvas {
      background: #000000;
      // background: var(--bs-body-bg);
      visibility: hidden;
      transform: translateX(-100%);
      border-radius: 0;
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 80%;
      z-index: 1200;
      overflow-y: scroll;
      overflow-x: hidden;
      transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out,
        -webkit-transform 0.3s ease-in-out;

      .sub-nav {
        li {
          padding: 0;

          > a::before,
          > a::after {
            content: none;
          }
        }
      }

      .iq-nav-menu {
        li {
          .sub-nav {
            a {
              padding: 1.375rem 0.9375rem;
              color: var(--bs-white);
              border: solid;
              border-width: 0 0 0.0625rem;
              border-image-source: linear-gradient(
                90deg,
                rgb(31, 31, 31),
                rgba(31, 31, 31, 0.6),
                rgba(31, 31, 31, 0)
              );
              border-image-slice: 1;
              font-weight: $font-weight-normal;
              font-size: 0.875rem;

              &:hover,
              &.active {
                color: var(--bs-primary) !important;
              }
            }
          }
        }
      }
    }

    &.mobile-offcanvas.show {
      visibility: visible;
      transform: translateX(0);
    }

    &.mobile-offcanvas .container,
    &.mobile-offcanvas .container-fluid {
      display: block;
    }

    .offcanvas-header {
      opacity: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      pointer-events: auto;
      padding-bottom: 3.125em;
    }
  }

  .iq-navbar {
    .navbar-collapse {
      &:not(.offcanvas-collapse) {
        position: absolute;
        top: 96%;
        left: 0;
        width: 100%;
        flex-grow: unset;
        background: #000;
        // background: var(--bs-body-bg);
        z-index: 1081;
        box-shadow: $box-shadow-sm;
        transition: all 400ms ease;

        .navbar-nav {
          flex-direction: row;
          float: right;
          padding-right: 1rem;
          align-items: center;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .iq-navbar {
    .navbar-collapse {
      &:not(.offcanvas-collapse) {
        top: 100%;
      }

      .navbar-nav.navbar-list {
        &:not(.offcanvas-collapse) {
          flex-direction: row;
          float: right;
        }
      }
    }
  }

  header {
    .navbar {
      .horizontal-nav {
        padding-left: 0;
        padding-right: 0;

        .container-fluid {
          padding: 0 1rem;
        }
      }
    }
  }
}

// ============================
.navbar {
  ul {
    li {
      .iq-header-sub-menu {
        li {
          &:hover > a::before {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: all 0.5s ease-in-out;
          }

          > a::before {
            position: absolute;
            content: "";
            background: var(--bs-primary);
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 0.25em;
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
            transition: all 0.5s ease-in-out;
          }

          padding: 0 0.9375em;
          margin-right: 0;

          &:hover > a::after {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: all 0.5s ease-in-out;
          }

          > a::after {
            position: absolute;
            content: "";
            background: transparent -webkit-gradient(linear, right top, left top, from(rgba(var(--bs-primary-rgb), 0)), to(rgba(var(--bs-primary-rgb), 0.3)));
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
            transition: all 0.5s ease-in-out;
            z-index: -1;
          }
        }
      }
    }
  }
}

.nav-item {
  span {
    &.dots {
      height: 0.625em;
      width: 0.625em;
      font-size: 0;
      text-align: center;
      padding: 0;
      position: absolute;
      top: 0.188em;
      right: 0.75em;
      border-radius: 50%;
    }
  }
}

.menu-right {
  .nav-item {
    span {
      &.dots {
        right: 0;
      }
    }
  }

  li {
    .iq-sub-dropdown {
      .iq-sub-card {
        position: relative;
        font-size: inherit;
        padding: 0.938em 0.938em;
        line-height: normal;
        color: inherit;
        text-align: left;
        display: inline-block;
        width: 100%;
        border: 0.063em solid;
        border-image-slice: 0.063em;
        border-width: 0 0 0.063em 0;
        border-image-source: linear-gradient(
          to right,
          rgba(209, 208, 207, 0.6),
          rgba(209, 208, 207, 0.3),
          rgba(209, 208, 207, 0)
        );
      }
    }
  }
}

.iq-nav-menu {
  li {
    position: relative;
    margin-right: 2.8125em;

    &:last-child {
      margin-right: 0;
    }

    a[aria-expanded="true"] {
      .arrow-active {
        opacity: 0;
        display: none;
      }

      .arrow-hover {
        opacity: 1;
        display: block;
        margin: 6px;
        font-size: 0.75em;
        transition: all 0.6s ease-in-out;
      }
    }

    a {
      .iq-arrow-right {
        margin-right: 0;
        margin-left: auto;
        font-size: 15px;
        width: 15px;
        transition: all 0.6s ease-in-out;
      }

      .arrow-active {
        font-size: 0.75em;
      }
    }
  }
}

.iq-nav-menu li a .arrow-hover,
.iq-nav-menu li a[aria-expanded="true"] .arrow-active {
  opacity: 0;
  display: none;
  font-size: 0.75em;
}

.toggle-menu {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  background: var(--bs-primary);
  border-radius: 0;
  text-align: center;
  display: block;
  height: 1.5em;
  width: 1.5em;
  line-height: 1.5em;
  font-size: 1em;
  z-index: 9;
  color: var(--bs-white);
  transition: all 0.3s ease-in-out;
  display: none;
}

@media (max-width: 1199px) {
  .toggle-menu {
    display: block;
  }

  .iq-nav-menu {
    li {
      margin-right: 0;

      .nav-link {
        &.active {
          color: var(--bs-primary);
          font-weight: 500;
        }
      }
    }
  }

  .iq-navbar {
    z-index: 889;
  }

  .iq-nav-menu {
    > li {
      margin: 0 1rem 1.5rem;

      &:last-child {
        margin: 0 1rem 1.5rem;
      }

      > a {
        padding: 0 0 1.5rem;
        color: var(--bs-white);
        border: solid;
        border-width: 0 0 0.0625rem;
        border-image-source: linear-gradient(
          90deg,
          rgba(31, 31, 31, 1),
          rgba(31, 31, 31, 0.6),
          rgba(31, 31, 31, 0)
        );
        border-image-slice: 1;
      }
    }
  }
}

.iq-nav-menu .nav-item > .nav-link:hover,
.iq-nav-menu .nav-item > .nav-link:hover i {
  color: var(--bs-primary);
}

.iq-nav-menu {
  li {
    &.active {
      & > a {
        color: var(--bs-primary);
      }
    }

    & > ul {
      li {
        padding: 0;
        margin: 0;
      }
    }
  }

  & > li {
    & > a {
      font-weight: 500;
      font-size: 1rem;
      color: #fff;
    }
  }

  a {
    position: relative;
    display: block;
    padding: 0.625rem 1.875rem;
    outline-width: 0;
    color: var(--bs-body-color);
    text-decoration: none;
  }

  .menu-arrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bs-primary);

  &:focus-visible{
    box-shadow: none;
  }
}

header {
  .navbar {
    ul {
      li {
        i {
          display: inline-block;
          font-weight: 700;
          font-size: 1em;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .navbar-right {
    &.menu-right {
      ul {
        li {
          margin-right: 2.3em;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar {
    ul {
      li {
        &:hover {
          & > .sub-menu {
            display: block !important;
            -webkit-animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1,
              menu-sub-dropdown-animation-move-up 0.3s ease 1;
            animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1,
              menu-sub-dropdown-animation-move-up 0.3s ease 1;
          }
        }
      }
    }
  }

  .iq-nav-menu {
    li {
      & > ul {
        position: absolute;
        height: auto !important;
        top: 100%;
        width: 100%;
        min-width: 16rem;
        z-index: 999;
        left: auto;
        right: auto;
        padding: 0.9375rem 0;
        display: none;
        margin: 0;
        background: var(--bs-gray-900);
        box-shadow: 0 0.625rem 1.875rem rgb(0 0 0 / 10%);
        animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1,
          menu-sub-dropdown-animation-move-up 0.3s ease 1;

        li {
          padding: 0;
          padding: 0 0.9375em;

          a {
            -webkit-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
            padding: 0.5rem 1.25rem 0.5rem 1.25rem;
            transition: all 0.4s ease-in-out;
            padding: 0.5rem 1.25rem 0.5rem 1.25rem;
            overflow: hidden;
            font-size: 0.875em;
            border-radius: 0;
            display: inline-block;
            width: 100%;
            line-height: 1em;
            padding: 0.938em;
            color: #fff;
            // color: var(--bs-body-color);
            position: relative;
          }
        }

        ul {
          box-shadow: 0rem 0.25rem 2rem 0rem rgb(0 0 0 / 10%);
          left: auto;
          right: 100%;
          top: 0;
          box-shadow: 0rem 0.25rem 2rem 0rem rgb(0 0 0 / 10%);
        }
      }

      &:hover {
        & > ul {
          display: block;
        }
        & > ul.mega-menu-item {
          display: flex;
        }
      }

      &.active {
        & > a {
          & > i {
            color: var(--bs-primary);
          }
        }
      }
    }

    .menu-image-box {
      a {
        padding: 0em 1em;
      }
    }

    ul {
      border-left: 0;
    }

    & > li {
      & > a {
        font-size: 0.875rem;
        padding: 1.0625rem var(--bs-navbar-nav-link-padding-x);
        line-height: 1.5rem;
        z-index: 9;
        text-decoration: none;
        text-transform: capitalize;
        font-weight: 600;
        letter-spacing: 0.0625em;
      }
    }
  }

  header {
    .navbar {
      ul {
        li {
          a {
            &:hover {
              color: var(--bs-primary);
            }
          }
        }
      }
    }
  }

  .iq-product-menu-responsive {
    &.offcanvas-end {
      width: auto;
    }
  }

  .product-offcanvas {
    .modal-backdrop {
      &.show {
        opacity: 0;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .iq-nav-menu {
    li {
      > ul {
        ul {
          left: 100%;
          right: auto;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .horizontal-nav {
    &.mobile-offcanvas {
      width: 90%;
    }
  }
}

// animation

.scale-in-hor-right {
  animation: scale-in-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-hor-right {
  0% {
    transform: scaleX(0);
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    transform: scaleX(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes scale-in-hor-right {
  0% {
    transform: scaleX(0);
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    transform: scaleX(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

.scale-out-hor-right {
  animation: scale-out-hor-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes scale-out-hor-right {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    transform: scaleX(0);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes scale-out-hor-right {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    transform: scaleX(0);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

.onhover{
  &:hover{
    color: var(--bs-primary);
  }
}

.btn-color{
  background: var(--bs-primary);
  color: white;
  &:hover{
    background: rgb(var(--bs-primary-rgb),2);
    color: white;
  }
}