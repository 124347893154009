div.progressBar {
  position: relative;
  z-index: 9;
    width: 98%;
    padding: 14px;
    margin-bottom: 10px;
    margin-top: 10px;
    border: 2px solid rgba(150,153,163,.16);
    border-radius: 8px; /* Rounded corners for the modal */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Box shadow for a subtle lift effect */
    text-align: center;
    animation: fadeInOut 1.5s ease-in-out infinite;
    p.downloadText {
      color: white;
      font-size: 16px;
    }
    @keyframes fadeInOut {
      0%, 100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
    @media  (max-width: 2600px) {
      p.downloadText {
        font-size: 16px; 
      }
      width: 98%;
      left: 10px;
      top: 0px;  
    }
    @media  (max-width: 2500px) {
       p.downloadText{
        font-size: 15px; 
      }
      width: 98%;
      left: 10px;
      top: 0px; 
    }
    @media  (max-width: 2200px) {
       p.downloadText{
        font-size: 14px; 
      }
      width: 98%;
      left: 10px;
      top: 0px; 
    }
    @media  (max-width: 1980px) {
       p.downloadText{
        font-size: 13px; 
      }
      top: 410px; 
    }
    @media  (max-width: 1768px) {
       p.downloadText{
        font-size: 12px; 
      }
      width: 98%;
      left: 10px;
      top: 0px; 
    }
    @media  (max-width: 1600px) {
       p.downloadText{
        font-size: 11px; 
      }
    }
    @media  (max-width: 1400px) {
       p.downloadText{
        font-size: 10px; 
      }
    }
    @media  (max-width: 1200px) {
       p.downloadText{
        font-size: 9px; 
      }
    }


    @media (max-width: 1280px) {
       p.downloadText{
        font-size: 10px; 
      }
    }
    @media (max-width: 980px) {
       p.downloadText{
        font-size: 7px; 
      }
    }
    @media (max-width: 768px) {
       p.downloadText{
        font-size: 7px; 
      } 
    }
    @media (max-width: 640px) {
       p.downloadText{
        font-size: 7px; 
      }
    }
    @media (max-width: 480px) {
       p.downloadText{
        font-size: 6px; 
      } 
    }
    @media (max-width: 360px) {
       p.downloadText{
        font-size: 6px; 
      }
    }
  }