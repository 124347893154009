div.commonPage {
    padding: 60px 5px 40px;
    background-color: var(--bg-color);
    div.top {
      &.inside{

        position: absolute;
        top: 60px;
        z-index: 8;
        left: 60px;
        @media all and (max-width: 640px) {
            top: 50px;
            z-index: 8;
            left: 20px;
          }
          h1.heading{
            text-align: left;
            color: var(--white-color);
          }
      }
      h1.heading {
        font-size: 26px;
        margin-bottom: 10px;
        margin-top: 20px;
        color: var(--heading-color);
        text-align: center;
        @media all and (max-width: 640px) {
          font-size: 22px;
          margin-top: 50px;
        }
      }
      p.desc {
        font-size: 18px;
        color: var(--text-color);
        @media all and (max-width: 640px) {
          font-size: 14px;
        }
      }
    }
    div.bgImageContainer {
      width: 100%;
      display: block;
      height: 508px;
      position: relative;
      @media all and (max-width: 768px) {
        height: 556px;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media all and (max-width: 768px) {
          object-fit: cover;
        }
      }
    }
    div.bottomGradient {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      // z-index: 1;
      height: 100%;
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      background: linear-gradient(
          78.83deg,
          rgb(16, 20, 31) 0%,
          rgba(16, 20, 31, 0) 61.46%
        ),
        linear-gradient(
          360deg,
          rgb(16, 20, 31) 0%,
          rgba(16, 20, 31, 0) 48.44%,
          rgba(16, 20, 31, 0.4) 100%
        );
  
  
    }
    div.bottom {
      margin-top: 30px;
      div.itemsContainer{
        display: flex;
        flex-wrap: wrap;
        div.items {
          &.hidden {
            visibility: hidden;
            @media all and (max-width:980px){
              display: none;
            }
           
          }
          &.rowCount3 {
            width: 32.5%;
            margin-right: 13px;
            margin-bottom: 30px;
            &:nth-child(3n) {
              margin-right: 0;
            }
            @media all and (max-width: 1200px) {
              width: 32%;
            }
            &.landscape {
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(3n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.1);
                      }
                    }
                  }
                  // &:last-child {
                  //   div.showCardContainer {
                  //     div.square {
                  //       transform: translateX(-60px) translateY(-60px) scale(1.1);
                  //     }
                  //   }
                  // }
                }
              }
  
              // @media all and (max-width: 1400px) {
              //   width: 16.2%;
              // }
              // @media all and (max-width: 1200px) {
              //   width: 16.1%;
              //   //
              // }
              @media all and (max-width: 980px) {
                width: 24.3%;
                margin-right: 6px;
                margin-bottom: 40px;
                &:nth-child(6n) {
                  margin-right: 6px;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 768px) {
                width: 32.3%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48.2%;
  
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 480px) {
                width: 100%;
                margin-right: 0;
                &:nth-child(6n) {
                  margin-right: 0;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
  
                &:nth-child(2n) {
                  margin-right: 0;
                }
                @media all and (max-width: 360px) {
                  width: 100%;
  
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                  margin-right: 0;
                }
              }
            }
            &.portrait {
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(3n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                  @media all and (max-width: 1300px) {
                    &:nth-child(6n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px)
                            scale(1.2);
                        }
                      }
                    }
                    &:nth-child(7n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                  }
                  &:last-child {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
              }
              // width: 13.8%;
              // &:nth-child(7n) {
              //   margin-right: 0;
              // }
              // @media all and (max-width: 1300px) {
              //   width: 16%;
              //   &:nth-child(7n) {
              //     margin-right: 6px;
              //   }
              //   &:nth-child(6n) {
              //     margin-right: 0;
              //   }
              // }
              @media all and (max-width: 980px) {
                width: 24%;
                margin-right: 6px;
                margin-bottom: 40px;
                &:nth-child(5n) {
                  margin-right: 6px;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
                &:nth-child(6n) {
                  margin-right: 6px;
                }
              }
              @media all and (max-width: 768px) {
                width: 32%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48%;
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }
          }
          &.rowCount4 {
            width: 24%;
            margin-right: 13px;
            margin-bottom: 30px;
            &:nth-child(4n) {
              margin-right: 0;
            }
            @media all and (max-width: 1200px) {
              width: 23.8%;
            }
            &.landscape {
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(4n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.1);
                      }
                    }
                  }
                  // &:last-child {
                  //   div.showCardContainer {
                  //     div.square {
                  //       transform: translateX(-60px) translateY(-60px) scale(1.1);
                  //     }
                  //   }
                  // }
                }
              }
  
              // @media all and (max-width: 1400px) {
              //   width: 16.2%;
              // }
              // @media all and (max-width: 1200px) {
              //   width: 16.1%;
              //   //
              // }
              @media all and (max-width: 980px) {
                width: 24.3%;
                margin-right: 6px;
                margin-bottom: 40px;
                &:nth-child(6n) {
                  margin-right: 6px;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 768px) {
                width: 32.3%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48.2%;
  
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 480px) {
                width: 100%;
                margin-right: 0;
                &:nth-child(6n) {
                  margin-right: 0;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
  
                &:nth-child(2n) {
                  margin-right: 0;
                }
                @media all and (max-width: 360px) {
                  width: 100%;
  
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                  margin-right: 0;
                }
              }
            }
            &.portrait {
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(4n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                  @media all and (max-width: 1300px) {
                    &:nth-child(6n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px)
                            scale(1.2);
                        }
                      }
                    }
                    &:nth-child(7n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                  }
                  &:last-child {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
              }
              // width: 13.8%;
              // &:nth-child(7n) {
              //   margin-right: 0;
              // }
              // @media all and (max-width: 1300px) {
              //   width: 16%;
              //   &:nth-child(7n) {
              //     margin-right: 6px;
              //   }
              //   &:nth-child(6n) {
              //     margin-right: 0;
              //   }
              // }
              @media all and (max-width: 980px) {
                width: 24%;
                margin-right: 6px;
                margin-bottom: 40px;
                &:nth-child(5n) {
                  margin-right: 6px;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
                &:nth-child(6n) {
                  margin-right: 6px;
                }
              }
              @media all and (max-width: 768px) {
                width: 32%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48%;
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }
          }
          &.rowCount5 {
            width: 19.3%;
            margin-right: 6px;
            margin-bottom: 30px;
            &:nth-child(5n) {
              margin-right: 0;
            }
            @media all and (max-width: 1200px) {
              width: 19.3%;
            }
            &.landscape {
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(5n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.1);
                      }
                    }
                  }
                  // &:last-child {
                  //   div.showCardContainer {
                  //     div.square {
                  //       transform: translateX(-60px) translateY(-60px) scale(1.1);
                  //     }
                  //   }
                  // }
                }
              }
  
              // @media all and (max-width: 1400px) {
              //   width: 16.2%;
              // }
              // @media all and (max-width: 1200px) {
              //   width: 16.1%;
              //   //
              // }
              @media all and (max-width: 980px) {
                width: 24.3%;
                margin-right: 6px;
                margin-bottom: 40px;
                &:nth-child(6n) {
                  margin-right: 6px;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 768px) {
                width: 32.3%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48.2%;
  
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 480px) {
                width: 100%;
                margin-right: 0;
                &:nth-child(6n) {
                  margin-right: 0;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
  
                &:nth-child(2n) {
                  margin-right: 0;
                }
                @media all and (max-width: 360px) {
                  width: 100%;
  
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                  margin-right: 0;
                }
              }
            }
            &.portrait {
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(5n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                  @media all and (max-width: 1300px) {
                    &:nth-child(6n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px)
                            scale(1.2);
                        }
                      }
                    }
                    &:nth-child(7n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                  }
                  &:last-child {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
              }
              // width: 13.8%;
              // &:nth-child(7n) {
              //   margin-right: 0;
              // }
              // @media all and (max-width: 1300px) {
              //   width: 16%;
              //   &:nth-child(7n) {
              //     margin-right: 6px;
              //   }
              //   &:nth-child(6n) {
              //     margin-right: 0;
              //   }
              // }
              @media all and (max-width: 980px) {
                width: 24%;
                margin-right: 6px;
                margin-bottom: 40px;
                &:nth-child(5n) {
                  margin-right: 6px;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
                &:nth-child(6n) {
                  margin-right: 6px;
                }
              }
              @media all and (max-width: 768px) {
                width: 32%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48%;
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }
          }
          &.rowCount6 {
            width: 16%;
            margin-right: 6px;
            margin-bottom: 30px;
            &:nth-child(6n){
                margin-right: 0;
            }
            @media all and (max-width:1400px){
                width: 16%;
            }
            @media all and (max-width:1200px){
                width: 16%;
            }
            &.landscape {
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(6n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.1);
                      }
                    }
                  }
                  // &:last-child {
                  //   div.showCardContainer {
                  //     div.square {
                  //       transform: translateX(-60px) translateY(-60px) scale(1.1);
                  //     }
                  //   }
                  // }
                }
              }
  
              // @media all and (max-width: 1400px) {
              //   width: 16.2%;
              // }
              // @media all and (max-width: 1200px) {
              //   width: 16.1%;
              //   //
              // }
              @media all and (max-width: 980px) {
                width: 24.3%;
                margin-right: 6px;
                margin-bottom: 40px;
                &:nth-child(6n) {
                  margin-right: 6px;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 768px) {
                width: 32.3%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48.2%;
  
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 480px) {
                width: 100%;
                margin-right: 0;
                &:nth-child(6n) {
                  margin-right: 0;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
  
                &:nth-child(2n) {
                  margin-right: 0;
                }
                @media all and (max-width: 360px) {
                  width: 100%;
  
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                  margin-right: 0;
                }
              }
            }
            &.portrait {
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(6n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                  @media all and (max-width: 1300px) {
                    &:nth-child(6n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px)
                            scale(1.2);
                        }
                      }
                    }
                    &:nth-child(7n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                  }
                  &:last-child {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
              }
              // width: 13.8%;
              // &:nth-child(7n) {
              //   margin-right: 0;
              // }
              // @media all and (max-width: 1300px) {
              //   width: 16%;
              //   &:nth-child(7n) {
              //     margin-right: 6px;
              //   }
              //   &:nth-child(6n) {
              //     margin-right: 0;
              //   }
              // }
              @media all and (max-width: 980px) {
                width: 24%;
                margin-right: 6px;
                margin-bottom: 40px;
                &:nth-child(5n) {
                  margin-right: 6px;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
                &:nth-child(6n) {
                  margin-right: 6px;
                }
              }
              @media all and (max-width: 768px) {
                width: 32%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48%;
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }
          }
          &.rowCount7 {
            width: 13.9%;
            margin-right: 6px;
            margin-bottom: 30px;
            &:nth-child(7n) {
              margin-right: 0;
            }  
            @media all and (max-width: 1200px) {
              width: 19.3%;
            }
            &.portrait {
              @media all and (max-width: 2600px) {
                width: 14%;
                margin-right: 6.5px;
                }
                @media all and (max-width: 2500px) {
                  width: 14%;
                 margin-right: 5.5px;
                }
                @media all and (max-width: 2200px) {
                  width: 13.9%;
                  margin-right: 6.5px;
                }
                @media all and (max-width: 1980px) {
                  width: 14%;
                  margin-right: 4.5px;
                }
                @media all and (max-width: 1768px) {
                  width: 14%;
                  margin-right: 4px;
                }
                @media all and (max-width: 1600px) {
                  width: 13.7%;
                  margin-right: 7.5px;
                }
                @media all and (max-width: 1400px) {
                  width: 14%;
                  margin-right: 3px;
                }
                @media all and (max-width: 1200px) {
                  width: 13.6%;
                  margin-right: 6.1px;
                }
                
                
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(7n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                  @media all and (max-width: 1300px) {
                    &:nth-child(6n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(-60px) translateY(-60px)
                            scale(1.2);
                        }
                      }
                    }
                    &:nth-child(7n) {
                      div.showCardContainer {
                        div.square {
                          transform: translateX(60px) translateY(-60px) scale(1.2);
                        }
                      }
                    }
                  }
                  &:last-child {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                }
              }
              
              @media all and (max-width: 980px) {
                width: 24%;
                margin-right: 6px;
                margin-bottom: 40px;
                &:nth-child(5n) {
                  margin-right: 6px;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
                &:nth-child(6n) {
                  margin-right: 6px;
                }
              }
              @media all and (max-width: 768px) {
                width: 32%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48%;
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }
          }
          &.rowCount8 {
            width: 12%;
            margin-right: 8px;
            margin-bottom: 30px;
            &:nth-child(8n) {
              margin-right: 0;
            }
            @media all and (max-width: 1200px) {
              width: 12%;
              margin-right: 6px;
            }
            &.landscape {
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.1);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(5n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.1);
                      }
                    }
                  }
                 
                }
              }
  
             
              @media all and (max-width: 980px) {
                width: 24.3%;
                margin-right: 6px;
                margin-bottom: 40px;
                &:nth-child(6n) {
                  margin-right: 6px;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 768px) {
                width: 32.3%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48.2%;
  
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 480px) {
                width: 100%;
                margin-right: 0;
                &:nth-child(6n) {
                  margin-right: 0;
                }
                &:nth-child(4n) {
                  margin-right: 0;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
  
                &:nth-child(2n) {
                  margin-right: 0;
                }
                @media all and (max-width: 360px) {
                  width: 100%;
  
                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                  margin-right: 0;
                }
              }
            }
            &.portrait {
              @media all and (max-width: 2600px) {
                width: 12.1%;
                margin-right: 10px;
                &:nth-child(8n) {
                  margin-right: 0px;
                }
              }
              @media all and (max-width: 2500px) {
                width: 12%;
                margin-right: 11px;
              }
              @media all and (max-width: 2200px) {
                width: 12.2%;
                margin-right: 6px;
              }
              @media all and (max-width: 1980px) {
                width: 12%;
                margin-right: 9px;
              }
              @media all and (max-width: 1768px) {
                width: 11.9%;
                margin-right: 10px;
              }
              @media all and (max-width: 1600px) {
                width: 12%;
                margin-right: 7px;
              }
              @media all and (max-width: 1400px) {
                width: 12%;
                margin-right: 6px;
              }
              @media all and (max-width: 1200px) {
                width: 12%;
                margin-right: 5px;
              }
              @media all and (min-width: 981px) {
                &:hover {
                  div.showCardContainer {
                    .square {
                      position: absolute;
                      z-index: 9999999;
                      transform: translateX(60px) translateY(-60px) scale(1.2);
                      div.metaData {
                        display: block;
                        visibility: visible !important;
                        animation: fadeIn 0.3s ease-in-out;
                      }
                    }
                  }
                  &:nth-child(8n) {
                    div.showCardContainer {
                      div.square {
                        transform: translateX(-60px) translateY(-60px) scale(1.2);
                      }
                    }
                  }
                 
                
                }
              }
              
              @media all and (max-width: 980px) {
                width: 24%;
                margin-right: 6px;
                margin-bottom: 40px;
               
              }
              @media all and (max-width: 768px) {
                width: 32%;
                &:nth-child(4n) {
                  margin-right: 6px;
                }
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @media all and (max-width: 640px) {
                width: 48%;
                &:nth-child(3n) {
                  margin-right: 6px;
                }
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }
          }
          @media all and (max-width: 980px) {
            width: 24%;
            margin-right: 6px;
            margin-bottom: 40px;
            &:nth-child(5n) {
              margin-right: 6px;
            }
            &:nth-child(4n) {
              margin-right: 0;
            }
            &:nth-child(6n) {
              margin-right: 6px;
            }
          }
          @media all and (max-width: 640px) {
            width: 48%;
            &:nth-child(3n) {
              margin-right: 6px;
            }
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
          &.landscape {
            @media all and (max-width: 980px) {
              width: 24.3%;
              margin-right: 6px;
              margin-bottom: 40px;
              &:nth-child(6n) {
                margin-right: 6px;
              }
              &:nth-child(4n) {
                margin-right: 0;
              }
            }
            @media all and (max-width: 768px) {
              width: 32.3%;
              &:nth-child(4n) {
                margin-right: 6px;
              }
              &:nth-child(3n) {
                margin-right: 0;
              }
            }
            @media all and (max-width: 640px) {
              width: 48.2%;
  
              &:nth-child(3n) {
                margin-right: 6px;
              }
              &:nth-child(2n) {
                margin-right: 0;
              }
            }
            @media all and (max-width: 480px) {
              width: 100%;
              margin-right: 0;
              &:nth-child(6n) {
                margin-right: 0;
              }
              &:nth-child(4n) {
                margin-right: 0;
              }
              &:nth-child(3n) {
                margin-right: 0;
              }
  
              &:nth-child(2n) {
                margin-right: 0;
              }
              @media all and (max-width: 360px) {
                width: 100%;
  
                &:nth-child(3n) {
                  margin-right: 0;
                }
                margin-right: 0;
              }
            }
          }
          &.portrait {
          @media all and (max-width: 980px) {
              width: 24%;
              margin-right: 6px;
              margin-bottom: 40px;
              &:nth-child(5n) {
                margin-right: 6px;
              }
              &:nth-child(4n) {
                margin-right: 0;
              }
              &:nth-child(6n) {
                margin-right: 6px;
              }
            }
            @media all and (max-width: 768px) {
              width: 32%;
              &:nth-child(4n) {
                margin-right: 6px;
              }
              &:nth-child(3n) {
                margin-right: 0;
              }
            }
            @media all and (max-width: 640px) {
              width: 48%;
              &:nth-child(3n) {
                margin-right: 6px;
              }
              &:nth-child(2n) {
                margin-right: 0;
              }
            }
          }
        }
    }
    }
    div.LoadMoreButtonContainer{
      text-align: center;
      @media all and (max-width:980px){
        margin-top: 20px;
      }
      button{
        width: 8rem;
        padding: 6px 1px 9px;
        border-radius: 2px;
        background-color: var(--secondary-color);
        color: var(--white-color);
        cursor: pointer;
        transition: 0.3s ease-in-out;
        margin: 0 auto;
        &:hover{
          background-color: var(--secondary-hover-color);
        }
        span{
          font-size: 14px;
        }
  
      }
    }
  }
  
