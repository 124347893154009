div.help-center-containter{
    min-height: 500vh;
    margin-top: 0px;
    position: relative;
    @media all and (max-width: 900px) and (orientation: landscape) {
        min-height: 1000vh;
    }

    #page-container {
        overflow-y: hidden;
    }
}