.videoBanner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 95vh;

  #background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .bannerImage{
    height:95vh;
    @media all and (max-width:480px){
      height: 50vh;
    }
  }

  button.bannerButton{
    position:absolute;
    padding: 15px 20px;
    right: 30px;
    bottom: 50px;
    border: none;
    font-size: 0.9rem;
    font-weight: 500;
    border-radius: 7px;
    background: var(--white-color);
    &:hover{
      background-color: var(--button-color);
      color: var(--white-color);
    }
    @media all and (max-width:480px) {
      right: 20px;
      bottom: 60px;
      font-size: 0.8rem;
    }
  }
  @media all and (max-width:480px){
    height: 50vh;
  }

}
div.deviceList {
  display: flex;
  background: var(--white-color);
  padding-top: 30px;
  justify-content: space-between;
  padding-bottom: 100px;
@media all and (max-width: 640px) {
  flex-direction: column;
}
  div.left {
    width: 40%;
    padding-left: 60px;
  @media all and (max-width: 768px) {
  padding-left: 30px;
}
@media all and (max-width: 640px) {
  width: 100%;
  margin-bottom: 30px;
}
    h1.heading {
      color: var(--black-color);
    }

    p.description {
      color: var(--text-color);
    }
  }

  div.right {
    width: 40%;
  @media all and (max-width: 1980px) {
  width: 43%;
}
@media all and (max-width: 1600px) {
  width: 46%;
}
@media all and (max-width: 1400px) {
  width: 55%;
}
@media all and (max-width: 1200px) {
  width: 46%;
}
@media all and (max-width: 980px) {
  width: 55%;
}
@media all and (max-width: 768px) {
  width: 56%;
}
@media all and (max-width: 640px) {
  width: 91%;
  margin: 0 auto;
}
    ul.devices {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        cursor: pointer;
        width: 17rem;
        height: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;
        margin-bottom: 6px;
        background: rgb(54, 124, 160);
        background: linear-gradient(
          90deg,
          rgba(54, 124, 160, 1) 0%,
          rgba(14, 64, 115, 1) 35%,
          rgba(0, 41, 97, 1) 100%
        );
        transition: 0.2s ease-in-out;
    &:nth-child(3n) {
          margin-right: 0;
        }
    @media all and (max-width: 2200px) {
      width: 15rem;
    }
    @media all and (max-width: 1980px) {
      width: 13rem;
      height: 9rem;
    }
    @media all and (max-width:1200px){
    
      
      &:nth-child(3n) {
      margin-right: 6px;
      }
      &:nth-child(2n){
      margin-right: 0;
      }
    }
    @media all and (max-width: 980px) {
    width: 12rem;
    height: 7rem;
  }
  @media all and (max-width: 768px) {
    width: 10rem;
  }
  @media all and (max-width: 640px) {
    width: 13rem;
  }
  @media all and (max-width: 480px) {
    width: 10rem;
  }
  @media all and (max-width: 360px) {
    width: 100%;
    margin-right: 0;
    &:nth-child(3n) {
      margin-right: 0;
      }
  }
        
   
        &:hover {
          box-shadow: 0px 1px 6px 4px #333;
        }
        span {
          color: var(--white-color);
          font-weight: 700;
          font-size: 30px;
    @media all and (max-width: 980px) {
      font-size: 24px;
    }
        }
      }
    }
  }
    
  }