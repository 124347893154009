div.live-video-wrapper{
  height: auto;
  width:'100%';
  div.videoPlayer{
    background-color: var(--black-color);
    height: 100vh;
    position: relative;
    // overflow: hidden;
    div.topContainer{
        position: absolute;
        left: 3%;
        top: 5%;
        z-index: 99;
        display: flex;
        align-items: center;
        div.backButton{
            margin-right: 20px;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            &:hover{
                transform: scale(1.2);
            }
            svg{
                color: var(--white-color) !important;
                width: 35px !important;
                height: 35px !important;
            }
        }
        h1.title{
            color: var(--white-color);
            font-size: 28px;
        }
    }
    video{
        height: 100% !important;
        display: block !important;
        width: 100% !important;
    }
    .vjs_video_3-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
        padding-top: 0 !important; 
    }
    .vjs-fluid:not(.vjs-audio-only-mode) {
        padding-top: 0 !important; 
    }
    .video-js.vjs-fluid:not(.vjs-audio-only-mode){
        height: 100% !important;
    }
    @media all and (min-width:768px){
        &:hover {
          div.bottom {
            div.details {
              transform: translateY(0);
              
            }
          }
        }
      }
    div.bottom {
        width: 90%;
        position: absolute;
        // bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        height: 200px;
        overflow: hidden;
        bottom: 10%;
        @media all and (max-width:1200px){
          bottom: 8%;
        }
        @media all and (max-width:768px){
          position: relative;
          bottom: unset;
          left: unset;
          transform: unset;
          width: 100%;
          height: auto;
      }
  
        div.details {
          display: flex;
          justify-content: space-between;
          height: 100%;
          transform: translateY(200px);
          transition: 0.3s ease-in-out;
          background-color: rgba(41,36,35,0.6);
          padding: 20px;
          border-radius: 8px;
          @media all and (max-width:768px){
             flex-direction: column;
             transform: unset;
          }
         
  
          h1.heading {
            font-size: 28px;
            color: var(--white-color);
            margin-bottom: 10px;
            @media all and (max-width:768px){
              font-size: 22px;
           }
          }
          h1.title {
            font-size: 20px;
            color: var(--text-hover);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          //   width: 100%;
        
          }
          span.time{
              font-size: 14px;
              color: var(--inner-text-color);
              display: inline-block;
              margin-bottom: 10px;
          }
          p.description {
            color: var(--white-color);
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          div.left {
            width: 48%;
            height: 100%;
            @media all and (max-width:768px){
              width: 100%;
              margin-bottom: 20px;
           }
          }
          div.right {
            width: 48%;
            height: 100%;
            @media all and (max-width:768px){
              width: 100%;
           }
          }
        }
      }
  
}

div.commentsContainer {
  width: 90%;
  margin: 0 auto;
  padding: 60px 0;
  @media all and (max-width:1200px){
    width: 80%;
  }
  @media all and (max-width:768px){
    width: 90%;
  }
  @media all and (max-width:640px){
    width: 100%;
  }
  div.top {
    div.head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 10px;
      span.commentsCount {
        color: var(--white-color);
        font-size: 22px;
        @media all and (max-width:640px){
          font-size: 16px;
        }
      }

      div.sortContainer {
        select {
          background-color: transparent;
          border: 1px solid var(--border-color);
          padding: 10px 15px;
          color: var(--white-color);
          font-size: 16px;
          border-radius: 6px;
          option {
            color: var(--black-color);
          }
        }
      }
    }
    
    div.textContainer {
      display: flex;
      padding: 40px 0 20px;
      border-bottom: 1px solid var(--border-color);

      div.imageContainer {
        margin-right: 20px;
        width: 45px;
        height: 45px;
          img{
            height: 100%;
            width: 100%;
            border-radius: 50%;
          }
        span.nameAvatar {
          width: 45px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--white-color);
          background-color: green;
          border-radius: 50%;
        }
      }
      div.inputContainer {
        width: 90%;
        // border: 1px solid #e1e8ed;
        border-radius: 10px;
        // background-color: #ffffff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      
        textarea {
          display: block;
          width: 100%;
          height: 45px;
          padding: 10px 15px;
          border-radius: 5px;
          margin-bottom: 20px;
          border: 1px solid #e1e8ed;
          &:focus-visible {
            border: 3px solid var(--button-color) !important;
            outline: none;
          }
        }
      div.upload-featue{
        display: flex;
        justify-content: space-between;
        width: 100%;
        div.selectedImage {
          width: 20%;
          height: 30%;
          margin-bottom: 20px;
          img {
            max-width: 50%;
            border-radius: 5px;
            height: auto;
          }
          button.removeButton {
            position: absolute;
            background: white;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            font-size: 20px;
            color: var(--button-color);
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: background-color 0.3s ease;
          
            &:hover {
              background-color: #f2f2f2;
            }
          
            svg {
              width: 15px;
              height: 15px;
              fill: var(--button-color);
            }
          }
          
        }
     
        div.actions {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
      
          .buttonContainer {
            display: flex;
            align-items: center;
            margin-right: 10px;

      
            label.customFileUpload {
              // background-color: #1da1f2;
              color: white;
         margin-right: 10px;
              font-weight: 600;
              padding: 7px 20px;
              border-radius: 6px;
              font-size: 14px;
              cursor: pointer;
              display: flex;
              align-items: center;
              border: 1px solid #1da1f2;
              transition: 0.4s ease-in-out;
            
              &:hover {
                background-color: transparent;
                color: var(--white-color);
              }
            }
      
            input[type="file"] {
              display: none;
            }
      
            button.giphyButton {
              // background-color: #1da1f2;
              background: none;
              color: white;
              font-weight: 600;
              padding: 7px 20px;
              border-radius: 6px;
              font-size: 14px;
              cursor: pointer;
              display: flex;
              align-items: center;
              border: 1px solid #1da1f2;
              transition: 0.4s ease-in-out;
           
              &:hover {
                background-color: transparent;
                color: var(--white-color);
              }
            }
          }
      
          button.submit {
            background-color: var(--button-color);
            color: var(--white-color);
            font-weight: 600;
            padding: 10px 20px;
            border-radius: 6px;
            font-size: 14px;
            cursor: pointer;
            border: 1px solid var(--button-color);
            transition: 0.4s ease-in-out;
            &:hover {
              background-color: transparent;
              color: var(--white-color);
            }
          }
        }
      }
       
    
        
      }
      
    
      
      @media (max-width: 1200px) {
        div.inputContainer {
          width: 90%;
      
          textarea {
            height: 40px;
            padding: 8px 12px;
          }
      div.upload-featue{
        flex-direction: column;
        .selectedImage{
          width: 100%;
        }
            .actions {
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
              .buttonContainer {
                margin-bottom: 10px;
                width: 100%;
                label.customFileUpload,
                button.giphyButton {
                  width: 50%;
                  margin-bottom: 10px;
                  text-align: center;
                  justify-content: center;
                }
              }
        
              button.submit {
                width: 100%;
                text-align: center;
              }
            }
      }
    
        }
      }
      
      @media (max-width: 768px) {
        div.inputContainer {
          width: 90%;
      
          textarea {
            height: 35px;
            padding: 6px 10px;
          }
          div.upload-featue{
            flex-direction: column;
            .selectedImage{
              width: 100%;
            }
            .actions {
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
              .buttonContainer {
                flex-direction: column;
                width: 100%;
                text-align: center;
                label.customFileUpload,
                button.giphyButton {
                  width: 100%;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  text-align: center;
                  justify-content: center;
                }
              }
        
              button.submit {
                width: 100%;
                text-align: center;
              }
            }
          }
       
        }
      }
      
      @media (max-width: 480px) {
        div.inputContainer {
 
      
          textarea {
            height: 30px;
            padding: 4px 8px;
          }
          div.upload-featue{
            flex-direction: column;
            .selectedImage{
              width: 100%;
            }
            .actions {
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
              .buttonContainer {
                flex-direction: column;
                width: 100%;
        
                label.customFileUpload,
                button.giphyButton {
                  width: 100%;
                  margin-right: 0;
                  margin-bottom: 5px;
                  text-align: center;
                  justify-content: center;
                }
              }
        
              button.submit {
                width: 100%;
                text-align: center;
              }
            }
          }
        
        }
      }
      
      
  
    }
  }

  div.bottom {
    div.comments {
      div.eachComment{
        border-bottom: 1px solid var(--border-color);
        &:last-child{
          border: none;
        }
        div.containers {
          padding: 40px 0 25px;
          
          div.replyMessagesContainer {
            padding: 40px 0 0;
           
            div.containers{
              padding: 0 0 0;
              display: flex;
              border: none;
            }
           
          }
          div.commentItem {
            display: flex;
            &:last-child{
              border: none;
            }
  
            div.left {
              width: 45px;
              margin-right: 20px;
              div.profileImageContainer{
                width: 100%;
                height: 45px;
                @media all and (max-width:640px){
                  height: 41px;
                }
                img{
                  width: 100%;
                  display: block;
                  height: 100%;
                border-radius: 50%;

                }
              }
              span.nameAvatar {
                width: 100%;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--white-color);
                background-color: green;
                border-radius: 50%;
              }
            }
  
            div.right {
              width: 90%;
              @media all and (max-width:640px){
                width: 96%;
              }
              h1.name {
                font-size: 16px;
                color: var(--white-color);
              }
  
              span.time {
                font-size: 13px;
                color: var(--inner-text-color);
                font-weight: 600;
                display: inline-block;
                margin-bottom: 20px;
              }
  
              p.comment {
                font-size: 16px;
                color: var(--white-color);
                margin-bottom: 20px;
              }
              div.comment-image-container {
                margin-top: 10px;
                margin-bottom: 30px;
                width: 20%;
                height: auto;
                img {
                  max-width: 50%;
                  height: auto;
                  border-radius: 8px;
                  
                  }
                }
                
            
              div.actions {
                display: flex;
                span.item {
                  display: flex;
                  align-items: center;
                  margin-right: 20px;
                  font-size: 11px;
                  color: var(--inner-text-color);
                  transition: 0.4s ease-in-out;
                  cursor: pointer;
                  &:hover {
                    opacity: 0.6;
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                  span {
                    svg {
                      width: 15px !important;
                      height: 15px !important;
                      color: rgb(190, 190, 187);
                      fill: rgb(190, 190, 187);
                      margin-right: 5px;
                    }
                  }
                }
              }
            }
          }
         
        }

      }
    }
  }
}

    
    div.customModal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
    
      .modalContent {
        background: white;
        padding: 20px;
        border-radius: 10px;
        position: relative;
        max-width: 90%;
        max-height: 80%;
        overflow-y: auto;
    
        .closeButton {
          position: absolute;
          top: -6px;
          right: 2px;
          background: none;
          border: none;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
}

