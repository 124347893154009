div.replyField {
  display: flex;
  width: 100%;
  padding-top: 30px;
  animation: fadeIn 0.4s ease-in-out;
  &.child{
    width: 92%;
    margin: 0 0 0 auto;
  }
  div.left{
    margin-right: 20px;
      div.imageContainer {
        width: 45px;
        height: 45px;
       
          img{
            width: 100%;
            display: block;
            height: 100%;
            border-radius: 50%;

        }
          span.nameAvatar {
            width: 100%;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--white-color);
            background-color: green;
            border-radius: 50%;
          }
        }
  }
  div.right{
    width: 100%;
    div.inputContainer {
      width: 90%;
      // border: 1px solid #e1e8ed;
      border-radius: 10px;
      // background-color: #ffffff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
      textarea {
        display: block;
        width: 100%;
        height: 45px;
        padding: 10px 15px;
        border-radius: 5px;
        margin-bottom: 20px;
        border: 1px solid #e1e8ed;
        &:focus-visible {
          border: 3px solid var(--button-color) !important;
          outline: none;
        }
      }
    div.upload-featue{
      display: flex;
      justify-content: space-between;
      width: 100%;
      div.selectedImageReply {
        width: 20%;
        height: 30%;
        margin-bottom: 20px;
        img {
          max-width: 50%;
          border-radius: 5px;
          height: auto;
        }
        button.removeButton {
          position: absolute;
          background: white;
          border: none;
          border-radius: 50%;
          cursor: pointer;
          font-size: 20px;
          color: var(--button-color);
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          transition: background-color 0.3s ease;
        
          &:hover {
            background-color: #f2f2f2;
          }
        
          svg {
            width: 15px;
            height: 15px;
            fill: var(--button-color);
          }
        }
        
      }
   
      div.actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
    
        div.buttonContainer {
          display: flex;
          align-items: center;
          button.cancel{
            background-color: var(--grey-color);
            border: 1px solid var(--grey-color);
            margin-right: 10px;
              color: var(--white-color);
              font-weight: 600;
              padding: 10px 20px;
              border-radius: 6px;
              font-size: 14px;
              cursor: pointer;
              margin-bottom: 10px;
              transition: 0.4s ease-in-out;
            &:hover{
              background-color: transparent;
            }
          }
      
    
          label.customFileUploadReply {
            // background-color: #1da1f2;
            color: white;
       margin-right: 10px;
            font-weight: 600;
            padding: 7px 20px;
            border-radius: 6px;
            font-size: 14px;
            margin-bottom: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            border: 1px solid #1da1f2;
            transition: 0.4s ease-in-out;
           
            &:hover {
              background-color: transparent;
              color: var(--white-color);
            }
          }
    
          input[type="file"] {
            display: none;
          }
    
          button.giphyButton {
            // background-color: #1da1f2;
            background: none;
            color: white;
            margin-bottom: 10px;
            font-weight: 600;
            padding: 7px 20px;
            border-radius: 6px;
            font-size: 14px;
            margin-right: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            border: 1px solid #1da1f2;
            transition: 0.4s ease-in-out;
           
            &:hover {
              background-color: transparent;
              color: var(--white-color);
            }
          }
        }
        
        button.submit {
          background-color: var(--button-color);
          color: var(--white-color);
          font-weight: 600;
          margin-bottom: 10px;
          padding: 10px 20px;
          border-radius: 6px;
          font-size: 14px;
          cursor: pointer;
          border: 1px solid var(--button-color);
          transition: 0.4s ease-in-out;
          &:hover {
            background-color: transparent;
            color: var(--white-color);
          }
        }
      }
    }
     
  
      
    }
    
  
    
    @media (max-width: 1200px) {
      div.inputContainer {
        width: 90%;
    
        textarea {
          height: 40px;
          padding: 8px 12px;
        }
    div.upload-featue{
      flex-direction: column;
      .selectedImageReply{
        width: 100%;
      }
          .actions {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .buttonContainer {
            margin-bottom: 10px;
              width: 100%;
              label.customFileUploadReply,
              button.giphyButton {
                width: 20%;

                text-align: center;
                justify-content: center;
              }
            }
            button.cancel {
              width: 20%;}
            button.submit {
              width: 40%;
              text-align: center;
            }
          }
    }
  
      }
    }
    
    @media (max-width: 768px) {
      div.inputContainer {
        width: 90%;
    
        textarea {
          height: 35px;
          padding: 6px 10px;
        }
        div.upload-featue{
          flex-direction: column;
          .selectedImageReply{
            width: 100%;
          }
          .actions {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .buttonContainer {
              flex-direction: column-reverse;
              width: 100%;
              text-align: center;
              label.customFileUploadReply,
              button.giphyButton {
                width: 100%;
                margin-right: 10px;

                text-align: center;
                justify-content: center;
              }
            }
            button.cancel {
              width: 100%;}
            button.submit {
              width: 100%;
              text-align: center;
            }
          }
        }
     
      }
    }
    
    @media (max-width: 480px) {
      div.inputContainer {

    
        textarea {
          height: 30px;
          padding: 4px 8px;
        }
        div.upload-featue{
          flex-direction: column;
          .selectedImageReply{
            width: 100%;
          }
          .actions {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .buttonContainer {
              flex-direction: column-reverse;
              width: 100%;
             
              button.submit {
                width: 100%;
                text-align: center;
              }
              label.customFileUploadReply,
              button.giphyButton {
                width: 100%;
                margin-right: 0;
                margin-bottom: 5px;
                text-align: center;
                justify-content: center;
              }
              button.cancel {
                width: 100%;}
            }
      
          
           
          }
        }
      
      }
    }
      // div.inputContainer {
      //   textarea {
      //     display: block;
      //     width: 100%;
      //     height: 45px;
      //     padding: 10px 15px;
      //     border-radius: 5px;
      //     margin-bottom: 20px;
      //     &:focus-visible {
      //       border: 3px solid var(--button-color) !important;
      //       outline: none;
      //     }
      //   }
      //   div.actions {
      //     display: flex;
      //     justify-content: flex-end;

      //     p {
      //       font-size: 14px;
      //       color: var(--text-color);
      //       span {
      //         color: var(--grey-color);
      //         cursor: pointer;
      //         &:hover {
      //           text-decoration: underline;
      //         }
      //       }
      //     }

      //     div.buttonContainer {
      //       button {
      //         background-color: var(--button-color);
      //         color: var(--white-color);
      //         font-weight: 600;
      //         padding: 10px 20px;
      //         border-radius: 6px;
      //         font-size: 14px;
      //         cursor: pointer;
      //         border: 1px solid var(--button-color);
      //         transition: 0.4s ease-in-out;
      //         &:hover {
      //           background-color: transparent;
      //         }
      //         &.cancel{
      //           background-color: var(--grey-color);
      //           border: 1px solid var(--grey-color);
      //           margin-right: 20px;
      //           &:hover{
      //             background-color: transparent;
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
  }
}