.breadcrumb-item {
    + .breadcrumb-item {
        padding-left: 0;
        padding-right: var(--#{$prefix}breadcrumb-item-padding-x);

        &::before{
            float: right;
            padding-right: 0;
            padding-left: var(--bs-breadcrumb-item-padding-x);
        }
    }
}

.iq-breadcrumb{
    ol{
        padding-right: 0;
    }
}