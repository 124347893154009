div.paymentProcessingScreen { 
    padding: 100px 0;
    background-color: var(--sub-bg-color);
    .paypal-button-container {
        min-width: 326px !important;
    max-width: 284px !important;
    }
    span.success{
        color: var(--success-color);
    }
	div.wrapper { 
        @media all and (max-width:640px){
            width: 90%;
        }

    }

    div.paymentContainer{
     
            width: 90%;
            height: 50rem;
            margin: 0 auto;
            display: flex;
            justify-content: center;
        
    }
}