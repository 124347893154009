.banner-home-swiper{
    .time{
        direction: ltr;
    }

    .banner-home-swiper-image{
        &::before{
            background: linear-gradient(-90deg,
					rgba(0, 0, 0, 0.9) 0%,
					rgba(20, 20, 20, 0.9) 15%,
					rgba(83, 100, 141, 0) 100%);
        }
    }
}

.slider-ratting {
    ul {
        direction: ltr;

        li {
            margin-right: 0;
            margin-left: 0.313em;
        }
    }
}