.trending-contens {
    .trending-tab-slider {
        .trending-tab-slider-image {

            &::before {
                background: linear-gradient(-90deg,
                        rgba(var(--bs-black-rgb), 0.9) 0%,
                        rgba(20, 20, 20, 0.9) 15%,
                        rgba(83, 100, 141, 0) 100%);
            }
        }
    }

    .swiper-arrow{
        &.swiper-button-prev{
            left: auto;
            right: 0;

            i{
                display: inline-block;
                transform: scaleX(-1);
            }
        }

        &.swiper-button-next{
            left: 0;
            right: auto;

            i{
                display: inline-block;
                transform: scaleX(-1);
            }
        }
    }
}

.swiper-bg:before{
    background: linear-gradient(-90deg,
      rgba(var(--bs-black-rgb), 0.9) 0%,
      rgba(20, 20, 20, 0.9) 15%,
      rgba(83, 100, 141, 0) 100%);
}