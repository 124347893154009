div.logoutAll{
    div.overlayModal{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 999999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    div.closeIconContainer{
        position: fixed;
        top: 20px;
        right: 30px;
        z-index: 9999999;
        svg{
            width: 20px !important;
            height: 20px !important;
        }
    }
    div.contents{
        z-index: 9999999;
        text-align: center;
        position: fixed;
        left: 50%;
        // right: 0;
        top: 50%;
        // bottom: 0;
        transform: translate(-50%,-50%);
        background-color: var(--bg-color);
        width: 40%;
        margin: 0 auto;
        padding: 60px 60px 60px;
        display: flex;
        flex-direction: column;
        @media all and (max-width:1200px){
            padding: 30px;
        }
        @media all and (max-width:768px){
            width: 80%;
        }
        @media all and (max-width:480px){
            width: 90%;
        }
        h1.error{
            font-size: 20px;
            color: #E72A31;
            margin-bottom: 10px;
        }
        p{
            color: var(--white-color);
            font-size: 16px;
        }
       h1{
        color: var(--white-color);
        font-size: 18px;
        @media all and (max-width:768px){
            font-size: 16px;
        }
       }
       div.buttonContainer {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        button {
          background-color: var(--button-color);
          padding: 10px 12px;
          color: var(--text-color);
          font-size: 13px;
          cursor: pointer;
          width: 10rem;
          border-radius: 5px;
          &:first-child{
            margin-right: 6px;
          }
          
        }
      }
    }
}