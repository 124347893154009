/*!
* Version: 2.0.0
* Template: Streamit - Responsive Bootstrap 5.3.2 Template
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for Template.
*
*/

// scss-docs-start import-stack
// Configuration
@import "./bootstrap/functions";
// Streamit Design system
@import "./streamit-design-system/variables";
@import "./streamit-design-system/variables-dark";
@import "./bootstrap/variables";
@import "./bootstrap/variables-dark";
@import "./bootstrap/maps";
@import "./bootstrap/mixins";
@import "./bootstrap/utilities";

// Custom Mixin And Helper
@import "./streamit-design-system/helper/mixins";
@import "./streamit-design-system/helper/reboot";
@import "./streamit-design-system/helper/root";

// ui kit
@import "custom/ui-kit/ui-kit";

// Banner
@import "./custom/slider/slider";

// Widget
@import "./custom/widget/widget";

// Pages
@import "./custom/pages/index";

// Plugin
@import "./streamit-design-system/plugins/prism";