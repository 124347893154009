.iq-watching-block {
  .block-images {
    .iq-preogress {

      .progress-bar-height {
        height: 1px;
      }

      .data-left-timing {
        padding-left: 0;
        padding-right: 20px;
        text-align: right;
      }
    }
  }
}