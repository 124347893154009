div.ceyFlixSubscriptionPage {
    padding: 100px 0 0;
    div.subscriptionFeatures {
        padding: 10px 100px 100px;
        color: var(--text-color);

        h3 {
            width: 100%;
            margin: 0 0 30px;
            text-align: center;
            color: var(--inner-text-color);
        }

        div.subscriptionConditionList {

            ul.conditionList {
                // list-style:decimal;

                li {
                    color: var(--inner-text-color);
                    margin: 13px 0;
                    list-style:decimal;
                }
            }
        }

        @media all and (max-width:1024px) {
            padding: 10px 0 80px ;
            margin: 0 15px 0 15px;
        }
    }
}