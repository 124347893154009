div.autoplayComponent{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color:#000;
    height: 100%;
    z-index: 9;
    div.content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        div.progressBar{
            width: 100px;
            height: 100px;
        }
        span.timer{
            display: inline-block;
            margin-top: 10px;
            font-weight: 600;
            color: var(--white-color);
        }
    }
}