div.cancelSub{
    div.overlayModal{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 999999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    div.closeIconContainer{
        position: fixed;
        top: 20px;
        right: 30px;
        z-index: 999999;
        cursor: pointer;
        svg{
            width: 20px !important;
            height: 20px !important;
            stroke:#E72A31 !important;
        }
    }
    div.contents{
        z-index: 9999999;
        text-align: center;
        position: fixed;
        left: 50%;
        // right: 0;
        top: 50%;
        // bottom: 0;
        transform: translate(-50%,-50%);
        background-color: var(--bg-color);
        width: 40%;
        margin: 0 auto;
        padding: 60px 60px 60px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        @media all and (max-width:1200px){
            padding: 30px;
        }
        @media all and (max-width:768px){
            width: 80%;
        }
        @media all and (max-width:480px){
            width: 90%;
        }
        h1.error{
            font-size: 20px;
            color: #E72A31;
            margin-bottom: 10px;
        }
        p{
            color: var(--white-color);
            font-size: 16px;
        }
       h1{
        color: var(--white-color);
        font-size: 18px;
        @media all and (max-width:768px){
            font-size: 16px;
        }
       }
       div.buttons{
        display: flex;
        justify-content: center;
        margin-top: 20px;
       button.bg-button{
           margin: 20px auto 0;
           width: 30%;
       }
       
       div.buttonContainer { 
           display: flex;
       padding: 18px 20px;
       border-radius: 4px;
       align-items: center;
       margin-bottom: 15px;
       cursor: pointer;
       margin-right: 20px;
       transition: 0.2s ease-in-out;
       justify-content: center;
       position: relative;
       width: 10rem;
       &:last-child{
        margin-right:0;
        div.background{
            background: var(--error-color);
        }
       }
       @media all and (max-width:640px){
           width: 15rem;
           padding: 10px 20px;
       }
       @media all and (max-width:480px){
           width: 10rem;
       }
       &:hover{
           div.background{

               transform: scale(1.0476190476,1.0357142857);
           }
       }
           div.background{
               background: var(--success-color);
               position: absolute;
               top: 0;
               left: 0;
               right: 0;
               bottom: 0;
               border-radius: 4px;
               transition: 0.1s ease-in-out;
               z-index: 1;

           }
           button { 
               background: transparent;
               font-size: 15px;
               color: var( --text-color);
               font-weight: 600;
               position: relative;
               z-index: 2;
               cursor: pointer;


           }
       }
   }
    }
}