.iq-card {
    .block-social-info {
        .share-wrapper {
            right: -0.375rem;
            left: 0;
        }
    }
    .share-boxs {
        .share-shape {
            right: .75rem;
            left: auto;
        }
    }
}

.card-style-slider {
    li.swiper-slide { 
        padding: 5px !important;
        &.swiper-slide-active {
            .card-hover {
                &:hover {
                .block-images {
                    transform: scale3d(1.1, 1.1, 1) translate3d(-12%, 0, 0) perspective(31.25rem);
                }
                }
            }
        } 
        &.swiper-active.last {
            .card-hover {
            &:hover {
                .block-images {
                transform: scale3d(1.1, 1.1, 1) translate3d(4%, 0, 0) perspective(31.25rem);
                }
            }
            }
        }
    }
  }
  
