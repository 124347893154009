div.resetPassword { 
    background: linear-gradient(354deg, rgba(20,20,20,1) 0%, rgba(20,20,20,1) 38%, rgba(129,17,23,1) 100%);

    padding: 150px 0 ;

      span.error{
        color: #E72A31;
        font-size: 12px;
       text-align: left;
       display: block;
       margin-bottom: 10px;

    
  }
  span.success{
      font-size: 14px;
      display: block;
      text-align: center;
      margin-top: 10px;
  }
  h1.heading { 
        font-size: 34px;
        padding-bottom: 10px;
        text-align: center;
        color: var(--white-color);
        @media all and (max-width:640px){
            font-size: 22px;
            margin: 0 20px;
        }
  }
  
  div.contents { 
        margin-top: 40px;
        @media all and (max-width:480px){
            padding: 0 10px;
        }
    
  
  
  
    div.formSection { 
            width: 30%;
            margin: 0 auto;
            @media all and (max-width:1200px){
                width: 55%;
            }
            @media all and (max-width:980px){
                width: 75%;
            }
            @media all and (max-width:640px){
                width: 100%;
            }
            form{
              div.inputContainer{
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 20px;
                  &:nth-child(3){
                      margin-bottom: 60px;
                  }
                  label{
                      font-size: 16px;
                      margin-bottom: 8px;
                      color: var(--white-color);
                  }
                  input{
                      outline: none;
                      font-size: 14px;
                      background: transparent;
                      text-align: left;
                    padding: 20px 15px;
                    border: 1px solid var(--grey-color);
                    caret-color: var(--white-color);
                    color: var(--white-color);
                    margin-bottom: 10px;
                  border-radius: 5px;

                    &:focus{
                        border: 1px solid var(--white-color);
                    }
                    @media all and (max-width:640px){
                        padding: 16px 15px;
                    }
                  }
              }
              div.buttonContainer { 
                display: flex;
            padding: 18px 20px;
            align-items: center;
            margin-bottom: 15px;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            justify-content: center;
            position: relative;
            width: 20rem;
            margin: 0 auto;
            @media all and (max-width:640px){
                width: 15rem;
            }
            @media all and (max-width:480px){
                width: 10rem;
            }
            &:hover{
                div.background{

                    transform: scale(1.0476190476,1.0357142857);
                }
            }
                div.background{
                    background: hsla(226,7%,61%,.16);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    transition: 0.1s ease-in-out;
                    z-index: 1;
                  border-radius: 5px;


                }
                button { 
                    background: transparent;
                    font-size: 15px;
                    color: var( --white-color);
                    font-weight: 600;
                    position: relative;
                    z-index: 2;
                    cursor: pointer;


                }
            }
            }
    }
  }
  }