.tab-slider-banner {
    position: relative;

    .tab-slider-banner-images{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &::before {
            position: absolute;
            content: "";
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0.1875rem;
            background: linear-gradient(90deg, rgba(var(--bs-black-rgb), 0.9) 0%, rgba(20, 20, 20, 0.9) 15%, rgba(83, 100, 141, 0) 100%);
        }
    }
}

.tab-block {
    border-radius: 0.1875rem;
    background: var(--bs-gray-900);
    padding: 2.19rem 0;

    .tab-title {
        padding: 0 2.19rem;
        margin-bottom: .94rem;
    }

    .nav-tabs {
        padding: 0 2.19rem;
        gap: 2.81rem;
        &::-webkit-scrollbar {
            display: none;
          }
        .nav-link {
            padding: 1rem 0;
        }
    }

    .tab-content {
        padding: 0 2.19rem;

        .tab-pane {
            li {
                margin-bottom: 1.25rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .image-box {
                img {
                    width: 7.5rem;
                    height: 5.3125rem;
                    object-fit: cover;
                }
            }
        }
    }
}

.tab-slider {
    .tab-block {
        .tab-pane {
            max-height: 25rem;
            overflow-y: auto;
            &::-webkit-scrollbar {
                display: none;
              }
        }
    }
}

@media (max-width:767px) {
    .tab-block {
        .tab-content {
            .tab-pane {
                .image-box {
                    img {
                        width: 5rem;
                    }
                }
            }
        }
    }
}