div.homePage {
  // overflow-x: hidden;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: var(--bg-color);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-bottom: 49px;
  &::-webkit-scrollbar {
    display: none;
  }
  .css-iz3z40-MuiDialog-container {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .swiper {
    overflow: visible;
  }
  // .swiper-button-disabled {
  //   display: none !important;
  // }

  div.allCategories{
    // margin-top: -269px;
    margin-top: 25px;
    // @media all and (max-width:400px){
    //   margin-top: -226px;
    // }
    @media all and (max-width:1500px){
      margin-top: -226px;
    }
    @media all and (max-width:4500px){
      margin-top: -226px;
    }
    @media all and (max-width:1400px){
      margin-top: -180px;
    }
    @media all and (max-width:1200px){
      margin-top: -160px;
    }
    @media all and (max-width:980px){
      margin-top: -147px;
    }
    @media all and (max-width:768px){
      margin-top: -25px;
    }
    @media all and (max-width:640px){
      margin-top: -11px;
    }
    @media all and (max-width:480px){
      margin-top: -37px;
    }
  }

  div.allCategoriesWithoutBanner{
    margin-top: 230px;

    @media all and (max-width:980px){
      margin-top: 70px;
    }
    
  }

  div.showInformationContainer {
    position: fixed;
    z-index: 999999999;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--overlay);
    padding-top: 100px;
    display: none;

    // top: 50%;
    // transform: translate(-50%,-50%);
    overflow-y: scroll;
    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    &.active {
      display: block;
      animation: fadeIn 0.6s ease-in-out;
      div.info {
        transform: scale(1);
      }
    }

    div.info {
      transform: scale(0);
      transition: 1s ease-in-out;
      background-color: var(--bg-color);
      width: 50%;
      margin: 0 auto;
      animation: pop 0.3s linear 1;
      // position: absolute;
      // left: 50%;
      // transform: translate(-50%,5%);
      &::-webkit-scrollbar {
        border-radius: 8px;
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--button-color);
        border-radius: 30px;
      }
      &::-webkit-scrollbar-track {
        background-color: var(--overlay);
      }
    }
  }
  div.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--overlay);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.LoadMoreButtonContainer{
    text-align: center;
    margin-top: -130px;
    @media all and (max-width:980px){
      margin-top: 20px;
    }
    button{
      width: 8rem;
      padding: 6px 1px 9px;
      border-radius: 2px;
      background-color: var(--secondary-color);
      color: var(--white-color);
      cursor: pointer;
      transition: 0.3s ease-in-out;
      margin: 0 auto;
      &:hover{
        background-color: var(--secondary-hover-color);
      }
      span{
        font-size: 14px;
      }

    }
  }
  
  @keyframes pop {
    50% {
      transform: scale(1.2);
    }
  }
}
