div.videoPlayerContainer {
  position: relative;
  padding: 100px 0 0;

  // overflow: hidden;
  div.arrowContainer {
    width: 5%;
    cursor: pointer;

    h3 {
      width: 100%;
      background: transparent;
      cursor: pointer;

      svg {
        width: 30px;
        height: 100%;
        fill: white !important;
      }
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }


  div.playerContainer {
    width: 90%;
    margin: 0 auto;
    background-color: var(--black-color);
    position: relative;

    div.donationContainer {
      div.bannerContainer {
        position: relative;

        div.overlay {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: rgba(000, 000, 000, 0.6);
        }

        div.donate {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 70%;
          text-align: center;

          @media all and (max-width:1200px) {
            width: 80%;
          }

          @media all and (max-width:768px) {
            width: 100%;
          }

          h1 {
            text-align: center;
            color: var(--white-color);
            font-weight: 400;
            margin-bottom: 10px;

            @media all and (max-width:1200px) {
              font-size: 25px;
            }

            @media all and (max-width:980px) {
              font-size: 20px;
            }

            @media all and (max-width:640px) {
              font-size: 14px;
            }
          }

          div.buttonContainer {
            button {
              background-color: var(--button-color);
              color: var(--white-color);
              font-weight: 600;
              padding: 10px 20px;
              border-radius: 6px;
              font-size: 14px;
              cursor: pointer;
              border: 1px solid var(--button-color);
              transition: 0.4s ease-in-out;
              width: 12rem;

              &:hover {
                background-color: transparent;
              }

              @media all and (max-width:640px) {
                width: 8rem;
                padding: 8px 20px;
                font-size: 13px;

              }

              @media all and (max-width:480px) {
                width: 6rem;
                padding: 5px 20px;
                font-size: 12px;

              }
            }
          }
        }

        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
  div.multiAudio {
    padding: 10px 0;
    // width: 90%;
    // margin: 0 auto;
    width: 65%;
    margin-left: 5%;
    @media all and (max-width:980px) {
      width: 80%;
    }
    @media all and (max-width:640px) {
    width: 95%;
    }
    @media all and (max-width:480px) {
      width: 55%;
      }
    @media all and (max-width:360px) {
        width: 70%;
        }
    div.selectContainer{
        width: 40%;
        @media all and (max-width:480px) {
           width: 100%;
        }

    }
}
  video {
    height: 100% !important;
    display: block !important;
    width: 100% !important;
  }

  // .vjs_video_3-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
  //     padding-top: 0 !important;
  // }
  // .vjs-fluid:not(.vjs-audio-only-mode) {
  //     padding-top: 0 !important;
  // }
  // .video-js.vjs-fluid:not(.vjs-audio-only-mode){
  //     height: 100% !important;
  // }
  div.videoMetaData {
    width: 90%;
    margin: 0 auto;
    padding-top: 30px;

    div.head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      @media all and (max-width:980px) {
        flex-direction: column;
        align-items: flex-start;
      }

      h1.videoTitle {
        color: var(--white-color);
        font-size: 30px;
      }

      div.buttonContainer {
        display: flex;

        @media all and (max-width:980px) {
          justify-content: flex-start;
          margin-top: 10px;
        }

        button {
          background-color: var(--button-color);
          color: var(--white-color);
          font-weight: 600;
          padding: 10px 20px;
          border-radius: 6px;
          font-size: 14px;
          cursor: pointer;
          border: 1px solid var(--button-color);
          transition: 0.4s ease-in-out;

          &:hover {
            background-color: transparent;
          }

          &.myList {
            margin-right: 20px;
          }
        }
      }
    }

    div.categoriesAndRating {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      flex-wrap: wrap;
    }

    div.categories {
      display: flex;
      flex-wrap: wrap;
    }

    span.category {
      font-size: 16px;
      color: var(--inner-text-color);
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box;
    }

    span.category::after {
      content: "•";
      margin: 0 5px 0 10px;
    }

    span.category:last-child::after {
      display: none;
    }

    span.category:hover {
      text-decoration: underline;
    }

    span.rating {
      margin-left: 10px;
      padding: 2px 6px;
      background-color: var(--grey-color);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      border-radius: 3px;
    }


    @media (max-width: 600px) {
      div.categoriesAndRating {
        flex-direction: column;
        align-items: flex-start;
      }

      span.rating {
        margin-top: 10px;
        margin-left: 0;
      }

      div.categories {
        width: 100%;
      }

      span.category {
        white-space: normal;
      }
    }

    p.description {
      color: var(--inner-text-color);
      margin-bottom: 20px;
    }

    div.extraInfoContainer {
      div.metaItem {
        margin-bottom: 20px;

        h6.subHeading {
          font-size: 16px;
          color: var(--white-color);
          margin-bottom: 10px;
        }

        div.items {
          width: 30%;

          &.oneRow {
            display: flex;
            width: 60%;
            flex-wrap: wrap;

            span.item {
              margin-right: 10px;
              margin-bottom: 0;
              display: block;
              color: var(--inner-text-color);
              cursor: pointer;

              &:last-child {
                span.comma {
                  display: none;
                }
              }
            }

          }

          span.itemInfo {
            cursor: pointer;
          }

          div.item {
            display: flex;
            justify-content: space-between;
            color: var(--inner-text-color);
            margin-bottom: 6px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  div.upNextContainer {
    width: 90%;
    margin: 0 auto;

    h1.heading {
      text-align: left;
      color: var(--heading-color);
      display: flex;
      width: 90%;
      margin: 0 auto;
      flex-wrap: wrap;

      @media all and (max-width:980px) {
        width: 100%;
      }

      @media all and (max-width:768px) {
        font-size: 24px;
      }
    }

    div.upNext {
      display: flex;
      width: 90%;
      margin: 0 auto;
      flex-wrap: wrap;
      margin-top: 20px;

      @media all and (max-width:980px) {
        width: 100%;
      }

      div.item {
        width: 32.5%;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
          margin-bottom: 0;
        }

        @media all and (max-width:1200px) {
          width: 32%;
        }

        @media all and (max-width:768px) {
          width: 48.5%;
          margin-bottom: 20px;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @media all and (max-width:480px) {
          width: 100%;
          margin-right: 0;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }

      div.itemPortrait {
        width: 15%;
        margin-right: 10px;
        margin-bottom: 20px;

        &:last-child {
          margin-right: 0;
          margin-bottom: 0;
        }

        @media all and (max-width:980px) {
          width: 20%;
        }

        @media all and (max-width:768px) {
          width: 23%;


          // &:nth-child(2n) {
          //     margin-right: 0;
          // }
        }

        @media all and (max-width:640px) {
          width: 30%;
        }

        @media all and (max-width:480px) {
          width: 46%;

        }

        @media all and (max-width:360px) {
          width: 45%;

        }
      }
    }
  }

  span.line {
    height: 1px;
    background-color: var(--border-color);
    width: 100%;
    display: block;
    margin-top: 70px;
  }

  div.files {
    width: 90%;
    margin: 0 auto;
  }

  div.commentsContainer {
    width: 70%;
    margin: 0 auto;
    padding: 60px 0;

    @media all and (max-width:1200px) {
      width: 80%;
    }

    @media all and (max-width:768px) {
      width: 90%;
    }

    @media all and (max-width:640px) {
      width: 100%;
    }

    div.top {
      div.head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 10px;

        span.commentsCount {
          color: var(--white-color);
          font-size: 22px;

          @media all and (max-width:640px) {
            font-size: 16px;
          }
        }

        div.sortContainer {
          select {
            background-color: transparent;
            border: 1px solid var(--border-color);
            padding: 10px 15px;
            color: var(--white-color);
            font-size: 16px;
            border-radius: 6px;

            option {
              color: var(--black-color);
            }
          }
        }
      }

      div.textContainer {
        display: flex;
        padding: 40px 0 20px;
        border-bottom: 1px solid var(--border-color);

        div.imageContainer {
          margin-right: 20px;
          width: 45px;
          height: 45px;

          img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
          }

          span.nameAvatar {
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--white-color);
            background-color: green;
            border-radius: 50%;
          }
        }

        div.inputContainer {
          width: 90%;
          // border: 1px solid #e1e8ed;
          border-radius: 10px;
          // background-color: #ffffff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

          textarea {
            display: block;
            width: 100%;
            height: 45px;
            padding: 10px 15px;
            border-radius: 5px;
            margin-bottom: 20px;
            border: 1px solid #e1e8ed;

            &:focus-visible {
              border: 3px solid var(--button-color) !important;
              outline: none;
            }
          }

          div.upload-featue {
            display: flex;
            justify-content: space-between;
            width: 100%;

            div.selectedImage {
              width: 20%;
              height: 30%;
              margin-bottom: 20px;

              img {
                max-width: 50%;
                border-radius: 5px;
                height: auto;
              }

              button.removeButton {
                position: absolute;
                background: white;
                border: none;
                border-radius: 50%;
                cursor: pointer;
                font-size: 20px;
                color: var(--button-color);
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                transition: background-color 0.3s ease;

                &:hover {
                  background-color: #f2f2f2;
                }

                svg {
                  width: 15px;
                  height: 15px;
                  fill: var(--button-color);
                }
              }

            }

            div.actions {
              display: flex;
              justify-content: flex-end;
              align-items: flex-start;

              .buttonContainer {
                display: flex;
                align-items: center;
                margin-right: 10px;


                label.customFileUpload {
                  // background-color: #1da1f2;
                  color: white;
                  margin-right: 10px;
                  font-weight: 600;
                  padding: 7px 20px;
                  border-radius: 6px;
                  font-size: 14px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  border: 1px solid #1da1f2;
                  transition: 0.4s ease-in-out;

                  &:hover {
                    background-color: transparent;
                    color: var(--white-color);
                  }
                }

                input[type="file"] {
                  display: none;
                }

                button.giphyButton {
                  // background-color: #1da1f2;
                  background: none;
                  color: white;
                  font-weight: 600;
                  padding: 7px 20px;
                  border-radius: 6px;
                  font-size: 14px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  border: 1px solid #1da1f2;
                  transition: 0.4s ease-in-out;

                  &:hover {
                    background-color: transparent;
                    color: var(--white-color);
                  }
                }
              }

              button.submit {
                background-color: var(--button-color);
                color: var(--white-color);
                font-weight: 600;
                padding: 10px 20px;
                border-radius: 6px;
                font-size: 14px;
                cursor: pointer;
                border: 1px solid var(--button-color);
                transition: 0.4s ease-in-out;

                &:hover {
                  background-color: transparent;
                  color: var(--white-color);
                }
              }
            }
          }



        }



        @media (max-width: 1200px) {
          div.inputContainer {
            width: 90%;

            textarea {
              height: 40px;
              padding: 8px 12px;
            }

            div.upload-featue {
              flex-direction: column;

              .selectedImage {
                width: 100%;
              }

              .actions {
                flex-direction: column;
                align-items: flex-start;
                width: 100%;

                .buttonContainer {
                  margin-bottom: 10px;
                  width: 100%;

                  label.customFileUpload,
                  button.giphyButton {
                    width: 50%;
                    margin-bottom: 10px;
                    text-align: center;
                    justify-content: center;
                  }
                }

                button.submit {
                  width: 100%;
                  text-align: center;
                }
              }
            }

          }
        }

        @media (max-width: 768px) {
          div.inputContainer {
            width: 90%;

            textarea {
              height: 35px;
              padding: 6px 10px;
            }

            div.upload-featue {
              flex-direction: column;

              .selectedImage {
                width: 100%;
              }

              .actions {
                flex-direction: column;
                align-items: flex-start;
                width: 100%;

                .buttonContainer {
                  flex-direction: column;
                  width: 100%;
                  text-align: center;

                  label.customFileUpload,
                  button.giphyButton {
                    width: 100%;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    text-align: center;
                    justify-content: center;
                  }
                }

                button.submit {
                  width: 100%;
                  text-align: center;
                }
              }
            }

          }
        }

        @media (max-width: 480px) {
          div.inputContainer {


            textarea {
              height: 30px;
              padding: 4px 8px;
            }

            div.upload-featue {
              flex-direction: column;

              .selectedImage {
                width: 100%;
              }

              .actions {
                flex-direction: column;
                align-items: flex-start;
                width: 100%;

                .buttonContainer {
                  flex-direction: column;
                  width: 100%;

                  label.customFileUpload,
                  button.giphyButton {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 5px;
                    text-align: center;
                    justify-content: center;
                  }
                }

                button.submit {
                  width: 100%;
                  text-align: center;
                }
              }
            }

          }
        }



      }
    }

    div.bottom {
      div.comments {
        div.eachComment {
          border-bottom: 1px solid var(--border-color);

          &:last-child {
            border: none;
          }

          div.containers {
            padding: 40px 0 25px;

            div.replyMessagesContainer {
              padding: 40px 0 0;

              div.containers {
                padding: 0 0 0;
                display: flex;
                border: none;
              }

            }

            div.commentItem {
              display: flex;

              &:last-child {
                border: none;
              }

              div.left {
                width: 45px;
                margin-right: 20px;

                div.profileImageContainer {
                  width: 100%;
                  height: 45px;

                  @media all and (max-width:640px) {
                    height: 41px;
                  }

                  img {
                    width: 100%;
                    display: block;
                    height: 100%;
                    border-radius: 50%;

                  }
                }

                span.nameAvatar {
                  width: 100%;
                  height: 45px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: var(--white-color);
                  background-color: green;
                  border-radius: 50%;
                }
              }

              div.right {
                width: 90%;

                @media all and (max-width:640px) {
                  width: 96%;
                }

                h1.name {
                  font-size: 16px;
                  color: var(--white-color);
                }

                span.time {
                  font-size: 13px;
                  color: var(--inner-text-color);
                  font-weight: 600;
                  display: inline-block;
                  margin-bottom: 20px;
                }

                p.comment {
                  font-size: 16px;
                  color: var(--white-color);
                  margin-bottom: 20px;
                }

                div.comment-image-container {
                  margin-top: 10px;
                  margin-bottom: 30px;
                  width: 20%;
                  height: auto;

                  img {
                    max-width: 50%;
                    height: auto;
                    border-radius: 8px;

                  }
                }


                div.actions {
                  display: flex;

                  span.item {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    font-size: 11px;
                    color: var(--inner-text-color);
                    transition: 0.4s ease-in-out;
                    cursor: pointer;

                    &:hover {
                      opacity: 0.6;
                    }

                    &:last-child {
                      margin-right: 0;
                    }

                    span {
                      svg {
                        width: 15px !important;
                        height: 15px !important;
                        color: rgb(190, 190, 187);
                        fill: rgb(190, 190, 187);
                        margin-right: 5px;
                      }
                    }
                  }
                }
              }
            }

          }

        }
      }
    }
  }

  div.customModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .modalContent {
      background: white;
      padding: 20px;
      border-radius: 10px;
      position: relative;
      max-width: 90%;
      max-height: 80%;
      overflow-y: auto;

      .closeButton {
        position: absolute;
        top: -6px;
        right: 2px;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
}

.vjs-control-bar {
  height: 60px !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.vjs-control {
  font-size: 20px;
  width: 50px;
  height: 50px;
  color: #fff;
}

.vjs-control>.vjs-icon-placeholder,
.vjs-control>.vjs-icon-play,
.vjs-control>.vjs-icon-pause,
.vjs-control>.vjs-icon-volume-high,
.vjs-control>.vjs-icon-captions,
.vjs-control>.vjs-icon-fullscreen-enter,
.vjs-control>.vjs-icon-fullscreen-exit {
  font-size: 20px !important;
  background: #fff;
  color: #fff;

}

.vjs-remaining-time-display {
  display: none;
}

.vjs-live-display {
  font-size: 15px;
  position: absolute;
  bottom: 15px;
}