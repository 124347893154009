.body-class-1 {
  scroll-behavior: smooth;
}

.main-img {
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;

    h1 {
      margin: 0;
      font-size: 4.375rem;
      color: $white;

      span {
        padding: 0.375rem 0.875rem;
        display: inline-block;
      }
    }
  }
}

.bd-heading-1 {
  .bd-example {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0rem;
    }
  }
}

.bd-cheatsheet {
  section {
    article {
      .card {
        &.iq-document-card {
          padding: $spacer * 1.5;

          .tab-content {
            pre {
              &.language-markup {
                margin: unset;
              }
            }
          }
        }
      }
    }
  }
}

.bd-aside {
  &.card {
    &.iq-document-card {
      padding: $spacer * 1.5;
    }
  }
}

/* Table of contents */
.bd-aside {
  a {
    padding: .25rem .5rem;
    margin-top: .125rem;
    margin-left: .25rem;
    color: rgba($body-color, .95);
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: rgba(var(--bs-primary-rgb), .1);
    }
  }

  .active {
    font-weight: 600;
    background-color: rgba(var(--bs-primary-rgb), .1);
    color: var(--bs-primary);
  }

  .btn {
    padding: .25rem .5rem;
    font-weight: 600;
    border: 0;

    .right-icon {
      transition: transform .35s ease;
    }

    &:hover,
    &:focus {
      background-color: rgba(var(--bs-primary-rgb), .1);
      color: var(--bs-primary);
    }
  }

  .btn[aria-expanded="true"] {
    .right-icon {
      transform: rotate(90deg);
    }
  }
}

.scrollspy-example {
  position: relative;
  height: 12.5rem;
  margin-top: .5rem;
  overflow: auto;
}


[id="modal"] .bd-example .btn,
[id="buttons"] .bd-example .btn,
[id="tooltips"] .bd-example .btn,
[id="popovers"] .bd-example .btn,
[id="dropdowns"] .bd-example .btn-group,
[id="dropdowns"] .bd-example .dropdown,
[id="dropdowns"] .bd-example .dropup,
[id="dropdowns"] .bd-example .dropend,
[id="dropdowns"] .bd-example .dropstart {
  // margin: 0 1rem 1rem 0;
  margin-top: .5rem;
}

/* Layout */
@media (min-width: 1200px) {
  .body-class-1 {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: auto;
  }

  .bd-aside {
    &.sticky-xl-top {
      top: 2rem !important;
    }
  }

  .bd-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    grid-column: 1 / span 3;
  }

  .sticky-xl-top {
    z-index: 2;
  }

  .bd-cheatsheet,
  .bd-cheatsheet section,
  .bd-cheatsheet article {
    display: grid;
    gap: $grid-gutter-width;
    grid-template-columns: 3fr 1fr;
    grid-column: span 2 / span 2;
    grid-template-rows: auto;
  }

  .bd-aside {
    grid-area: 1 / 1;
    scroll-margin-top: 4rem;
  }

  .bd-cheatsheet section,
  .bd-cheatsheet section>.iq-side-content {
    top: 2rem;
    scroll-margin-top: 0;
  }

  .bd-cheatsheet {

    // grid-area: 1 / 2;
    article,
    .bd-heading {
      top: calc(7.2rem + #{$grid-gutter-width});
      scroll-margin-top: calc(7.8rem + #{$grid-gutter-width});
    }

    .bd-heading {
      z-index: 1;
      order: 2;

      .card {
        margin-bottom: 0;
      }
    }

    article {
      .iq-document-card {
        margin-bottom: unset;

        &.iq-doc-head {
          margin-top: -8.8rem;
        }
      }
    }
  }
}

//***********************************
// off canvas
//*********************************// 

@media (min-width: 768px) and (max-width: 1200px) {
  .mobile-offcanvas {
    width: calc(100% - 60%) !important;
  }
}


@media (max-width: 1199px) {
  .header {
    .container {
      max-width: unset !important;
    }
  }

  aside {
    &.mobile-offcanvas {
      margin-top: unset !important;

      .offcanvas-header {
        display: block;
      }
    }
  }

  .body-class-1 {
    padding: unset;

    &.container {
      max-width: unset;
    }
  }

  #content {
    .iq-side-content {
      .card {
        margin-top: 1.25rem;
      }
    }
  }

  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1200;
    width: calc(100% - 25%);
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility .3s ease-in-out, transform .3s ease-in-out;
    background: #ffffffd6;
    backdrop-filter: blur(0.5rem);
  }

  .mobile-offcanvas {
    &.show {
      visibility: visible;
      transform: translateY(0);
    }

    .container,
    .container-fluid {
      display: block;
    }
  }
}

@media (min-width: 1200px) {
  aside {
    &.mobile-offcanvas {
      .offcanvas-header {
        display: none;
      }
    }
  }

  .bd-cheatsheet {
    section {
      .iq-side-content {
        grid-column-end: 3;

        .card {
          margin-bottom: 0;
        }
      }
    }
  }
}

body.offcanvas-active {
  overflow: hidden;
}

body.uikit {
  .btn-download {
    display: none;
  }
}

.uisheet {
  &.screen-darken {
    height: 100%;
    width: 0%;
    z-index: 30;
    position: fixed;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba($gray-900, 0.6);
    transition: opacity .2s linear, visibility 0.2s, width 2s ease-in;

    &.active {
      z-index: 10;
      transition: opacity .3s ease, width 0s;
      opacity: 1;
      width: 100%;
      visibility: visible;
    }
  }
}

.middle {
  display: inline !important;

  .mid-menu {
    position: fixed;
    top: 9.375rem;
    left: 0rem;
    z-index: 999;
    text-align: center;
    cursor: pointer;
    background: var(--bs-primary);
    color: $white;
    padding: 0.2rem;
    border-radius: 0 1rem 1rem 0;
  }
}

.top-1 {
  top: $spacer;
}

.elem-list {
  max-height: calc(80vh + 4rem);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 10px;
  }

  /* Scroll Track */
  &::-webkit-scrollbar-track {
    background: $body-bg;
    border-radius: 10px;
  }

  /* Scroll Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba($dark, 0.2);
    border-radius: 10px;
    transition: all 400ms ease;
    cursor: pointer;
  }

  /* Scroll Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($dark, 0.2);
    border-radius: 10px;
    transition: all 400ms ease;
    cursor: pointer;
  }
}

.card {
  .card-body {
    .iq-media-group-1 {
      .iq-media-1 {
        margin-left: -1.25rem;
        position: relative;
        z-index: 0;

        @if $enable-transitions==true {}

        &:hover {
          z-index: 9;
        }

        img {
          border: 2px solid $border-color;
        }

        &:first-child {
          margin-left: 0;
        }
      }

    }

    .iq-media-1 {
      display: inline-flex;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      &:hover {
        text-decoration: none;
      }

      border-radius: $border-radius-sm;
    }
  }
}