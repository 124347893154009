@import "./Components/Header/scss/header";
@import "./Screens/homepage/scss/features";
@import "./Screens/homepage/scss/homepage";
@import "./Screens/homepage/scss/categories";
@import "./Components/ShowCard/scss/showCard";
@import "./Components/Footer/scss/footer";
@import "./Screens/ShowDetailsScreen/scss/detailsScreen";
@import "./Screens/Accounts/Login/scss/login";
@import "./Screens/Accounts/Register/scss/register";
@import "./Screens/Accounts/Checkout/scss/checkout";
@import "./Components/CommonPage/scss/commonPage";
@import "./Screens/PaymentStatus/PaymentFailed/scss/paymentFailed";
@import "./Screens/PaymentStatus/PaymentSuccess/scss/paymentSuccess";
@import "./Screens/Accounts/ForgotPassword/scss/forgotPassword";
@import "./Screens/Accounts/ForgotPassword/scss/ResetPassword";
@import "./Components/Header/scss/headerAccounts";
@import "./Components/Modals/scss/OtpModal";
@import "./Components/Modals/scss/continueWatching";
@import "./Components/Modals/scss/currentPasswordModal";
@import "./Components/Modals/scss/watchWithoutAds";
@import "./Components/Modals/scss/logoutAllModal";
@import "./Components/Modals/scss/cancelSubscriptionModal";
@import "./Screens/LoginRequest/scss/loginRequest";
@import "./Components/Modals/scss/playNextEpisode";
@import "./Screens/Accounts/AccountSettings/scss/accountSetting";
@import "./Screens/Accounts/AccountSettings/scss/BillingDetails";
@import "./Screens/Accounts/AccountSettings/scss/streamingActivity";
@import "./Screens/homepage/scss/live";
@import "./Components/ShowsRow/scss/showsRow";
@import "./Screens/Subscription/scss/subscription";
@import "./Screens/Subscription/scss/SubscriptionTwo";
@import "./Screens/Subscription/scss/ideabiz";
@import "./Screens/Subscription/scss/payment";
@import "./Screens/Subscription/scss/paymentProcessing";
@import "./Screens/Search/scss/search";
@import "./Screens/MyList/scss/myList";
@import "./Screens/PrivacyPolicy/scss/privacyPolicy";
@import "./Screens/TermsAndConditions/scss/termsAndConditions";
@import "./Screens/ContactPages/scss/contactUs";
@import "./Screens/ContactPages/scss/contactSupport";
@import "./Screens/About/scss/about";
@import "./Screens/SubscriptionPlan/scss/plans";
@import "./Screens/HelpCenter/scss/help-center";
@import "./Components/Loading/scss/loading";
@import "./Components/NewsCard/NewsCard";
@import "./Screens/NewsSection/scss/LatestNews";
@import "./Screens/NewsSection/scss/newsDetails";
@import "./Screens/Blogs/scss/blogs";
@import "./Screens/LandingScreen/scss/landingScreen";
@import "./Screens/NotFound/scss/nowFound";
@import "./Components/PlayerShowCard/scss/PlayerShowCard";
@import "./Components/CategoriesShowCard/scss/CategoriesShowCard";
@import "./Components/Modals/scss/trailerModal";
@import "./Components/Loading/scss/smallLoading";
@import "./Screens/VideoPlayer/scss/videoPlayer";
@import "./Components/Loading/mainLoading";
@import "./Screens/ShowDetailsScreen/scss/detailsScreenModal";
@import "./Screens/ShowDetailsScreen/scss/episodeContainer";
@import "./Components/EpisodeCard/scss/episodeCard";
@import "./Components/SimiliarShowCard/scss/similiarShowsCard";
@import "./Components/Modals/scss/podcastModal";
@import "./Screens/LinkTvApp/linkTvApp";
@import "./Screens/EventDetailsScreenModal/scss/evenDetailsScreenModal";
@import "./Components/OpenApp/scss/openApp";
@import "./Components/Modals/scss/languageModal";
@import "./Screens/AffliatePage/scss/affliatePage";
@import "./Components/Modals/scss/loginModal";
@import "./Screens/Videoplayer_and_Comments/scss/videoplayer_new";
@import "./Screens/Videoplayer_and_Comments/Components/scss/autoplay";
@import "./Screens/Videoplayer_and_Comments/Components/scss/commentReply";
@import "./Screens/CinemaVault/scss/cinemaVault";
@import "./Components/Modals/scss/scheduleDetailsModal";
@import "./Screens/LiveChannels/scss/liveChannels";
@import "./Routes/scss/layoutsRoutes";
@import "./Components/SubscriptionPlanDetails/scss/subscriptionPlanDetails";
@import "./Screens/CeyFlixSubscriptionPage/scss/ceyFLixSubscription";
@import "./Screens/CeyFlixHelpCenterPage/scss/ceyFlixHelpCenterpage";
@import '~bootstrap/scss/bootstrap';
@import './assets/scss/streamit.scss';
@import './assets/scss/custom.scss';
@import './assets/scss/rtl.scss';
@import './Components/Modals/scss/trailerModel1';
@import './Screens/EpisodeList/scss/episodeList';
@import './Components/Modals/scss/noScheduleAvailableModal';
@import './Screens/Subscription/scss/squarePaymentProcessing';
@import './Components/Modals/scss/socialLoginModal';
@import './Screens/Shorts/scss/shorts';
@import './Screens/Shorts/ShortsComments/scss/commentRepliesShorts';
@import './Screens/B2BDetailsScreen/scss/b2bDetailsScreen';
@import './Screens/PlaylistPage/scss/playlist';
@import './Components/PlaylistCard/scss/playlistCard';
@import './Components/Modals/scss/progressBarModal';
@import './Screens/LiveChannels/scss/livePlayerCenter';
:root{
  --primary-color:#141414;
  --secondary-color:#e50914;
  --secondary-hover-color:rgba(229,9,20,0.8);
  --grey-color:#777;
  --text-hover:#b3b3b3;
  --bg-color:#000;
  // --bg-color:#141414;
  --secondary-bg-color:#313131;
  --overlay:rgba(16,20,31,.64);
  --white-color:#fff;
  --border-color:hsl(0deg 0% 100% / 15%);
  --account-settings:#1d1c1c;
  --text-color:#fff;
  --success-color:#4BB543;
  --error-color:#E72A31;
  --yellow-color:#f8d406;
  --black-color:#000;
  --button-color:#e50914;
  --primary-btn-color:#fff;
  --secondary-btn-color:rgba(109, 109, 110, 0.7);
  --primary-btn-hover:rgba(255, 255, 255, 0.75);
  --secondary-btn-hover:rgba(109, 109, 110, 0.4);
  --heading-color:#fff;
  --modal-bg-color:#ffffff;
  --sub-bg-color:#141414;
  --sub-heading-color:#e50914;
  --default-bg-color:#141414;
  --default-border-color:rgba(182, 183, 190, 0.16);
  --default-text-hover-color:#e50914;
  --transparent-color:rgba(0, 0, 0, 0.9);
  --duration-color:#46d369;
  --freeBtn-color:#2b9947;
  --freeBtn-hover:#46d369;
  --inner-text-color:#9699a3;
  --bs-primary-rgb: 229, 9, 20;
  --bs-primary: #e50914;

  
}
body{
  

    -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    .css-iz3z40-MuiDialog-container{
    }
    .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
      background-color: rgba(0, 0, 0, 0.8) !important;
      
    }
    .MuiPaper-root {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
      max-width: 779px !important;
      background-color:var(--bg-color) !important;
      color: var(--text-color) !important;
      // min-width: 51%;
      @media all and (max-width:1200px){
        // min-width: 80% !important;
        }
      @media all and (max-width:980px){
      max-width: 100% !important;
      }
    }
    .css-1k2f74c-MuiPaper-root-MuiDialog-paper {
      background-color:var(--bg-color) !important;
      color: var(--text-color) !important;


    }
    // .css-1jh78f5-MuiPaper-root-MuiDialog-paper {
    //   @media all and (max-width:980px){
    //     margin: 0 !important;
    //     }
    // }
    &.hideScroll{

      .MuiDialog-container{
          -ms-overflow-style: none ;  /* IE and Edge */
        scrollbar-width: none ;  /* Firefox */
        &::-webkit-scrollbar {
          display: none ;
        }
      }
    }
    .MuiDialog-root {
      // z-index: 9999999999;
    }
      &.showScrollbar{
    .MuiDialog-container{
      -ms-overflow-style: unset !important;  /* IE and Edge */
    scrollbar-width: unset !important;  /* Firefox */
    &::-webkit-scrollbar {
      display: block !important;
    }
  }
}
    }
.wrapper{
    width: 92%;
    margin: 0 auto;
   
}
.swiper-button-next.swiper-button-disabled {
  display: none !important;
}
.swiper-button-prev.swiper-button-disabled {
display: none !important;
}
.swiper-button-next{
// top: 22px !important;
  &::after{
      font-size: 1rem !important;
      color: var(--white-color);
      
  }
}
.swiper-button-prev{
// top: 22px !important;

  &::after{
    font-size: 1rem !important;
    color: var(--white-color);
    position: absolute;
    
}
}

.swiper-button{
    width: 1.875rem !important;
    height: 1.875rem !important;
    text-align: center;
    line-height: 1.875rem;
    color: var(--bs-white);
    background: transparent;
    border-radius: 50%;
    border: 0.0625em solid var(--bs-white);
    z-index: 1;
    transition: all 0.3s ease-in-out;
    font-size: 1em;
}

span {
  color: #fff;
}

.nav-link{
  color: #fff;
}

span.error{
	font-size: 14px;
    color: #E72A31;
    display: block;
    margin-top: 10px;
	font-weight: normal !important;
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  #progressBarContainer {
    position: fixed;
    z-index: 999999999;
    background: rgba(255,255,255,0.05);
    width: 100%;
    top: 0;
    right: 0;
}
  
  
#progressBar {
    background: linear-gradient(to left, var(--button-color), var(--button-color));
    transform-origin: top left;
    transform: scale(0,0);
    opacity: 1;
    transition: 0.3s ease-in-out;
}

#progressBarContainer,
#progressBar {
    height: 100vh;
    width: 8px;
}
div.faqs{
  padding: 100px 0;
  h1{
    text-align: center;
    color: var(--white-color);
    margin-bottom: 20px;
    
  }
  p{
    color: var(--text-color) !important;
    &.nb{
      margin-top: 20px;
    }
  }
  .accordion__button{
    background-color:var(--secondary-bg-color) ;
    color: var(--text-color);
  }
  .accordion__panel{
    background-color: #1d1c1c;
  }
}
