div.resubscribe-page {
  font-family: Avenir-Roman;
  padding: 100px 5px;
  background-color: var(--bg-color);
  min-height: 90vh;
  color: var(--text-color);
}

div.resubscribe-page div.card-details-wrapper {
  display: flex;
  justify-content: center;
}

div.resubscribe-page div.card-details-wrapper div.left {
  margin: 50px;
  background: #ddd;
  padding: 50px;
  margin-right: 0px;
}

div.resubscribe-page div.card-details-wrapper div.left div.plan {
  color: var(--text-color);
  margin-bottom: 10px;
  font-size: large;
  text-align: center;
}

div.resubscribe-page div.card-details-wrapper div.right {
  margin: 50px;
  background: #ddd;
  padding: 50px;
  margin-left: 0px;
}

div.resubscribe-page div.card-details-wrapper div.right div {
  background-color: #000;
  padding: 10px;
  width: 200px;
  text-align: center;
  margin: 10px 0px;
  border-radius: 2px;
  cursor: pointer;
  /* border: 1px solid #000; */
  transition: background-color 1s ease, filter 1s ease;
}

div.resubscribe-page div.card-details-wrapper div.right div:hover {
  background-color: var(--bg-color);
  color: var(--text-color);

}

div.resubscribe-page div.membership-wrapper {
  color: var(--text-color);
  margin: 0px 100px;
  background: var(--bg-color);
  padding: 5px;
}

div.resubscribe-page div.membership-wrapper div.membership-title {
  background: var(--bg-color);
}

div.resubscribe-page div.membership-wrapper div.plan-details-wrapper {
  margin: 0px;
  background: var(--bg-color);
  padding: 15px;
  border: 1px solid #2e2e43b8;
}

div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details-inner-wrapper {
  display: flex;
  justify-content: space-between;
}

div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details {
  /* border: 1px solid #212130; */
  border-radius: 1px;
  min-height: 100px;
  margin: 10px;
  padding: 10px;
  width: 100%;
}

div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details div.plan-title {
  font-size: large;
  font-weight: bolder;
  margin-bottom: 10px;
}

div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details div.plan-description {
  font-size: medium;
  font-weight: bolder;
  margin-bottom: 10px;
}

div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details div.plan-description div.card-details {
  display: flex;
  background: #2e64af;
  padding: 20px;
  border-radius: 2px;
  flex-direction: row;
  justify-content: start;
  min-height: 183px;
}

div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details div.plan-change {
  font-size: medium;
  font-weight: bolder;
  border-top: 1px solid #2e2e4312;
  padding: 15px;
  cursor: pointer;
  background: #2e2e434d;
  border-radius: 2px;
}

div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details div.resubscribe {
  margin-top: 67px;
  display: flex;
  justify-content: space-between;
}

div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details div.plan-change:hover {
  color: var(--text-hover)
}

.resubscribe-success {
  color: green;
  font-size: large;
  font-weight: bolder;
  background: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 2px;
}

div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details div.resubscribe:hover {
  color: var(--text-color)
}

div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details div.resubscribe div.restart-membership {
  background: var(--button-color);
  padding: 10px;
  border-radius: 2px;
  width: 400px;
  text-align: center;
  margin-left: 10px;
  align-content: center;
}

div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details div.resubscribe div.restart-membership:hover {
  background: var(--button-hover);
}

.ta-c {
  text-align: center;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30% !important;
}

.mt-10 {
  margin-top: 10px;
}

.c-green {
  color: green;
}

.resubscribe-success {
  background: #fff;
  padding: 10px;
  align-items: center;
  align-content: center;
  width: 100%;
  border-radius: 2px;
  font-weight: bold;
}

.card-details {
  background: #0e0e14;
  padding: 10px;
  border-radius: 2px;
}

@media all and (min-width:769px) and (max-width:1025px) {
  div.resubscribe-page {
    padding: 60px 5px;
  }

  div.resubscribe-page div.membership-wrapper {
    margin: 0px 5px;
  }

  div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details-inner-wrapper {
    width: 95%;
  }

  div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details div.resubscribe div.restart-membership {
    width: 140px;
    height: 45px;
    margin-top: 5px;
    align-content: center;
  }

  .w-30 {
    width: 40% !important;
  }
}


@media all and (max-width:769px) {
  div.resubscribe-page div.membership-wrapper {
    margin: 0px;
    padding: 0px;
  }

  div.resubscribe-page div.membership-wrapper div.plan-details-wrapper {
    padding: 0px;
    margin: 0px;
    margin-top: 20px;
    padding-top: 10px;
    border: transparent;
  }

  div.resubscribe-page div.membership-wrapper div.plan-details-wrapper {
    padding: 0px;
    border: transparent;
  }

  .plan-details-inner-wrapper {
    flex-direction: column;
    width: 93%;
  }

  div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details div.resubscribe {
    flex-direction: column;
  }

  div.resubscribe-page div.membership-wrapper div.plan-details-wrapper div.plan-details div.resubscribe div.restart-membership {
    width: 100%;
  }

  .w-30 {
    width: 100% !important;
  }
}