div.showCardContainer {
  width: 100%;
  cursor: pointer;
  &.noPointer{
    cursor: default;
  }
  position: relative;
  div.showMoreOverlay{
    display: none;
  }
  // &.cardHover{
  //   &:hover{
  //     .square{
  //       position: absolute;
  //       z-index: 99;
  //       transform: translateX(60px) translateY(-60px) scale(1.4);
  //       div.metaData{
  //         display: block;
  //         visibility: visible !important;
  //         animation: fadeIn 0.3s ease-in-out;
  //       }
  //     }
  //   }
  // }
  .square {
    width: 100%;
    
    transition: transform 500ms ease-out, border-radius 200ms ease-out;
   
    &.hide{
      div.metaData{
        display: none;
        @media all and (max-width:980px){
          display: block;
        }
      }
    }
      div.metaData{
        display: none;
        @media all and (max-width:980px){
          display: block;
        }

      }
    div.imageContainer {
      width: 100%;
      display: block;
      position: relative;
      overflow: hidden;
      // z-index: -1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      img {
        width: 100%;
        display: block;
        transition: 0.2s ease-in-out;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
      }
      div.premiumStatus {
        div.premium {
          position: absolute;
          top: 6px;
          left: 6px;
          z-index: 8;
          background-color: rgba(8, 25, 43, 0.65);
          // padding: 6px 6px 3px;
          width: 35px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          @media all and (max-width: 1200px) {
            width: 26px;
          }
          svg {
            width: 18px !important;
            height: 18px !important;
            @media all and (max-width: 1200px) {
              width: 12px !important;
              heigth: 12px !important;
            }
          }
        }
        div.free {
          position: absolute;
          top: 6px;
          left: 6px;
          z-index: 8;
          width: 50px;
          @media all and (max-width: 1200px) {
            width: 30px;
          }
          img {
            display: block;
            width: 100%;
          }
        }
      }
      
      div.extraActions {
        visibility: hidden;
        animation: fadeOut 0.2s ease-in-out forwards;
  
        div.showCardOverlay {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: var(--overlay);
          z-index: 8;
        }
       
       
      }
    }
    div.continueWatchingBar{
      height: 3px;
      background-color: var(--grey-color);
      width: 64%;
      margin: 10px auto 0;
      div.line{
        height: 100%; 
        background-color: var(--secondary-color);
      }
    }
    div.continueWatchingContainer{
      display: none;
      align-items: center;
      margin: 5px 0;
      div.continueWatchingBarHover{
        height: 2px;
        background-color: var(--grey-color);
        width: 70%;
        margin: 0 auto;
        div.line{
          height: 100%; 
          background-color: var(--secondary-color);
        }
      }
      div.watchedInfo{
        span{
          font-size: 10px;
          display: block;
          color: var(--text-color);
        }
      }
    }
    div.metaData {
      // display: none;
      background: #181818;
      color: #fff;
      width: 100%;
      height: fit-content;
      padding: 8px 10px;
      box-sizing: border-box;
      // transition: opacity 300ms ease-out, border-radius 200ms ease-out;
      visibility: hidden;
      transition: 0.9s ease-in-out;
      // animation: fadeOut 0.3s ease-in-out;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      @media all and (max-width:980px){
        visibility: visible;
      }
      h1.title{
        font-size: 11px;
        color: var(--text-color);
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      div.buttons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        span.material-symbols-outlined{
          font-weight: 300 !important;
          font-size: 20px !important;

        }
        svg{
          width: 100% !important;
          height: 15px !important;

        }
        button{
          background-color: rgba(42,42,42,.6);
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 1px solid hsla(0,0%,100%,.5);
          cursor: pointer;

          &.play{
            background-color: var(--white-color);
            margin-right: 4px;
            transition: 0.2s ease-in-out;
            &:hover{
              background-color: var(--primary-btn-hover);
            }
            svg{
              color: var(--black-color);
            }
          }
          &.addToList,&.moreInfo{
            color: var(--white-color);
            transition: 0.2s ease-in-out;
            position: relative;
            &:hover{
              background-color: var(--secondary-btn-hover);
              border-color: var(--white-color);
            }
          }
          &.moreInfo,&.addToList{
            &:hover{
              span.tooltip{

                display: flex;
                animation: fadeIn 0.3s ease-in-out;
                @media all and (max-width:980px){
                  display: none;
                }
              }
            }
            span.tooltip{
              display: none;
              justify-content: center;
              align-items: center;
              background-color: #d6d5d5;
              position: absolute;
              top: 0;
              left: 28px;
              width: 7rem;
              padding:3px 8px 4px;
              color: var(--bg-color);
              font-weight: 500;
              border-radius: 2px;
              font-size: 12px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              &.remove{
                width: 9rem;
              }
              &::after{
                content: "";
                position: absolute;
                top: 26%;
                left: -5px;
                margin-left: -5px;
                border-width: 6px;
                border-style: solid;
                border-color: #d6d5d5 transparent transparent transparent;             
            transform: rotate(90deg);
              
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              }

              }
          }
        }
        div.left{
          display: flex;
          align-items: center;
        }
      }
      div.extraInfo{
        margin-bottom: 6px;
        span{
          font-size: 10px;
          &.duration{
            color: var(--duration-color);
            margin-right: 6px;
            font-weight: 500;
          }
          &.rating{
            padding: 1px 6px;
            border: 1px solid var(--border-color);
            font-size: 9px;
            margin-right: 6px;
          }
        }
      }
      div.categories{
        display: flex;
        flex-wrap: wrap;
        @media all and (max-width:980px){
          display: none;
        }
        div.item{
          padding-right: 4px;
          display: flex;
          margin-bottom: 3px;
          &:first-child{
            span.dot{
              display: none;
            }
          }
          span{
            color: var(--text-color);
            font-size:10px;
            &.dot{
              font-size: 9px;
              &::before{
                color: #646464;
                content: "\2022";
                display: inline-block;
                margin-right: 4px;
              }
            }
          }

        }

      }
    }
  }
  
}

.landscape-margin-bottom{
  margin-bottom: -40px;
}

.title-margin-left{
  margin-left: -4px;
}

.swipper_button_mobile{
  @media all and (max-width:768px){
    display: none !important;
  }
}