@charset "UTF-8";
.navbar-brand img.logo {
  height: 2.375rem;
  max-height: 2.375rem;
}
.navbar-brand svg {
  width: 10rem;
}

header.header-sticky,
header .header-sticky {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  -webkit-backdrop-filter: blur(0.025em);
          backdrop-filter: blur(0.025em);
  width: 100%;
  display: inline-block;
  transition: all 0.3s ease-out 0s;
  background: rgba(0, 0, 0, 0.0392156863);
}
header.header-sticky.sticky,
header .header-sticky.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  animation: header-slide-down 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-fill-mode: initial;
  transition: all 0.3s ease-out 0s;
}
header h1.categoryName, header .categoryName.h1 {
  font-size: 14px;
  margin-bottom: 20px;
  color: grey;
}
header div.closeIcon {
  position: absolute;
  right: 5px;
  top: 30%;
  display: none;
}
@media all and (max-width: 768px) {
  header div.closeIcon {
    display: block;
  }
}
header div.closeIcon svg {
  width: 16px !important;
  height: 16px !important;
  color: var(--white-color);
}
header .navbar-toggler-icon {
  background-image: none;
  position: relative;
  background: none;
  text-align: center;
  background: var(--bs-primary);
}
header div.dropdowns {
  position: absolute;
  top: 91px;
  right: 40px;
  border-radius: 5px;
  z-index: 99999;
  display: none;
  margin: 0;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #000;
  background-clip: padding-box;
}
header .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
  width: 1360px;
  padding: 2rem 1rem;
}
header .mega-menu-content .iq-nav-menu li ul.mega-menu-item li {
  width: 16.4%;
  display: inline-block;
  border-bottom: 0;
}
header .mega-menu-content .iq-nav-menu li ul.mega-menu-item li .nav-link span {
  font-size: 1rem;
  font-weight: 400;
  padding: 1.375rem 0.9375rem;
  transition: color 400ms ease, background 400ms ease;
}
header .mega-menu-content .iq-nav-menu li ul.mega-menu-item li .nav-link:hover span {
  color: var(--bs-primary);
}
header.header-center-home .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
  left: 30%;
  transform: translateX(-30%);
}
header .navbar .sub-nav.mega-menu-item li:hover > a::before,
header .navbar .sub-nav.mega-menu-item li > a.active::before {
  content: none;
}

/*===================
Merchandise Header
========================*/
.header-merchandise .navbar .horizontal-nav {
  padding-left: 0;
}
.header-merchandise .navbar > .container-fluid, .header-merchandise .navbar > .container-xxl, .header-merchandise .navbar > .container-xl, .header-merchandise .navbar > .container-lg, .header-merchandise .navbar > .container-md, .header-merchandise .navbar > .container-sm,
.header-merchandise .navbar > .container {
  justify-content: start;
}
.header-merchandise .header-top {
  padding: 1rem 0;
  background-color: #141414;
}
.header-merchandise .header-bottom {
  position: relative;
  background: rgba(var(--bs-black-rgb), 0.5);
  -webkit-backdrop-filter: blur(0.625em);
          backdrop-filter: blur(0.625em);
  display: inline-block;
  width: 100%;
  z-index: 999;
  transition: all 0.3s ease-out 0s;
}
.header-merchandise .iq-nav-menu li:first-child .nav-link {
  padding-left: 0;
}
.header-merchandise .iq-nav-menu li .sub-nav li .nav-link {
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

div.optionsList {
  display: flex;
  flex-direction: column;
}
div.optionsList div.languageOption {
  font-size: 14px;
  padding: 8px 12px;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
div.optionsList div.languageOption[data-selected=true] {
  color: var(--gradient-left-color);
  font-weight: bold;
}
div.optionsList div.languageOption[data-selected=true]:after {
  content: "✔";
  color: white;
  margin-left: auto;
  font-weight: bold;
}
div.optionsList div.languageOption:hover {
  color: var(--gradient-left-color);
}

.iq-meta-menu {
  gap: 1.5625rem;
}
.iq-meta-menu li a {
  color: var(--bs-white);
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.0625rem;
}
.iq-meta-menu li a :hover {
  color: var(--bs-primary);
}

.search-box-2 {
  position: relative;
}
.search-box-2 input {
  height: auto;
  background-color: var(--bs-body-bg);
  padding: 0.5rem 1rem 0.5rem 2.5rem;
}
.search-box-2 .search-btn {
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  top: 48%;
  left: 1rem;
  transform: translateY(-50%);
}

/*==========
sidebar cart
===============*/
.sidebar-cart .cart-items-list {
  height: 29rem;
  overflow-y: auto;
}
.sidebar-cart .offcanvas-body {
  background-color: var(--bs-gray-900);
}

/*====================
Slide Down Animation
=========================*/
@keyframes header-slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (max-width: 1800px) {
  header .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
    width: 1190px;
  }
}
@media (max-width: 1600px) {
  header .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
    width: 1024px;
  }
}
@media (max-width: 1280px) {
  header .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
    width: 980px;
  }
  header.header-center-home .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
    transform: translateX(-25%);
  }
  header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav.mega-menu-item {
    max-width: 980px;
  }
}
@media (max-width: 1199px) {
  header .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
    width: auto;
  }
  header .mega-menu-content .iq-nav-menu li ul.mega-menu-item li {
    width: 100%;
    display: inline-block;
  }
  header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav.mega-menu-item {
    max-width: inherit;
    box-shadow: none;
    padding: 0;
    max-width: 100%;
    margin-left: 0;
  }
  header.header-center-home .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
    left: 0;
    transform: none;
  }
}
@media (min-width: 1200px) {
  header .mega-menu-content .iq-nav-menu li ul.mega-menu-item li .nav-link span {
    padding-bottom: 0;
  }
  header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav.mega-menu-item {
    max-width: 1360px;
  }
  header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav.mega-menu-item li a.nav-link {
    text-align: center;
    font-weight: 500;
  }
  header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav.mega-menu-item li a.nav-link::after {
    display: none;
  }
  header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav.mega-menu-item li a.nav-link img {
    box-shadow: 0px 0px 87.3px 2.7px rgba(var(--bs-black-rgb), 0.06);
    transition: all 0.3s ease-in-out;
  }
  header ul.sub-nav.mega-menu-item {
    overflow: auto;
  }
  header.header-center .iq-nav-menu li > ul.mega-menu-item {
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
  }
  header.header-center .iq-nav-menu > li:first-child {
    position: static;
  }
}
.iq-search-bar.dropdown-menu {
  border: none;
  min-width: 25em;
  left: auto;
  right: 0;
}
.iq-search-bar .search-input {
  height: 4em;
  padding: 0 1em;
  border: none;
  border-radius: 0;
  color: var(--bs-white);
}
.iq-search-bar .input-group-text {
  padding: 0;
  background-color: transparent;
}
.iq-search-bar .search-submit {
  color: var(--bs-white);
  cursor: pointer;
  background: var(--bs-primary);
  border: none;
  outline: none;
  box-shadow: none;
  height: 3em;
  width: 3em;
  position: absolute;
  background: transparent;
  top: 50%;
  transform: translateY(-50%);
}

.iq-responsive-menu {
  padding: 1.0625rem 0;
}

div.inputContainer-header {
  position: relative;
  width: 10%;
  margin-right: 20px;
  margin-top: 5px;
}
div.inputContainer-header input.headerInput {
  border: none;
  outline: none;
  width: 100%;
  margin-right: auto;
  background-color: transparent;
  font-size: 13px;
  color: var(--grey-color);
  caret-color: var(--grey-color);
  display: none;
}
div.inputContainer-header input.headerInput::-moz-placeholder {
  color: #cac9c8;
  font-size: 13px;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
div.inputContainer-header input.headerInput::placeholder {
  color: #cac9c8;
  font-size: 13px;
  transition: 0.3s ease-in-out;
}
div.inputContainer-header.showInput {
  background-color: var(--black-color);
  padding: 8px 6px;
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border: 1px solid var(--white-color);
  margin-top: 0;
}
div.inputContainer-header.showInput input {
  display: block;
  width: 200px;
}
@media all and (max-width: 1200px) {
  div.inputContainer-header.showInput {
    margin-top: 10px;
    width: 70%;
  }
  div.inputContainer-header.showInput input {
    display: block;
  }
}
@media all and (max-width: 980px) {
  div.inputContainer-header {
    width: 12%;
    justify-content: center;
    padding: 10px 8px;
  }
  div.inputContainer-header.showInput {
    width: 100%;
    justify-content: space-between;
  }
}
div.inputContainer-header:hover {
  border-color: #c5c5c8;
}
div.inputContainer-header svg {
  width: 20px !important;
  height: 29px !important;
  color: var(--white-color) !important;
  margin-right: 6px;
  cursor: pointer;
}
div.inputContainer-header div.searchSuggestionsContainer {
  position: absolute;
  top: 47px;
  z-index: 9;
  background-color: var(--transparent-color);
  padding: 15px;
  width: 100%;
  left: 0;
  cursor: default;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
@media all and (max-width: 768px) {
  div.inputContainer-header div.searchSuggestionsContainer {
    display: none;
  }
}
div.inputContainer-header div.searchSuggestionsContainer h6.heading, div.inputContainer-header div.searchSuggestionsContainer .heading.h6 {
  font-size: 12px;
  color: var(--white-color);
  margin-bottom: 10px;
}
div.inputContainer-header div.searchSuggestionsContainer h6.heading span.count, div.inputContainer-header div.searchSuggestionsContainer .heading.h6 span.count {
  font-weight: normal;
}
div.inputContainer-header div.searchSuggestionsContainer ul.lists li.list {
  display: flex;
  margin-bottom: 3px;
  cursor: pointer;
}
div.inputContainer-header div.searchSuggestionsContainer ul.lists li.list div.left {
  margin-right: 10px;
}
div.inputContainer-header div.searchSuggestionsContainer ul.lists li.list div.left div.imageContainer {
  width: 50px;
  height: 50px;
}
div.inputContainer-header div.searchSuggestionsContainer ul.lists li.list div.left div.imageContainer img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}
div.inputContainer-header div.searchSuggestionsContainer ul.lists li.list div.right h1.title, div.inputContainer-header div.searchSuggestionsContainer ul.lists li.list div.right .title.h1 {
  font-size: 14px;
  color: var(--white-color);
}
div.inputContainer-header div.searchSuggestionsContainer ul.lists li.list div.right span.director {
  font-size: 12px;
  color: var(--grey-color);
}
div.inputContainer-header div.searchSuggestionsContainer div.seeAll {
  text-align: center;
  border-top: 1px solid #eaeaea;
  padding-top: 10px;
  margin-top: 20px;
  cursor: pointer;
}
div.inputContainer-header div.searchSuggestionsContainer div.seeAll button {
  background-color: transparent;
  font-size: 14px;
  color: var(--white-color);
  font-weight: 700;
  cursor: pointer;
}
div.inputContainer-header div.closeIcon {
  position: absolute;
  right: 5px;
}
div.inputContainer-header div.closeIcon svg {
  width: 16px !important;
  height: 16px !important;
}
div.inputContainer-header div.searchLoading {
  position: absolute;
  right: 30px;
}

ul.max-height-scroll {
  max-height: 550px;
  overflow-y: scroll;
}
ul.max-height-scroll::-webkit-scrollbar {
  display: none;
}

div.featured {
  overflow: hidden;
  width: 100%;
  position: relative;
  animation: fadeIn 0.6s ease-in-out;
}
div.featured .swiper {
  height: 100%;
}
div.featured .swiper-button-disabled {
  display: none !important;
}
div.featured .swiper-pagination-bullet {
  background-color: var(--heading-color) !important;
  opacity: 0.8 !important;
}
div.featured .swiper-pagination-bullet-active {
  background-color: var(--grey-color) !important;
  opacity: 1 !important;
}
div.featured div.bgImageContainer {
  width: 100%;
  display: block;
  height: 100%;
}
div.featured div.bgImageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  div.featured div.bgImageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.featured div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}
div.featured div.bottomVignette {
  background-color: transparent;
  background-image: linear-gradient(180deg, hsla(0, 0%, 8%, 0) 0, hsla(0, 0%, 8%, 0.15) 15%, hsla(0, 0%, 8%, 0.35) 29%, hsla(0, 0%, 8%, 0.58) 44%, #141414 68%, #141414);
  background-position: 0 top;
  background-repeat: repeat-x;
  background-size: 100% 100%;
  bottom: -11px;
  height: 100px;
  opacity: 1;
  top: auto;
  width: 100%;
  z-index: 9;
  position: absolute;
}
div.featured div.contents {
  position: absolute;
  bottom: 30px;
  width: 92%;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
div.featured div.contents span.line {
  height: 1px;
  background-color: var(--border-color);
  width: 30%;
  display: block;
  margin-bottom: 20px;
}
@media all and (max-width: 768px) {
  div.featured div.contents span.line {
    display: none;
  }
}
div.featured div.contents h1.title, div.featured div.contents .title.h1 {
  font-size: 55px;
  color: var(--white-color);
  margin-bottom: 30px;
  animation: slideUp 0.5s ease-in-out;
}
@media all and (max-width: 768px) {
  div.featured div.contents h1.title, div.featured div.contents .title.h1 {
    font-size: 36px;
    margin-bottom: 0;
  }
}
div.featured div.contents div.containers {
  display: flex;
  align-items: center;
}
div.featured div.contents div.containers div.left {
  width: 8%;
  margin-right: 30px;
}
@media all and (max-width: 1200px) {
  div.featured div.contents div.containers div.left {
    width: 12%;
  }
}
@media all and (max-width: 980px) {
  div.featured div.contents div.containers div.left {
    width: 14%;
  }
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.left {
    display: none;
  }
}
div.featured div.contents div.containers div.left div.activeThumb {
  width: 100%;
}
div.featured div.contents div.containers div.left div.activeThumb div.imageContainer {
  width: 100%;
}
div.featured div.contents div.containers div.left div.activeThumb div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 8px;
}
div.featured div.contents div.containers div.right {
  width: 78%;
}
div.featured div.contents div.containers div.right div.metaData {
  padding: 35px 0;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.right div.metaData {
    padding: 20px 0;
  }
}
div.featured div.contents div.containers div.right div.metaData div.datas {
  animation: slideUp 0.5s ease-in-out;
}
div.featured div.contents div.containers div.right div.metaData div.datas span {
  color: var(--grey-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.right div.metaData div.datas span {
    font-size: 15px;
  }
}
div.featured div.contents div.containers div.right div.metaData div.datas span.year {
  margin-right: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.duration {
  margin-right: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.duration::before {
  content: "·";
  margin-right: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.certificate {
  display: inline-block;
  padding: 1px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 12px;
  font-weight: 700;
  color: var(--white-color);
  border-radius: 4px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres {
  margin-top: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span {
  margin-right: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span::before {
  content: "·";
  margin-right: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span:first-child::before {
  display: none;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span:last-child {
  margin-right: 0;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer {
  margin-top: 30px;
  background: var(--text-hover);
  padding: 12px 24px;
  width: 13rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  color: var(--white-color);
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer.no-flex {
  justify-content: left;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer svg {
  margin-right: 10px;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer:hover {
  background: var(--button-hover);
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer span {
  color: var(--white-color);
  font-weight: 700;
  font-size: 15px;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer span.free {
  display: inline-block;
  padding: 1px 6px;
  color: #000;
  background-color: var(--white-color);
  border-radius: 30px;
  font-size: 10px;
}
@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

div.homePage {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: var(--bg-color);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-bottom: 49px;
}
div.homePage::-webkit-scrollbar {
  display: none;
}
div.homePage .css-iz3z40-MuiDialog-container {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
div.homePage .css-iz3z40-MuiDialog-container::-webkit-scrollbar {
  display: none !important;
}
div.homePage .swiper {
  overflow: visible;
}
div.homePage div.allCategories {
  margin-top: 25px;
}
@media all and (max-width: 1500px) {
  div.homePage div.allCategories {
    margin-top: -226px;
  }
}
@media all and (max-width: 4500px) {
  div.homePage div.allCategories {
    margin-top: -226px;
  }
}
@media all and (max-width: 1400px) {
  div.homePage div.allCategories {
    margin-top: -180px;
  }
}
@media all and (max-width: 1200px) {
  div.homePage div.allCategories {
    margin-top: -160px;
  }
}
@media all and (max-width: 980px) {
  div.homePage div.allCategories {
    margin-top: -147px;
  }
}
@media all and (max-width: 768px) {
  div.homePage div.allCategories {
    margin-top: -25px;
  }
}
@media all and (max-width: 640px) {
  div.homePage div.allCategories {
    margin-top: -11px;
  }
}
@media all and (max-width: 480px) {
  div.homePage div.allCategories {
    margin-top: -37px;
  }
}
div.homePage div.allCategoriesWithoutBanner {
  margin-top: 230px;
}
@media all and (max-width: 980px) {
  div.homePage div.allCategoriesWithoutBanner {
    margin-top: 70px;
  }
}
div.homePage div.showInformationContainer {
  position: fixed;
  z-index: 999999999;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--overlay);
  padding-top: 100px;
  display: none;
  overflow-y: scroll;
}
div.homePage div.showInformationContainer.active {
  display: block;
  animation: fadeIn 0.6s ease-in-out;
}
div.homePage div.showInformationContainer.active div.info {
  transform: scale(1);
}
div.homePage div.showInformationContainer div.info {
  transform: scale(0);
  transition: 1s ease-in-out;
  background-color: var(--bg-color);
  width: 50%;
  margin: 0 auto;
  animation: pop 0.3s linear 1;
}
div.homePage div.showInformationContainer div.info::-webkit-scrollbar {
  border-radius: 8px;
  width: 6px;
}
div.homePage div.showInformationContainer div.info::-webkit-scrollbar-thumb {
  background-color: var(--button-color);
  border-radius: 30px;
}
div.homePage div.showInformationContainer div.info::-webkit-scrollbar-track {
  background-color: var(--overlay);
}
div.homePage div.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}
div.homePage div.LoadMoreButtonContainer {
  text-align: center;
  margin-top: -130px;
}
@media all and (max-width: 980px) {
  div.homePage div.LoadMoreButtonContainer {
    margin-top: 20px;
  }
}
div.homePage div.LoadMoreButtonContainer button {
  width: 8rem;
  padding: 6px 1px 9px;
  border-radius: 2px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  cursor: pointer;
  transition: 0.3s ease-in-out;
  margin: 0 auto;
}
div.homePage div.LoadMoreButtonContainer button:hover {
  background-color: var(--secondary-hover-color);
}
div.homePage div.LoadMoreButtonContainer button span {
  font-size: 14px;
}
@keyframes pop {
  50% {
    transform: scale(1.2);
  }
}

div.categoriesContainer {
  position: relative;
  z-index: 9;
}
div.categoriesContainer.landscape.rowCount3 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 653px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 576px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 589px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 503px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 461px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 520px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 417px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 488px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 378px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 461px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 330px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 423px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 286px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount3 {
    margin-bottom: 375px;
  }
  div.categoriesContainer.landscape.rowCount3.lastChild {
    margin-bottom: 251px !important;
  }
}
div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 29rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    width: 5.5rem;
    height: 29rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 23rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 21rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 19rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 16.9rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 15.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    height: 10.9rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 29rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    width: 5.5rem;
    height: 29rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 23rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 21rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 19rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 16.9rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 15.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    height: 10.9rem;
  }
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount3 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount4 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 550px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 449px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 499px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 398px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 363px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 440px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 342px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 407px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 301px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 386px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 268px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 360px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 243px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount4 {
    margin-bottom: 338px;
  }
  div.categoriesContainer.landscape.rowCount4.lastChild {
    margin-bottom: 194px !important;
  }
}
div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 23rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    width: 5.5rem;
    height: 20rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 18rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 16rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 14rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 11.9rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 11.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    height: 8.9rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 23rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    width: 5.5rem;
    height: 20rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 18rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 16rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 14rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 11.9rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 11.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    height: 8.9rem;
  }
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount4 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount5 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 498px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 366px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 447px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 329px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 300px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 397px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 270px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 373px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 244px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 365px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 218px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 337px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 186px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount5 {
    margin-bottom: 301px;
  }
  div.categoriesContainer.landscape.rowCount5.lastChild {
    margin-bottom: 162px !important;
  }
}
div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 18rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    width: 5.5rem;
    height: 16rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 14rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 12rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 11rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 9.9rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 9.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    height: 7.9rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 18rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    width: 5.5rem;
    height: 16rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 14rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 12rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 11rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 9.9rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 9.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    height: 7.9rem;
  }
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount5 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount6 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 435px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 321px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 411px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 283px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 268px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 361px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 231px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 339px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 211px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 330px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 191px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 311px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 170px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount6 {
    margin-bottom: 290px;
  }
  div.categoriesContainer.landscape.rowCount6.lastChild {
    margin-bottom: 147px !important;
  }
}
div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 15rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    width: 5.5rem;
    height: 13rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 12rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 11rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 10rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 8.9rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 7.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    height: 6.9rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 15rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    width: 5.5rem;
    height: 13rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 12rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 11rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 10rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 8.9rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 7.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    height: 6.9rem;
  }
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount6 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount7 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 386px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 321px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 365px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 283px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 268px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 325px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 231px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 339px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 211px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 300px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 191px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 280px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 170px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount7 {
    margin-bottom: 290px;
  }
  div.categoriesContainer.landscape.rowCount7.lastChild {
    margin-bottom: 147px !important;
  }
}
div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 15rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    width: 5.5rem;
    height: 13rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 12rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 11rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 9rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 8rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 7.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    height: 6rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 15rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    width: 5.5rem;
    height: 13rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 12rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 11rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 9rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 8rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 7.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    height: 6rem;
  }
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount7 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount8 {
  margin-bottom: 280px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 360px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 321px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 355px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 283px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 320px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 268px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 300px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 231px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 315px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 211px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 300px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 191px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 285px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 170px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount8 {
    margin-bottom: 290px;
  }
  div.categoriesContainer.landscape.rowCount8.lastChild {
    margin-bottom: 147px !important;
  }
}
div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 11rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    width: 5.5rem;
    height: 11rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 8rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 7rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 8rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 7rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 6rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    height: 5rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 11rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    width: 5.5rem;
    height: 11rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 8rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 7rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 8rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 7rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 6rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    height: 5rem;
  }
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape.rowCount8 .swiper-button-prev {
    display: none;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape .swiper-button-next, div.categoriesContainer.landscape .swiper-button-prev {
    display: none;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.landscape {
    margin-top: 0;
    margin-bottom: 24px;
  }
}
div.categoriesContainer.portrait.rowCount3 {
  margin-bottom: 325px;
  margin-top: -161px;
}
div.categoriesContainer.portrait.rowCount3.lastChild {
  margin-bottom: 1000px !important;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1387px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 1375px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1248px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 1214px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1228px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 1144px !important;
  }
}
@media all and (max-width: 2080px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1138px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 1100px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1116px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 1054px !important;
  }
}
@media all and (max-width: 1860px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1033px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 995px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 1024px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 931px !important;
  }
}
@media all and (max-width: 1680px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 956px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 892px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 920px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 822px !important;
  }
}
@media all and (max-width: 1480px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 864px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 782px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 836px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 738px !important;
  }
}
@media all and (max-width: 1300px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 775px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 681px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 730px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 625px !important;
  }
}
@media all and (max-width: 1080px) {
  div.categoriesContainer.portrait.rowCount3 {
    margin-bottom: 672px;
  }
  div.categoriesContainer.portrait.rowCount3.lastChild {
    margin-bottom: 572px !important;
  }
}
div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 77rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    width: 5.5rem;
    height: 67rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 61rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 55rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 50rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 43.9rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 36.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    height: 31.9rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 77rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    width: 5.5rem;
    height: 67rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 61rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 55rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 50rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 43.9rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 36.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    height: 31.9rem;
  }
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount3 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount4 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 1125px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 1037px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 1018px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 915px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 931px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 829px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 840px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 751px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 787px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 690px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 709px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 598px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 658px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 521px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount4 {
    margin-bottom: 559px;
  }
  div.categoriesContainer.portrait.rowCount4.lastChild {
    margin-bottom: 433px !important;
  }
}
div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
  right: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 59rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    width: 5.5rem;
    height: 51rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 46rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 41rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 37rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 32.9rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 28.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    height: 23.9rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
  left: -54px;
  height: 124px;
  width: 4%;
  top: 5%;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 59rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    width: 5.5rem;
    height: 51rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 46rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 41rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 37rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 32.9rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 28.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    height: 23.9rem;
  }
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount4 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount5 {
  margin-bottom: 593px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 955px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 833px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 851px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 733px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 798px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 678px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 735px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 606px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 678px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 559px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 615px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 496px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 564px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 422px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount5 {
    margin-bottom: 502px;
  }
  div.categoriesContainer.portrait.rowCount5.lastChild {
    margin-bottom: 345px !important;
  }
}
div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
  right: -54px;
  height: 425px;
  width: 4%;
  margin-top: -22px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 46rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    width: 5.5rem;
    height: 41rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 37rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 34rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 31rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 26.9rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 24.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    height: 18.9rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
  left: -54px;
  height: 422px;
  width: 4%;
  top: 5%;
  margin-top: -21px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 46rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    width: 5.5rem;
    height: 41rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 37rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 34rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 31rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 26.9rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 24.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    height: 18.9rem;
  }
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount5 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount6 {
  margin-bottom: 325px;
  margin-top: -161px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 820px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 700px !important;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 731px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 640px !important;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 672px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 600px !important;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 635px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 570px !important;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 575px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 500px !important;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 537px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 450px !important;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 520px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 400px !important;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount6 {
    margin-bottom: 439px;
  }
  div.categoriesContainer.portrait.rowCount6.lastChild {
    margin-bottom: 320px !important;
  }
}
div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
  right: -54px;
  height: 351px;
  width: 4%;
  top: 5%;
  margin-top: -22px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 38rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    width: 5.5rem;
    height: 36rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 31rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 29rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 25rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 22rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 20.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    height: 17rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
  left: -54px;
  height: 352px;
  width: 4%;
  top: 5%;
  margin-top: -24px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 38rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    width: 5.5rem;
    height: 36rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 31rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 29rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 25rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 22rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 20.8rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    height: 17rem;
  }
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount6 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount7 {
  margin-bottom: 325px;
  margin-top: 150px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 525px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 498px;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 435px;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 395px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 350px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 315px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 275px;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-bottom: 235px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount7 {
    margin-top: 0;
    margin-bottom: 24px;
  }
}
div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
  right: -54px;
  height: 300px;
  width: 4%;
  margin-top: 0px;
  top: 43% !important;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 32rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    width: 5.5rem;
    height: 31rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 27rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 25rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 22rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 20rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 17rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    height: 14.9rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
  left: -54px;
  height: 300px;
  width: 4%;
  top: 5%;
  margin-top: -23px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 32rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    width: 5.5rem;
    height: 31rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 27rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 25rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 22rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 20rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 17rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    height: 14.9rem;
  }
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount7 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount8 {
  margin-bottom: 356px;
  margin-top: 130px;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 465px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 430px;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 390px;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 360px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 320px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 285px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 240px;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-bottom: 232px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount8 {
    margin-top: 0;
    margin-bottom: 24px;
  }
}
div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
  right: -54px;
  height: 261px;
  width: 4%;
  top: 4%;
  margin-top: -22px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 28rem;
    width: 6rem;
    right: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    width: 5.5rem;
    height: 27rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 24rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 21rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next::after {
    position: absolute;
    right: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 19rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next::after {
    font-size: 18px !important;
    right: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 17rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next::after {
    font-size: 15px !important;
    right: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 15rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    height: 13rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next::after {
    font-size: 14px !important;
    right: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-next {
    display: none;
  }
}
div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
  left: -54px;
  height: 260px;
  width: 4%;
  top: 5%;
  margin-top: -22px;
  background: hsla(0, 0%, 8%, 0.5);
}
@media all and (max-width: 2600px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 28rem;
    width: 6rem;
    left: -90px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    width: 5.5rem;
    height: 27rem;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 24rem;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 21rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev::after {
    position: absolute;
    left: 48px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 19rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev::after {
    font-size: 18px !important;
    left: 53px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 17rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev::after {
    font-size: 15px !important;
    left: 60px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 15rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    height: 13rem;
  }
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev::after {
    font-size: 14px !important;
    left: 69px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait.rowCount8 .swiper-button-prev {
    display: none;
  }
}
div.categoriesContainer.portrait .swiper-button-next, div.categoriesContainer.portrait .swiper-button-prev {
  right: 20px;
  margin-top: 50px;
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait .swiper-button-next, div.categoriesContainer.portrait .swiper-button-prev {
    display: none;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer.portrait {
    margin-top: 0;
    margin-bottom: 24px;
  }
}
div.categoriesContainer:hover {
  z-index: 99;
}
div.categoriesContainer.hideArrow div.itemsContainer div.swiper div.swiper-wrapper {
  z-index: 99;
}
div.categoriesContainer .swiper-slide {
  z-index: 9;
}
@media all and (min-width: 981px) {
  div.categoriesContainer .swiper-slide.landscape.hover:hover {
    z-index: 99 !important;
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover div.show div.showCardContainer div.square {
    border-radius: 10px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.6666666667);
    transform: translateY(-60px) scale(1.4);
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover div.show div.showCardContainer div.square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover div.show div.showCardContainer div.square div.imageContainer img {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover div.show div.showCardContainer div.square div.continueWatchingBar {
    display: none;
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover div.show div.showCardContainer div.square div.continueWatchingContainer {
    display: flex;
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover:first-child div.show div.showCardContainer div.square {
    transform: translateY(-60px) scale(1.4) !important;
  }
  div.categoriesContainer .swiper-slide.landscape.hover:hover:last-child div.show div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.4);
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover {
    z-index: 99 !important;
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover div.show div.showCardContainer div.square {
    border-radius: 10px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.6666666667);
    transform: translateY(-60px) scale(1.2);
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover div.show div.showCardContainer div.square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover div.show div.showCardContainer div.square div.imageContainer img {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover div.show div.showCardContainer div.square div.continueWatchingBar {
    display: none;
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover div.show div.showCardContainer div.square div.continueWatchingContainer {
    display: flex;
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover:first-child div.show div.showCardContainer div.square {
    transform: translateY(-60px) scale(1.2) !important;
  }
  div.categoriesContainer .swiper-slide.portrait.hover:hover:last-child div.show div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
div.categoriesContainer:hover .swiper-button-next::after {
  opacity: 1;
}
div.categoriesContainer:hover .swiper-button-prev::after {
  opacity: 1;
}
div.categoriesContainer .swiper-button-next::after {
  opacity: 0;
}
div.categoriesContainer .swiper-button-prev::after {
  opacity: 0;
}
div.categoriesContainer div.wrapper:hover div.itemsContainer div.categoryNameContainer span.seeAll {
  visibility: visible;
}
div.categoriesContainer div.itemsContainer {
  position: relative;
  width: 93%;
  margin: 0 auto;
  visibility: hidden;
}
div.categoriesContainer div.itemsContainer.showSlider {
  visibility: visible;
  animation: fadeIn 1.5s ease-in-out;
}
div.categoriesContainer div.itemsContainer:hover div.categoryNameContainer div.exploreAll div.rightArrow {
  opacity: 1;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer {
  cursor: pointer;
  display: inline-block;
  align-items: center;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer h1, div.categoriesContainer div.itemsContainer div.categoryNameContainer .h1 {
  font-size: 19px;
  color: var(--text-color);
  max-width: 99%;
  font-weight: 500;
  margin-bottom: 10px;
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer div.categoryNameContainer h1, div.categoriesContainer div.itemsContainer div.categoryNameContainer .h1 {
    font-size: 16px;
  }
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer:hover div.exploreAll div.seeAll {
  opacity: 1;
  max-width: 100%;
  transform: translateX(10px);
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer:hover div.exploreAll div.rightArrow svg {
  width: 10px !important;
  height: 10px !important;
  transform: translateX(10px) rotate(-90deg);
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.content {
  display: flex;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.exploreAll {
  color: #54b9c5;
  display: flex;
  align-items: center;
  margin-top: -6px;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.exploreAll div.seeAll {
  cursor: pointer;
  display: inline-block;
  font-size: 0.9vw;
  line-height: 0.8vw;
  margin-right: 4px;
  max-width: 0;
  opacity: 0;
  font-weight: 500;
  transition: max-width 1s, opacity 1s, transform 0.75s;
  vertical-align: bottom;
  white-space: nowrap;
  transform: translateX(-10px);
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer div.categoryNameContainer div.exploreAll div.seeAll {
    font-size: 1vw;
  }
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.exploreAll div.rightArrow {
  opacity: 0;
  transition: 0.3s ease-in-out;
  line-height: 0;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.exploreAll div.rightArrow svg {
  transform: rotate(-90deg);
  transition: 0.2s ease-in-out;
  width: 20px !important;
  height: 20px !important;
}
div.categoriesContainer div.itemsContainer div.show {
  transition: 0.4s ease-in-out;
  position: absolute;
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer div.show {
    position: unset;
  }
}
@media all and (min-width: 320px) {
  div.categoriesContainer div.itemsContainer div.show {
    width: 100%;
  }
}
div.categoriesContainer div.itemsContainer div.showMoreCard {
  background-color: var(--bg-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  cursor: pointer;
}
@media all and (max-width: 2600px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    width: 250px;
    height: 259px;
  }
}
@media all and (max-width: 2500px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 225px;
  }
}
@media all and (max-width: 2200px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 200px;
  }
}
@media all and (max-width: 1980px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 180px;
  }
}
@media all and (max-width: 1768px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 162px;
  }
}
@media all and (max-width: 1600px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 142px;
  }
}
@media all and (max-width: 1400px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 120px;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    height: 100px;
    width: 150px;
  }
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    transform: translateY(50%);
    height: 100px;
  }
}
@media all and (max-width: 640px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard {
    transform: none;
    min-height: 208px;
    width: 200px;
  }
}
div.categoriesContainer div.itemsContainer div.showMoreCard:hover h2, div.categoriesContainer div.itemsContainer div.showMoreCard:hover .h2 {
  text-decoration: underline;
}
div.categoriesContainer div.itemsContainer div.showMoreCard h2, div.categoriesContainer div.itemsContainer div.showMoreCard .h2 {
  color: var(--white-color);
  font-size: 18px;
  letter-spacing: 0.3px;
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer div.showMoreCard h2, div.categoriesContainer div.itemsContainer div.showMoreCard .h2 {
    font-size: 15px;
  }
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.hover:last-child div.show.visibleShowAllButton div.showCardContainer div.square {
  transform: none !important;
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.hover:last-child div.show.visibleShowAllButton div.showCardContainer div.square div.metaData {
  display: none !important;
}
@media all and (max-width: 980px) {
  div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide.hover:last-child div.show.visibleShowAllButton div.showCardContainer div.square div.metaData {
    display: block !important;
  }
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay {
  background-color: var(--bg-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 99;
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay:hover h2, div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay:hover .h2 {
  text-decoration: underline;
}
div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay h2, div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay .h2 {
  color: var(--white-color);
  font-size: 20px;
  letter-spacing: 1px;
}
@media all and (max-width: 1400px) {
  div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay h2, div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay .h2 {
    font-size: 18px;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay h2, div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay .h2 {
    font-size: 15px;
  }
}
@media all and (max-width: 360px) {
  div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay h2, div.categoriesContainer div.itemsContainer .swiper .swiper-wrapper .swiper-slide:last-child div.show.visibleShowAllButton div.showCardContainer div.showMoreOverlay .h2 {
    font-size: 18px;
  }
}

@media all and (min-width: 1025px) {
  .margincontainer {
    margin-bottom: -60px;
  }
}
@media all and (max-width: 980px) {
  .margincontainer {
    margin-bottom: -70px;
  }
}

@media all and (min-width: 1025px) {
  .minimumHeightContainer {
    min-height: 202px;
  }
}
@media all and (min-width: 1400px) {
  .minimumHeightContainer {
    min-height: 220px;
  }
}
@media all and (min-width: 1600px) {
  .minimumHeightContainer {
    min-height: 240px !important;
  }
}
@media all and (min-width: 1768px) {
  .minimumHeightContainer {
    min-height: 289px !important;
  }
}
@media all and (min-width: 1980px) {
  .minimumHeightContainer {
    min-height: 326px !important;
  }
}
@media all and (min-width: 2500px) {
  .minimumHeightContainer {
    min-height: 387px !important;
  }
}
@media all and (min-width: 2600px) {
  .minimumHeightContainer {
    min-height: 420px !important;
  }
}

.marginLessContainer {
  margin-bottom: 0;
}

div.showCardContainer {
  width: 100%;
  cursor: pointer;
  position: relative;
}
div.showCardContainer.noPointer {
  cursor: default;
}
div.showCardContainer div.showMoreOverlay {
  display: none;
}
div.showCardContainer .square {
  width: 100%;
  transition: transform 500ms ease-out, border-radius 200ms ease-out;
}
div.showCardContainer .square.hide div.metaData {
  display: none;
}
@media all and (max-width: 980px) {
  div.showCardContainer .square.hide div.metaData {
    display: block;
  }
}
div.showCardContainer .square div.metaData {
  display: none;
}
@media all and (max-width: 980px) {
  div.showCardContainer .square div.metaData {
    display: block;
  }
}
div.showCardContainer .square div.imageContainer {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.showCardContainer .square div.imageContainer img {
  width: 100%;
  display: block;
  transition: 0.2s ease-in-out;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
div.showCardContainer .square div.imageContainer div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.showCardContainer .square div.imageContainer div.premiumStatus div.premium {
    width: 26px;
  }
}
div.showCardContainer .square div.imageContainer div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.showCardContainer .square div.imageContainer div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.showCardContainer .square div.imageContainer div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 50px;
}
@media all and (max-width: 1200px) {
  div.showCardContainer .square div.imageContainer div.premiumStatus div.free {
    width: 30px;
  }
}
div.showCardContainer .square div.imageContainer div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.showCardContainer .square div.imageContainer div.extraActions {
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.showCardContainer .square div.imageContainer div.extraActions div.showCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.showCardContainer .square div.continueWatchingBar {
  height: 3px;
  background-color: var(--grey-color);
  width: 64%;
  margin: 10px auto 0;
}
div.showCardContainer .square div.continueWatchingBar div.line {
  height: 100%;
  background-color: var(--secondary-color);
}
div.showCardContainer .square div.continueWatchingContainer {
  display: none;
  align-items: center;
  margin: 5px 0;
}
div.showCardContainer .square div.continueWatchingContainer div.continueWatchingBarHover {
  height: 2px;
  background-color: var(--grey-color);
  width: 70%;
  margin: 0 auto;
}
div.showCardContainer .square div.continueWatchingContainer div.continueWatchingBarHover div.line {
  height: 100%;
  background-color: var(--secondary-color);
}
div.showCardContainer .square div.continueWatchingContainer div.watchedInfo span {
  font-size: 10px;
  display: block;
  color: var(--text-color);
}
div.showCardContainer .square div.metaData {
  background: #181818;
  color: #fff;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 10px;
  box-sizing: border-box;
  visibility: hidden;
  transition: 0.9s ease-in-out;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media all and (max-width: 980px) {
  div.showCardContainer .square div.metaData {
    visibility: visible;
  }
}
div.showCardContainer .square div.metaData h1.title, div.showCardContainer .square div.metaData .title.h1 {
  font-size: 11px;
  color: var(--text-color);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.showCardContainer .square div.metaData div.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
div.showCardContainer .square div.metaData div.buttons span.material-symbols-outlined {
  font-weight: 300 !important;
  font-size: 20px !important;
}
div.showCardContainer .square div.metaData div.buttons svg {
  width: 100% !important;
  height: 15px !important;
}
div.showCardContainer .square div.metaData div.buttons button {
  background-color: rgba(42, 42, 42, 0.6);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
}
div.showCardContainer .square div.metaData div.buttons button.play {
  background-color: var(--white-color);
  margin-right: 4px;
  transition: 0.2s ease-in-out;
}
div.showCardContainer .square div.metaData div.buttons button.play:hover {
  background-color: var(--primary-btn-hover);
}
div.showCardContainer .square div.metaData div.buttons button.play svg {
  color: var(--black-color);
}
div.showCardContainer .square div.metaData div.buttons button.addToList, div.showCardContainer .square div.metaData div.buttons button.moreInfo {
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
}
div.showCardContainer .square div.metaData div.buttons button.addToList:hover, div.showCardContainer .square div.metaData div.buttons button.moreInfo:hover {
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.showCardContainer .square div.metaData div.buttons button.moreInfo:hover span.tooltip, div.showCardContainer .square div.metaData div.buttons button.addToList:hover span.tooltip {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}
@media all and (max-width: 980px) {
  div.showCardContainer .square div.metaData div.buttons button.moreInfo:hover span.tooltip, div.showCardContainer .square div.metaData div.buttons button.addToList:hover span.tooltip {
    display: none;
  }
}
div.showCardContainer .square div.metaData div.buttons button.moreInfo span.tooltip, div.showCardContainer .square div.metaData div.buttons button.addToList span.tooltip {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #d6d5d5;
  position: absolute;
  top: 0;
  left: 28px;
  width: 7rem;
  padding: 3px 8px 4px;
  color: var(--bg-color);
  font-weight: 500;
  border-radius: 2px;
  font-size: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.showCardContainer .square div.metaData div.buttons button.moreInfo span.tooltip.remove, div.showCardContainer .square div.metaData div.buttons button.addToList span.tooltip.remove {
  width: 9rem;
}
div.showCardContainer .square div.metaData div.buttons button.moreInfo span.tooltip::after, div.showCardContainer .square div.metaData div.buttons button.addToList span.tooltip::after {
  content: "";
  position: absolute;
  top: 26%;
  left: -5px;
  margin-left: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #d6d5d5 transparent transparent transparent;
  transform: rotate(90deg);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.showCardContainer .square div.metaData div.buttons div.left {
  display: flex;
  align-items: center;
}
div.showCardContainer .square div.metaData div.extraInfo {
  margin-bottom: 6px;
}
div.showCardContainer .square div.metaData div.extraInfo span {
  font-size: 10px;
}
div.showCardContainer .square div.metaData div.extraInfo span.duration {
  color: var(--duration-color);
  margin-right: 6px;
  font-weight: 500;
}
div.showCardContainer .square div.metaData div.extraInfo span.rating {
  padding: 1px 6px;
  border: 1px solid var(--border-color);
  font-size: 9px;
  margin-right: 6px;
}
div.showCardContainer .square div.metaData div.categories {
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 980px) {
  div.showCardContainer .square div.metaData div.categories {
    display: none;
  }
}
div.showCardContainer .square div.metaData div.categories div.item {
  padding-right: 4px;
  display: flex;
  margin-bottom: 3px;
}
div.showCardContainer .square div.metaData div.categories div.item:first-child span.dot {
  display: none;
}
div.showCardContainer .square div.metaData div.categories div.item span {
  color: var(--text-color);
  font-size: 10px;
}
div.showCardContainer .square div.metaData div.categories div.item span.dot {
  font-size: 9px;
}
div.showCardContainer .square div.metaData div.categories div.item span.dot::before {
  color: #646464;
  content: "•";
  display: inline-block;
  margin-right: 4px;
}

.landscape-margin-bottom {
  margin-bottom: -40px;
}

.title-margin-left {
  margin-left: -4px;
}

@media all and (max-width: 768px) {
  .swipper_button_mobile {
    display: none !important;
  }
}

div.footerContainer {
  padding: 30px 0;
  position: relative;
  z-index: 9;
  background-color: var(--bg-color);
  border-top: 1px solid var(--border-color);
}
div.footerContainer div.topContainer {
  display: flex;
  justify-content: space-between;
}
div.footerContainer div.topContainer div.left {
  width: 20%;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 20px;
}
div.footerContainer div.topContainer div.left div.logoContainer {
  width: 120px;
}
@media all and (min-width: 768px) {
  div.footerContainer div.topContainer div.left div.logoContainer {
    width: 200px;
  }
}
div.footerContainer div.topContainer div.left div.logoContainer img {
  display: block;
  width: 100%;
}
div.footerContainer div.topContainer div.right {
  width: 20%;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.footerContainer div.topContainer div.right {
    width: 35%;
  }
}
div.footerContainer div.topContainer div.right ul.social {
  display: flex;
  align-items: center;
}
div.footerContainer div.topContainer div.right ul.social li {
  margin-right: 10px;
  cursor: pointer;
}
div.footerContainer div.topContainer div.right ul.social li:last-child {
  margin-right: 0;
}
div.footerContainer div.topContainer div.right ul.social li:hover svg {
  opacity: 0.6;
  color: var(--text-hover);
}
div.footerContainer div.topContainer div.right ul.social li svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.footerContainer div.centerContainer div.informationContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
}
div.footerContainer div.centerContainer div.informationContainer div.items {
  width: 20%;
  color: var(--text-color);
}
@media all and (max-width: 768px) {
  div.footerContainer div.centerContainer div.informationContainer div.items {
    width: 25%;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 640px) {
  div.footerContainer div.centerContainer div.informationContainer div.items {
    width: 33%;
  }
}
div.footerContainer div.centerContainer div.informationContainer div.items h6.heading, div.footerContainer div.centerContainer div.informationContainer div.items .heading.h6 {
  font-size: 12px;
  color: var(--inner-text-color);
  margin-bottom: 10px;
}
div.footerContainer div.centerContainer div.informationContainer div.items ul li span {
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.footerContainer div.centerContainer div.informationContainer div.items ul li span:hover {
  opacity: 0.6;
  color: var(--text-hover);
}
div.footerContainer div.bottomContainer {
  border-top: 1px solid var(--border-color);
  padding: 40px 0;
}
div.footerContainer div.bottomContainer div.stores {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
div.footerContainer div.bottomContainer div.stores svg {
  margin-right: 10px;
  width: 120px !important;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.footerContainer div.bottomContainer div.stores svg:last-child {
  margin-right: 0;
}
div.footerContainer div.bottomContainer div.stores svg:hover {
  opacity: 0.8;
}
div.footerContainer div.bottomContainer p {
  text-align: center;
  font-size: 12px;
  color: var(--grey-color);
  opacity: 0.7;
}
div.footerContainer div.bottomContainer div.typeOfPayments {
  color: var(--grey-color) !important;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
div.footerContainer div.bottomContainer div.typeOfPayments i {
  margin-right: 6px;
}
div.footerContainer div.bottomContainer div.typeOfPayments i:last-child {
  margin-right: 0;
}

div.detailsScreen {
  padding: 50px 0 0;
}
div.detailsScreen div.topBackgroundContainer {
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100%;
  background-size: cover;
  padding-bottom: 20px;
}
div.detailsScreen div.topBackgroundContainer div.contents {
  display: flex;
  justify-content: space-between;
}
div.detailsScreen div.topBackgroundContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0.6) 70%, rgba(0, 0, 0, 0.8) 100%);
}
div.detailsScreen div.topBackgroundContainer div.left {
  padding-top: 220px;
  width: 23%;
  z-index: 9;
  padding-left: 5px;
}
@media all and (max-width: 2500px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    padding-left: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    padding-left: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    padding-left: 30px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    width: 24%;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    width: 100%;
    padding-top: 280px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left h1.title, div.detailsScreen div.topBackgroundContainer div.left .title.h1 {
  color: var(--white-color);
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 16px;
}
div.detailsScreen div.topBackgroundContainer div.left h2.director, div.detailsScreen div.topBackgroundContainer div.left .director.h2 {
  color: var(--white-color);
  font-size: 13px;
  font-weight: normal;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left h2.director, div.detailsScreen div.topBackgroundContainer div.left .director.h2 {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left h2.director span.name, div.detailsScreen div.topBackgroundContainer div.left .director.h2 span.name {
  font-weight: 700;
  font-size: 14px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left h2.director span.name, div.detailsScreen div.topBackgroundContainer div.left .director.h2 span.name {
    font-size: 15px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left span.year {
  font-size: 13px;
  color: var(--white-color);
  margin-bottom: 10px;
  display: inline-block;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo {
  display: flex;
  color: var(--text-color);
  align-items: center;
  border-bottom: 1px solid var(--text-color);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon {
  margin-right: 5px;
  line-height: 0;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon svg {
  width: 16px !important;
  height: 16px !important;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon svg path {
  fill: var(--text-color) !important;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration span {
  font-size: 13px;
  font-weight: 500;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration span {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.language {
  display: flex;
  align-items: center;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.language span {
  font-size: 13px;
  font-weight: 500;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.language span {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left div.watchListButton {
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.left div.watchListButton svg {
  width: 25px !important;
  height: 25px !important;
}
div.detailsScreen div.topBackgroundContainer div.categoryName {
  background-color: #68abff;
  padding: 10px 6px;
  width: 8rem;
  text-transform: uppercase;
  color: var(--white-color);
  font-weight: 500;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 9;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.categoryName {
    display: none;
  }
}
div.detailsScreen div.topBackgroundContainer div.top {
  position: absolute;
  right: 0;
  text-align: right;
  padding-top: 60px;
  z-index: 9;
}
@media all and (max-width: 2500px) {
  div.detailsScreen div.topBackgroundContainer div.top {
    right: 100px;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen div.topBackgroundContainer div.top {
    right: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.topBackgroundContainer div.top {
    right: 60px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top {
    padding-top: 20px;
    right: 5px;
  }
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 20px;
  margin-top: 10px;
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings div.icon {
  margin-right: 5px;
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings div.icon svg {
  width: 18px !important;
  height: 18px !important;
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings h1.rating, div.detailsScreen div.topBackgroundContainer div.top div.ratings .rating.h1 {
  font-size: 22px;
  color: var(--white-color);
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top div.ratings h1.rating, div.detailsScreen div.topBackgroundContainer div.top div.ratings .rating.h1 {
    font-size: 23px;
  }
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings h1.rating span.outOf, div.detailsScreen div.topBackgroundContainer div.top div.ratings .rating.h1 span.outOf {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 700;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top div.ratings h1.rating span.outOf, div.detailsScreen div.topBackgroundContainer div.top div.ratings .rating.h1 span.outOf {
    font-size: 15px;
  }
}
div.detailsScreen div.topBackgroundContainer div.top div.trailer {
  padding-right: 5px;
  padding-top: 90px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top div.trailer {
    display: none;
  }
}
div.detailsScreen div.topBackgroundContainer div.top div.trailer button.trailerBtn {
  background-color: transparent;
  border: 1px solid var(--white-color);
  width: 5rem;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 6px;
  color: var(--white-color);
  margin-left: auto;
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.top div.trailer button.trailerBtn:hover {
  border-color: var(--button-hover);
  background-color: var(--button-hover);
}
div.detailsScreen div.topBackgroundContainer div.top div.trailer button.trailerBtn div.icon {
  line-height: 0;
  margin-left: 8px;
}
div.detailsScreen div.topBackgroundContainer div.top span.totalRatings {
  color: var(--text-color);
  font-size: 13px;
  font-weight: 700;
  padding-right: 3px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top span.totalRatings {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.right {
  width: 73%;
  padding-top: 270px;
  z-index: 8;
  display: flex;
}
@media all and (max-width: 2500px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    padding-right: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    padding-right: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    padding-right: 30px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    width: 70%;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    display: none;
  }
}
div.detailsScreen div.topBackgroundContainer div.right div.synopsis {
  color: var(--white-color);
  margin-right: 40px;
  width: 50%;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.right div.synopsis {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
div.detailsScreen div.topBackgroundContainer div.right div.synopsis h1.head, div.detailsScreen div.topBackgroundContainer div.right div.synopsis .head.h1 {
  font-size: 16px;
}
div.detailsScreen div.topBackgroundContainer div.right div.synopsis p.synopis {
  font-size: 13px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.right div.synopsis p.synopis {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.right div.ourTake {
  color: var(--white-color);
  width: 50%;
}
div.detailsScreen div.topBackgroundContainer div.right div.ourTake h1.head, div.detailsScreen div.topBackgroundContainer div.right div.ourTake .head.h1 {
  font-size: 16px;
}
div.detailsScreen div.topBackgroundContainer div.right div.ourTake p.ourTake {
  font-size: 13px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.right div.ourTake p.ourTake {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.pageDown {
  z-index: 9;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.pageDown:hover {
  bottom: -10px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.pageDown {
    display: none;
  }
}
div.detailsScreen div.topBackgroundContainer div.pageDown svg {
  width: 35px !important;
  height: 35px !important;
  transform: rotate(-90deg);
  fill: var(--text-color);
}
div.detailsScreen div.topBackgroundContainer div.playIconContainer {
  position: absolute;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid var(--white-color);
  transition: 0.3s ease-in-out;
  z-index: 9;
  left: 50%;
  top: 23%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.playIconContainer {
    top: 30%;
  }
}
div.detailsScreen div.topBackgroundContainer div.playIconContainer svg {
  width: 30px !important;
  height: 30px !important;
  color: var(--white-color);
}
div.detailsScreen div.topBackgroundContainer div.playIconContainer:hover {
  background-color: var(--button-hover);
  border-color: var(--button-hover);
}
div.detailsScreen div.topBackgroundContainer div.subscriptions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  z-index: 9;
  padding-left: 5px;
  padding-bottom: 30px;
}
@media all and (max-width: 2500px) {
  div.detailsScreen div.topBackgroundContainer div.subscriptions {
    padding-left: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen div.topBackgroundContainer div.subscriptions {
    padding-left: 30px;
  }
}
div.detailsScreen div.topBackgroundContainer div.subscriptions div.items {
  z-index: 9;
  cursor: pointer;
  margin-right: 10px;
  background-color: var(--yellow-color);
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  color: var(--white-color);
  margin-bottom: 20px;
  border-radius: 30px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.subscriptions div.items {
    font-size: 12px;
  }
}
div.detailsScreen div.topBackgroundContainer div.subscriptions div.items.free {
  background-color: var(--success-color);
}
div.detailsScreen div.topBackgroundContainer div.shareContainer {
  position: absolute;
  right: 5px;
  z-index: 9;
  bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer span {
  color: var(--white-color);
  font-size: 14px;
  margin-right: 15px;
  transition: 0.2s ease-in-out;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer span.active {
  transform: translateX(-10px);
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.icon {
  line-height: 0;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.icon svg {
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.icon .closeIcon {
  stroke: var(--white-color) !important;
  display: block;
  width: 11px !important;
  height: 11px !important;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons {
  line-height: 0;
  animation: fadeIn 0.9s;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .facebook {
  margin-right: 15px;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .facebook div.icon svg {
  color: var(--white-color);
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .twitter {
  margin-right: 15px;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .twitter div.icon svg {
  color: var(--white-color);
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .clipBoard {
  margin-right: 15px;
  display: inline;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .clipBoard svg {
  fill: var(--white-color);
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreen div.responsiveContent {
  padding-bottom: 25px;
  display: none;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.responsiveContent {
    display: block;
  }
}
div.detailsScreen div.responsiveContent div.categoryNameSM {
  background-color: rgb(242, 89, 89);
  padding: 12px 16px;
  text-transform: uppercase;
  color: var(--white-color);
  font-weight: 700;
  font-size: 15px;
}
div.detailsScreen div.responsiveContent div.synopsisSM {
  padding: 25px 15px;
  color: var(--text-color);
}
div.detailsScreen div.responsiveContent div.synopsisSM h1.heading, div.detailsScreen div.responsiveContent div.synopsisSM .heading.h1 {
  font-size: 16px;
  margin-bottom: 8px;
}
div.detailsScreen div.responsiveContent div.synopsisSM p.synopsis {
  font-size: 16px;
}
div.detailsScreen div.responsiveContent div.ourTakeSM {
  padding: 0 15px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.detailsScreen div.responsiveContent div.ourTakeSM h1.heading, div.detailsScreen div.responsiveContent div.ourTakeSM .heading.h1 {
  font-size: 16px;
  margin-bottom: 8px;
}
div.detailsScreen div.responsiveContent div.ourTakeSM p.ourTake {
  font-size: 16px;
}
div.detailsScreen div.responsiveContent div.trailer {
  padding-right: 15px;
}
div.detailsScreen div.responsiveContent div.trailer button.trailerBtn {
  background-color: transparent;
  border: 1px solid var(--text-color);
  width: 5.5rem;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 7px 10px;
  color: var(--text-color);
  margin-left: auto;
  cursor: pointer;
}
div.detailsScreen div.responsiveContent div.trailer button.trailerBtn:hover {
  border-color: var(--button-hover);
  background-color: var(--button-hover);
}
div.detailsScreen div.responsiveContent div.trailer button.trailerBtn div.icon {
  line-height: 0;
  margin-left: 8px;
}
div.detailsScreen div.categories {
  padding: 30px 4px 0;
  background-color: var(--secondary-bg-color);
}
div.detailsScreen div.categories h1.heading, div.detailsScreen div.categories .heading.h1 {
  color: var(--text-color);
  font-size: 17px;
  margin-bottom: 20px;
}
div.detailsScreen div.categories div.categoryLists ul {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.categories div.categoryLists ul li.list {
  display: flex;
  margin-right: 10px;
  width: 24%;
  background-color: var(--white-color);
  margin-bottom: 10px;
  cursor: pointer;
}
div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.categories div.categoryLists ul li.list {
    width: 32.6%;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-right: 0;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(4n) {
    margin-right: 10px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.categories div.categoryLists ul li.list {
    width: 49%;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-right: 10px;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.categories div.categoryLists ul li.list {
    width: 100%;
    margin-right: 0;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-right: 0;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(2n) {
    margin-right: 0;
  }
}
div.detailsScreen div.categories div.categoryLists ul li.list div.left div.imageContainer {
  width: 70px;
}
div.detailsScreen div.categories div.categoryLists ul li.list div.left div.imageContainer img {
  display: block;
  width: 100%;
}
div.detailsScreen div.categories div.categoryLists ul li.list div.right {
  padding: 10px;
}
div.detailsScreen div.categories div.categoryLists ul li.list div.right h1.title, div.detailsScreen div.categories div.categoryLists ul li.list div.right .title.h1 {
  font-size: 14px;
  text-transform: uppercase;
}
div.detailsScreen div.awardsAndFestivals {
  padding: 30px 4px 0;
  background-color: var(--secondary-bg-color);
}
div.detailsScreen div.awardsAndFestivals h1.heading, div.detailsScreen div.awardsAndFestivals .heading.h1 {
  color: var(--text-color);
  font-size: 17px;
  margin-bottom: 20px;
}
div.detailsScreen div.awardsAndFestivals ul.lists {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list {
  margin-right: 10px;
  width: 24%;
  margin-bottom: 20px;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.awardsAndFestivals ul.lists li.list {
    width: 32.6%;
  }
  div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-right: 0;
  }
  div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(4n) {
    margin-right: 10px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.awardsAndFestivals ul.lists li.list {
    width: 49%;
  }
  div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-right: 10px;
  }
  div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.awardsAndFestivals ul.lists li.list {
    width: 100%;
    margin-right: 0;
  }
  div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-right: 0;
  }
  div.detailsScreen div.awardsAndFestivals ul.lists li.list:nth-child(2n) {
    margin-right: 0;
  }
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list div.imageContainer {
  width: 40px;
  filter: grayscale(100%);
  background: rgb(200, 200, 200);
  margin-bottom: 20px;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list div.imageContainer img {
  display: block;
  width: 100%;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list h1.title, div.detailsScreen div.awardsAndFestivals ul.lists li.list .title.h1 {
  color: var(--text-color);
  font-size: 14px;
  margin-bottom: 10px;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list div.descriptionAndYear {
  display: flex;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list div.descriptionAndYear span.year {
  margin-right: 6px;
  font-size: 13px;
  color: var(--grey-color);
  font-weight: normal;
}
div.detailsScreen div.awardsAndFestivals ul.lists li.list div.descriptionAndYear p.description {
  font-size: 13px;
  color: var(--grey-color);
  font-weight: normal;
}
div.detailsScreen div.castAndCrew {
  background-color: var(--secondary-bg-color);
  padding: 30px 4px 0;
}
div.detailsScreen div.castAndCrew h1.heading, div.detailsScreen div.castAndCrew .heading.h1 {
  color: var(--text-color);
  font-size: 17px;
  margin-bottom: 15px;
}
div.detailsScreen div.castAndCrew ul.lists {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.castAndCrew ul.lists li.list {
  width: 12%;
  background-color: var(--white-color);
  margin-right: 7px;
  margin-bottom: 20px;
  cursor: pointer;
}
div.detailsScreen div.castAndCrew ul.lists li.list:hover div.info h1.name, div.detailsScreen div.castAndCrew ul.lists li.list:hover div.info .name.h1 {
  color: var(--grey-color);
}
div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(8n) {
  margin-right: 0;
}
div.detailsScreen div.castAndCrew ul.lists li.list:last-child {
  margin-right: 0 !important;
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.castAndCrew ul.lists li.list {
    width: 13.7%;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(7n) {
    margin-right: 0;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(8n) {
    margin-right: 7px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.castAndCrew ul.lists li.list {
    width: 16%;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(7n) {
    margin-right: 7px;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(6n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.castAndCrew ul.lists li.list {
    width: 24%;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(4n) {
    margin-right: 0;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(6n) {
    margin-right: 7px;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.castAndCrew ul.lists li.list {
    width: 32%;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(4n) {
    margin-right: 7px;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.castAndCrew ul.lists li.list {
    width: 49%;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(3n) {
    margin-right: 7px;
  }
  div.detailsScreen div.castAndCrew ul.lists li.list:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.castAndCrew ul.lists li.list {
    width: 47.8%;
  }
}
div.detailsScreen div.castAndCrew ul.lists li.list div.imageContainer {
  filter: grayscale(100%);
  background: rgb(200, 200, 200);
  width: 100%;
}
div.detailsScreen div.castAndCrew ul.lists li.list div.imageContainer img {
  width: 100%;
  display: block;
}
div.detailsScreen div.castAndCrew ul.lists li.list div.info {
  padding: 10px 10px 30px;
}
div.detailsScreen div.castAndCrew ul.lists li.list div.info h1.name, div.detailsScreen div.castAndCrew ul.lists li.list div.info .name.h1 {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--text-color);
}
div.detailsScreen div.castAndCrew ul.lists li.list div.info p.role {
  font-size: 11px;
  color: var(--grey-color);
  text-transform: uppercase;
}
div.detailsScreen div.relatedFilms {
  background-color: var(--secondary-bg-color);
  padding: 30px 4px 0;
}
div.detailsScreen div.relatedFilms h1.heading, div.detailsScreen div.relatedFilms .heading.h1 {
  color: var(--text-color);
  font-size: 17px;
  margin-bottom: 15px;
}
div.detailsScreen div.relatedFilms div.films {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.relatedFilms div.films div.show {
  margin-right: 10px;
  margin-bottom: 10px;
}
div.detailsScreen div.relatedFilms div.films div.show.portrait {
  width: 16%;
}
div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(6n) {
  margin-right: 0;
}
@media all and (max-width: 1300px) {
  div.detailsScreen div.relatedFilms div.films div.show.portrait {
    width: 19%;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(6n) {
    margin-right: 10px;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(5n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.relatedFilms div.films div.show.portrait {
    width: 24%;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(5n) {
    margin-right: 10px;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreen div.relatedFilms div.films div.show.portrait {
    width: 32%;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(4n) {
    margin-right: 10px;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.relatedFilms div.films div.show.portrait {
    width: 48%;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(3n) {
    margin-right: 10px;
  }
  div.detailsScreen div.relatedFilms div.films div.show.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.detailsScreen div.relatedFilms div.films div.show.landscape {
  width: 24.3%;
}
div.detailsScreen div.relatedFilms div.films div.show.landscape:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.relatedFilms div.films div.show.landscape {
    width: 32.5%;
  }
  div.detailsScreen div.relatedFilms div.films div.show.landscape:nth-child(4n) {
    margin-right: 10px;
  }
  div.detailsScreen div.relatedFilms div.films div.show.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.relatedFilms div.films div.show.landscape {
    width: 32%;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreen div.relatedFilms div.films div.show.landscape {
    width: 49%;
  }
  div.detailsScreen div.relatedFilms div.films div.show.landscape:nth-child(3n) {
    margin-right: 10px;
  }
  div.detailsScreen div.relatedFilms div.films div.show.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.relatedFilms div.films div.show.landscape {
    width: 48%;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.relatedFilms div.films div.show.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.detailsScreen div.relatedFilms div.films div.show.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

select.season-select {
  max-width: 30%;
  margin-left: 100px;
}
@media all and (max-width: 1367px) {
  select.season-select {
    margin-left: 20px;
  }
}
@media all and (max-width: 480px) {
  select.season-select {
    max-width: 87%;
  }
}

.detail-subscription-container {
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
  width: 94vw;
  margin-left: -15px;
  margin-bottom: 20px;
  padding: 5px;
}
.detail-subscription-container button {
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 6px 8px;
  background-color: var(--secondary-color);
  display: block;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.detail-subscription-container button:hover {
  background-color: var(--secondary-hover-color);
}
.detail-subscription-container button.free {
  background-color: var(--freeBtn-color);
}
.detail-subscription-container button.free:hover {
  background-color: var(--freeBtn-hover);
}
.detail-subscription-container button.free span {
  color: var(--white-color);
}
.detail-subscription-container button span {
  color: var(--white-color);
  font-size: 12px;
  display: block;
}
.detail-subscription-container button:last-child {
  margin-right: 0;
}
.detail-subscription-container .package-item {
  padding: 5px 20px;
  background: rgba(204, 204, 204, 0.08);
  border-radius: 3px;
  margin: 10px;
  cursor: pointer;
  z-index: 9999;
  display: inline-block;
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .detail-subscription-container .package-item {
    width: 100%;
  }
}
.detail-subscription-container .package-item .heading-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.detail-subscription-container .package-item .heading-section .plan-options-wrapper {
  align-content: center;
  margin-top: 20px;
  cursor: pointer;
}
.detail-subscription-container .package-item .heading-section .plan-options {
  width: 20px;
  height: 20px;
  cursor: pointer !important;
}
.detail-subscription-container .package-item .heading-section .heading-wrapper {
  display: flex;
  flex-direction: column;
}
.detail-subscription-container .package-item .heading-section .heading {
  font-size: large;
  color: var(--text-color);
}
@media only screen and (max-width: 768px) {
  .detail-subscription-container .package-item .heading-section .heading {
    font-size: 15px;
  }
}
.detail-subscription-container .package-item .heading-section .sub-heading {
  font-size: small;
  color: rgba(175, 182, 188, 0.9607843137);
}
.detail-subscription-container .package-item .continue-button {
  width: 100px;
  height: 30px;
  border-radius: 2px;
  font-size: medium;
  background: #2dbebd;
  color: #121212;
  float: right;
}
@media only screen and (max-width: 768px) {
  .detail-subscription-container .package-item .continue-button {
    width: 100%;
    height: 40px;
    font-size: 18px;
  }
}
.detail-subscription-container .package-item .continue-button:hover {
  background: rgba(204, 204, 204, 0.0901960784);
  color: #2dbebd;
}
.detail-subscription-container .package-item:hover {
  background: var(--bs-primary);
}
.detail-subscription-container .package-item:hover .sub-heading {
  color: #fff;
}

div.loginPage {
  background: linear-gradient(354deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 38%, var(--bs-primary) 100%);
}
div.loginPage span.error {
  color: #E72A31;
  font-size: 12px;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
div.loginPage div.updatesContainer {
  text-align: center;
  padding-top: 100px;
  width: 50%;
  margin: 0 auto -90px;
}
@media all and (max-width: 1200px) {
  div.loginPage div.updatesContainer {
    width: 60%;
  }
}
@media all and (max-width: 640px) {
  div.loginPage div.updatesContainer {
    width: 90%;
  }
}
div.loginPage div.updatesContainer p {
  background-color: #fff;
  font-size: 14px;
  color: var(--error-color);
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  border-bottom: 4px solid var(--error-color);
}
div.loginPage div.loginContainer {
  width: 20rem;
  margin: 0 auto;
  padding: 130px 0;
  text-align: center;
}
@media all and (max-width: 640px) {
  div.loginPage div.loginContainer {
    width: 95%;
  }
}
div.loginPage div.loginContainer div.top div#appleid-signin {
  height: 60px;
  cursor: pointer;
  padding: 5px;
  border: transparent;
  border-radius: 10px;
  transition: border 5s ease;
}
div.loginPage div.loginContainer div.top div#appleid-signin:hover {
  border: 1px solid transparent;
}
div.loginPage div.loginContainer div.top div.userIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
}
div.loginPage div.loginContainer div.top div.userIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
}
div.loginPage div.loginContainer div.top h1, div.loginPage div.loginContainer div.top .h1 {
  color: var(--text-color);
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.loginPage div.loginContainer div.top h1, div.loginPage div.loginContainer div.top .h1 {
    font-size: 25px;
  }
}
div.loginPage div.loginContainer div.top div.facebookContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.loginPage div.loginContainer div.top div.facebookContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.top div.facebookContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3d6ac3;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.left {
  line-height: 0;
  margin-right: 50px;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.right span {
  color: var(--text-color);
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.loginPage div.loginContainer div.top div.googleContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.loginPage div.loginContainer div.top div.googleContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.top div.googleContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.top div.googleContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.loginPage div.loginContainer div.top div.googleContainer div.left {
  line-height: 0;
  margin-right: 50px;
}
div.loginPage div.loginContainer div.top div.googleContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.loginPage div.loginContainer div.top div.googleContainer div.right span {
  color: #3c4043;
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.loginPage div.loginContainer div.top div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.loginPage div.loginContainer div.top div.seperate span {
  font-size: 12px;
  color: var(--text-color);
}
div.loginPage div.loginContainer div.top div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--inner-text-color);
}
div.loginPage div.loginContainer div.top div.seperate span.line:first-child {
  margin-right: 8px;
}
div.loginPage div.loginContainer div.top div.seperate span.line:last-child {
  margin-left: 8px;
}
div.loginPage div.loginContainer div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.loginPage div.loginContainer div.seperate span {
  font-size: 12px;
  color: var(--text-color);
}
div.loginPage div.loginContainer div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--inner-text-color);
}
div.loginPage div.loginContainer div.seperate span.line:first-child {
  margin-right: 8px;
}
div.loginPage div.loginContainer div.seperate span.line:last-child {
  margin-left: 8px;
}
div.loginPage div.loginContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.loginPage div.loginContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.buttonContainer div.background {
  background: rgba(150, 153, 163, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginPage div.loginContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
}
div.loginPage div.loginContainer div.bottom {
  margin-top: 15px;
}
div.loginPage div.loginContainer div.bottom h3, div.loginPage div.loginContainer div.bottom .h3 {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
div.loginPage div.loginContainer div.bottom form {
  margin-bottom: 20px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer {
  background-color: var(--white-color);
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input {
  background: transparent;
  outline: none;
  border: none;
  text-align: left;
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer {
  background-color: var(--white-color);
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input {
  background: transparent;
  outline: none;
  border: none;
  text-align: left;
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer div.background {
  background: rgba(150, 153, 163, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
}
div.loginPage div.loginContainer div.bottom form h4.forgotPassword, div.loginPage div.loginContainer div.bottom form .forgotPassword.h4 {
  color: var(--white-color);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
  cursor: pointer;
}
div.loginPage div.loginContainer div.bottom form h4, div.loginPage div.loginContainer div.bottom form .h4 {
  font-size: 15px;
  color: #d5d5d6;
  font-weight: 400;
}
div.loginPage div.loginContainer div.bottom form h4 span, div.loginPage div.loginContainer div.bottom form .h4 span {
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
div.loginPage div.loginContainer div.bottom p {
  font-size: 11px;
  color: var(--inner-text-color);
  line-height: 1.6em;
}
div.loginPage div.loginContainer div.bottom p span {
  color: var(--white-color);
  cursor: pointer;
}

div.registerPage {
  background: linear-gradient(354deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 38%, var(--bs-primary) 100%);
}
div.registerPage span.error {
  color: #e72a31;
  font-size: 12px;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer {
  width: 20rem;
  margin: 0 auto;
  padding: 130px 0 100px;
  text-align: center;
}
@media all and (max-width: 640px) {
  div.registerPage div.registerContainer {
    width: 95%;
  }
}
div.registerPage div.registerContainer div.top div.userIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
}
div.registerPage div.registerContainer div.top div.userIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
}
div.registerPage div.registerContainer div.top h1, div.registerPage div.registerContainer div.top .h1 {
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.registerPage div.registerContainer div.top h1, div.registerPage div.registerContainer div.top .h1 {
    font-size: 25px;
  }
}
div.registerPage div.registerContainer div.top h6, div.registerPage div.registerContainer div.top .h6 {
  font-size: 15px;
  color: var(--inner-text-color);
  margin-bottom: 20px;
}
div.registerPage div.registerContainer div.top div.facebookContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.top div.facebookContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.top div.facebookContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3d6ac3;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.left {
  line-height: 0;
  margin-right: 50px;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.right span {
  color: var(--text-color);
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.registerPage div.registerContainer div.top div.googleContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.registerPage div.registerContainer div.top div.googleContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.top div.googleContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.top div.googleContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.registerPage div.registerContainer div.top div.googleContainer div.left {
  line-height: 0;
  margin-right: 50px;
}
div.registerPage div.registerContainer div.top div.googleContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.registerPage div.registerContainer div.top div.googleContainer div.right span {
  color: #3c4043;
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.registerPage div.registerContainer div.top div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.registerPage div.registerContainer div.top div.seperate span {
  font-size: 12px;
  color: var(--text-color);
}
div.registerPage div.registerContainer div.top div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--inner-text-color);
}
div.registerPage div.registerContainer div.top div.seperate span.line:first-child {
  margin-right: 8px;
}
div.registerPage div.registerContainer div.top div.seperate span.line:last-child {
  margin-left: 8px;
}
div.registerPage div.registerContainer div.bottom {
  margin-top: 15px;
}
div.registerPage div.registerContainer div.bottom h3, div.registerPage div.registerContainer div.bottom .h3 {
  color: var(--white-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
div.registerPage div.registerContainer div.bottom form {
  margin-bottom: 20px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne {
  background-color: var(--white-color);
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input {
  background: transparent;
  outline: none;
  border: none;
  text-align: left;
  width: 100%;
  padding: 17px 15px;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo label {
  font-size: 12px;
  color: var(--inner-text-color);
  text-align: left;
  display: block;
  font-weight: 600;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers {
  display: flex;
  height: 100%;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input {
  outline: none;
  border: none;
  text-align: left;
  width: 100%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-webkit-outer-spin-button,
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input[type=number] {
  -moz-appearance: textfield;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.left {
  margin-right: 10px;
  width: 70%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.left input[type=date] {
  padding: 17px 15px;
  border-radius: 8px;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right {
  width: 30%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer {
  background-color: var(--white-color);
  border-radius: 8px;
  position: relative;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer svg {
  position: absolute;
  right: 5px;
  width: 15px !important;
  height: 15px !important;
  color: var(--inner-text-color);
  top: 50%;
  transform: translateY(-50%);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer select {
  padding: 17px 15px;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer select option {
  padding: 17px 15px;
  background-color: var(--text-color);
  color: var(--black-color);
  border-bottom: 1px solid var(--border-color);
  position: relative;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer div.background {
  background: rgba(150, 153, 163, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
}
div.registerPage div.registerContainer div.bottom form h4.forgotPassword, div.registerPage div.registerContainer div.bottom form .forgotPassword.h4 {
  color: var(--white-color);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
  cursor: pointer;
}
div.registerPage div.registerContainer div.bottom h4.signIn, div.registerPage div.registerContainer div.bottom .signIn.h4 {
  font-size: 12px;
  color: #d5d5d6;
  font-weight: 400;
}
div.registerPage div.registerContainer div.bottom h4.signIn span, div.registerPage div.registerContainer div.bottom .signIn.h4 span {
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
div.registerPage div.registerContainer div.bottom p {
  font-size: 11px;
  color: var(--inner-text-color);
  line-height: 1.6em;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom p span {
  color: var(--white-color);
  cursor: pointer;
}

div.checkout-page {
  font-family: Avenir-Roman;
  padding: 100px 5px;
  background-color: var(--black-color);
  min-height: 90vh;
}
div.checkout-page span.heading {
  text-transform: none;
  color: #fff;
  letter-spacing: 0.5px;
  font-size: 40px;
}
@media only screen and (max-width: 768px) {
  div.checkout-page span.heading {
    font-size: 30px;
  }
}
div.checkout-page div.sign-in-link-section {
  margin-bottom: 30px;
  text-align: right;
  font-size: medium;
  font-weight: lighter;
}
@media only screen and (max-width: 768px) {
  div.checkout-page div.sign-in-link-section {
    text-align: left;
  }
}
div.checkout-page div.sign-in-link-section .sub-heading {
  margin-right: 10px;
  font-size: 15px;
}
div.checkout-page div.sign-in-link-section .signin-link {
  font-size: medium;
  cursor: pointer;
  color: #2dbebd;
}
div.checkout-page div.sign-in-link-section .signin-link:hover {
  color: #fff;
}
div.checkout-page div.wrapper {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  div.checkout-page div.wrapper {
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) {
  div.checkout-page div.wrapper .left .user-section {
    width: 485px;
  }
}
@media only screen and (min-width: 1024px) {
  div.checkout-page div.wrapper .left .user-section {
    width: 420px;
    margin-right: 40px;
  }
}
div.checkout-page div.wrapper .left .user-section .forgot-link {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
div.checkout-page div.wrapper .left .user-section .forgot-link .forgot-text {
  margin-top: -5px;
}
div.checkout-page div.wrapper .left .user-section .login-with-email-password {
  text-align: center;
  margin: 20px 0px;
  background: rgba(18, 18, 18, 0.4);
  padding: 10px;
  border-radius: 2px;
}
div.checkout-page div.wrapper .left .user-section .login-with-email-wrapper {
  margin: 20px 0px;
}
div.checkout-page div.wrapper .left .user-section .login-with-email-wrapper button {
  padding: 10px;
  width: 100%;
  background: var(--button-color);
  color: var(--text-color);
  border-radius: 2px;
  font-size: 17px;
}
div.checkout-page div.wrapper .left .user-section .login-with-email-wrapper button:hover {
  font-weight: 600;
  background: rgba(204, 204, 204, 0.0901960784);
  color: #2dbebd;
}
div.checkout-page div.wrapper .left .user-section .seperate {
  display: flex;
  margin: 20px 0px;
}
div.checkout-page div.wrapper .left .user-section .seperate .line {
  width: 50%;
  background: rgba(204, 204, 204, 0.3411764706);
  height: 1px;
  margin-top: 10px;
}
div.checkout-page div.wrapper .left .user-section .seperate .devider {
  margin: 0px 5px;
  color: rgba(204, 204, 204, 0.3411764706);
  font-size: medium;
}
div.checkout-page div.wrapper .left .user-section .existing-user-message {
  width: 485px;
  padding: 15px;
  background: #121212;
  margin: 20px 0px 0px 0px;
  border-radius: 2px;
}
@media only screen and (max-width: 768px) {
  div.checkout-page div.wrapper .left .user-section .existing-user-message {
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  div.checkout-page div.wrapper .left .user-section .existing-user-message {
    width: 420px;
  }
}
div.checkout-page div.wrapper .left .user-section .existing-user-message .close-message {
  font-size: x-small;
  border: 1px solid rgba(255, 255, 255, 0.5098039216);
  padding: 0px 5px;
  border-radius: 50%;
  cursor: pointer;
  float: right;
  margin-top: -10px;
  margin-right: -10px;
  color: rgba(255, 255, 255, 0.5098039216);
}
div.checkout-page div.wrapper .left .user-section .login-success-message {
  max-width: 485px;
  background: #121212;
  padding: 25px;
  border-radius: 2px;
  font-size: large;
  margin-top: 20px;
}
@media only screen and (max-width: 768px) {
  div.checkout-page div.wrapper .left .user-section .login-success-message {
    width: 100%;
    padding: 20px;
    font-size: medium;
  }
}
div.checkout-page div.wrapper .left .user-section .login-button {
  width: 100px;
  height: 35px;
  border-radius: 2px;
  font-size: 17px;
  background: var(--button-color);
  color: var(--text-color);
  float: right;
}
div.checkout-page div.wrapper .left .user-section .login-button:hover {
  background: rgba(204, 204, 204, 0.0901960784);
  color: #2dbebd;
}
div.checkout-page div.wrapper .left .user-section .password-section {
  margin-top: 25px;
}
div.checkout-page div.wrapper .left .user-section .password-section .password-show {
  float: right;
  font-weight: 600;
  cursor: pointer;
  color: #2dbebd;
  position: relative;
  top: 35px;
  right: 10px;
}
div.checkout-page div.wrapper .left .user-section .password-section .password-show:hover {
  color: #fff;
}
div.checkout-page div.wrapper .left .user-section input {
  width: 100%;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 2px;
  padding: 10px;
}
div.checkout-page div.wrapper .left .coupon-wrapper .coupon-link {
  display: flex;
  margin-bottom: 10px;
}
div.checkout-page div.wrapper .left .coupon-wrapper .coupon-link .coupon-text {
  margin-top: 12px;
  margin-right: 10px;
}
div.checkout-page div.wrapper .left .coupon-wrapper .coupon-link .coupon-check {
  width: 15px;
  cursor: pointer;
  margin-top: 12px;
  margin-right: 5px;
}
div.checkout-page div.wrapper .left .coupon-wrapper .coupon-section input {
  width: 65%;
  margin-right: 5%;
  height: 35px;
  padding: 10px;
}
div.checkout-page div.wrapper .left .coupon-wrapper .coupon-section button {
  width: 30%;
  height: 34px;
  border-radius: 2px;
  background: var(--button-color);
  color: var(--text-color);
}
div.checkout-page div.wrapper .left .coupon-wrapper .coupon-section button:hover {
  background: rgba(204, 204, 204, 0.0901960784);
  color: #2dbebd;
}
@media only screen and (min-width: 1024px) {
  div.checkout-page div.wrapper .left {
    width: 420px;
    margin-right: 40px;
  }
}
div.checkout-page div.wrapper .right {
  width: 60%;
  border-left: 2px solid rgba(221, 221, 221, 0.2392156863);
  padding-left: 70px;
}
@media only screen and (max-width: 768px) {
  div.checkout-page div.wrapper .right {
    border-left: transparent;
    border-top: 2px solid;
    width: 100%;
    margin-top: 20px;
    padding: 10px 0px;
  }
}
div.checkout-page div.wrapper .right span.heading {
  text-transform: none;
  color: #fff;
  letter-spacing: 0.5px;
  font-size: 30px;
}
@media only screen and (max-width: 768px) {
  div.checkout-page div.wrapper .right span.heading {
    font-size: 20px;
  }
}
div.checkout-page div.wrapper .right .package-section p.disclaimer-text {
  font-size: smaller;
}
div.checkout-page div.wrapper .right .package-section p.disclaimer-text span {
  font-size: small !important;
}
div.checkout-page div.wrapper .right .package-section .package-item {
  padding: 20px;
  background: rgba(204, 204, 204, 0.0901960784);
  border-radius: 3px;
  margin: 10px 0px;
}
div.checkout-page div.wrapper .right .package-section .package-item .plan-description {
  color: #fff;
  max-height: 150px;
  overflow-y: scroll;
}
div.checkout-page div.wrapper .right .package-section .heading-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
div.checkout-page div.wrapper .right .package-section .heading-section .plan-options-wrapper {
  align-content: center;
  margin-top: 20px;
  cursor: pointer;
}
div.checkout-page div.wrapper .right .package-section .heading-section .plan-options {
  width: 20px;
  height: 20px;
  cursor: pointer !important;
}
div.checkout-page div.wrapper .right .package-section .heading-section .heading-wrapper {
  display: flex;
  flex-direction: column;
}
div.checkout-page div.wrapper .right .package-section .heading-section .heading {
  font-size: 25px;
}
@media only screen and (max-width: 768px) {
  div.checkout-page div.wrapper .right .package-section .heading-section .heading {
    font-size: 15px;
  }
}
div.checkout-page div.wrapper .right .package-section .details {
  color: #2dbebd;
  cursor: pointer;
}
div.checkout-page div.wrapper .right .continue-button {
  width: 200px;
  height: 50px;
  border-radius: 2px;
  font-size: 20px;
  background: var(--button-color);
  color: var(--text-color);
  float: right;
}
@media only screen and (max-width: 768px) {
  div.checkout-page div.wrapper .right .continue-button {
    width: 100%;
    height: 40px;
    font-size: 18px;
  }
}
div.checkout-page div.wrapper .right .continue-button:hover {
  background: rgba(204, 204, 204, 0.0901960784);
  color: #2dbebd;
}
div.checkout-page .link {
  font-size: medium;
  cursor: pointer;
  color: #2dbebd;
}
div.checkout-page .link:hover {
  color: #fff;
}
div.checkout-page .white-link {
  cursor: pointer;
  color: #fff;
}
div.checkout-page .white-link:hover {
  color: #774ed6;
}
div.checkout-page .error {
  color: red;
}
div.checkout-page .success {
  color: #1bbf1b;
  margin-top: 5px;
}

div.commonPage {
  padding: 60px 5px 40px;
  background-color: var(--bg-color);
}
div.commonPage div.top.inside {
  position: absolute;
  top: 60px;
  z-index: 8;
  left: 60px;
}
@media all and (max-width: 640px) {
  div.commonPage div.top.inside {
    top: 50px;
    z-index: 8;
    left: 20px;
  }
}
div.commonPage div.top.inside h1.heading, div.commonPage div.top.inside .heading.h1 {
  text-align: left;
  color: var(--white-color);
}
div.commonPage div.top h1.heading, div.commonPage div.top .heading.h1 {
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--heading-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.commonPage div.top h1.heading, div.commonPage div.top .heading.h1 {
    font-size: 22px;
    margin-top: 50px;
  }
}
div.commonPage div.top p.desc {
  font-size: 18px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.commonPage div.top p.desc {
    font-size: 14px;
  }
}
div.commonPage div.bgImageContainer {
  width: 100%;
  display: block;
  height: 508px;
  position: relative;
}
@media all and (max-width: 768px) {
  div.commonPage div.bgImageContainer {
    height: 556px;
  }
}
div.commonPage div.bgImageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  div.commonPage div.bgImageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.commonPage div.bottomGradient {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}
div.commonPage div.bottom {
  margin-top: 30px;
}
div.commonPage div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.commonPage div.bottom div.itemsContainer div.items.hidden {
  visibility: hidden;
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.hidden {
    display: none;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19.3%;
  margin-right: 6px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5 {
    width: 19.3%;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
  width: 16%;
  margin-right: 6px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-right: 0;
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 16%;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 16%;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount7 {
  width: 13.9%;
  margin-right: 6px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount7:nth-child(7n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7 {
    width: 19.3%;
  }
}
@media all and (max-width: 2600px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 6.5px;
  }
}
@media all and (max-width: 2500px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 5.5px;
  }
}
@media all and (max-width: 2200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.9%;
    margin-right: 6.5px;
  }
}
@media all and (max-width: 1980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 4.5px;
  }
}
@media all and (max-width: 1768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 4px;
  }
}
@media all and (max-width: 1600px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.7%;
    margin-right: 7.5px;
  }
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 3px;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.6%;
    margin-right: 6.1px;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount8 {
  width: 12%;
  margin-right: 8px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount8:nth-child(8n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8 {
    width: 12%;
    margin-right: 6px;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 2600px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12.1%;
    margin-right: 10px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(8n) {
    margin-right: 0px;
  }
}
@media all and (max-width: 2500px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 11px;
  }
}
@media all and (max-width: 2200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12.2%;
    margin-right: 6px;
  }
}
@media all and (max-width: 1980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 9px;
  }
}
@media all and (max-width: 1768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 11.9%;
    margin-right: 10px;
  }
}
@media all and (max-width: 1600px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 6px;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 5px;
  }
}
@media all and (min-width: 981px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:nth-child(8n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(5n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 32.3%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 48.2%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 32%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 48%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.commonPage div.LoadMoreButtonContainer {
  text-align: center;
}
@media all and (max-width: 980px) {
  div.commonPage div.LoadMoreButtonContainer {
    margin-top: 20px;
  }
}
div.commonPage div.LoadMoreButtonContainer button {
  width: 8rem;
  padding: 6px 1px 9px;
  border-radius: 2px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  cursor: pointer;
  transition: 0.3s ease-in-out;
  margin: 0 auto;
}
div.commonPage div.LoadMoreButtonContainer button:hover {
  background-color: var(--secondary-hover-color);
}
div.commonPage div.LoadMoreButtonContainer button span {
  font-size: 14px;
}

div.failed {
  padding: 178px 0;
}
div.failed div.failedContainer {
  background-color: #fff;
  width: 25%;
  margin: 0 auto;
  position: relative;
  padding: 70px 20px 30px;
  text-align: center;
  border-radius: 8px;
}
@media all and (max-width: 1200px) {
  div.failed div.failedContainer {
    width: 31%;
  }
}
@media all and (max-width: 980px) {
  div.failed div.failedContainer {
    width: 41%;
  }
}
@media all and (max-width: 768px) {
  div.failed div.failedContainer {
    width: 45%;
  }
}
@media (max-width: 640px) {
  div.failed div.failedContainer {
    width: 52%;
  }
}
@media (max-width: 480px) {
  div.failed div.failedContainer {
    width: 68%;
  }
}
div.failed div.failedContainer h5, div.failed div.failedContainer .h5 {
  color: #000;
  margin-bottom: 20px;
  font-size: 30px;
}
div.failed div.failedContainer p {
  color: #636363;
  font-size: 15px;
  margin-bottom: 40px;
}
div.failed div.failedContainer button {
  width: 100%;
  outline: none;
  border: 1px solid var(--bs-primary);
  background-color: var(--bs-primary);
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.failed div.failedContainer button:hover {
  background-color: transparent;
  color: #000;
}
div.failed div.failedContainer div.crossIcon {
  text-align: center;
  position: absolute;
  top: -35px;
  right: 0;
  left: 0;
}
div.failed div.failedContainer div.crossIcon svg {
  padding: 20px;
  background-color: var(--bs-primary);
  border-radius: 50%;
}

/* div.successContainer {
    padding: 100px 0;
    background-color: #fff;
    width: 30%;
    margin: 0 auto;
} */
div.success {
  padding: 178px 0;
}
div.success div.successContainer {
  background-color: #fff;
  width: 25%;
  margin: 0 auto;
  position: relative;
  padding: 70px 20px 30px;
  text-align: center;
  border-radius: 8px;
}
div.success div.successContainer p.checking {
  color: var(--bs-primary);
}
@media all and (max-width: 1200px) {
  div.success div.successContainer {
    width: 31%;
  }
}
@media all and (max-width: 980px) {
  div.success div.successContainer {
    width: 41%;
  }
}
@media all and (max-width: 768px) {
  div.success div.successContainer {
    width: 45%;
  }
}
@media (max-width: 640px) {
  div.success div.successContainer {
    width: 52%;
  }
}
@media (max-width: 480px) {
  div.success div.successContainer {
    width: 68%;
  }
}
div.success div.successContainer h5, div.success div.successContainer .h5 {
  color: #000;
  margin-bottom: 20px;
  font-size: 30px;
}
div.success div.successContainer p {
  color: #636363;
  font-size: 15px;
  margin-bottom: 40px;
}
div.success div.successContainer button {
  width: 100%;
  outline: none;
  border: 1px solid var(--bs-primary);
  background-color: var(--bs-primary);
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.success div.successContainer button:hover {
  background-color: transparent;
  color: #000;
}
div.success div.successContainer div.checkIcon {
  text-align: center;
  position: absolute;
  top: -35px;
  right: 0;
  left: 0;
}
div.success div.successContainer div.checkIcon svg {
  padding: 20px;
  background-color: var(--bs-primary);
  border-radius: 50%;
}

div.forgotPassword {
  background: linear-gradient(354deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 38%, var(--bs-primary) 100%);
}
div.forgotPassword span.error {
  color: #e72a31;
  font-size: 12px;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
div.forgotPassword div.forgotPasswordContainer {
  padding: 200px 0;
}
div.forgotPassword div.forgotPasswordContainer h1.heading, div.forgotPassword div.forgotPasswordContainer .heading.h1 {
  font-size: 34px;
  color: var(--white-color);
  text-align: center;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer h1.heading, div.forgotPassword div.forgotPasswordContainer .heading.h1 {
    font-size: 26px;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer {
  text-align: center;
  width: 35rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer {
    width: 25rem;
  }
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer {
    width: 100%;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input {
  background: transparent;
  outline: none;
  border: 1px solid var(--grey-color);
  text-align: left;
  width: 100%;
  padding: 20px 15px;
  caret-color: var(--white-color);
  color: var(--white-color);
  border-radius: 5px;
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input:focus {
  border: 1px solid var(--white-color);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
    width: 10rem;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer div.background {
  background: hsla(226, 7%, 61%, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s ease-in-out;
  z-index: 1;
  border-radius: 5px;
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.resetPassword {
  background: linear-gradient(354deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 38%, rgb(129, 17, 23) 100%);
  padding: 150px 0;
}
div.resetPassword span.error {
  color: #E72A31;
  font-size: 12px;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
div.resetPassword span.success {
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 10px;
}
div.resetPassword h1.heading, div.resetPassword .heading.h1 {
  font-size: 34px;
  padding-bottom: 10px;
  text-align: center;
  color: var(--white-color);
}
@media all and (max-width: 640px) {
  div.resetPassword h1.heading, div.resetPassword .heading.h1 {
    font-size: 22px;
    margin: 0 20px;
  }
}
div.resetPassword div.contents {
  margin-top: 40px;
}
@media all and (max-width: 480px) {
  div.resetPassword div.contents {
    padding: 0 10px;
  }
}
div.resetPassword div.contents div.formSection {
  width: 30%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.resetPassword div.contents div.formSection {
    width: 55%;
  }
}
@media all and (max-width: 980px) {
  div.resetPassword div.contents div.formSection {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection {
    width: 100%;
  }
}
div.resetPassword div.contents div.formSection form div.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
div.resetPassword div.contents div.formSection form div.inputContainer:nth-child(3) {
  margin-bottom: 60px;
}
div.resetPassword div.contents div.formSection form div.inputContainer label {
  font-size: 16px;
  margin-bottom: 8px;
  color: var(--white-color);
}
div.resetPassword div.contents div.formSection form div.inputContainer input {
  outline: none;
  font-size: 14px;
  background: transparent;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid var(--grey-color);
  caret-color: var(--white-color);
  color: var(--white-color);
  margin-bottom: 10px;
  border-radius: 5px;
}
div.resetPassword div.contents div.formSection form div.inputContainer input:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection form div.inputContainer input {
    padding: 16px 15px;
  }
}
div.resetPassword div.contents div.formSection form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.resetPassword div.contents div.formSection form div.buttonContainer {
    width: 10rem;
  }
}
div.resetPassword div.contents div.formSection form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.resetPassword div.contents div.formSection form div.buttonContainer div.background {
  background: hsla(226, 7%, 61%, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s ease-in-out;
  z-index: 1;
  border-radius: 5px;
}
div.resetPassword div.contents div.formSection form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

header.accountsPageHeader {
  height: 100px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: space-between;
  margin: 0 auto;
}
header.accountsPageHeader div.left div.logoContainer h1, header.accountsPageHeader div.left div.logoContainer .h1 {
  width: 120px;
}
@media all and (max-width: 480px) {
  header.accountsPageHeader div.left div.logoContainer h1, header.accountsPageHeader div.left div.logoContainer .h1 {
    width: 72px;
  }
}
@media all and (max-width: 360px) {
  header.accountsPageHeader div.left div.logoContainer h1, header.accountsPageHeader div.left div.logoContainer .h1 {
    width: 72px;
  }
}
header.accountsPageHeader div.left div.logoContainer h1 img, header.accountsPageHeader div.left div.logoContainer .h1 img {
  display: block;
  width: 100%;
}
header.accountsPageHeader div.right button {
  background: transparent;
  color: var(--white-color);
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

div.otpModal div.overlayOtp {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.otpModal div.otpContainer {
  z-index: 99;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  /* Firefox */
}
@media all and (max-width: 1200px) {
  div.otpModal div.otpContainer {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.otpModal div.otpContainer {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.otpModal div.otpContainer {
    width: 90%;
  }
}
div.otpModal div.otpContainer label {
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--text-color);
}
div.otpModal div.otpContainer h4, div.otpModal div.otpContainer .h4 {
  font-size: 30px;
  margin-bottom: 10px;
  color: var(--text-color);
}
div.otpModal div.otpContainer h6, div.otpModal div.otpContainer .h6 {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--text-color);
}
div.otpModal div.otpContainer button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.otpModal div.otpContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid var(--text-color);
  caret-color: var(--white-color);
  margin-bottom: 10px;
  color: var(--white-color);
}
div.otpModal div.otpContainer input:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.otpModal div.otpContainer input {
    padding: 16px 15px;
  }
}
div.otpModal div.otpContainer input::-webkit-outer-spin-button,
div.otpModal div.otpContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.otpModal div.otpContainer input[type=number] {
  -moz-appearance: textfield;
}
div.otpModal div.otpContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.otpModal div.otpContainer div.buttonContainer {
    padding: 10px 20px;
  }
}
div.otpModal div.otpContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.otpModal div.otpContainer div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s ease-in-out;
  z-index: 99;
  border-radius: 4px;
}
div.otpModal div.otpContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 999;
  cursor: pointer;
}
div.otpModal div.otpContainer div.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
div.otpModal div.otpContainer div.buttonsContainer button {
  background: var(--button-color);
  color: var(--text-color);
  position: relative;
  z-index: 999;
  cursor: pointer;
  width: 100px;
  height: 40px;
  border: transparent;
  border-radius: 3px;
  font-size: medium;
  font-weight: 400;
  margin: 5px;
}
div.otpModal div.otpContainer div.buttonsContainer button:hover {
  background: var(--white-color);
  color: var(--button-color);
}
div.otpModal div.otpContainer div.buttonsContainer .cancel {
  background: transparent;
  border: 1px solid;
}
div.otpModal div.otpContainer div.buttonsContainer .cancel:hover {
  color: rgba(0, 0, 0, 0.7);
}

div.continueWatching div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.continueWatching div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  padding: 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--border-color);
  box-shadow: 0 0 0 1px var(--border-color), 0 4px 65px rgba(245, 235, 235, 0.3);
}
@media all and (max-width: 1200px) {
  div.continueWatching div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.continueWatching div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.continueWatching div.contents {
    width: 90%;
  }
}
div.continueWatching div.contents h1, div.continueWatching div.contents .h1 {
  color: var(--white-color);
  font-size: 22px;
}
div.continueWatching div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.continueWatching div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.continueWatching div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-right: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.continueWatching div.contents div.buttons div.buttonContainer:last-child {
  margin-right: 0;
}
@media all and (max-width: 640px) {
  div.continueWatching div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.continueWatching div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.continueWatching div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.continueWatching div.contents div.buttons div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.continueWatching div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

@media all and (min-width: 2560px) {
  div.continueWatching-bottom {
    margin-bottom: 13rem;
    margin-top: -4rem;
  }
}
@media all and (min-width: 1920px) and (max-width: 2559px) {
  div.continueWatching-bottom {
    margin-bottom: 6rem;
    margin-top: -4rem;
  }
}
@media all and (min-width: 769px) and (max-width: 1919px) {
  div.continueWatching-bottom {
    margin-bottom: 2rem;
    margin-top: -4rem;
  }
}
@media all and (max-width: 768px) {
  div.continueWatching-bottom {
    margin-top: -4rem;
  }
}

div.currentPasswordModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.currentPasswordModal div.currentPasswordContainer {
  z-index: 9999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  display: flex;
  flex-direction: column;
  /* Firefox */
}
@media all and (max-width: 1200px) {
  div.currentPasswordModal div.currentPasswordContainer {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.currentPasswordModal div.currentPasswordContainer {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.currentPasswordModal div.currentPasswordContainer {
    width: 90%;
  }
}
div.currentPasswordModal div.currentPasswordContainer label {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--heading-color);
  text-transform: uppercase;
  font-weight: 700;
}
div.currentPasswordModal div.currentPasswordContainer button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.currentPasswordModal div.currentPasswordContainer input {
  outline: none;
  font-size: 14px;
  background: var(--black-color);
  text-align: left;
  padding: 18px 15px;
  border: 1px solid var(--text-color);
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
}
div.currentPasswordModal div.currentPasswordContainer input:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.currentPasswordModal div.currentPasswordContainer input {
    padding: 16px 15px;
  }
}
div.currentPasswordModal div.currentPasswordContainer input::-webkit-outer-spin-button,
div.currentPasswordModal div.currentPasswordContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.currentPasswordModal div.currentPasswordContainer input[type=number] {
  -moz-appearance: textfield;
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer {
  margin-top: 20px;
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer button {
  background-color: var(--button-color);
  border-radius: 3px;
  padding: 10px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
  width: 10rem;
}

div.watchWithoutAds div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.watchWithoutAds div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.watchWithoutAds div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.watchWithoutAds div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.watchWithoutAds div.contents {
    width: 90%;
  }
}
div.watchWithoutAds div.contents h1, div.watchWithoutAds div.contents .h1 {
  color: var(--text-color);
  font-size: 22px;
}
div.watchWithoutAds div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.watchWithoutAds div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-right: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer:last-child {
  margin-right: 0;
}
@media all and (max-width: 640px) {
  div.watchWithoutAds div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.watchWithoutAds div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.logoutAll div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.logoutAll div.closeIconContainer {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 9999999;
}
div.logoutAll div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.logoutAll div.contents {
  z-index: 9999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.logoutAll div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.logoutAll div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.logoutAll div.contents {
    width: 90%;
  }
}
div.logoutAll div.contents h1.error, div.logoutAll div.contents .error.h1 {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.logoutAll div.contents p {
  color: var(--white-color);
  font-size: 16px;
}
div.logoutAll div.contents h1, div.logoutAll div.contents .h1 {
  color: var(--white-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.logoutAll div.contents h1, div.logoutAll div.contents .h1 {
    font-size: 16px;
  }
}
div.logoutAll div.contents div.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
div.logoutAll div.contents div.buttonContainer button {
  background-color: var(--button-color);
  padding: 10px 12px;
  color: var(--text-color);
  font-size: 13px;
  cursor: pointer;
  width: 10rem;
  border-radius: 5px;
}
div.logoutAll div.contents div.buttonContainer button:first-child {
  margin-right: 6px;
}

div.cancelSub div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.cancelSub div.closeIconContainer {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 999999;
  cursor: pointer;
}
div.cancelSub div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  stroke: #E72A31 !important;
}
div.cancelSub div.contents {
  z-index: 9999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.cancelSub div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.cancelSub div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.cancelSub div.contents {
    width: 90%;
  }
}
div.cancelSub div.contents h1.error, div.cancelSub div.contents .error.h1 {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.cancelSub div.contents p {
  color: var(--white-color);
  font-size: 16px;
}
div.cancelSub div.contents h1, div.cancelSub div.contents .h1 {
  color: var(--white-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.cancelSub div.contents h1, div.cancelSub div.contents .h1 {
    font-size: 16px;
  }
}
div.cancelSub div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.cancelSub div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.cancelSub div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-right: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.cancelSub div.contents div.buttons div.buttonContainer:last-child {
  margin-right: 0;
}
div.cancelSub div.contents div.buttons div.buttonContainer:last-child div.background {
  background: var(--error-color);
}
@media all and (max-width: 640px) {
  div.cancelSub div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.cancelSub div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.cancelSub div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.cancelSub div.contents div.buttons div.buttonContainer div.background {
  background: var(--success-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.cancelSub div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.loginRequest {
  background: linear-gradient(354deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 38%, rgb(129, 17, 23) 100%);
  background-repeat: no-repeat;
  height: 100vh;
}

div.playNextEpisode div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.playNextEpisode div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.playNextEpisode div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.playNextEpisode div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.playNextEpisode div.contents {
    width: 90%;
  }
}
div.playNextEpisode div.contents h1, div.playNextEpisode div.contents .h1 {
  color: var(--text-color);
  font-size: 22px;
}
div.playNextEpisode div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.playNextEpisode div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-right: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer:last-child {
  margin-right: 0;
}
@media all and (max-width: 640px) {
  div.playNextEpisode div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.playNextEpisode div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.playNextEpisode div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.playNextEpisode div.contents div.buttons div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.accountSettings {
  padding: 80px 5px;
  position: relative;
  display: flex;
  background-color: var(--account-settings);
  flex-wrap: wrap;
}
@media all and (max-width: 768px) {
  div.accountSettings {
    flex-direction: column;
  }
}
div.accountSettings span.error {
  font-size: 14px;
  color: #e72a31;
  display: block;
  margin-top: 10px;
  font-weight: normal !important;
}
div.accountSettings div.leftContainer {
  width: 18%;
  position: relative;
  margin-right: 20px;
}
@media all and (max-width: 1300px) {
  div.accountSettings div.leftContainer {
    width: 25%;
  }
}
@media all and (max-width: 980px) {
  div.accountSettings div.leftContainer {
    width: 31%;
  }
}
@media all and (max-width: 768px) {
  div.accountSettings div.leftContainer {
    width: 60%;
    margin: 0 auto;
  }
}
@media all and (max-width: 640px) {
  div.accountSettings div.leftContainer {
    width: 90%;
    margin: 0 auto 15px;
  }
}
div.accountSettings div.leftContainer ul.tabs {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media all and (max-width: 768px) {
  div.accountSettings div.leftContainer ul.tabs {
    position: unset;
  }
}
div.accountSettings div.leftContainer ul.tabs li {
  font-size: 13px;
  color: var(--white-color);
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #c8c8c8;
  margin-bottom: 10px;
  cursor: pointer;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.accountSettings div.leftContainer ul.tabs li.active {
  color: var(--secondary-color);
}
div.accountSettings div.rightContainer {
  width: 80%;
}
@media all and (max-width: 1300px) {
  div.accountSettings div.rightContainer {
    width: 70%;
  }
}
@media all and (max-width: 980px) {
  div.accountSettings div.rightContainer {
    width: 65%;
  }
}
@media all and (max-width: 768px) {
  div.accountSettings div.rightContainer {
    width: 90%;
    margin: 0 auto;
  }
}
div.accountSettings div.rightContainer div.top {
  background-color: var(--bg-color);
  padding: 8px 15px;
  margin-bottom: 10px;
}
div.accountSettings div.rightContainer div.top h1, div.accountSettings div.rightContainer div.top .h1 {
  color: var(--white-color);
  font-size: 17px;
}
div.accountSettings div.rightContainer div.bottom {
  background-color: var(--bg-color);
  padding: 30px 25px;
}
div.accountSettings div.rightContainer div.bottom div.profileSection {
  padding-bottom: 40px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  text-align: center;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer input {
  display: none;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer label {
  font-size: 13px;
  color: var(--white-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  transition: 0.3s ease-in-out;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer label:hover {
  color: var(--secondary-color);
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row {
  padding-bottom: 25px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row h1.heading, div.accountSettings div.rightContainer div.bottom div.contactDetails div.row .heading.h1 {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField {
    width: 100%;
  }
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField.noMargin {
  margin-bottom: 0;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField label {
  font-size: 12px;
  color: var(--white-color);
  margin-bottom: 10px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField input {
  width: 100%;
  background-color: transparent;
  outline: none;
  border: 1px solid #c8c8c8;
  padding: 10px;
  border-radius: 5px;
  color: var(--white-color);
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField input:focus {
  border: 2px solid var(--white-color);
  border-radius: 5px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField input:disabled {
  color: var(--grey-color);
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField select {
  width: 100%;
  background-color: var(--bg-color);
  outline: none;
  border: 1px solid #c8c8c8;
  padding: 10px;
  color: var(--white-color);
  border-radius: 5px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField select:focus {
  border: 2px solid var(--white-color);
  border-radius: 5px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row span.language {
  color: var(--secondary-color);
  font-size: 14px;
  display: inline-block;
  margin: 10px 0;
  cursor: pointer;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.buttonContainer button {
  background-color: var(--button-color);
  padding: 8px 12px;
  color: var(--text-color);
  font-size: 13px;
  cursor: pointer;
  border-radius: 3px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.buttonContainer button:disabled,
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.buttonContainer button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: var(--text-color);
  cursor: default;
}
div.accountSettings div.rightContainer div.planDetails {
  background-color: var(--bg-color);
  padding: 30px 25px;
}
div.accountSettings div.rightContainer div.planDetails h1.heading, div.accountSettings div.rightContainer div.planDetails .heading.h1 {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--white-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.planDetails h2.planName, div.accountSettings div.rightContainer div.planDetails .planName.h2 {
  font-size: 12px;
  color: var(--white-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.planDetails p.cancelled {
  color: var(--error-color);
  margin-bottom: 10px;
}
div.accountSettings div.rightContainer div.planDetails div.buttonContainer {
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.planDetails div.buttonContainer button {
  background-color: var(--button-color);
  padding: 8px 12px;
  color: var(--white-color);
  font-size: 12px;
  cursor: pointer;
  border-radius: 3px;
}
div.accountSettings div.rightContainer div.planDetails span.link {
  display: block;
  font-size: 12px;
  color: var(--button-hover);
  font-weight: 700;
  margin-bottom: 10px;
  cursor: pointer;
}
div.accountSettings div.rightContainer div.streamingActivity {
  background-color: var(--bg-color);
  padding: 30px 25px;
}
@media all and (max-width: 1200px) {
  div.accountSettings div.rightContainer div.streamingActivity {
    width: 100%;
  }
}
div.accountSettings div.rightContainer div.streamingActivity p {
  font-size: 18px;
  color: var(--white-color);
  margin-bottom: 30px;
}
div.accountSettings div.rightContainer div.streamingActivity ul.recentDeviceContainer li {
  border-bottom: 1px dashed var(--heading-color);
  padding-bottom: 30px;
  margin-bottom: 30px;
}
div.accountSettings div.rightContainer div.streamingActivity ul.recentDeviceContainer li span {
  color: var(--white-color);
  display: block;
  font-size: 14px;
}
div.accountSettings div.rightContainer div.streamingActivity ul.recentDeviceContainer li span:first-child {
  margin-bottom: 10px;
}
div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer {
    width: 10rem;
  }
}
div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.billingAndSubscription {
  padding: 80px 0;
  background-color: var(--bg-color);
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription {
    width: 100%;
  }
}
div.billingAndSubscription h1.heading, div.billingAndSubscription .heading.h1 {
  font-size: 24px;
  color: var(--heading-color);
  margin-bottom: 30px;
}
div.billingAndSubscription div.billingDetails {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.billingDetails {
    width: 90%;
  }
}
div.billingAndSubscription div.billingDetails h6.subHeading, div.billingAndSubscription div.billingDetails .subHeading.h6 {
  font-size: 14px;
  color: var(--white-color);
  font-weight: normal;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer {
  border: 3px solid var(--border-color);
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer span.head {
  display: block;
  color: var(--white-color);
  margin-bottom: 10px;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer span.value {
  display: block;
  color: var(--white-color);
  font-size: 14px;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer span.value.bold {
  font-weight: 600;
  margin-bottom: 20px;
}
@media all and (max-width: 500px) {
  div.billingAndSubscription div.billingDetails div.billingDetailsContainer .active-card-details .left {
    margin-top: 20px;
  }
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer .active-card-details .right {
  background-color: #fff;
  text-align: center;
  margin-top: 10px;
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer .active-card-details .right:hover {
  background-color: #016cdf;
  color: #fff;
}
@media all and (max-width: 500px) {
  div.billingAndSubscription div.billingDetails div.billingDetailsContainer .active-card-details .right {
    margin: 15px;
  }
}
div.billingAndSubscription div.subscriptionDetails {
  width: 80%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.subscriptionDetails {
    width: 90%;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li.head {
  display: flex;
  color: var(--heading-color);
  font-size: 14px;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li.head {
    display: none;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li {
  border-bottom: 1px solid #c8c8c8;
  display: flex;
  padding: 6px;
  color: var(--white-color);
  font-size: 14px;
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left {
  display: flex;
  width: 80%;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left {
    flex-direction: column;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.date {
  width: 17%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.date {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.transactionType {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.transactionType {
    width: 100%;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.servicePeriod {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.servicePeriod {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.paymentMethod {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.paymentMethod {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
  width: 25%;
}
@media all and (max-width: 980px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
    width: 30%;
  }
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
    width: 100%;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.right {
  width: 20%;
  text-align: right;
}
@media all and (max-width: 980px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.right {
    width: 8%;
  }
}
div.billingAndSubscription div.bottom {
  width: 80%;
  margin: 30px auto 0;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.bottom {
    width: 90%;
  }
}
div.billingAndSubscription div.bottom p {
  color: var(--white-color);
  font-size: 12px;
}
div.billingAndSubscription div.bottom div.buttonContainer {
  margin-top: 20px;
  text-align: center;
}
div.billingAndSubscription div.bottom div.buttonContainer button {
  background-color: var(--button-hover);
  padding: 8px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
  width: 20rem;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.bottom div.buttonContainer button {
    width: 100%;
  }
}

div.liveSection {
  width: 100%;
  margin-top: 30px;
}
div.liveSection h1.heading, div.liveSection .heading.h1 {
  font-size: 28px;
  color: var(--white-color);
  margin-bottom: 10px;
}
div.liveSection div.videoContainer {
  width: 100%;
  position: relative;
}
div.liveSection div.videoContainer video {
  display: block;
  width: 100%;
}
@media all and (min-width: 768px) {
  div.liveSection div.videoContainer:hover div.bottom div.details {
    transform: translateY(0);
  }
}
div.liveSection div.videoContainer div.bottom {
  width: 90%;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  overflow: hidden;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom {
    position: relative;
    bottom: unset;
    left: unset;
    transform: unset;
    width: 100%;
    height: auto;
  }
}
div.liveSection div.videoContainer div.bottom div.details {
  display: flex;
  justify-content: space-between;
  height: 100%;
  transform: translateY(200px);
  transition: 0.3s ease-in-out;
  background-color: rgba(41, 36, 35, 0.6);
  padding: 20px;
  border-radius: 8px;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details {
    flex-direction: column;
    transform: unset;
  }
}
div.liveSection div.videoContainer div.bottom div.details h1.heading, div.liveSection div.videoContainer div.bottom div.details .heading.h1 {
  font-size: 28px;
  color: var(--white-color);
  margin-bottom: 10px;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details h1.heading, div.liveSection div.videoContainer div.bottom div.details .heading.h1 {
    font-size: 22px;
  }
}
div.liveSection div.videoContainer div.bottom div.details h1.title, div.liveSection div.videoContainer div.bottom div.details .title.h1 {
  font-size: 20px;
  color: var(--text-hover);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.liveSection div.videoContainer div.bottom div.details span.time {
  font-size: 14px;
  color: var(--text-color);
  display: inline-block;
  margin-bottom: 10px;
}
div.liveSection div.videoContainer div.bottom div.details p.description {
  color: var(--white-color);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
div.liveSection div.videoContainer div.bottom div.details div.left {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.liveSection div.videoContainer div.bottom div.details div.right {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details div.right {
    width: 100%;
  }
}

div.showsRow {
  background-color: var(--secondary-bg-color);
  padding: 40px 5px 0;
}
div.showsRow h1.heading, div.showsRow .heading.h1 {
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.showsRow .swiper-button-prev {
  left: 0 !important;
  top: 50% !important;
}
div.showsRow .swiper-button-prev::after {
  left: 20px !important;
}
div.showsRow .swiper-button-disabled {
  display: none;
}
div.showsRow .swiper-button-next {
  right: 0 !important;
  top: 50% !important;
}
div.showsRow .swiper-button-next::after {
  right: 20px !important;
}
div.showsRow div.seasonContainer {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 20rem;
  margin-bottom: 20px;
  background: transparent;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: var(--white-color);
  cursor: pointer;
}
@media all and (max-width: 480px) {
  div.showsRow div.seasonContainer {
    width: 100%;
  }
}
div.showsRow div.seasonContainer svg {
  width: 26px !important;
  height: 26px !important;
  transition: 0.3s ease-in-out;
}
div.showsRow div.seasonContainer svg.active {
  transform: rotate(-180deg);
}
div.showsRow div.seasonContainer div.list {
  position: absolute;
  top: 55px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 9;
  cursor: pointer;
  height: 100px;
  overflow-y: scroll;
  border: 1px solid var(--border-color);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: none;
}
div.showsRow div.seasonContainer div.list::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.showsRow div.seasonContainer div.list::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px;
}
div.showsRow div.seasonContainer div.list::-webkit-scrollbar-track {
  background-color: var(--grey-color);
}
div.showsRow div.seasonContainer div.list.active {
  display: block;
}
div.showsRow div.seasonContainer div.list div.item {
  width: 100%;
  padding: 20px 10px;
  border-bottom: 1px solid var(--border-color);
  background-color: #1c1f29;
}
div.showsRow div.seasonContainer div.list div.item:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
div.showsRow div.seasonContainer div.list div.item span {
  font-size: 16px;
  color: var(--white-color);
}

div.subcriptionPage {
  padding: 150px 5px;
  background-color: var(--secondary-bg-color);
}
div.subcriptionPage h1.heading, div.subcriptionPage .heading.h1 {
  text-align: center;
  font-size: 34px;
  color: var(--white-color);
}
div.subcriptionPage div.subscriptionContainer {
  width: 90%;
  margin: 30px auto 0;
  overflow-x: scroll;
}
div.subcriptionPage div.subscriptionContainer table {
  width: 100%;
  border-collapse: collapse;
}
div.subcriptionPage div.subscriptionContainer table thead tr th {
  width: 80%;
}
div.subcriptionPage div.subscriptionContainer table thead tr th button {
  display: inline-block;
  padding: 10px 25px;
  border-radius: 30px;
  color: var(--white-color);
  cursor: pointer;
}
div.subcriptionPage div.subscriptionContainer table thead tr th button.active {
  background-color: var(--text-hover);
}
div.subcriptionPage div.subscriptionContainer table thead tr th button.no-active {
  background-color: var(--text-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr {
  border-bottom: 1px solid var(--border-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td {
  padding: 10px 0;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.big span {
  color: var(--white-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span {
  display: block;
  text-align: center;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.no-active {
  color: var(--text-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.no-active svg {
  stroke: var(--text-color) !important;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.active {
  color: var(--text-hover);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.active svg {
  stroke: var(--text-hover) !important;
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 50px auto 0;
}
@media all and (max-width: 640px) {
  div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
    width: 10rem;
  }
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.subscriptionList {
  padding: 100px 5px;
  background-color: var(--sub-bg-color);
}
div.subscriptionList h1.heading, div.subscriptionList .heading.h1 {
  font-size: 28px;
  text-align: center;
  color: var(--bs-primary);
}
div.subscriptionList div.wrapper {
  width: 90%;
}
div.subscriptionList div.subscriptionDetails {
  border: 1px solid var(--border-color);
  box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
  padding: 15px 28px;
  margin-top: 50px;
  color: var(--text-color);
}
div.subscriptionList div.subscriptionDetails h2.heading, div.subscriptionList div.subscriptionDetails .heading.h2 {
  font-size: 22px;
  color: var(--bs-primary);
  text-align: center;
}
div.subscriptionList div.subscriptionDetails ul.conditions {
  list-style: square;
  margin-top: 30px;
}
div.subscriptionList div.subscriptionDetails ul.conditions li {
  line-height: 28px;
}
div.subscriptionList div.subscriptionDetails ul.conditions li span {
  font-size: 16px;
}
div.subscriptionList div.pricing {
  margin-top: 30px;
}
div.subscriptionList div.pricing ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}
div.subscriptionList div.pricing ul li {
  margin-right: 20px;
  margin-bottom: 30px;
  padding: 60px 60px;
  border: 1px solid var(--border-color);
  width: 20rem;
  text-align: center;
  box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
}
div.subscriptionList div.pricing ul li:last-child {
  margin-right: 0;
}
div.subscriptionList div.pricing ul li button {
  display: block;
  border: none;
  outline: none;
  padding: 10px 25px;
  background-color: transparent;
  border: 1px solid var(--border-color);
  margin: 0 auto 20px;
  color: #fff;
  border-radius: 30px;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}
div.subscriptionList div.pricing ul li button:hover {
  background-color: var(--sub-heading-color);
  border: 1px solid var(--sub-heading-color);
  color: var(--white-color);
}
div.subscriptionList div.pricing ul li span.category {
  display: block;
  margin-bottom: 10px;
  color: var(--white-color);
}
div.subscriptionList div.pricing ul li span.price {
  display: block;
  color: var(--white-color);
}
div.subscriptionList div.pricing ul li span.price.discount span.priceCut {
  margin-right: 10px;
  text-decoration: line-through;
  display: block;
}

/* div.subscriptionList div.subscriptionDetails ul.conditions li::before { 
    content: "-";
    margin-right: 4px;
} */
@media all and (max-width: 1200px) {
  div.subscriptionList div.subscriptionDetails h2.heading, div.subscriptionList div.subscriptionDetails .heading.h2 {
    font-size: 20px;
  }
  div.subscriptionList div.subscriptionDetails ul.conditions li {
    font-size: 15px;
  }
}
@media all and (max-width: 980px) {
  div.subscriptionList div.subscriptionDetails h2.heading, div.subscriptionList div.subscriptionDetails .heading.h2 {
    font-size: 19px;
  }
}
@media all and (max-width: 768px) {
  div.subscriptionList div.subscriptionDetails h2.heading, div.subscriptionList div.subscriptionDetails .heading.h2 {
    font-size: 18px;
  }
}
@media all and (max-width: 640px) {
  div.subscriptionList div.pricing ul {
    flex-direction: column;
  }
  div.subscriptionList div.pricing ul li {
    margin-right: 0;
    width: 80%;
    margin: 0 auto 20px;
  }
  div.subscriptionList div.pricing ul li:last-child {
    margin-bottom: 0;
  }
  div.subscriptionList div.pricing ul li button {
    margin: 0 auto 20px;
  }
}
@media all and (max-width: 480px) {
  div.subscriptionList div.pricing ul li {
    width: 100%;
  }
  div.subscriptionList h1.heading, div.subscriptionList .heading.h1 {
    font-size: 25px;
  }
}
div.ideabiz-containter {
  min-height: 100vh;
}

div.paymentScreen {
  padding: 100px 0;
  background-color: var(--sub-bg-color);
}
div.paymentScreen .paypal-button-container {
  min-width: 326px !important;
  max-width: 284px !important;
}
div.paymentScreen span.success {
  color: var(--success-color);
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper {
    width: 90%;
  }
}
div.paymentScreen div.wrapper h1.heading, div.paymentScreen div.wrapper .heading.h1 {
  text-align: center;
  font-size: 30px;
  color: var(--white-color);
  margin-bottom: 30px;
  text-transform: uppercase;
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper h1.heading, div.paymentScreen div.wrapper .heading.h1 {
    font-size: 25px;
  }
}
div.paymentScreen div.wrapper div.mainContainer {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
div.paymentScreen div.wrapper div.mainContainer.centerDiv {
  justify-content: center;
}
div.paymentScreen div.wrapper div.mainContainer.modifyDiv {
  justify-content: space-evenly;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer {
    flex-direction: column;
    width: 80%;
  }
  div.paymentScreen div.wrapper div.mainContainer.modifyDiv {
    align-items: center;
  }
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer {
    width: 100%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left {
  width: 50%;
  border-right: 2px solid var(--border-color);
  padding-right: 1%;
}
div.paymentScreen div.wrapper div.mainContainer div.left.small {
  width: 33.3%;
}
div.paymentScreen div.wrapper div.mainContainer div.left.no-border {
  border-right: none;
}
@media all and (max-width: 1200px) {
  div.paymentScreen div.wrapper div.mainContainer div.left.small {
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.left {
    width: 100%;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left.small {
    width: 90%;
    border-right: none;
    border-bottom: 2px solid var(--border-color);
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer label {
  font-size: 20px;
  display: block;
  color: var(--text-color);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 25rem;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
  width: 25rem;
}
@media all and (max-width: 1600px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 23rem;
  }
}
@media all and (max-width: 1400px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 25rem;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 21rem;
  }
}
@media all and (max-width: 1200px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 24rem;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 18rem;
  }
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 22rem;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 90%;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    width: 90%;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    flex-direction: column;
  }
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer.small {
    flex-direction: column;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: #000;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid transparent;
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
  margin-right: 20px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input:focus {
  border: 1px solid var(--text-color);
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
    padding: 16px 15px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
    margin-bottom: 20px;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
    width: 15rem;
    padding: 16px 20px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
    width: 10rem;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer {
  margin-top: 30px;
  margin-bottom: 20px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer h2.heading, div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer .heading.h2 {
  font-size: 20px;
  color: var(--text-color);
  font-weight: 400;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways {
  display: flex;
  flex-wrap: wrap;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
  margin: 5px;
  width: 90px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li:last-child {
  margin-right: 0;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
    width: 70px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
    width: 20%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer {
  width: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer:hover {
  transform: scale(1.1);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer img {
  width: 100%;
  display: block;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer img.ideabiz-icon {
  border-radius: 5px;
}
div.paymentScreen div.wrapper div.mainContainer div.center {
  width: 33.3%;
  padding: 0 40px 0;
  border-right: 2px solid var(--border-color);
}
div.paymentScreen div.wrapper div.mainContainer div.center.small {
  width: 33.3%;
}
div.paymentScreen div.wrapper div.mainContainer div.center.no-border {
  border-right: none;
  display: flex;
  width: 50%;
  justify-content: center;
}
div.paymentScreen div.wrapper div.mainContainer div.center.no-border div.paymentGatewayContainer ul.gatewaysSquare li div.squareImageContainer {
  margin-left: 0;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.center {
    width: 90%;
    padding: 40px 0 0;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid var(--border-color);
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.small {
    width: 90%;
    border-right: none;
    border-bottom: 2px solid var(--border-color);
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.no-border {
    border-bottom: none;
    width: 90%;
    justify-content: left;
  }
}
@media all and (max-width: 768px) {
  div.paymentScreen div.wrapper div.mainContainer div.center {
    width: 100%;
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.small {
    width: 90%;
    border-right: none;
    border-bottom: 2px solid var(--border-color);
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.no-border {
    border-bottom: none;
    width: 100%;
    justify-content: left;
  }
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.center.small {
    width: 90%;
    border-right: none;
    border-bottom: 2px solid var(--border-color);
  }
  div.paymentScreen div.wrapper div.mainContainer div.center.no-border {
    border-bottom: none;
    width: 90%;
    justify-content: left;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.center iframe {
  z-index: 2 !important;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer {
  margin-bottom: 20px;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer h2.heading, div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer .heading.h2 {
  font-size: 20px;
  color: var(--white-color);
  font-weight: 400;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li {
  margin-right: 20px;
  width: 250px;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li:last-child {
  margin-right: 0;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li {
    width: 70px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li {
    width: 20%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li div.cinetImageContainer {
  width: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li div.cinetImageContainer:hover {
  transform: scale(1.1);
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li div.cinetImageContainer img {
  width: 100%;
  display: block;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysCinet li div.cinetImageContainer {
    width: 200px;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysSquare {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 60%;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysSquare {
    width: 30%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysSquare li {
  margin-right: 20px;
  width: 250px;
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysSquare li div.squareImageContainer {
  width: 100%;
  transition: 0.3s ease-in-out;
  margin-left: 45%;
  cursor: pointer;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysSquare li div.squareImageContainer {
    margin-left: 0;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysSquare li div.squareImageContainer:hover {
  transform: scale(1.1);
}
div.paymentScreen div.wrapper div.mainContainer div.center div.paymentGatewayContainer ul.gatewaysSquare li div.squareImageContainer img {
  width: 100%;
  display: block;
}
div.paymentScreen div.wrapper div.mainContainer div.right {
  width: 50%;
  padding: 0 40px 0;
}
div.paymentScreen div.wrapper div.mainContainer div.right.small {
  width: 33.3%;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.right {
    width: 90%;
    padding: 40px 0 0;
    border-left: none;
  }
  div.paymentScreen div.wrapper div.mainContainer div.right.small {
    width: 90%;
  }
}
@media all and (max-width: 768px) {
  div.paymentScreen div.wrapper div.mainContainer div.right {
    width: 100%;
  }
  div.paymentScreen div.wrapper div.mainContainer div.right.small {
    width: 90%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.right iframe {
  z-index: 2 !important;
}

div.paymentProcessingScreen {
  padding: 100px 0;
  background-color: var(--sub-bg-color);
}
div.paymentProcessingScreen .paypal-button-container {
  min-width: 326px !important;
  max-width: 284px !important;
}
div.paymentProcessingScreen span.success {
  color: var(--success-color);
}
@media all and (max-width: 640px) {
  div.paymentProcessingScreen div.wrapper {
    width: 90%;
  }
}
div.paymentProcessingScreen div.paymentContainer {
  width: 90%;
  height: 50rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

div.searchPage {
  padding: 100px 5px 40px;
  background-color: var(--bg-color);
  min-height: 730px;
}
div.searchPage div.top {
  border-bottom: 1px solid var(--border-color);
}
div.searchPage div.top h1.heading, div.searchPage div.top .heading.h1 {
  font-size: 28px;
  margin-bottom: 10px;
  color: var(--heading-color);
}
@media all and (max-width: 640px) {
  div.searchPage div.top h1.heading, div.searchPage div.top .heading.h1 {
    font-size: 26px;
  }
}
div.searchPage div.top p.desc {
  font-size: 18px;
  color: var(--heading-color);
  margin-bottom: 15px;
}
@media all and (max-width: 640px) {
  div.searchPage div.top p.desc {
    font-size: 14px;
  }
}
div.searchPage div.bottom {
  margin-top: 10px;
}
div.searchPage div.bottom div.categories {
  display: flex;
  margin-bottom: 72px;
}
div.searchPage div.bottom div.categories button {
  background-color: transparent;
  outline: none;
  color: var(--white-color);
  padding: 8px 30px;
  border-radius: 30px;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid transparent;
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 15px;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 6px;
    font-size: 13px;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 8px;
    font-size: 10px;
  }
}
@media all and (max-width: 360px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 5px;
  }
}
div.searchPage div.bottom div.categories button:last-child {
  margin-right: 0;
}
div.searchPage div.bottom div.categories button.active {
  border: 1px solid var(--grey-color);
  background-color: var(--secondary-btn-hover);
  color: var(--white-color);
}
div.searchPage div.bottom div.categories button:hover {
  background-color: var(--button-hover);
  color: var(--grey-color);
}
div.searchPage div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.searchPage div.bottom div.itemsContainer div.items.hidden {
  visibility: hidden;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19.3%;
  margin-right: 6px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5 {
    width: 19.3%;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
  width: 16%;
  margin-right: 6px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-right: 0;
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 16%;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 16%;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount7 {
  width: 13.9%;
  margin-right: 6px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount7:nth-child(7n) {
  margin-right: 0;
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 2600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 7px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(7n) {
    margin-right: 0;
  }
}
@media all and (max-width: 2500px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 6.5px;
  }
}
@media all and (max-width: 2200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 6px;
  }
}
@media all and (max-width: 1980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 5px;
  }
}
@media all and (max-width: 1768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 4.8px;
  }
}
@media all and (max-width: 1600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.8%;
    margin-right: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 3px;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.7%;
    margin-right: 6.1px;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount8 {
  width: 12%;
  margin-right: 8px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount8:nth-child(8n) {
  margin-right: 0;
}
@media all and (max-width: 2500px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 10px;
  }
}
@media all and (max-width: 2200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 9px;
  }
}
@media all and (max-width: 1980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 8px;
  }
}
@media all and (max-width: 1768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 7px;
  }
}
@media all and (max-width: 1600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 6px;
  }
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 5px;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 11.9%;
    margin-right: 5px;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
  width: 11%;
  margin-right: 21px;
}
@media all and (max-width: 2600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(8n) {
    margin-right: 0;
  }
}
@media all and (max-width: 2500px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 11px;
  }
}
@media all and (max-width: 2200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 10px;
  }
}
@media all and (max-width: 1980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 9px;
  }
}
@media all and (max-width: 1768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 8px;
  }
}
@media all and (max-width: 1600px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 6px;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 11.9%;
    margin-right: 6px;
  }
}
@media all and (min-width: 981px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:nth-child(8n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 32.3%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 48.2%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 32%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-right: 0;
  }
}

div.myList {
  padding: 50px 0 0;
  min-height: 680px;
  background-color: var(--bg-color);
}
div.myList div.top {
  background-color: var(--bg-color);
  padding: 40px 0 70px;
}
div.myList div.top h1.heading, div.myList div.top .heading.h1 {
  font-size: 30px;
  color: var(--heading-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.myList div.top h1.heading, div.myList div.top .heading.h1 {
    font-size: 25px;
  }
}
div.myList div.top p.desc {
  font-size: 18px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.myList div.top p.desc {
    font-size: 14px;
  }
}
div.myList div.bottom {
  background-color: var(--bg-color);
  padding: 0 50px 100px;
}
@media all and (max-width: 640px) {
  div.myList div.bottom {
    padding: 0 20px;
  }
}
div.myList div.bottom div.noContentInfo {
  width: 20rem;
  margin: 0 auto;
  padding: 50px 5px 200px;
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.noContentInfo {
    width: 100%;
  }
}
div.myList div.bottom div.noContentInfo h4.heading, div.myList div.bottom div.noContentInfo .heading.h4 {
  font-size: 15px;
  color: var(--heading-color);
  margin-bottom: 10px;
  text-align: center;
}
div.myList div.bottom div.noContentInfo p {
  font-size: 14px;
  color: var(--heading-color);
  line-height: 1.8em;
  text-align: center;
}
div.myList div.bottom div.noContentInfo p svg {
  width: 12px !important;
  height: 12px !important;
  fill: var(--text-color);
}
div.myList div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.myList div.bottom div.itemsContainer div.items.hidden {
  visibility: hidden;
}
div.myList div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(3n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount3.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-right: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(4n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount4.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19.3%;
  margin-right: 6px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-right: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5 {
    width: 19.3%;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount5.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount6 {
  width: 16%;
  margin-right: 6px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-right: 0;
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6 {
    width: 16%;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6 {
    width: 16%;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount6.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount7 {
  width: 13.6%;
  margin-right: 11px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount7:nth-child(7n) {
  margin-right: 0;
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7 {
    width: 16%;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7 {
    width: 16%;
  }
}
@media all and (max-width: 2600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13%;
    margin-right: 29px;
  }
}
@media all and (max-width: 2500px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13%;
    margin-right: 25px;
  }
}
@media all and (max-width: 2200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.8%;
    margin-right: 8px;
  }
}
@media all and (max-width: 1980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.9%;
    margin-right: 6px;
  }
}
@media all and (max-width: 1768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.9%;
    margin-right: 5px;
  }
}
@media all and (max-width: 1600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.8%;
    margin-right: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.8%;
    margin-right: 5px;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 13.7%;
    margin-right: 5px;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 1600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.8%;
    margin-right: 7px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(7n) {
    margin-right: 0px;
  }
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 14%;
    margin-right: 3px;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 13.7%;
    margin-right: 6.1px;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) and (max-width: 1300px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(6n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:nth-child(7n) div.showCardContainer div.square {
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount7.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount8 {
  width: 12%;
  margin-right: 8px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount8:nth-child(8n) {
  margin-right: 0;
}
@media all and (max-width: 2500px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 10px;
  }
}
@media all and (max-width: 2200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 9px;
  }
}
@media all and (max-width: 1980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 8px;
  }
}
@media all and (max-width: 1768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 7px;
  }
}
@media all and (max-width: 1600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 6px;
  }
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 12%;
    margin-right: 5px;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 11.9%;
    margin-right: 5px;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.1);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:hover:nth-child(5n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.1);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
  width: 11%;
  margin-right: 21px;
}
@media all and (max-width: 2600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(8n) {
    margin-right: 0;
  }
}
@media all and (max-width: 2500px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 11px;
  }
}
@media all and (max-width: 2200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 10px;
  }
}
@media all and (max-width: 1980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 9px;
  }
}
@media all and (max-width: 1768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 8px;
  }
}
@media all and (max-width: 1600px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 12%;
    margin-right: 6px;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 11.9%;
    margin-right: 6px;
  }
}
@media all and (min-width: 981px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square {
    position: absolute;
    z-index: 9999999;
    transform: translateX(60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:hover div.showCardContainer .square div.metaData {
    display: block;
    visibility: visible !important;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:nth-child(8n) div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:hover:last-child div.showCardContainer div.square {
    transform: translateX(-60px) translateY(-60px) scale(1.2);
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.rowCount8.portrait:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 24.3%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(6n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 32.3%;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 48.2%;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(6n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) and (max-width: 360px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 100%;
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 24%;
    margin-right: 6px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(6n) {
    margin-right: 6px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 32%;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-right: 6px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-right: 0;
  }
}

div.privacyPolicy {
  padding: 130px 0;
  background-color: var(--bg-color);
}
div.privacyPolicy h1.noData, div.privacyPolicy .noData.h1 {
  text-align: center;
  color: var(--heading-color);
}

div.termsAndConditions {
  padding: 130px 0;
  background-color: var(--bg-color);
}
div.termsAndConditions h1.noData, div.termsAndConditions .noData.h1 {
  text-align: center;
  color: var(--heading-color);
}

div.contactUs {
  padding: 130px 0;
}

div.contactSupport {
  padding: 130px 0;
  background-color: var(--bg-color);
}
div.contactSupport h1.heading, div.contactSupport .heading.h1 {
  font-size: 28px;
  margin-bottom: 30px;
  color: var(--white-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.contactSupport h1.heading, div.contactSupport .heading.h1 {
    font-size: 28px;
  }
}
div.contactSupport div.formContainer {
  width: 50%;
  margin: 0 auto;
}
@media all and (max-width: 980px) {
  div.contactSupport div.formContainer {
    width: 100%;
  }
}
div.contactSupport div.formContainer form div.full {
  margin-bottom: 20px;
}
div.contactSupport div.formContainer form div.full input,
div.contactSupport div.formContainer form div.full select {
  color: var(--white-color) !important;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: left;
  padding: 20px 15px;
  border: 1px solid var(--white-color);
  caret-color: var(--white-color);
  display: block;
  width: 100%;
  color: var(--white-color);
}
div.contactSupport div.formContainer form div.full input option,
div.contactSupport div.formContainer form div.full select option {
  color: #000 !important;
}
div.contactSupport div.formContainer form div.full input:focus,
div.contactSupport div.formContainer form div.full select:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.contactSupport div.formContainer form div.full input,
  div.contactSupport div.formContainer form div.full select {
    padding: 16px 15px;
  }
}
div.contactSupport div.formContainer form div.full label {
  color: var(--white-color);
  font-size: 16px;
  display: inline-block;
  margin-bottom: 10px;
}
div.contactSupport div.formContainer form div.full.message {
  display: flex;
  flex-direction: column;
}
div.contactSupport div.formContainer form div.full.message textarea {
  height: 150px;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  caret-color: var(--text-color);
  color: var(--white-color);
  padding: 20px 15px;
}
div.contactSupport div.formContainer form div.full.message textarea:focus {
  border: 1px solid var(--white-color);
}
div.contactSupport div.formContainer form div.full div.buttonContainer {
  margin-bottom: 20px;
  text-align: center;
}
div.contactSupport div.formContainer form div.full div.buttonContainer button {
  background-color: var(--button-color);
  padding: 8px 12px;
  color: var(--text-color);
  font-size: 13px;
  cursor: pointer;
  border-radius: 3px;
  width: 10rem;
}

div.aboutUs {
  padding: 130px 0;
  background-color: var(--bg-color);
}
div.aboutUs h1.noData, div.aboutUs .noData.h1 {
  text-align: center;
  color: var(--heading-color);
}

div.subscription-plan-containter {
  min-height: 300vh;
  margin-top: 0px;
  position: relative;
}
@media all and (max-width: 768px) {
  div.subscription-plan-containter {
    min-height: 500vh;
  }
}
div.subscription-plan-containter #page-container {
  overflow-y: hidden;
}

div.help-center-containter {
  min-height: 500vh;
  margin-top: 0px;
  position: relative;
}
@media all and (max-width: 900px) and (orientation: landscape) {
  div.help-center-containter {
    min-height: 1000vh;
  }
}
div.help-center-containter #page-container {
  overflow-y: hidden;
}

/* Demo */
/* Animation */
div.loadingContent {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loadingContent div.loadingOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--bg-color);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loadingContent div.loading-container {
  width: 256px;
  height: 256px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loadingContent .loader {
  position: relative;
  width: 54px;
  height: 54px;
  display: block;
}
div.loadingContent .loader:before, div.loadingContent .loader:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2) inset;
}
div.loadingContent .loader:after {
  box-shadow: 0 2px 0 var(--button-color) inset;
  animation: rotate 0.9s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

div.newsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--bg-color);
  transition: 0.4s ease-in-out;
}
div.newsCard:hover {
  background-color: var(--secondary-bg-color);
}
@media all and (max-width: 980px) {
  div.newsCard {
    flex-direction: column;
  }
}
div.newsCard div.left {
  width: 25%;
}
@media all and (max-width: 1400px) {
  div.newsCard div.left {
    width: 30%;
  }
}
@media all and (max-width: 1200px) {
  div.newsCard div.left {
    width: 34%;
  }
}
@media all and (max-width: 980px) {
  div.newsCard div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.newsCard div.left div.imageContainer {
  width: 100%;
}
div.newsCard div.left div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 8px;
}
div.newsCard div.right {
  width: 72%;
}
@media all and (max-width: 1400px) {
  div.newsCard div.right {
    width: 66%;
  }
}
@media all and (max-width: 1200px) {
  div.newsCard div.right {
    width: 63%;
  }
}
@media all and (max-width: 980px) {
  div.newsCard div.right {
    width: 100%;
  }
}
div.newsCard div.right h1.title, div.newsCard div.right .title.h1 {
  font-size: 22px;
  margin-bottom: 20px;
  color: var(--white-color);
}
@media all and (max-width: 1200px) {
  div.newsCard div.right h1.title, div.newsCard div.right .title.h1 {
    margin-bottom: 10px;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right h1.title, div.newsCard div.right .title.h1 {
    font-size: 20px;
  }
}
div.newsCard div.right p {
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 10px;
}
@media all and (max-width: 1200px) {
  div.newsCard div.right p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right p {
    font-size: 13px;
  }
}
div.newsCard div.right div.dateSection {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media all and (max-width: 1200px) {
  div.newsCard div.right div.dateSection {
    margin-bottom: 10px;
  }
}
div.newsCard div.right div.dateSection span.icon {
  width: 20px;
  display: inline-block;
  margin-right: 10px;
}
div.newsCard div.right div.dateSection span.icon img {
  width: 100%;
  display: block;
}
div.newsCard div.right div.dateSection span.date {
  font-size: 14px;
  color: var(--text-hover);
}
@media all and (max-width: 640px) {
  div.newsCard div.right div.dateSection span.date {
    font-size: 13px;
  }
}
div.newsCard div.right div.buttonContainer {
  display: flex;
  padding: 12px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 20px 0 0;
}
@media all and (max-width: 768px) {
  div.newsCard div.right div.buttonContainer {
    margin: 20px 0 0;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right div.buttonContainer {
    width: 12rem;
  }
}
@media all and (max-width: 480px) {
  div.newsCard div.right div.buttonContainer {
    width: 15rem;
  }
}
div.newsCard div.right div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.newsCard div.right div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.newsCard div.right div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.latestNews {
  padding: 130px 0 80px;
  position: relative;
}
div.latestNews div.head {
  display: flex;
  justify-content: right;
}
@media all and (max-width: 640px) {
  div.latestNews div.head {
    padding-right: 20px;
  }
}
div.latestNews div.head div.logoContainer {
  width: 150px;
}
@media all and (max-width: 640px) {
  div.latestNews div.head div.logoContainer {
    width: 120px;
  }
}
div.latestNews div.head div.logoContainer img {
  display: block;
  width: 100%;
}
div.latestNews div.body h1.heading, div.latestNews div.body .heading.h1 {
  font-size: 34px;
  color: var(--white-color);
  margin-bottom: 30px;
}
div.latestNews div.body div.gradientBg {
  background: url(./assets/Icons/gradient.svg) repeat-x center bottom;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 200px;
}
div.latestNews div.body div.gradientBg div.buttonContainer {
  text-align: center;
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
  border: 1px solid var(--text-hover);
  background-color: transparent;
  color: var(--text-hover);
  padding: 6px 20px;
  font-size: 14px;
  border-radius: 6px;
  width: 15%;
  transition: 0.3s ease-in-out;
  margin: 140px auto 0;
  cursor: pointer;
}
@media all and (max-width: 1200px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 20%;
  }
}
@media all and (max-width: 980px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 25%;
  }
}
@media all and (max-width: 769px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 30%;
  }
}
@media all and (max-width: 769px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 30%;
  }
}
@media all and (max-width: 640px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 35%;
  }
}
@media all and (max-width: 480px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 45%;
  }
}
@media all and (max-width: 360px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 50%;
  }
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore svg {
  transition: 0.3s ease-in-out;
  margin-left: 6px;
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore:hover {
  background-color: var(--text-hover);
  color: var(--white-color);
}

div.articleInfo {
  padding: 130px 0 80px;
}
div.articleInfo div.wrapper {
  width: 80%;
}
div.articleInfo div.wrapper h1.heading, div.articleInfo div.wrapper .heading.h1 {
  font-size: 32px;
  color: var(--white-color);
}
@media all and (max-width: 640px) {
  div.articleInfo div.wrapper h1.heading, div.articleInfo div.wrapper .heading.h1 {
    padding-left: 20px;
  }
}
div.articleInfo div.wrapper div.dateSection {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
@media all and (max-width: 640px) {
  div.articleInfo div.wrapper div.dateSection {
    padding-left: 20px;
  }
}
div.articleInfo div.wrapper div.dateSection span.icon {
  width: 20px;
  display: block;
  margin-right: 10px;
}
div.articleInfo div.wrapper div.dateSection span.icon img {
  display: block;
  width: 100%;
}
div.articleInfo div.wrapper div.dateSection span.date {
  font-size: 12px;
  color: var(--text-hover);
}
div.articleInfo div.wrapper div.articleDetails {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 20px auto 0;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.articleDetails {
    width: 90%;
  }
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails {
    flex-direction: column;
  }
}
div.articleInfo div.wrapper div.articleDetails div.left {
  width: 100%;
  margin-bottom: 30px;
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.articleInfo div.wrapper div.articleDetails div.left div.imageContainer {
  width: 100%;
  margin: 0 auto;
}
div.articleInfo div.wrapper div.articleDetails div.left div.imageContainer img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.articleInfo div.wrapper div.articleDetails div.right {
  width: 100%;
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails div.right {
    width: 100%;
  }
}
div.articleInfo div.wrapper div.articleDetails div.right p {
  font-size: 15px;
  margin-bottom: 20px;
  color: var(--text-color);
}
div.articleInfo div.wrapper div.articleDetails div.right p:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.articleDetails div.right p {
    font-size: 13px;
  }
}
div.articleInfo div.wrapper div.videoContainer {
  width: 80%;
  margin: 90px auto 0;
}
div.articleInfo div.wrapper div.videoContainer video {
  display: block;
  width: 100%;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.videoContainer {
    width: 90%;
  }
}
div.articleInfo div.wrapper div.buttonContainer {
  text-align: center;
  margin-top: 50px;
}
div.articleInfo div.wrapper div.buttonContainer button {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 6px 10px;
  width: 15%;
  border-radius: 10px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 20%;
    font-size: 14px;
  }
}
@media all and (max-width: 641px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 35%;
    padding: 6px 15px;
  }
}
div.articleInfo div.wrapper div.buttonContainer button span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
div.articleInfo div.wrapper div.buttonContainer button svg {
  transition: 0.4s ease-in-out;
}
div.articleInfo div.wrapper div.buttonContainer button:hover {
  border-color: var(--icons-color);
}
div.articleInfo div.wrapper div.buttonContainer button:hover span {
  color: var(--icons-color);
}
div.articleInfo div.wrapper div.buttonContainer button:hover svg {
  fill: var(--icons-color);
}

div.landingScreen {
  padding: 70px 0 0;
}
div.landingScreen h1.heading, div.landingScreen .heading.h1 {
  color: var(--white-color);
  margin-bottom: 10px;
  font-size: 45px;
  font-weight: 500;
}
@media all and (max-width: 1400px) {
  div.landingScreen h1.heading, div.landingScreen .heading.h1 {
    font-size: 38px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen h1.heading, div.landingScreen .heading.h1 {
    font-size: 31px;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen h1.heading, div.landingScreen .heading.h1 {
    font-size: 27px;
  }
}
div.landingScreen p.description {
  color: var(--white-color);
  margin-bottom: 10px;
  font-size: 23px;
}
@media all and (max-width: 1400px) {
  div.landingScreen p.description {
    font-size: 19px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen p.description {
    font-size: 17px;
  }
}
div.landingScreen div.buttonContainer {
  display: flex;
  padding: 12px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
}
@media all and (max-width: 768px) {
  div.landingScreen div.buttonContainer {
    margin: 20px 0 0;
    width: 13rem;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.buttonContainer {
    width: 12rem;
    margin: 0 auto;
  }
}
@media all and (max-width: 480px) {
  div.landingScreen div.buttonContainer {
    width: 15rem;
  }
}
div.landingScreen div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.landingScreen div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.landingScreen div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  div.landingScreen div.buttonContainer button {
    font-size: 13px;
  }
}
div.landingScreen div.spotlight {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight {
    flex-direction: column-reverse;
    height: 269px;
  }
}
div.landingScreen div.spotlight.rightType {
  position: relative;
}
div.landingScreen div.spotlight.rightType div.left {
  width: 45%;
  position: absolute;
  left: 7%;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to right, #000 80%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight.rightType div.left {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    left: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -44%;
  }
}
div.landingScreen div.spotlight.rightType div.right {
  width: 60%;
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 1;
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight.rightType div.right {
    left: unset;
    top: 0;
    width: 100%;
    height: 52%;
  }
}
div.landingScreen div.spotlight.rightType div.right div.imageContainer {
  width: 100%;
  height: 100%;
}
div.landingScreen div.spotlight.rightType div.right div.imageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.landingScreen div.watchNowSection {
  display: flex;
  color: var(--black-color);
  align-items: center;
  background-color: var(--black-color);
  position: relative;
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection {
    flex-direction: column;
    height: 269px;
    margin-top: 99px;
  }
}
div.landingScreen div.watchNowSection div.left {
  width: 60%;
  height: 100%;
  position: absolute;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.left {
    left: unset;
    top: 0;
    width: 100%;
    height: 55%;
  }
}
div.landingScreen div.watchNowSection div.left div.imageContainer {
  height: 100%;
  width: 100%;
}
div.landingScreen div.watchNowSection div.left div.imageContainer img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  width: 100%;
}
div.landingScreen div.watchNowSection div.right {
  width: 50%;
  position: absolute;
  right: 0;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to left, #000 87%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    right: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -47%;
  }
}
div.landingScreen div.watchNowSection div.right h1, div.landingScreen div.watchNowSection div.right .h1,
div.landingScreen div.watchNowSection div.right p,
div.landingScreen div.watchNowSection div.right div.buttonContainer {
  margin-left: 80px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right h1, div.landingScreen div.watchNowSection div.right .h1,
  div.landingScreen div.watchNowSection div.right p,
  div.landingScreen div.watchNowSection div.right div.buttonContainer {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right div.buttonContainer {
    margin: 0 auto;
  }
}
div.landingScreen div.watchNowSection div.right p {
  font-size: 20px;
}
@media all and (max-width: 1400px) {
  div.landingScreen div.watchNowSection div.right p {
    font-size: 19px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.watchNowSection div.right p {
    font-size: 17px;
  }
}
div.landingScreen div.other.rightType {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  position: relative;
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType {
    flex-direction: column-reverse;
    height: 269px;
  }
}
div.landingScreen div.other.rightType div.left {
  width: 45%;
  position: absolute;
  left: 7%;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to right, #000 80%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType div.left {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    left: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -91%;
  }
}
div.landingScreen div.other.rightType div.right {
  width: 60%;
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 1;
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType div.right {
    left: unset;
    top: 129px;
    width: 100%;
    height: 52%;
  }
}
div.landingScreen div.other.rightType div.right div.imageContainer {
  width: 100%;
  height: 100%;
}
div.landingScreen div.other.rightType div.right div.imageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.landingScreen div.device {
  display: flex;
  background: var(--white-color);
  padding-top: 30px;
  justify-content: space-between;
  padding-bottom: 100px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.device {
    margin-top: 245px;
    flex-direction: column;
  }
}
div.landingScreen div.device div.left {
  width: 40%;
  padding-left: 60px;
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.left {
    padding-left: 30px;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.landingScreen div.device div.left h1.heading, div.landingScreen div.device div.left .heading.h1 {
  color: var(--black-color);
}
div.landingScreen div.device div.left p.description {
  color: var(--text-color);
}
div.landingScreen div.device div.right {
  width: 40%;
}
@media all and (max-width: 1980px) {
  div.landingScreen div.device div.right {
    width: 43%;
  }
}
@media all and (max-width: 1600px) {
  div.landingScreen div.device div.right {
    width: 46%;
  }
}
@media all and (max-width: 1400px) {
  div.landingScreen div.device div.right {
    width: 55%;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.device div.right {
    width: 46%;
  }
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.right {
    width: 56%;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.right {
    width: 91%;
    margin: 0 auto;
  }
}
div.landingScreen div.device div.right ul.devices {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
div.landingScreen div.device div.right ul.devices li {
  cursor: pointer;
  width: 17rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  margin-bottom: 6px;
  background: rgb(54, 124, 160);
  background: linear-gradient(90deg, rgb(54, 124, 160) 0%, rgb(14, 64, 115) 35%, rgb(0, 41, 97) 100%);
  transition: 0.2s ease-in-out;
}
div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 2200px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 15rem;
  }
}
@media all and (max-width: 1980px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 13rem;
    height: 9rem;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
    margin-right: 6px;
  }
  div.landingScreen div.device div.right ul.devices li:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 12rem;
    height: 7rem;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 13rem;
  }
}
@media all and (max-width: 480px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 360px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 100%;
    margin-right: 0;
  }
  div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
    margin-right: 0;
  }
}
div.landingScreen div.device div.right ul.devices li:hover {
  box-shadow: 0px 1px 6px 4px #333;
}
div.landingScreen div.device div.right ul.devices li span {
  color: var(--white-color);
  font-weight: 700;
  font-size: 30px;
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right ul.devices li span {
    font-size: 24px;
  }
}

div.notFound {
  padding: 60px 0;
}
@media all and (max-width: 768px) {
  div.notFound {
    padding: 80px 0;
  }
}
@media all and (max-width: 480px) {
  div.notFound {
    padding: 100px 0;
  }
}
div.notFound div.imageContainer {
  width: 40%;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.notFound div.imageContainer {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.imageContainer {
    width: 60%;
  }
}
div.notFound div.imageContainer img {
  width: 100%;
  display: block;
}
div.notFound div.content {
  text-align: center;
}
div.notFound div.content h1, div.notFound div.content .h1 {
  font-size: 80px;
  color: var(--text-hover);
  line-height: 0.8em;
}
@media all and (max-width: 768px) {
  div.notFound div.content h1, div.notFound div.content .h1 {
    font-size: 70px;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content h1, div.notFound div.content .h1 {
    font-size: 60px;
  }
}
div.notFound div.content p {
  font-size: 25px;
  color: var(--text-color);
}
@media all and (max-width: 768px) {
  div.notFound div.content p {
    font-size: 20px;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content p {
    font-size: 16px;
  }
}
@media all and (max-width: 360px) {
  div.notFound div.content p {
    font-size: 14px;
  }
}
div.notFound div.content div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.notFound div.content div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content div.buttonContainer {
    width: 10rem;
  }
}
div.notFound div.content div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.notFound div.content div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.notFound div.content div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.playerShowCard {
  height: 100%;
}
div.playerShowCard div.imageContainer {
  width: 100%;
  position: relative;
  height: 100%;
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer {
    height: 400px;
  }
}
div.playerShowCard div.imageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer img {
    height: 400px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.playerShowCard div.imageContainer div.playIconContainer {
  position: absolute;
  transform: translate(-50%, -50%);
  border: 1px solid var(--white-color);
  z-index: 999;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 50%;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
div.playerShowCard div.imageContainer div.playIconContainer svg {
  color: var(--white-color);
  width: 30px !important;
  height: 30px !important;
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.playIconContainer {
    padding: 9px;
  }
  div.playerShowCard div.imageContainer div.playIconContainer svg {
    width: 20px !important;
    height: 20px !important;
  }
}
div.playerShowCard div.imageContainer div.playIconContainer:hover {
  border: 1px solid var(--button-hover);
  background-color: var(--button-hover);
}
div.playerShowCard div.imageContainer div.metaData {
  position: absolute;
  z-index: 999;
  width: 100%;
  left: 50px;
  top: 30%;
  transition: 0.4s ease-in-out;
  visibility: hidden;
}
@media all and (max-width: 2600px) {
  div.playerShowCard div.imageContainer div.metaData {
    left: 77px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData {
    width: 90%;
  }
}
div.playerShowCard div.imageContainer div.metaData.show {
  visibility: visible;
  animation: fadeIn 1.5s ease-in-out;
}
@media all and (max-width: 1200px) {
  div.playerShowCard div.imageContainer div.metaData {
    left: 42px;
  }
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData {
    left: 22px;
  }
}
div.playerShowCard div.imageContainer div.metaData.gagalin h1.showName, div.playerShowCard div.imageContainer div.metaData.gagalin .showName.h1 {
  animation: fadeIn 0.5s ease-in-out;
  font-family: "Gagalin-Regular", sans-serif !important;
}
div.playerShowCard div.imageContainer div.metaData.gota h1.showName, div.playerShowCard div.imageContainer div.metaData.gota .showName.h1 {
  animation: fadeIn 0.5s ease-in-out;
  font-size: 40px;
  font-family: "gota-Regular", sans-serif !important;
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData.gota h1.showName, div.playerShowCard div.imageContainer div.metaData.gota .showName.h1 {
    font-size: 25px;
  }
}
div.playerShowCard div.imageContainer div.metaData img.showTitleImage {
  width: 30rem;
  transition: 0.9s ease-in-out;
  animation: fadeIn 0.5s ease-in-out;
  position: relative;
  left: 0;
  margin-bottom: 10px;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData img.showTitleImage {
    width: 30rem;
    height: auto;
    bottom: 20px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData img.showTitleImage {
    width: 30rem;
    height: auto;
    bottom: 20px;
  }
}
@media all and (max-width: 480px) {
  div.playerShowCard div.imageContainer div.metaData img.showTitleImage {
    width: 30rem;
    height: auto;
    bottom: 20px;
  }
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData img.showTitleImage {
    width: 30rem;
    height: auto;
    bottom: 20px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData img.showTitleImage {
    width: 30rem;
    height: auto;
    bottom: 20px;
  }
}
div.playerShowCard div.imageContainer div.metaData h1.showName, div.playerShowCard div.imageContainer div.metaData .showName.h1 {
  font-size: 50px;
  font-weight: 700;
  color: var(--white-color);
  text-transform: uppercase;
  transition: 0.9s ease-in-out;
  animation: fadeIn 0.5s ease-in-out;
  position: relative;
  left: 0;
  margin-bottom: 10px;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData h1.showName, div.playerShowCard div.imageContainer div.metaData .showName.h1 {
    left: 0;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData h1.showName, div.playerShowCard div.imageContainer div.metaData .showName.h1 {
    left: 0;
  }
}
@media all and (max-width: 480px) {
  div.playerShowCard div.imageContainer div.metaData h1.showName, div.playerShowCard div.imageContainer div.metaData .showName.h1 {
    left: 0;
  }
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData h1.showName, div.playerShowCard div.imageContainer div.metaData .showName.h1 {
    font-size: 45px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData h1.showName, div.playerShowCard div.imageContainer div.metaData .showName.h1 {
    font-size: 25px;
  }
}
div.playerShowCard div.imageContainer div.metaData p.description {
  color: var(--text-color);
  font-size: 16px;
  max-width: 70%;
  position: relative;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData p.description {
    font-size: 13px;
    max-width: 59%;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData p.description {
    max-width: 100%;
  }
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer {
  display: flex;
  margin-top: 20px;
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button {
  display: flex;
  padding: 7px 8px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button:focus {
  border: 2px solid var(--white-color);
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button {
    padding: 3px 7px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button {
    padding: 5px 7px;
  }
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button div.icon {
  margin-right: 10px;
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button div.icon svg {
  width: 27px !important;
  height: 27px !important;
  display: block;
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button div.icon svg {
    width: 20px !important;
    height: 28px !important;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button div.icon svg {
    width: 15px !important;
    height: 15px !important;
  }
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button div.icon img {
  width: 100%;
  display: block;
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button span {
  font-size: 17px;
  font-weight: 500;
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button span {
    font-size: 15px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button span {
    font-size: 12px;
  }
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.play {
  width: 8rem;
  background-color: var(--primary-btn-color);
  margin-right: 10px;
  border: 2px solid transparent;
  color: var(--black-color);
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.play:hover {
  background-color: var(--primary-btn-hover);
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.play svg {
  color: var(--black-color);
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.play {
    width: 7rem;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.play {
    width: 6rem;
  }
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.moreInfo {
  width: 10rem;
  background-color: var(--secondary-btn-color);
  color: var(--text-color);
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.moreInfo:hover {
  background-color: var(--secondary-btn-hover);
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.moreInfo {
    width: 8rem;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.moreInfo {
    width: 7rem;
  }
}
div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.moreInfo div.icon svg {
  width: 25px !important;
  height: 25px !important;
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData div.buttonContainer button.moreInfo div.icon svg {
    width: 18px !important;
    height: 18px !important;
  }
}
div.playerShowCard div.imageContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}

div.categoriesShowCard {
  position: relative;
  height: 650px;
  margin-top: 30px;
}
div.categoriesShowCard div.backgroundImageContainer {
  width: 100%;
  display: block;
  height: 100%;
}
div.categoriesShowCard div.backgroundImageContainer img.bgImage {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.categoriesShowCard div.backgroundImageContainer div.metaInfo {
  position: absolute;
  top: 30px;
  left: 130px;
  z-index: 9;
}
@media all and (max-width: 1200px) {
  div.categoriesShowCard div.backgroundImageContainer div.metaInfo {
    left: 77px;
  }
}
@media all and (max-width: 768px) {
  div.categoriesShowCard div.backgroundImageContainer div.metaInfo {
    left: 57px;
  }
}
@media all and (max-width: 640px) {
  div.categoriesShowCard div.backgroundImageContainer div.metaInfo {
    left: 30px;
  }
}
div.categoriesShowCard div.backgroundImageContainer div.metaInfo h1, div.categoriesShowCard div.backgroundImageContainer div.metaInfo .h1 {
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 40px;
  cursor: pointer;
}
div.categoriesShowCard div.backgroundImageContainer div.metaInfo h1::first-line, div.categoriesShowCard div.backgroundImageContainer div.metaInfo .h1::first-line {
  color: rgb(255, 234, 0);
}
div.categoriesShowCard div.backgroundImageContainer div.metaInfo p.description {
  font-size: 14px;
  color: var(--white-color);
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows {
  position: absolute;
  bottom: 30px;
  width: 90%;
  right: 0;
  z-index: 9;
}
@media all and (max-width: 640px) {
  div.categoriesShowCard div.backgroundImageContainer div.AllShows {
    width: 84% !important;
    right: 38px;
  }
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows div.shows {
  width: 100%;
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows .swiper-button-prev {
  background: transparent !important;
  left: -130px !important;
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows .swiper-button-prev.swiper-button-disabled {
  display: none;
}
@media all and (max-width: 1200px) {
  div.categoriesShowCard div.backgroundImageContainer div.AllShows {
    width: 98%;
  }
  div.categoriesShowCard div.backgroundImageContainer div.AllShows .swiper-button-next, div.categoriesShowCard div.backgroundImageContainer div.AllShows .swiper-button-prev {
    display: none;
  }
}
div.categoriesShowCard div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}

div.trailerModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.trailerModal div.head {
  position: fixed;
  z-index: 9999;
  color: var(--white-color);
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 20px;
  left: 0;
}
div.trailerModal div.head div.left h4.heading, div.trailerModal div.head div.left .heading.h4 {
  font-size: 13px;
}
div.trailerModal div.head div.left h1.title, div.trailerModal div.head div.left .title.h1 {
  font-size: 22px;
  text-transform: uppercase;
}
div.trailerModal div.head div.left span.director {
  font-weight: 700;
  font-size: 14px;
  margin-right: 5px;
}
div.trailerModal div.head div.left span.year {
  font-weight: normal;
  font-size: 13px;
}
div.trailerModal div.head div.right div.closeIcon {
  cursor: pointer;
}
div.trailerModal div.head div.right div.closeIcon svg {
  stroke: var(--white-color) !important;
}
div.trailerModal div.trailerPlayer {
  z-index: 9999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin: 0 auto;
}
@media all and (max-width: 1400px) {
  div.trailerModal div.trailerPlayer {
    top: 50%;
    width: 60%;
  }
}
@media all and (max-width: 1200px) {
  div.trailerModal div.trailerPlayer {
    top: 50%;
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.trailerModal div.trailerPlayer {
    top: 45%;
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.trailerModal div.trailerPlayer {
    width: 95%;
  }
}
div.trailerModal div.trailerPlayer video {
  width: 100%;
  display: block;
}

div.smallLoading div.loading-container {
  position: absolute;
  z-index: 99999999;
}
div.smallLoading .nf-loading-spinner {
  animation: load_spinner 0.9s linear infinite;
  background-image: url(https://assets.nflxext.com/en_us/pages/wiplayer/site-spinner.png);
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: 1px;
  left: 1px;
  margin: auto;
  position: absolute;
  right: 1px;
  text-align: center;
  top: 1px;
  width: 20px;
  height: 20px;
}
@keyframes load_spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(1turn);
  }
}

div.live-video-wrapper {
  height: auto;
  width: "100%";
}
div.live-video-wrapper div.videoPlayer {
  background-color: var(--black-color);
  height: 100vh;
  position: relative;
}
div.live-video-wrapper div.videoPlayer div.topContainer {
  position: absolute;
  left: 3%;
  top: 5%;
  z-index: 99;
  display: flex;
  align-items: center;
}
div.live-video-wrapper div.videoPlayer div.topContainer div.backButton {
  margin-right: 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.live-video-wrapper div.videoPlayer div.topContainer div.backButton:hover {
  transform: scale(1.2);
}
div.live-video-wrapper div.videoPlayer div.topContainer div.backButton svg {
  color: var(--white-color) !important;
  width: 35px !important;
  height: 35px !important;
}
div.live-video-wrapper div.videoPlayer div.topContainer h1.title, div.live-video-wrapper div.videoPlayer div.topContainer .title.h1 {
  color: var(--white-color);
  font-size: 28px;
}
div.live-video-wrapper div.videoPlayer video {
  height: 100% !important;
  display: block !important;
  width: 100% !important;
}
div.live-video-wrapper div.videoPlayer .vjs_video_3-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 0 !important;
}
div.live-video-wrapper div.videoPlayer .vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 0 !important;
}
div.live-video-wrapper div.videoPlayer .video-js.vjs-fluid:not(.vjs-audio-only-mode) {
  height: 100% !important;
}
@media all and (min-width: 768px) {
  div.live-video-wrapper div.videoPlayer:hover div.bottom div.details {
    transform: translateY(0);
  }
}
div.live-video-wrapper div.videoPlayer div.bottom {
  width: 90%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  overflow: hidden;
  bottom: 10%;
}
@media all and (max-width: 1200px) {
  div.live-video-wrapper div.videoPlayer div.bottom {
    bottom: 8%;
  }
}
@media all and (max-width: 768px) {
  div.live-video-wrapper div.videoPlayer div.bottom {
    position: relative;
    bottom: unset;
    left: unset;
    transform: unset;
    width: 100%;
    height: auto;
  }
}
div.live-video-wrapper div.videoPlayer div.bottom div.details {
  display: flex;
  justify-content: space-between;
  height: 100%;
  transform: translateY(200px);
  transition: 0.3s ease-in-out;
  background-color: rgba(41, 36, 35, 0.6);
  padding: 20px;
  border-radius: 8px;
}
@media all and (max-width: 768px) {
  div.live-video-wrapper div.videoPlayer div.bottom div.details {
    flex-direction: column;
    transform: unset;
  }
}
div.live-video-wrapper div.videoPlayer div.bottom div.details h1.heading, div.live-video-wrapper div.videoPlayer div.bottom div.details .heading.h1 {
  font-size: 28px;
  color: var(--white-color);
  margin-bottom: 10px;
}
@media all and (max-width: 768px) {
  div.live-video-wrapper div.videoPlayer div.bottom div.details h1.heading, div.live-video-wrapper div.videoPlayer div.bottom div.details .heading.h1 {
    font-size: 22px;
  }
}
div.live-video-wrapper div.videoPlayer div.bottom div.details h1.title, div.live-video-wrapper div.videoPlayer div.bottom div.details .title.h1 {
  font-size: 20px;
  color: var(--text-hover);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.live-video-wrapper div.videoPlayer div.bottom div.details span.time {
  font-size: 14px;
  color: var(--inner-text-color);
  display: inline-block;
  margin-bottom: 10px;
}
div.live-video-wrapper div.videoPlayer div.bottom div.details p.description {
  color: var(--white-color);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
div.live-video-wrapper div.videoPlayer div.bottom div.details div.left {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.live-video-wrapper div.videoPlayer div.bottom div.details div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.live-video-wrapper div.videoPlayer div.bottom div.details div.right {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.live-video-wrapper div.videoPlayer div.bottom div.details div.right {
    width: 100%;
  }
}
div.live-video-wrapper div.commentsContainer {
  width: 90%;
  margin: 0 auto;
  padding: 60px 0;
}
@media all and (max-width: 1200px) {
  div.live-video-wrapper div.commentsContainer {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.live-video-wrapper div.commentsContainer {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.live-video-wrapper div.commentsContainer {
    width: 100%;
  }
}
div.live-video-wrapper div.commentsContainer div.top div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}
div.live-video-wrapper div.commentsContainer div.top div.head span.commentsCount {
  color: var(--white-color);
  font-size: 22px;
}
@media all and (max-width: 640px) {
  div.live-video-wrapper div.commentsContainer div.top div.head span.commentsCount {
    font-size: 16px;
  }
}
div.live-video-wrapper div.commentsContainer div.top div.head div.sortContainer select {
  background-color: transparent;
  border: 1px solid var(--border-color);
  padding: 10px 15px;
  color: var(--white-color);
  font-size: 16px;
  border-radius: 6px;
}
div.live-video-wrapper div.commentsContainer div.top div.head div.sortContainer select option {
  color: var(--black-color);
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer {
  display: flex;
  padding: 40px 0 20px;
  border-bottom: 1px solid var(--border-color);
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.imageContainer {
  margin-right: 20px;
  width: 45px;
  height: 45px;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.imageContainer img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.imageContainer span.nameAvatar {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer {
  width: 90%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #e1e8ed;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage {
  width: 20%;
  height: 30%;
  margin-bottom: 20px;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage img {
  max-width: 50%;
  border-radius: 5px;
  height: auto;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage button.removeButton {
  position: absolute;
  background: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--button-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage button.removeButton:hover {
  background-color: #f2f2f2;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage button.removeButton svg {
  width: 15px;
  height: 15px;
  fill: var(--button-color);
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer label.customFileUpload {
  color: white;
  margin-right: 10px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer label.customFileUpload:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer input[type=file] {
  display: none;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer button.giphyButton {
  background: none;
  color: white;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions button.submit {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions button.submit:hover {
  background-color: transparent;
  color: var(--white-color);
}
@media (max-width: 1200px) {
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer {
    width: 90%;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer textarea {
    height: 40px;
    padding: 8px 12px;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .selectedImage {
    width: 100%;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer {
    margin-bottom: 10px;
    width: 100%;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUpload,
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 50%;
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer {
    width: 90%;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer textarea {
    height: 35px;
    padding: 6px 10px;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .selectedImage {
    width: 100%;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUpload,
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer textarea {
    height: 30px;
    padding: 4px 8px;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .selectedImage {
    width: 100%;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer {
    flex-direction: column;
    width: 100%;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUpload,
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
    text-align: center;
    justify-content: center;
  }
  div.live-video-wrapper div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers {
  padding: 40px 0 25px;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-right: 20px;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name, div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right .name.h1 {
  font-size: 16px;
  color: var(--white-color);
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 20px;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container {
  margin-top: 10px;
  margin-bottom: 30px;
  width: 20%;
  height: auto;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container img {
  max-width: 50%;
  height: auto;
  border-radius: 8px;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-right: 0;
}
div.live-video-wrapper div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-right: 5px;
}
div.live-video-wrapper div.customModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
div.live-video-wrapper div.customModal .modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
}
div.live-video-wrapper div.customModal .modalContent .closeButton {
  position: absolute;
  top: -6px;
  right: 2px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

div.mainLoading {
  padding: 130px 0 350px 60px;
}
@media all and (max-width: 640px) {
  div.mainLoading {
    padding: 130px 0 350px 30px;
  }
}
div.mainLoading div.topLoader {
  width: 100px;
  height: 20px;
  margin-bottom: 20px;
  animation: skeleton-loading1 0.5s linear infinite alternate;
}
div.mainLoading div.lists {
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
div.mainLoading div.lists::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
div.mainLoading div.lists ul.loaders {
  display: flex;
  width: 2900px;
}
div.mainLoading div.lists ul.loaders li {
  width: 210px;
  height: 110px;
  background-color: var(--transparent-color);
  margin-right: 3px;
  margin-bottom: 20px;
  opacity: 0;
  background-color: hsl(0, 0%, 16%);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
@media all and (max-width: 2500px) {
  div.mainLoading div.lists ul.loaders li {
    width: 250px;
  }
}
@media all and (max-width: 1600px) {
  div.mainLoading div.lists ul.loaders li {
    width: 210px;
  }
}
@media all and (max-width: 980px) {
  div.mainLoading div.lists ul.loaders li {
    width: 170px;
    height: 100px;
  }
}
@media all and (max-width: 640px) {
  div.mainLoading div.lists ul.loaders li {
    width: 130px;
    height: 80px;
  }
}
@media all and (max-width: 480px) {
  div.mainLoading div.lists ul.loaders li {
    width: 110px;
    height: 60px;
  }
}
div.mainLoading div.lists ul.loaders li:nth-child(1) {
  opacity: 0.2;
  animation: skeleton-loading1 0.5s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(1) {
  opacity: 0.3;
  animation: skeleton-loading2 0.6s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(2) {
  opacity: 0.4;
  animation: skeleton-loading3 0.7s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(3) {
  opacity: 0.5;
  animation: skeleton-loading4 0.8s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(4) {
  opacity: 0.6;
  animation: skeleton-loading5 0.9s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(5) {
  opacity: 0.7;
  animation: skeleton-loading6 1s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(6) {
  opacity: 0.8;
  animation: skeleton-loading6 1s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(7) {
  opacity: 0.9;
  animation: skeleton-loading6 1s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(7) {
  opacity: 1;
  animation: skeleton-loading6 1s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(8) {
  opacity: 1.1;
  animation: skeleton-loading6 1s linear alternate;
}
div.mainLoading div.lists ul.loaders li:nth-child(9) {
  opacity: 1.2;
  animation: skeleton-loading6 1s linear alternate;
}
@keyframes skeleton-loading1 {
  0% {
    background-color: hsl(210, 1%, 36%);
  }
  100% {
    background-color: hsl(0, 0%, 16%);
    opacity: 0.3;
  }
}
@keyframes skeleton-loading2 {
  0% {
    background-color: hsl(210, 1%, 36%);
  }
  100% {
    background-color: hsl(0, 0%, 16%);
    opacity: 0.4;
  }
}
@keyframes skeleton-loading3 {
  0% {
    background-color: hsl(210, 1%, 36%);
  }
  100% {
    background-color: hsl(0, 0%, 16%);
    opacity: 0.5;
  }
}
@keyframes skeleton-loading4 {
  0% {
    background-color: hsl(210, 1%, 36%);
  }
  100% {
    background-color: hsl(0, 0%, 16%);
    opacity: 0.6;
  }
}
@keyframes skeleton-loading5 {
  100% {
    opacity: 0.7;
  }
}
@keyframes skeleton-loading6 {
  100% {
    opacity: 0.8;
  }
}

div.detailsScreenModal {
  padding-bottom: 60px;
}
div.detailsScreenModal div.imgVideoContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 450px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer {
    height: 326px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer {
    height: 252px;
  }
}
div.detailsScreenModal div.imgVideoContainer.noHeight {
  height: unset;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer.noHeight div.bottomVignette {
    bottom: -31px;
  }
}
div.detailsScreenModal div.imgVideoContainer video {
  z-index: 3;
}
div.detailsScreenModal div.imgVideoContainer img {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
div.detailsScreenModal div.imgVideoContainer img.show {
  display: block;
}
div.detailsScreenModal div.imgVideoContainer img.hide {
  display: none;
}
div.detailsScreenModal div.imgVideoContainer div.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9999;
}
div.detailsScreenModal div.imgVideoContainer div.closeButton button {
  background-color: var(--bg-color);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  line-height: 0;
  cursor: pointer;
}
div.detailsScreenModal div.imgVideoContainer div.closeButton button svg {
  stroke: var(--white-color) !important;
}
div.detailsScreenModal div.imgVideoContainer div.bottomVignette {
  background-color: transparent;
  background-image: linear-gradient(180deg, hsla(0, 0%, 8%, 0) 0, hsla(0, 0%, 30%, 0.15) 15%, hsla(0, 0%, 8%, 0.35) 29%, hsla(0, 0%, 8%, 0.58) 40%, #141414 84%, #141414);
  background-position: 0 top;
  background-repeat: repeat-x;
  background-size: 100% 100%;
  bottom: -8px;
  height: 200px;
  opacity: 1;
  top: auto;
  width: 100%;
  z-index: 99;
  position: absolute;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer div.bottomVignette {
    bottom: 31px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions {
  position: absolute;
  bottom: 40px;
  z-index: 999;
  width: 100%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions h1.title, div.detailsScreenModal div.imgVideoContainer div.showActions .title.h1 {
  padding: 0 40px;
  animation: fadeIn 0.4s ease-in-out;
  font-size: 60px;
  z-index: 99;
  font-family: "Gagalin-Regular", sans-serif !important;
  color: var(--white-color);
  max-width: 80%;
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions h1.title, div.detailsScreenModal div.imgVideoContainer div.showActions .title.h1 {
    font-size: 40px;
    padding: 0 18px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions h1.title, div.detailsScreenModal div.imgVideoContainer div.showActions .title.h1 {
    font-size: 21px;
    margin-bottom: 6px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons {
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons {
    padding: 0 18px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left {
  display: flex;
  align-items: center;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play {
  display: flex;
  padding: 6px 1px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  width: 7.5rem;
  background-color: var(--primary-btn-color);
  margin-right: 10px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play:hover {
  background-color: var(--primary-btn-hover);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play {
    width: 5.5rem;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon {
  margin-right: 10px;
  line-height: 0;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon svg {
  width: 25px !important;
  height: 25px !important;
  color: var(--black-color);
  display: block;
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon svg {
    width: 16px !important;
    height: 16px !important;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon img {
  width: 100%;
  display: block;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play span {
  font-size: 16px;
  font-weight: 500;
  color: var(--black-color);
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play span {
    font-size: 12px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.subscribe {
  width: 8rem;
  padding: 6px 1px 9px;
  border-radius: 2px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.subscribe:hover {
  background-color: var(--secondary-hover-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.subscribe span {
  font-size: 14px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList {
  background-color: rgba(42, 42, 42, 0.6);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList svg {
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList:hover {
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList:hover span.tooltip {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList span.tooltip {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #d6d5d5;
  position: absolute;
  top: -50px;
  width: 10rem;
  padding: 6px 8px 6px;
  color: var(--bg-color);
  font-weight: 500;
  border-radius: 2px;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList span.tooltip.remove {
  width: 12rem;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList span.tooltip::after {
  content: "";
  position: absolute;
  top: 99%;
  left: 50%;
  margin-left: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #d6d5d5 transparent transparent transparent;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share {
  position: relative;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share button.shareButton {
  background-color: rgba(42, 42, 42, 0.6);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
  margin-left: 10px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share button.shareButton svg {
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share button.shareButton:hover {
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
  position: absolute;
  top: -126px;
  left: -74px;
  z-index: 999;
  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
  width: 232px;
  border-radius: 8px;
  display: none;
}
@media all and (max-width: 2500px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    left: -89px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    width: 200px;
    left: -74px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    width: 232px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    width: 11rem;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer.active {
  display: block;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer::after {
  content: "";
  bottom: -8px;
  width: 0;
  height: 0;
  border-color: rgba(16, 20, 31, 0) rgba(16, 20, 31, 0) rgb(87, 85, 85);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer::after {
    left: 50%;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer::after {
    border-color: rgba(16, 20, 31, 0) rgba(16, 20, 31, 0) rgb(87, 85, 85);
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--border-color) !important;
  width: 100% !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook div.icon {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer div.left {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  width: 100% !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter div.icon {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer div.left {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button {
  background-color: rgba(42, 42, 42, 0.6);
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0.12em solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
  opacity: 0.3;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button:hover {
  opacity: 1;
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button:hover svg {
  color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button svg {
  transition: 0.2s ease-in-out;
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
}
div.detailsScreenModal div.info {
  padding: 0 40px;
  margin-top: 5px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info {
    padding: 0 18px;
  }
}
div.detailsScreenModal div.info div.metaData {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.metaData {
    flex-direction: column;
  }
}
div.detailsScreenModal div.info div.metaData div.left {
  width: 60%;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.metaData div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.detailsScreenModal div.info div.metaData div.left div.top {
  display: flex;
  align-items: center;
}
div.detailsScreenModal div.info div.metaData div.left div.top span {
  font-size: 14px;
  margin-right: 10px;
}
div.detailsScreenModal div.info div.metaData div.left div.top span.duration {
  color: var(--duration-color);
}
div.detailsScreenModal div.info div.metaData div.left div.top span.rating {
  padding: 1px 6px;
  border: 1px solid var(--border-color);
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio {
  font-size: 14px;
  position: relative;
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio:hover span.tooltip {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio span.tooltip {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #d6d5d5;
  position: absolute;
  top: -41px;
  width: 145px;
  padding: 6px 8px 6px;
  color: var(--bg-color);
  font-weight: 500;
  border-radius: 2px;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9;
  left: -40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio span.tooltip::after {
  content: "";
  position: absolute;
  top: 99%;
  left: 50%;
  margin-left: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #d6d5d5 transparent transparent transparent;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom {
  margin-top: 30px;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom p.description {
  font-size: 14px;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer {
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button {
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 6px 8px;
  background-color: var(--secondary-color);
  display: block;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button:hover {
  background-color: var(--secondary-hover-color);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button.free {
  background-color: var(--freeBtn-color);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button.free:hover {
  background-color: var(--freeBtn-hover);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button.free span {
  color: var(--white-color);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button span {
  color: var(--white-color);
  font-size: 12px;
  display: block;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button:last-child {
  margin-right: 0;
}
div.detailsScreenModal div.info div.metaData div.right {
  width: 35%;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.metaData div.right {
    width: 100%;
  }
}
div.detailsScreenModal div.info div.metaData div.right span.heading {
  font-size: 12px;
  color: var(--grey-color);
  margin-right: 3px;
}
div.detailsScreenModal div.info div.metaData div.right div.cast,
div.detailsScreenModal div.info div.metaData div.right div.crew,
div.detailsScreenModal div.info div.metaData div.right div.categories, div.detailsScreenModal div.info div.metaData div.right div.director {
  margin-bottom: 10px;
}
div.detailsScreenModal div.info div.metaData div.right div.cast span,
div.detailsScreenModal div.info div.metaData div.right div.crew span,
div.detailsScreenModal div.info div.metaData div.right div.categories span, div.detailsScreenModal div.info div.metaData div.right div.director span {
  font-size: 13px;
  margin-right: 4px;
}
div.detailsScreenModal div.info div.metaData div.right :last-child {
  margin-bottom: 0;
}
div.detailsScreenModal div.info div.showTrailerContainer {
  margin-top: 20px;
}
div.detailsScreenModal div.info div.showTrailerContainer h1.heading, div.detailsScreenModal div.info div.showTrailerContainer .heading.h1 {
  color: var(--heading-color);
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 15px;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer {
  width: 200px;
  position: relative;
  cursor: pointer;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer:hover div.playIconContainer {
  visibility: visible;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 3px;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer div.playIconContainer {
  align-items: center;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  background: rgba(30, 30, 20, 0.5);
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  z-index: 9;
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer div.playIconContainer svg {
  width: 25px !important;
  height: 25px !important;
}
div.detailsScreenModal div.info div.similiarShowsContainer {
  margin-top: 40px;
}
div.detailsScreenModal div.info div.similiarShowsContainer h1.heading, div.detailsScreenModal div.info div.similiarShowsContainer .heading.h1 {
  color: var(--heading-color);
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 20px;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
  width: 31.7%;
  margin-right: 17px;
  margin-bottom: 20px;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:last-child {
  margin-right: 0;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    width: 48.7%;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
    margin-right: 17px;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    width: 48.4%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    margin-right: 10px;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
    margin-right: 10px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    width: 100%;
    margin-right: 0;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
    margin-right: 0;
  }
}

div.episodeContainer {
  margin-top: 80px;
}
div.episodeContainer div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
div.episodeContainer div.head div.left h1.heading, div.episodeContainer div.head div.left .heading.h1 {
  font-size: 22px;
  color: var(--heading-color);
}
div.episodeContainer div.head div.right div.seasonContainer {
  border: 0.1em solid rgb(77, 77, 77);
  background-color: rgb(36, 36, 36);
  padding: 6px 12px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
}
div.episodeContainer div.head div.right div.seasonContainer svg {
  width: 23px !important;
  height: 23px !important;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer {
  position: absolute;
  top: 45px;
  max-height: 150px;
  overflow-y: scroll;
  border: 0.1em solid rgb(77, 77, 77);
  background-color: rgb(36, 36, 36);
  width: 200px;
  right: 0;
  z-index: 9;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer.active {
  display: block;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer.noActive {
  display: none;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer::-webkit-scrollbar-thumb {
  background-color: var(--grey-color);
  border-radius: 30px;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer::-webkit-scrollbar-track {
  background-color: rgb(36, 36, 36);
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer li {
  padding: 8px;
  font-size: 16px;
  font-weight: 600;
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer li:hover {
  background-color: var(--bg-color);
}
div.episodeContainer div.head div.right div.seasonContainer ul.listContainer li span.episodes {
  font-size: 14px;
  font-weight: normal;
  margin-left: 6px;
}

div.episodeCard {
  display: flex;
  padding: 25px;
  border-bottom: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  position: relative;
}
@media all and (max-width: 640px) {
  div.episodeCard {
    flex-direction: column;
  }
}
div.episodeCard.episodePortrait {
  align-items: center;
}
div.episodeCard:hover {
  background-color: #333;
}
div.episodeCard:hover div.leftContainer div.imageContainer div.playIconContainer {
  visibility: visible;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.episodeCard div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.episodeCard div.premiumStatus div.premium {
    width: 26px;
  }
}
div.episodeCard div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.episodeCard div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.episodeCard div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 30px;
}
@media all and (max-width: 1200px) {
  div.episodeCard div.premiumStatus div.free {
    width: 30px;
  }
}
div.episodeCard div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.episodeCard div.leftContainer {
  display: flex;
  align-items: center;
  width: 25%;
  margin-right: 10px;
}
@media all and (max-width: 768px) {
  div.episodeCard div.leftContainer {
    margin-right: 15px;
  }
}
@media all and (max-width: 640px) {
  div.episodeCard div.leftContainer {
    width: 80%;
    flex-direction: column;
    margin: 0 auto 20px;
  }
}
@media all and (max-width: 480px) {
  div.episodeCard div.leftContainer {
    width: 45%;
  }
}
div.episodeCard div.leftContainer span.sNo {
  font-size: 22px;
}
@media all and (max-width: 640px) {
  div.episodeCard div.leftContainer span.sNo {
    display: none;
  }
}
div.episodeCard div.leftContainer div.imageContainer {
  width: 110px;
  margin-left: 18px;
  position: relative;
}
@media all and (max-width: 640px) {
  div.episodeCard div.leftContainer div.imageContainer {
    margin: 0 auto;
  }
}
div.episodeCard div.leftContainer div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 3px;
}
div.episodeCard div.leftContainer div.imageContainer div.playIconContainer {
  align-items: center;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  background: rgba(30, 30, 20, 0.5);
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  z-index: 9;
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.episodeCard div.leftContainer div.imageContainer div.playIconContainer svg {
  width: 25px !important;
  height: 25px !important;
}
div.episodeCard div.leftContainer div.imageContainer div.continueWatchingBar {
  position: absolute;
  bottom: 0;
  height: 0.2em;
  background-color: var(--grey-color);
  width: 100%;
  border-radius: 3px;
}
div.episodeCard div.leftContainer div.imageContainer div.continueWatchingBar div.line {
  height: 100%;
  border-radius: 3px;
  background-color: var(--secondary-color);
}
div.episodeCard div.rightContainer {
  width: 68%;
}
@media all and (max-width: 640px) {
  div.episodeCard div.rightContainer {
    width: 100%;
  }
}
div.episodeCard div.rightContainer div.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}
@media all and (max-width: 640px) {
  div.episodeCard div.rightContainer div.top div.left {
    margin-right: 20px;
  }
}
div.episodeCard div.rightContainer div.top div.left h4, div.episodeCard div.rightContainer div.top div.left .h4 {
  font-size: 13px;
  font-weight: 600;
  color: var(--heading-color);
}
@media all and (max-width: 480px) {
  div.episodeCard div.rightContainer div.top div.left h4, div.episodeCard div.rightContainer div.top div.left .h4 {
    font-size: 11px;
  }
}
div.episodeCard div.rightContainer div.top div.right span {
  font-size: 15px;
}
div.episodeCard div.rightContainer div.bottom p.description {
  font-size: 12px;
  color: var(--text-color);
}
@media all and (max-width: 480px) {
  div.episodeCard div.rightContainer div.bottom p.description {
    font-size: 10px;
  }
}
div.episodeCard div.rightContainer div.bottom p.description span.moreLess {
  cursor: pointer;
  color: var(--secondary-color);
  font-size: 11px;
}
@media all and (max-width: 480px) {
  div.episodeCard div.rightContainer div.bottom p.description span.moreLess {
    font-size: 10px;
  }
}
div.episodeCard div.rightContainer div.bottom p.description span.moreLess:hover {
  text-decoration: underline;
}

div.similiarShowsCard {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
  cursor: pointer;
}
div.similiarShowsCard:hover div.imageContainer div.playIconContainer {
  visibility: visible;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.similiarShowsCard div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.similiarShowsCard div.premiumStatus div.premium {
    width: 26px;
  }
}
div.similiarShowsCard div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.similiarShowsCard div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.similiarShowsCard div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 30px;
}
@media all and (max-width: 1200px) {
  div.similiarShowsCard div.premiumStatus div.free {
    width: 30px;
  }
}
div.similiarShowsCard div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.similiarShowsCard div.imageContainer {
  width: 100%;
  position: relative;
}
div.similiarShowsCard div.imageContainer::before {
  background-image: linear-gradient(198deg, rgba(0, 0, 0, 0.9), hsla(0, 0%, 9%, 0.5) 20%, transparent 28%);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
div.similiarShowsCard div.imageContainer img {
  width: 100%;
  display: block;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
div.similiarShowsCard div.imageContainer span.duration {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
}
div.similiarShowsCard div.imageContainer div.playIconContainer {
  align-items: center;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  background: rgba(30, 30, 20, 0.5);
  cursor: pointer;
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  z-index: 9;
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.similiarShowsCard div.imageContainer div.playIconContainer svg {
  width: 31px !important;
  height: 31px !important;
}
div.similiarShowsCard div.metaInformation {
  padding: 15px 13px 30px;
  background-color: #2f2f2f;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  min-height: 217px;
  max-height: 217px;
}
div.similiarShowsCard div.metaInformation h1.showName, div.similiarShowsCard div.metaInformation .showName.h1 {
  color: var(--duration-color);
  font-size: 14px;
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.similiarShowsCard div.metaInformation div.extraInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.left span {
  font-size: 14px;
  margin-right: 6px;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.left span.rating {
  padding: 1px 6px;
  border: 1px solid var(--border-color);
}
div.similiarShowsCard div.metaInformation div.extraInfo div.left span.year {
  margin-right: 0;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList {
  background-color: rgba(42, 42, 42, 0.6);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList svg {
  width: 20px !important;
  height: 20px !important;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList:hover {
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList:hover span.tooltip {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList span.tooltip {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #d6d5d5;
  position: absolute;
  top: -47px;
  right: -18px;
  width: 10rem;
  padding: 6px 8px 6px;
  color: var(--bg-color);
  font-weight: 500;
  border-radius: 2px;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList span.tooltip.remove {
  width: 12rem;
}
div.similiarShowsCard div.metaInformation div.extraInfo div.right button.addToList span.tooltip::after {
  content: "";
  position: absolute;
  top: 99%;
  right: 31px;
  margin-left: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #d6d5d5 transparent transparent transparent;
}
div.similiarShowsCard div.metaInformation p.description {
  font-size: 0.78em;
  color: var(--text-color);
  font-weight: 300;
  line-height: 1.7em;
  letter-spacing: 0.3px;
  word-wrap: break-word;
}

div.podcastModal .rhap_container {
  background-color: #303030 !important;
  border-radius: 5px;
}
div.podcastModal .rhap_time {
  color: #a29b9b !important;
}
div.podcastModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.podcastModal div.overlayModal img.overlayImage {
  width: 100%;
  height: 100vh;
}
div.podcastModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 9999999;
  cursor: pointer;
}
div.podcastModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.podcastModal div.contents {
  z-index: 99999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 90%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 90%;
  margin: 0 auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
div.podcastModal div.contents div.podcastContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
div.podcastModal div.contents div.podcastContainer div.left {
  width: 100%;
}
@media all and (max-width: 980px) {
  div.podcastModal div.contents div.podcastContainer div.left {
    width: 100%;
    margin-right: 0;
  }
}
div.podcastModal div.contents div.podcastContainer div.left div.imageContainer {
  width: 100%;
}
div.podcastModal div.contents div.podcastContainer div.left div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
div.podcastModal div.contents div.podcastContainer div.right {
  width: 100%;
}
@media all and (max-width: 980px) {
  div.podcastModal div.contents div.podcastContainer div.right {
    width: 100%;
  }
}
div.podcastModal div.contents div.podcastContainer div.right h1.title, div.podcastModal div.contents div.podcastContainer div.right .title.h1 {
  color: var(--text-color);
  font-size: 20px;
  margin-bottom: 10px;
}
@media all and (max-width: 640px) {
  div.podcastModal div.contents div.podcastContainer div.right div.audioContainer {
    width: 100%;
  }
}
div.podcastModal div.contents div.podcastContainer div.right div.audioContainer audio {
  width: 100%;
  display: block;
}

div.tvAppIndex {
  padding: 60px 0;
}
div.tvAppIndex div.tvActivationReverse div.topHeader {
  padding: 20px 10px;
  border-bottom: 1px solid var(--border-color);
}
div.tvAppIndex div.tvActivationReverse div.topHeader h1, div.tvAppIndex div.tvActivationReverse div.topHeader .h1 {
  font-size: 22px;
  color: var(--heading-color);
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer {
  padding: 50px 0 20px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer h2, div.tvAppIndex div.tvActivationReverse div.bottomContainer .h2 {
  color: var(--white-color);
  font-size: 18px;
  margin-bottom: 20px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer p {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 15px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form label {
  color: var(--heading-color);
  font-size: 15px;
  display: block;
  margin-bottom: 10px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form input {
  display: block;
  outline: none;
  border: none;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 30%;
}
@media all and (max-width: 640px) {
  div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form input {
    width: 100%;
  }
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form button {
  width: 8rem;
  padding: 6px 1px 9px;
  border-radius: 2px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 13px;
  margin-bottom: 10px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form button:hover {
  background-color: var(--secondary-hover-color);
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer p.terms {
  font-size: 15px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer p.terms span {
  color: rgb(63, 131, 242);
  cursor: pointer;
}
div.tvAppIndex div.linkTvApp {
  padding: 130px 0;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer {
  width: 35%;
  margin: 0 auto;
  background-color: #22293b;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
  border-radius: 10px;
  padding: 50px 60px;
}
@media all and (max-width: 1200px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 60%;
  }
}
@media all and (max-width: 640px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 75%;
  }
}
@media all and (max-width: 480px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 90%;
    padding: 40px 40px;
  }
}
div.tvAppIndex div.linkTvApp div.authCodeContainer h1.heading, div.tvAppIndex div.linkTvApp div.authCodeContainer .heading.h1 {
  color: var(--white-color);
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 8px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer p {
  color: var(--white-color);
  font-size: 14px;
  margin-bottom: 30px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer span.loading {
  display: block;
  color: var(--success-color);
  margin-bottom: 20px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.authCodeField {
  border: 1px solid var(--success-color);
  padding: 10px;
  font-size: 24px;
  color: var(--success-color);
  letter-spacing: 10px;
  font-weight: 700;
  margin-bottom: 15px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.authCodeField.expired {
  border: 1px solid var(--error-color);
  color: var(--error-color);
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired span {
  color: #fff;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired div.regenerateButton {
  margin-top: 10px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired div.regenerateButton button {
  background-image: linear-gradient(0deg, #2175d9, #2789ff);
  width: 12rem;
  border-radius: 4px;
  color: #fff;
  padding: 10px;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.29px;
  font-weight: 500;
  cursor: pointer;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer span.timer {
  color: #fff;
  font-size: 16px;
}

div.detailsScreenModal {
  padding-bottom: 60px;
  position: relative;
}
@media all and (max-width: 980px) {
  div.detailsScreenModal {
    position: unset;
  }
}
div.detailsScreenModal div.imgVideoContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 450px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer {
    height: 326px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer {
    height: 252px;
  }
}
div.detailsScreenModal div.imgVideoContainer.noHeight {
  height: unset;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer.noHeight div.bottomVignette {
    bottom: -31px;
  }
}
div.detailsScreenModal div.imgVideoContainer video {
  z-index: 3;
}
div.detailsScreenModal div.imgVideoContainer img {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
div.detailsScreenModal div.imgVideoContainer img.show {
  display: block;
}
div.detailsScreenModal div.imgVideoContainer img.hide {
  display: none;
}
div.detailsScreenModal div.imgVideoContainer div.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9999;
}
div.detailsScreenModal div.imgVideoContainer div.closeButton button {
  background-color: var(--bg-color);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  line-height: 0;
  cursor: pointer;
}
div.detailsScreenModal div.imgVideoContainer div.closeButton button svg {
  stroke: var(--white-color) !important;
}
div.detailsScreenModal div.imgVideoContainer div.bottomVignette {
  background-color: transparent;
  background-image: linear-gradient(180deg, hsla(0, 0%, 8%, 0) 0, hsla(0, 0%, 30%, 0.15) 15%, hsla(0, 0%, 8%, 0.35) 29%, hsla(0, 0%, 8%, 0.58) 40%, #141414 84%, #141414);
  background-position: 0 top;
  background-repeat: repeat-x;
  background-size: 100% 100%;
  bottom: -8px;
  height: 200px;
  opacity: 1;
  top: auto;
  width: 100%;
  z-index: 99;
  position: absolute;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer div.bottomVignette {
    bottom: 31px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions {
  position: absolute;
  bottom: 40px;
  z-index: 999;
  width: 100%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions img.title {
  top: -110px;
  width: 30rem;
  left: 30px;
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions img.title {
    top: -65px;
    width: 15rem;
    left: 20px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions h1.title, div.detailsScreenModal div.imgVideoContainer div.showActions .title.h1 {
  padding: 0 40px;
  animation: fadeIn 0.4s ease-in-out;
  font-size: 60px;
  z-index: 99;
  font-family: "Gagalin-Regular", sans-serif !important;
  color: var(--white-color);
  max-width: 80%;
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions h1.title, div.detailsScreenModal div.imgVideoContainer div.showActions .title.h1 {
    font-size: 40px;
    padding: 0 18px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions h1.title, div.detailsScreenModal div.imgVideoContainer div.showActions .title.h1 {
    font-size: 21px;
    margin-bottom: 6px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons {
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons {
    padding: 0 18px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left {
  display: flex;
  align-items: center;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play {
  display: flex;
  padding: 6px 1px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  width: 7.5rem;
  background-color: var(--primary-btn-color);
  margin-right: 10px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play:hover {
  background-color: var(--primary-btn-hover);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play {
    width: 5.5rem;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon {
  margin-right: 10px;
  line-height: 0;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon svg {
  width: 25px !important;
  height: 25px !important;
  color: var(--black-color);
  display: block;
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon svg {
    width: 16px !important;
    height: 16px !important;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play div.icon img {
  width: 100%;
  display: block;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play span {
  font-size: 16px;
  font-weight: 500;
  color: var(--black-color);
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.play span {
    font-size: 12px;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.subscribe {
  width: 8rem;
  padding: 6px 1px 9px;
  border-radius: 2px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.subscribe:hover {
  background-color: var(--secondary-hover-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.subscribe span {
  font-size: 14px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList {
  background-color: rgba(42, 42, 42, 0.6);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList svg {
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList:hover {
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList:hover span.tooltip {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList span.tooltip {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #d6d5d5;
  position: absolute;
  top: -50px;
  width: 10rem;
  padding: 6px 8px 6px;
  color: var(--bg-color);
  font-weight: 500;
  border-radius: 2px;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList span.tooltip.remove {
  width: 12rem;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left button.addToList span.tooltip::after {
  content: "";
  position: absolute;
  top: 99%;
  left: 50%;
  margin-left: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #d6d5d5 transparent transparent transparent;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share {
  position: relative;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share button.shareButton {
  background-color: rgba(42, 42, 42, 0.6);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
  margin-left: 10px;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share button.shareButton svg {
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share button.shareButton:hover {
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
  position: absolute;
  top: -126px;
  left: -74px;
  z-index: 999;
  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
  width: 232px;
  border-radius: 8px;
  display: none;
}
@media all and (max-width: 2500px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    left: -89px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    width: 200px;
    left: -74px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    width: 232px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer {
    width: 11rem;
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer.active {
  display: block;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer::after {
  content: "";
  bottom: -8px;
  width: 0;
  height: 0;
  border-color: rgba(16, 20, 31, 0) rgba(16, 20, 31, 0) rgb(87, 85, 85);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer::after {
    left: 50%;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer::after {
    border-color: rgba(16, 20, 31, 0) rgba(16, 20, 31, 0) rgb(87, 85, 85);
  }
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--border-color) !important;
  width: 100% !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook div.icon {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer button.facebook div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer div.left {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.facebookContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  width: 100% !important;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter div.icon {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer button.twitter div.icon svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer div.left {
  width: 10%;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.left div.share div.shareContainer div.twitterContainer div.right svg:hover {
  color: var(--text-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button {
  background-color: rgba(42, 42, 42, 0.6);
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0.12em solid hsla(0, 0%, 100%, 0.5);
  cursor: pointer;
  color: var(--white-color);
  transition: 0.2s ease-in-out;
  position: relative;
  opacity: 0.3;
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button:hover {
  opacity: 1;
  background-color: var(--secondary-btn-hover);
  border-color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button:hover svg {
  color: var(--white-color);
}
div.detailsScreenModal div.imgVideoContainer div.showActions div.buttons div.right div.volumeButton button svg {
  transition: 0.2s ease-in-out;
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
}
div.detailsScreenModal div.info {
  padding: 0 40px;
  margin-top: 5px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info {
    padding: 0 18px;
  }
}
div.detailsScreenModal div.info div.metaData {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.metaData {
    flex-direction: column;
  }
}
div.detailsScreenModal div.info div.metaData div.left {
  width: 60%;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.metaData div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.detailsScreenModal div.info div.metaData div.left div.top {
  display: flex;
  align-items: center;
}
div.detailsScreenModal div.info div.metaData div.left div.top span {
  font-size: 14px;
  margin-right: 10px;
}
div.detailsScreenModal div.info div.metaData div.left div.top span.duration {
  color: var(--duration-color);
}
div.detailsScreenModal div.info div.metaData div.left div.top span.ended {
  color: var(--duration-color);
  font-size: 18px;
}
div.detailsScreenModal div.info div.metaData div.left div.top span.ended span {
  color: var(--secondary-color);
  text-decoration: underline;
  cursor: pointer;
}
div.detailsScreenModal div.info div.metaData div.left div.top span.rating {
  padding: 1px 6px;
  border: 1px solid var(--border-color);
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio {
  font-size: 14px;
  position: relative;
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio:hover span.tooltip {
  display: flex;
  animation: fadeIn 0.3s ease-in-out;
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio span.tooltip {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #d6d5d5;
  position: absolute;
  top: -41px;
  width: 145px;
  padding: 6px 8px 6px;
  color: var(--bg-color);
  font-weight: 500;
  border-radius: 2px;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9;
  left: -40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.detailsScreenModal div.info div.metaData div.left div.top div.audio span.tooltip::after {
  content: "";
  position: absolute;
  top: 99%;
  left: 50%;
  margin-left: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: #d6d5d5 transparent transparent transparent;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom {
  margin-top: 30px;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom p.description {
  font-size: 14px;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer {
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button {
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 6px 8px;
  background-color: var(--secondary-color);
  display: block;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button:hover {
  background-color: var(--secondary-hover-color);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button.free {
  background-color: var(--freeBtn-color);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button.free:hover {
  background-color: var(--freeBtn-hover);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button.free span {
  color: var(--white-color);
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button span {
  color: var(--white-color);
  font-size: 12px;
  display: block;
}
div.detailsScreenModal div.info div.metaData div.left div.bottom div.subscriptionContainer button:last-child {
  margin-right: 0;
}
div.detailsScreenModal div.info div.metaData div.right {
  width: 35%;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.metaData div.right {
    width: 100%;
  }
}
div.detailsScreenModal div.info div.metaData div.right span.heading {
  font-size: 12px;
  color: var(--grey-color);
  margin-right: 3px;
}
div.detailsScreenModal div.info div.metaData div.right div.cast,
div.detailsScreenModal div.info div.metaData div.right div.crew,
div.detailsScreenModal div.info div.metaData div.right div.categories,
div.detailsScreenModal div.info div.metaData div.right div.director {
  margin-bottom: 10px;
}
div.detailsScreenModal div.info div.metaData div.right div.cast span,
div.detailsScreenModal div.info div.metaData div.right div.crew span,
div.detailsScreenModal div.info div.metaData div.right div.categories span,
div.detailsScreenModal div.info div.metaData div.right div.director span {
  font-size: 13px;
  margin-right: 4px;
}
div.detailsScreenModal div.info div.metaData div.right :last-child {
  margin-bottom: 0;
}
div.detailsScreenModal div.info div.showTrailerContainer {
  margin-top: 20px;
}
div.detailsScreenModal div.info div.showTrailerContainer h1.heading, div.detailsScreenModal div.info div.showTrailerContainer .heading.h1 {
  color: var(--heading-color);
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 15px;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer {
  width: 200px;
  position: relative;
  cursor: pointer;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer:hover div.playIconContainer {
  visibility: visible;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 3px;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer div.playIconContainer {
  align-items: center;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  background: rgba(30, 30, 20, 0.5);
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  z-index: 9;
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.detailsScreenModal div.info div.showTrailerContainer div.imageContainer div.playIconContainer svg {
  width: 25px !important;
  height: 25px !important;
}
div.detailsScreenModal div.info div.similiarShowsContainer {
  margin-top: 40px;
}
div.detailsScreenModal div.info div.similiarShowsContainer h1.heading, div.detailsScreenModal div.info div.similiarShowsContainer .heading.h1 {
  color: var(--heading-color);
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 20px;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
  width: 31.7%;
  margin-right: 17px;
  margin-bottom: 20px;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:last-child {
  margin-right: 0;
}
div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    width: 48.7%;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
    margin-right: 17px;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    width: 48.4%;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    margin-right: 10px;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
    margin-right: 10px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show {
    width: 100%;
    margin-right: 0;
  }
  div.detailsScreenModal div.info div.similiarShowsContainer div.cards div.show:nth-child(3n) {
    margin-right: 0;
  }
}
div.detailsScreenModal div.eventEndedOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
div.detailsScreenModal div.eventEndedOverlay h2, div.detailsScreenModal div.eventEndedOverlay .h2 {
  font-size: 30px;
  color: var(--white-color);
  margin-bottom: 10px;
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.eventEndedOverlay h2, div.detailsScreenModal div.eventEndedOverlay .h2 {
    font-size: 22px;
  }
}
div.detailsScreenModal div.eventEndedOverlay p {
  font-size: 20px;
  color: var(--secondary-color);
  cursor: pointer;
}
@media all and (max-width: 980px) {
  div.detailsScreenModal div.eventEndedOverlay p {
    text-decoration: underline;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreenModal div.eventEndedOverlay p {
    font-size: 16px;
  }
}
div.detailsScreenModal div.eventEndedOverlay p:hover {
  text-decoration: underline;
}

div.screenBanner {
  height: 552px;
}
@media all and (max-width: 980px) {
  div.screenBanner {
    height: 500px;
  }
}
@media all and (max-width: 640px) {
  div.screenBanner {
    height: 450px;
  }
}
@media all and (max-width: 480px) {
  div.screenBanner {
    height: 230px;
  }
}

div.openAppContainer {
  padding: 15px;
  position: fixed;
  bottom: 10px;
  width: 100%;
  background-color: var(--button-color);
  z-index: 100000000000000000;
  border-radius: 10px;
  width: 98%;
  margin: 0 auto;
  transform: translateX(-50%);
  left: 50%;
}
div.openAppContainer h1, div.openAppContainer .h1 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 12px;
}
div.openAppContainer p {
  font-size: 12px;
  color: #fff;
  margin-bottom: 10px;
}
div.openAppContainer div.buttons {
  display: flex;
  justify-content: space-between;
}
div.openAppContainer div.buttons button.notNow {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  padding: 6px 12px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 12px;
}
div.openAppContainer div.buttons button.openApp {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
  padding: 6px 12px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 12px;
}

div.languageModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(32, 32, 38, 0.8);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.languageModal div.overlayModal.root {
  background: var(--bg-color);
}
div.languageModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 999;
  cursor: pointer;
}
div.languageModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.languageModal div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white-color);
  width: 30%;
  margin: 0 auto;
  padding: 25px 25px 25px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.languageModal div.contents {
    padding: 30px;
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.languageModal div.contents {
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  div.languageModal div.contents {
    width: 60%;
  }
}
@media all and (max-width: 640px) {
  div.languageModal div.contents {
    width: 90%;
  }
}
@media all and (max-width: 480px) {
  div.languageModal div.contents {
    width: 90%;
  }
}
div.languageModal div.contents h1, div.languageModal div.contents .h1 {
  color: var(--button-color);
  font-size: 22px;
  margin-bottom: 10px;
}
div.languageModal div.contents div.inputContainer {
  width: 100%;
  margin-bottom: 20px;
}
div.languageModal div.contents div.inputContainer select {
  width: 100%;
  padding: 12px 10px;
  outline: none;
  border: 1px solid rgb(231, 238, 239);
  color: var(--button-color);
}
div.languageModal div.contents div.inputContainer select option {
  padding: 12px;
}
div.languageModal div.contents div.buttonContainer button {
  width: 100%;
  display: block;
  padding: 10px;
  background-color: var(--button-color);
  color: var(--white-color);
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.languageModal div.contents div.buttonContainer button:hover {
  background-color: transparent;
  color: var(--button-color);
}

div.affliatePage {
  padding: 30px 0 0;
  background-color: #fff;
}
div.affliatePage div.top {
  padding: 30px 0;
}
div.affliatePage div.top div.logoSection {
  width: 70%;
  margin: 0 auto;
}
@media all and (min-width: 980px) {
  div.affliatePage div.top div.logoSection {
    width: 30%;
  }
}
div.affliatePage div.top div.logoSection h1.image, div.affliatePage div.top div.logoSection .image.h1 {
  width: 100%;
}
div.affliatePage div.top div.logoSection h1.image img, div.affliatePage div.top div.logoSection .image.h1 img {
  width: 100%;
  display: block;
}
div.affliatePage div.top h1, div.affliatePage div.top .h1 {
  font-size: 24px;
  text-align: center;
}
div.affliatePage div.bottom {
  background-color: #022230;
  padding: 136px 20px;
  border-top-left-radius: 91%;
  border-top-right-radius: 0;
}
div.affliatePage div.bottom div.buttons {
  margin-left: 40px;
}
div.affliatePage div.bottom div.buttons div.buttonContainer {
  text-align: center;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button {
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 30px;
  width: 10rem;
  color: #fff;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button.useApp {
  background-color: #45c4af;
  margin-bottom: 20px;
  box-shadow: rgba(69, 196, 175, 0.35) 0px 5px 15px;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button.web {
  box-shadow: rgba(0, 0, 0, 0.03) 0px 16px 24px;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}

div.emailForm div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 30px;
  width: 33% !important;
}
@media all and (max-width: 1400px) {
  div.emailForm div.MuiPaper-root {
    width: 38% !important;
  }
}
@media all and (max-width: 1200px) {
  div.emailForm div.MuiPaper-root {
    width: 45% !important;
  }
}
@media all and (max-width: 980px) {
  div.emailForm div.MuiPaper-root {
    width: 55% !important;
  }
}
@media all and (max-width: 768px) {
  div.emailForm div.MuiPaper-root {
    width: 65% !important;
  }
}
@media all and (max-width: 640px) {
  div.emailForm div.MuiPaper-root {
    width: 85% !important;
  }
}
@media all and (max-width: 480px) {
  div.emailForm div.MuiPaper-root {
    width: 100% !important;
    padding: 10px 0;
    margin: 20px !important;
  }
}
div.emailForm div.MuiPaper-root h1, div.emailForm div.MuiPaper-root .h1 {
  width: 110px;
  margin: 0 auto 10px;
}
div.emailForm div.MuiPaper-root h1 img, div.emailForm div.MuiPaper-root .h1 img {
  display: block;
  width: 100%;
}
div.emailForm div.MuiPaper-root h2, div.emailForm div.MuiPaper-root .h2 {
  text-align: center;
  color: var(--white-color);
}
div.emailForm div.MuiPaper-root form label {
  display: block;
  color: var(--white-color);
  font-size: 14px;
  margin-bottom: 6px;
}
div.emailForm div.MuiPaper-root form input {
  display: block;
  padding: 12px 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}
div.emailForm div.MuiPaper-root form div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.emailForm div.MuiPaper-root form div.buttonContainer {
    padding: 10px 1px;
  }
}
div.emailForm div.MuiPaper-root form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.emailForm div.MuiPaper-root form div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.emailForm div.MuiPaper-root form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}

div.verifyEmailModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 20px;
  width: 33%;
}
@media all and (max-width: 1400px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 38%;
  }
}
@media all and (max-width: 1200px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 65%;
  }
}
@media all and (max-width: 640px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 85%;
  }
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 95%;
    padding: 0;
    margin: 20px !important;
  }
}
div.verifyEmailModal div.MuiPaper-root div.top {
  text-align: center;
}
div.verifyEmailModal div.MuiPaper-root div.top svg {
  width: 70px !important;
  height: 70px !important;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.top svg {
    width: 50px !important;
    height: 50px !important;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom {
  color: var(--text-color);
  text-align: center;
}
div.verifyEmailModal div.MuiPaper-root div.bottom div.heading h1, div.verifyEmailModal div.MuiPaper-root div.bottom div.heading .h1 {
  font-size: 22px;
  margin-bottom: 10px;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom div.heading h1, div.verifyEmailModal div.MuiPaper-root div.bottom div.heading .h1 {
    font-size: 18px;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom p {
  font-size: 15px;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom p {
    font-size: 14px;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom p span {
  color: rgb(100, 180, 241);
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom p span {
    font-size: 14px;
  }
}

div.loginRequestModal {
  text-align: center;
}
div.loginRequestModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 30px;
  width: 33%;
}
@media all and (max-width: 1400px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 38%;
  }
}
@media all and (max-width: 1200px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 65%;
  }
}
@media all and (max-width: 640px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 85%;
  }
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 95%;
    padding: 0;
    margin: 20px !important;
  }
}
div.loginRequestModal div.MuiPaper-root span.MuiSkeleton-root {
  background-color: rgba(255, 255, 255, 0.11) !important;
}
div.loginRequestModal div.MuiPaper-root span.MuiSkeleton-root::after {
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.24), transparent) !important;
}
div.loginRequestModal h4, div.loginRequestModal .h4 {
  font-size: 17px;
  color: var(--white-color);
  margin-bottom: 8px;
}
div.loginRequestModal div.requestDetails {
  background-color: var(--secondary-bg-color);
  padding: 15px 70px 15px 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.requestDetails {
    padding: 15px 13px 15px 10px;
  }
}
div.loginRequestModal div.requestDetails div.oneRow {
  display: flex;
  justify-content: space-between;
}
div.loginRequestModal div.requestDetails div.oneRow span {
  color: var(--text-color);
  margin-bottom: 8px;
  font-size: 15px;
}
div.loginRequestModal div.requestDetails div.oneRow span.label {
  font-style: italic;
}
div.loginRequestModal p {
  color: var(--text-color);
  margin-bottom: 10px;
  font-size: 14px;
}
div.loginRequestModal div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.buttonContainer {
    padding: 10px 1px;
  }
}
div.loginRequestModal div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginRequestModal div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginRequestModal div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}

div.appLoginModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 20px;
  width: 33% !important;
}
@media all and (max-width: 1400px) {
  div.appLoginModal div.MuiPaper-root {
    width: 38% !important;
  }
}
@media all and (max-width: 1200px) {
  div.appLoginModal div.MuiPaper-root {
    width: 45% !important;
  }
}
@media all and (max-width: 980px) {
  div.appLoginModal div.MuiPaper-root {
    width: 55% !important;
  }
}
@media all and (max-width: 768px) {
  div.appLoginModal div.MuiPaper-root {
    width: 65% !important;
  }
}
@media all and (max-width: 640px) {
  div.appLoginModal div.MuiPaper-root {
    width: 85% !important;
  }
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root {
    width: 95% !important;
    padding: 0;
    margin: 20px !important;
  }
}
div.appLoginModal div.MuiPaper-root p {
  color: var(--text-color);
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root p {
    font-size: 14px;
  }
}
div.appLoginModal div.MuiPaper-root div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root div.buttonContainer {
    padding: 10px 1px;
  }
}
div.appLoginModal div.MuiPaper-root div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.appLoginModal div.MuiPaper-root div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.appLoginModal div.MuiPaper-root div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}
div.appLoginModal div.MuiPaper-root span {
  color: var(--white-color);
  text-align: center;
  font-size: 14px;
  display: block;
}

div.videoPlayerContainer {
  position: relative;
  padding: 100px 0 0;
}
div.videoPlayerContainer div.arrowContainer {
  width: 5%;
  cursor: pointer;
}
div.videoPlayerContainer div.arrowContainer h3, div.videoPlayerContainer div.arrowContainer .h3 {
  width: 100%;
  background: transparent;
  cursor: pointer;
}
div.videoPlayerContainer div.arrowContainer h3 svg, div.videoPlayerContainer div.arrowContainer .h3 svg {
  width: 30px;
  height: 100%;
  fill: white !important;
}
div.videoPlayerContainer div.arrowContainer:hover {
  cursor: pointer;
  transform: scale(1.2);
}
div.videoPlayerContainer div.playerContainer {
  width: 90%;
  margin: 0 auto;
  background-color: var(--black-color);
  position: relative;
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer {
  position: relative;
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  text-align: center;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate {
    width: 100%;
  }
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1, div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate .h1 {
  text-align: center;
  color: var(--white-color);
  font-weight: 400;
  margin-bottom: 10px;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1, div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate .h1 {
    font-size: 25px;
  }
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1, div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate .h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1, div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate .h1 {
    font-size: 14px;
  }
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
  width: 12rem;
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button:hover {
  background-color: transparent;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button {
    width: 8rem;
    padding: 8px 20px;
    font-size: 13px;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button {
    width: 6rem;
    padding: 5px 20px;
    font-size: 12px;
  }
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer img {
  display: block;
  width: 100%;
}
div.videoPlayerContainer div.multiAudio {
  padding: 10px 0;
  width: 65%;
  margin-left: 5%;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.multiAudio {
    width: 80%;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.multiAudio {
    width: 95%;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.multiAudio {
    width: 55%;
  }
}
@media all and (max-width: 360px) {
  div.videoPlayerContainer div.multiAudio {
    width: 70%;
  }
}
div.videoPlayerContainer div.multiAudio div.selectContainer {
  width: 40%;
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.multiAudio div.selectContainer {
    width: 100%;
  }
}
div.videoPlayerContainer video {
  height: 100% !important;
  display: block !important;
  width: 100% !important;
}
div.videoPlayerContainer div.videoMetaData {
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
}
div.videoPlayerContainer div.videoMetaData div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.videoMetaData div.head {
    flex-direction: column;
    align-items: flex-start;
  }
}
div.videoPlayerContainer div.videoMetaData div.head h1.videoTitle, div.videoPlayerContainer div.videoMetaData div.head .videoTitle.h1 {
  color: var(--white-color);
  font-size: 30px;
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer {
  display: flex;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer {
    justify-content: flex-start;
    margin-top: 10px;
  }
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer button {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer button:hover {
  background-color: transparent;
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer button.myList {
  margin-right: 20px;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
div.videoPlayerContainer div.videoMetaData div.categories {
  display: flex;
  flex-wrap: wrap;
}
div.videoPlayerContainer div.videoMetaData span.category {
  font-size: 16px;
  color: var(--inner-text-color);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
div.videoPlayerContainer div.videoMetaData span.category::after {
  content: "•";
  margin: 0 5px 0 10px;
}
div.videoPlayerContainer div.videoMetaData span.category:last-child::after {
  display: none;
}
div.videoPlayerContainer div.videoMetaData span.category:hover {
  text-decoration: underline;
}
div.videoPlayerContainer div.videoMetaData span.rating {
  margin-left: 10px;
  padding: 2px 6px;
  background-color: var(--grey-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
}
@media (max-width: 600px) {
  div.videoPlayerContainer div.videoMetaData div.categoriesAndRating {
    flex-direction: column;
    align-items: flex-start;
  }
  div.videoPlayerContainer div.videoMetaData span.rating {
    margin-top: 10px;
    margin-left: 0;
  }
  div.videoPlayerContainer div.videoMetaData div.categories {
    width: 100%;
  }
  div.videoPlayerContainer div.videoMetaData span.category {
    white-space: normal;
  }
}
div.videoPlayerContainer div.videoMetaData p.description {
  color: var(--inner-text-color);
  margin-bottom: 20px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem {
  margin-bottom: 20px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem h6.subHeading, div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem .subHeading.h6 {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 10px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items {
  width: 30%;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items.oneRow {
  display: flex;
  width: 60%;
  flex-wrap: wrap;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items.oneRow span.item {
  margin-right: 10px;
  margin-bottom: 0;
  display: block;
  color: var(--inner-text-color);
  cursor: pointer;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items.oneRow span.item:last-child span.comma {
  display: none;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items span.itemInfo {
  cursor: pointer;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items div.item {
  display: flex;
  justify-content: space-between;
  color: var(--inner-text-color);
  margin-bottom: 6px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items div.item:last-child {
  margin-bottom: 0;
}
div.videoPlayerContainer div.upNextContainer {
  width: 90%;
  margin: 0 auto;
}
div.videoPlayerContainer div.upNextContainer h1.heading, div.videoPlayerContainer div.upNextContainer .heading.h1 {
  text-align: left;
  color: var(--heading-color);
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.upNextContainer h1.heading, div.videoPlayerContainer div.upNextContainer .heading.h1 {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.upNextContainer h1.heading, div.videoPlayerContainer div.upNextContainer .heading.h1 {
    font-size: 24px;
  }
}
div.videoPlayerContainer div.upNextContainer div.upNext {
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-top: 20px;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.upNextContainer div.upNext {
    width: 100%;
  }
}
div.videoPlayerContainer div.upNextContainer div.upNext div.item {
  width: 32.5%;
  margin-right: 10px;
}
div.videoPlayerContainer div.upNextContainer div.upNext div.item:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.item {
    width: 32%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.item {
    width: 48.5%;
    margin-bottom: 20px;
  }
  div.videoPlayerContainer div.upNextContainer div.upNext div.item:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.item {
    width: 100%;
    margin-right: 0;
  }
  div.videoPlayerContainer div.upNextContainer div.upNext div.item:nth-child(2n) {
    margin-right: 0;
  }
}
div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
  width: 15%;
  margin-right: 10px;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 20%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 23%;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 30%;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 46%;
  }
}
@media all and (max-width: 360px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.itemPortrait {
    width: 45%;
  }
}
div.videoPlayerContainer span.line {
  height: 1px;
  background-color: var(--border-color);
  width: 100%;
  display: block;
  margin-top: 70px;
}
div.videoPlayerContainer div.files {
  width: 90%;
  margin: 0 auto;
}
div.videoPlayerContainer div.commentsContainer {
  width: 70%;
  margin: 0 auto;
  padding: 60px 0;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.commentsContainer {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.commentsContainer {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer {
    width: 100%;
  }
}
div.videoPlayerContainer div.commentsContainer div.top div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}
div.videoPlayerContainer div.commentsContainer div.top div.head span.commentsCount {
  color: var(--white-color);
  font-size: 22px;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer div.top div.head span.commentsCount {
    font-size: 16px;
  }
}
div.videoPlayerContainer div.commentsContainer div.top div.head div.sortContainer select {
  background-color: transparent;
  border: 1px solid var(--border-color);
  padding: 10px 15px;
  color: var(--white-color);
  font-size: 16px;
  border-radius: 6px;
}
div.videoPlayerContainer div.commentsContainer div.top div.head div.sortContainer select option {
  color: var(--black-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer {
  display: flex;
  padding: 40px 0 20px;
  border-bottom: 1px solid var(--border-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.imageContainer {
  margin-right: 20px;
  width: 45px;
  height: 45px;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.imageContainer img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.imageContainer span.nameAvatar {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer {
  width: 90%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #e1e8ed;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage {
  width: 20%;
  height: 30%;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage img {
  max-width: 50%;
  border-radius: 5px;
  height: auto;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage button.removeButton {
  position: absolute;
  background: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--button-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage button.removeButton:hover {
  background-color: #f2f2f2;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.selectedImage button.removeButton svg {
  width: 15px;
  height: 15px;
  fill: var(--button-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer label.customFileUpload {
  color: white;
  margin-right: 10px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer label.customFileUpload:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer input[type=file] {
  display: none;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer button.giphyButton {
  background: none;
  color: white;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions .buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions button.submit {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue div.actions button.submit:hover {
  background-color: transparent;
  color: var(--white-color);
}
@media (max-width: 1200px) {
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer {
    width: 90%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
    height: 40px;
    padding: 8px 12px;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .selectedImage {
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer {
    margin-bottom: 10px;
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUpload,
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 50%;
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer {
    width: 90%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
    height: 35px;
    padding: 6px 10px;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .selectedImage {
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUpload,
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
    height: 30px;
    padding: 4px 8px;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .selectedImage {
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer {
    flex-direction: column;
    width: 100%;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUpload,
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
    text-align: center;
    justify-content: center;
  }
  div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers {
  padding: 40px 0 25px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-right: 20px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name, div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right .name.h1 {
  font-size: 16px;
  color: var(--white-color);
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 20px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container {
  margin-top: 10px;
  margin-bottom: 30px;
  width: 20%;
  height: auto;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container img {
  max-width: 50%;
  height: auto;
  border-radius: 8px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-right: 0;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-right: 5px;
}
div.videoPlayerContainer div.customModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
div.videoPlayerContainer div.customModal .modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
}
div.videoPlayerContainer div.customModal .modalContent .closeButton {
  position: absolute;
  top: -6px;
  right: 2px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.vjs-control-bar {
  height: 60px !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.vjs-control {
  font-size: 20px;
  width: 50px;
  height: 50px;
  color: #fff;
}

.vjs-control > .vjs-icon-placeholder,
.vjs-control > .vjs-icon-play,
.vjs-control > .vjs-icon-pause,
.vjs-control > .vjs-icon-volume-high,
.vjs-control > .vjs-icon-captions,
.vjs-control > .vjs-icon-fullscreen-enter,
.vjs-control > .vjs-icon-fullscreen-exit {
  font-size: 20px !important;
  background: #fff;
  color: #fff;
}

.vjs-remaining-time-display {
  display: none;
}

.vjs-live-display {
  font-size: 15px;
  position: absolute;
  bottom: 15px;
}

div.autoplayComponent {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #000;
  height: 100%;
  z-index: 9;
}
div.autoplayComponent div.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
div.autoplayComponent div.content div.progressBar {
  width: 100px;
  height: 100px;
}
div.autoplayComponent div.content span.timer {
  display: inline-block;
  margin-top: 10px;
  font-weight: 600;
  color: var(--white-color);
}

div.replyField {
  display: flex;
  width: 100%;
  padding-top: 30px;
  animation: fadeIn 0.4s ease-in-out;
}
div.replyField.child {
  width: 92%;
  margin: 0 0 0 auto;
}
div.replyField div.left {
  margin-right: 20px;
}
div.replyField div.left div.imageContainer {
  width: 45px;
  height: 45px;
}
div.replyField div.left div.imageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.replyField div.left div.imageContainer span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.replyField div.right {
  width: 100%;
}
div.replyField div.right div.inputContainer {
  width: 90%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
div.replyField div.right div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #e1e8ed;
}
div.replyField div.right div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.replyField div.right div.inputContainer div.upload-featue {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
div.replyField div.right div.inputContainer div.upload-featue div.selectedImageReply {
  width: 20%;
  height: 30%;
  margin-bottom: 20px;
}
div.replyField div.right div.inputContainer div.upload-featue div.selectedImageReply img {
  max-width: 50%;
  border-radius: 5px;
  height: auto;
}
div.replyField div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton {
  position: absolute;
  background: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--button-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}
div.replyField div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton:hover {
  background-color: #f2f2f2;
}
div.replyField div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton svg {
  width: 15px;
  height: 15px;
  fill: var(--button-color);
}
div.replyField div.right div.inputContainer div.upload-featue div.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer {
  display: flex;
  align-items: center;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.cancel {
  background-color: var(--grey-color);
  border: 1px solid var(--grey-color);
  margin-right: 10px;
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: 0.4s ease-in-out;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.cancel:hover {
  background-color: transparent;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer label.customFileUploadReply {
  color: white;
  margin-right: 10px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer label.customFileUploadReply:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer input[type=file] {
  display: none;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.giphyButton {
  background: none;
  color: white;
  margin-bottom: 10px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyField div.right div.inputContainer div.upload-featue div.actions button.submit {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.replyField div.right div.inputContainer div.upload-featue div.actions button.submit:hover {
  background-color: transparent;
  color: var(--white-color);
}
@media (max-width: 1200px) {
  div.replyField div.right div.inputContainer {
    width: 90%;
  }
  div.replyField div.right div.inputContainer textarea {
    height: 40px;
    padding: 8px 12px;
  }
  div.replyField div.right div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.replyField div.right div.inputContainer div.upload-featue .selectedImageReply {
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer {
    margin-bottom: 10px;
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUploadReply,
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 20%;
    text-align: center;
    justify-content: center;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions button.cancel {
    width: 20%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions button.submit {
    width: 40%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  div.replyField div.right div.inputContainer {
    width: 90%;
  }
  div.replyField div.right div.inputContainer textarea {
    height: 35px;
    padding: 6px 10px;
  }
  div.replyField div.right div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.replyField div.right div.inputContainer div.upload-featue .selectedImageReply {
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer {
    flex-direction: column-reverse;
    width: 100%;
    text-align: center;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUploadReply,
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 100%;
    margin-right: 10px;
    text-align: center;
    justify-content: center;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions button.cancel {
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions button.submit {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  div.replyField div.right div.inputContainer textarea {
    height: 30px;
    padding: 4px 8px;
  }
  div.replyField div.right div.inputContainer div.upload-featue {
    flex-direction: column;
  }
  div.replyField div.right div.inputContainer div.upload-featue .selectedImageReply {
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer {
    flex-direction: column-reverse;
    width: 100%;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer button.submit {
    width: 100%;
    text-align: center;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer label.customFileUploadReply,
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer button.giphyButton {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
    text-align: center;
    justify-content: center;
  }
  div.replyField div.right div.inputContainer div.upload-featue .actions .buttonContainer button.cancel {
    width: 100%;
  }
}

.videoBanner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 95vh;
}
.videoBanner #background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.videoBanner .bannerImage {
  height: 95vh;
}
@media all and (max-width: 480px) {
  .videoBanner .bannerImage {
    height: 50vh;
  }
}
.videoBanner button.bannerButton {
  position: absolute;
  padding: 15px 20px;
  right: 30px;
  bottom: 50px;
  border: none;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 7px;
  background: var(--white-color);
}
.videoBanner button.bannerButton:hover {
  background-color: var(--button-color);
  color: var(--white-color);
}
@media all and (max-width: 480px) {
  .videoBanner button.bannerButton {
    right: 20px;
    bottom: 60px;
    font-size: 0.8rem;
  }
}
@media all and (max-width: 480px) {
  .videoBanner {
    height: 50vh;
  }
}

div.deviceList {
  display: flex;
  background: var(--white-color);
  padding-top: 30px;
  justify-content: space-between;
  padding-bottom: 100px;
}
@media all and (max-width: 640px) {
  div.deviceList {
    flex-direction: column;
  }
}
div.deviceList div.left {
  width: 40%;
  padding-left: 60px;
}
@media all and (max-width: 768px) {
  div.deviceList div.left {
    padding-left: 30px;
  }
}
@media all and (max-width: 640px) {
  div.deviceList div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.deviceList div.left h1.heading, div.deviceList div.left .heading.h1 {
  color: var(--black-color);
}
div.deviceList div.left p.description {
  color: var(--text-color);
}
div.deviceList div.right {
  width: 40%;
}
@media all and (max-width: 1980px) {
  div.deviceList div.right {
    width: 43%;
  }
}
@media all and (max-width: 1600px) {
  div.deviceList div.right {
    width: 46%;
  }
}
@media all and (max-width: 1400px) {
  div.deviceList div.right {
    width: 55%;
  }
}
@media all and (max-width: 1200px) {
  div.deviceList div.right {
    width: 46%;
  }
}
@media all and (max-width: 980px) {
  div.deviceList div.right {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.deviceList div.right {
    width: 56%;
  }
}
@media all and (max-width: 640px) {
  div.deviceList div.right {
    width: 91%;
    margin: 0 auto;
  }
}
div.deviceList div.right ul.devices {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
div.deviceList div.right ul.devices li {
  cursor: pointer;
  width: 17rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  margin-bottom: 6px;
  background: rgb(54, 124, 160);
  background: linear-gradient(90deg, rgb(54, 124, 160) 0%, rgb(14, 64, 115) 35%, rgb(0, 41, 97) 100%);
  transition: 0.2s ease-in-out;
}
div.deviceList div.right ul.devices li:nth-child(3n) {
  margin-right: 0;
}
@media all and (max-width: 2200px) {
  div.deviceList div.right ul.devices li {
    width: 15rem;
  }
}
@media all and (max-width: 1980px) {
  div.deviceList div.right ul.devices li {
    width: 13rem;
    height: 9rem;
  }
}
@media all and (max-width: 1200px) {
  div.deviceList div.right ul.devices li:nth-child(3n) {
    margin-right: 6px;
  }
  div.deviceList div.right ul.devices li:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (max-width: 980px) {
  div.deviceList div.right ul.devices li {
    width: 12rem;
    height: 7rem;
  }
}
@media all and (max-width: 768px) {
  div.deviceList div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 640px) {
  div.deviceList div.right ul.devices li {
    width: 13rem;
  }
}
@media all and (max-width: 480px) {
  div.deviceList div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 360px) {
  div.deviceList div.right ul.devices li {
    width: 100%;
    margin-right: 0;
  }
  div.deviceList div.right ul.devices li:nth-child(3n) {
    margin-right: 0;
  }
}
div.deviceList div.right ul.devices li:hover {
  box-shadow: 0px 1px 6px 4px #333;
}
div.deviceList div.right ul.devices li span {
  color: var(--white-color);
  font-weight: 700;
  font-size: 30px;
}
@media all and (max-width: 980px) {
  div.deviceList div.right ul.devices li span {
    font-size: 24px;
  }
}

div.scheduleDetailsModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(32, 32, 38, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.scheduleDetailsModal div.contents {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: var(--bg-color);
  width: 50%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
}
div.scheduleDetailsModal div.contents div.top {
  position: relative;
  width: 100%;
  height: 269px;
  background-size: cover;
  background-position: top;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-repeat: no-repeat;
}
div.scheduleDetailsModal div.contents div.top div.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  cursor: pointer;
}
div.scheduleDetailsModal div.contents div.top div.closeIcon svg {
  color: var(--white-color);
  width: 20px !important;
  height: 20px !important;
}
div.scheduleDetailsModal div.contents div.bottom {
  background-color: var(--secondary-bg-color);
  padding: 40px 30px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 300px;
  overflow-y: scroll;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar {
  display: block;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar-track {
  background-color: var(--grey-color);
}
div.scheduleDetailsModal div.contents div.bottom div.channelLogo {
  width: 80px;
  height: 80px;
  background-color: rgba(150, 153, 163, 0.16);
  border-radius: 6px;
  margin-bottom: 10px;
}
div.scheduleDetailsModal div.contents div.bottom div.channelLogo div.logoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.scheduleDetailsModal div.contents div.bottom div.channelLogo div.logoContainer img {
  display: block;
  width: 40px;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData {
  color: var(--text-color);
}
div.scheduleDetailsModal div.contents div.bottom div.metaData h1.title, div.scheduleDetailsModal div.contents div.bottom div.metaData .title.h1 {
  font-size: 30px;
  margin-bottom: 10px;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData span.startTime, div.scheduleDetailsModal div.contents div.bottom div.metaData span.endTime {
  color: var(--inner-text-color);
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 10px;
  display: inline-block;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData div.additionalInfo span.caption {
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 8px;
  padding: 2px 4px;
  font-weight: 700;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-right: 15px;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData div.additionalInfo span.rating {
  background-color: hsla(226, 7%, 61%, 0.32);
  border-radius: 3px;
  color: var(--white-color);
  font-size: 12px;
  padding: 1px 8px;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData p.description {
  margin-top: 10px;
  font-size: 15px;
}

div.liveChannelsContainer {
  background-color: var(--secondary-bg-color);
  position: relative;
}
div.liveChannelsContainer div.topContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 580px;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer {
    min-height: 508px;
  }
}
div.liveChannelsContainer div.topContainer div.wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.wrapper {
    flex-direction: column-reverse;
  }
}
div.liveChannelsContainer div.topContainer div.bgImage {
  padding: 200px 0;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100%;
  max-height: 800px;
  position: absolute;
  z-index: 3;
  bottom: 0;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.bgImage {
    padding: 200px 0 150px;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.bgImage {
    display: none;
  }
}
div.liveChannelsContainer div.topContainer div.gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  z-index: 4;
  height: 500px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 94.15%);
}
div.liveChannelsContainer div.topContainer div.bannerContainer {
  position: relative;
  margin-bottom: 60px;
  width: 55%;
  margin-right: 30px;
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.bannerContainer {
    width: 100%;
  }
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info {
  color: var(--text-color);
  position: relative;
  z-index: 9;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel {
  background-color: #f52d2d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 3px;
  width: 3.5rem;
  border-radius: 3px;
  margin-bottom: 10px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel span {
  font-weight: 600;
  font-size: 11px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel svg {
  width: 16px !important;
  height: 16px !important;
  margin-right: 6px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.channelLogo {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.channelLogo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info h1.title, div.liveChannelsContainer div.topContainer div.bannerContainer div.info .title.h1 {
  font-size: 30px;
  margin-bottom: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo h6.timeLeft, div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo .timeLeft.h6 {
  color: var(--white-color);
  font-size: 15px;
  margin-right: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo span.caption {
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 10px;
  padding: 2px 4px;
  font-weight: 700;
  border-radius: 3px;
  outline: none;
  border: none;
  margin-right: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo span.rating {
  background-color: hsla(226, 7%, 61%, 0.32);
  border-radius: 4px;
  color: var(--white-color);
  color: var(--text-color);
  font-size: 12px;
  font-weight: 700;
  padding: 1px 8px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description {
  max-width: 100%;
  font-size: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readMore, div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readLess {
  color: var(--button-color);
  margin-left: 6px;
  cursor: pointer;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readMore:hover, div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readLess:hover {
  text-decoration: underline;
}
div.liveChannelsContainer div.topContainer div.videoContainer {
  right: 40px;
  z-index: 9;
  width: 70%;
  top: 90px;
  border-radius: 15px;
}
@media all and (max-width: 1600px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 70%;
  }
}
@media all and (max-width: 1400px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 60%;
  }
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    margin-bottom: 20px;
    width: 100%;
  }
}
div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
  height: 408px;
  border-radius: 15px;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 242px;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 300px;
  }
}
@media all and (max-width: 480px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 200px;
  }
}
div.liveChannelsContainer div.topContainer div.videoContainer video {
  border-radius: 15px;
}
div.liveChannelsContainer div.topContainer div.videoContainer div.videoImageContainer {
  width: 100%;
  height: 100%;
  background: var(--bg-color);
}
div.liveChannelsContainer div.topContainer div.videoContainer div.videoImageContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  height: 92%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 15.22%, rgba(16, 20, 31, 0.4) 100%);
}
div.liveChannelsContainer div.topContainer div.videoContainer div.videoImageContainer div.banner-image {
  height: 100%;
}
div.liveChannelsContainer div.topContainer div.videoContainer div.videoImageContainer div.banner-image img {
  width: 100%;
  height: 100%;
}
div.liveChannelsContainer div.wrapper {
  overflow: hidden;
}
div.liveChannelsContainer div.wrapper div.categories {
  margin-bottom: 30px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.liveChannelsContainer div.wrapper div.categories::-webkit-scrollbar {
  display: none;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons {
  width: 1200px;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button {
  background-color: var(--black-color);
  padding: 5px 10px;
  color: var(--white-color);
  margin-right: 10px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 30px;
  cursor: pointer;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button.active {
  background-color: var(--white-color);
  color: var(--black-color);
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button:last-child {
  margin-right: 0;
}
div.liveChannelsContainer div.wrapper div.scheduleTime {
  width: 90%;
  margin: 0 0 6px auto;
  display: flex;
  align-items: center;
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleTime div.time {
  border-left: 2px solid var(--border-color);
  padding-left: 10px;
  width: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleTime div.time span {
  color: var(--inner-text-color);
  font-size: 10px;
  font-weight: 700;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer {
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.rightGradient {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 10rem;
  z-index: 9;
  background: linear-gradient(95deg, rgba(0, 0, 0, 0), #000 101.15%);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10rem;
  z-index: 9;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 62.15%);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel {
  display: flex;
  align-items: center;
  width: 3000px;
  height: 75px;
  margin-bottom: 4px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime {
  position: absolute;
  top: -26px;
  right: 0;
  background-color: #f52d2d;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 3px 3px;
  width: 5rem;
  border-radius: 3px;
  color: var(--white-color);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime span {
  font-weight: 600;
  font-size: 11px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime div.icon {
  line-height: 0;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime div.icon svg {
  width: 16px !important;
  height: 16px !important;
  margin-right: 6px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left {
  width: 5%;
  margin-right: 4px;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard {
  width: 100%;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard div.imageContainer {
  width: 100%;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 4px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right {
  display: flex;
  align-items: center;
  width: 95%;
  height: 100%;
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem {
  background-color: rgb(25, 25, 25);
  padding: 25px 20px;
  border-radius: 4px;
  margin-right: 4px;
  height: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active {
  border: 2px solid var(--text-hover);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active div.progress {
  display: block;
  position: absolute;
  background: linear-gradient(95deg, rgba(25, 25, 25, 0), #363434 101.15%);
  width: 10%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active div.progress div.currentTime {
  display: none;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem:hover div.metaData {
  display: flex;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.progress {
  display: none;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem h1, div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem .h1 {
  font-size: 16px;
  color: var(--white-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 9;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData {
  display: none;
  justify-content: space-between;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData p.description {
  position: absolute;
  z-index: 99;
  color: var(--inner-text-color);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData div.infoIcon {
  text-align: right;
  position: absolute;
  z-index: 9999;
  right: 10px;
  top: 10px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData div.infoIcon svg {
  color: var(--inner-text-color);
  width: 20px !important;
  height: 20px !important;
}

div.LayoutsContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
div.LayoutsContainer div.routesContainer {
  flex: 1 0 auto;
}
div.LayoutsContainer div.resubscriptionInfo {
  display: flex;
  z-index: 9;
  width: 100%;
  height: 45px;
  margin-top: 75px;
  background: #fff;
}
div.LayoutsContainer div.resubscriptionInfo .resubscribeMessage {
  width: 85%;
  background: #fff;
  color: red;
  padding: 5px;
  text-align: center;
  font-weight: 600;
  margin: 5px;
}
div.LayoutsContainer div.resubscriptionInfo .resubscribeButton {
  width: 15%;
  background-color: #000;
  color: #fff;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  margin: 2px;
  transition: background-color 0.3s ease, filter 0.3s ease;
}
div.LayoutsContainer div.resubscriptionInfo .resubscribeButton:hover {
  filter: brightness(2);
}
@media (min-width: 427px) and (max-width: 769px) {
  div.LayoutsContainer div.resubscriptionInfo .resubscribeMessage {
    margin: 5px;
    padding: 5px;
  }
}
@media (max-width: 426px) {
  div.LayoutsContainer div.resubscriptionInfo {
    flex-direction: column;
    font-size: medium;
    height: 110px;
    padding: 5px;
  }
  div.LayoutsContainer div.resubscriptionInfo .resubscribeMessage {
    width: 100%;
    margin: 0px;
  }
  div.LayoutsContainer div.resubscriptionInfo .resubscribeButton {
    width: 100%;
    margin: 5px 0;
  }
  div.LayoutsContainer div.resubscriptionInfo .resubscribeMessage {
    padding: 5px;
    margin: 0px;
    font-size: small;
  }
}
@media (max-width: 321px) {
  div.LayoutsContainer div.resubscriptionInfo {
    height: 130px;
  }
}

div.subscriptionDetailsContainer {
  padding: 0 100px 10px 100px;
  color: whitesmoke;
}
div.subscriptionDetailsContainer div.planContainer {
  box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
  border-radius: 8px;
  margin: 20px auto;
  padding: 20px;
}
div.subscriptionDetailsContainer div.planContainer h3, div.subscriptionDetailsContainer div.planContainer .h3, div.subscriptionDetailsContainer div.planContainer h2, div.subscriptionDetailsContainer div.planContainer .h2 {
  text-align: center;
  padding: 15px;
}
div.subscriptionDetailsContainer div.planContainer h3, div.subscriptionDetailsContainer div.planContainer .h3 {
  font-weight: 200;
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer {
  width: 100%;
  overflow: auto;
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer table {
  border-collapse: collapse;
}
@media all and (min-width: 980px) {
  div.subscriptionDetailsContainer div.planContainer div.tableContainer table {
    width: 100%;
  }
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer tr:nth-child(2) td:nth-child(1) {
  min-width: 123px;
  text-align: center;
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer tr:nth-child(2n+1) {
  background-color: rgba(48, 50, 56, 0.3568627451);
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer tr:nth-child(2n) {
  background-color: rgba(32, 32, 33, 0.3568627451);
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer tr:nth-child(3) {
  background-color: rgba(233, 184, 95, 0.1882352941);
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer td {
  text-align: center;
  padding: 15px 20px;
  min-width: 123px;
}
div.subscriptionDetailsContainer div.planContainer div.tableContainer td:nth-child(1) {
  min-width: 150px;
  text-align: left;
  border: none;
}
@media all and (max-width: 1024px) {
  div.subscriptionDetailsContainer {
    padding: 0 0 10px;
  }
}

div.ceyFlixSubscriptionPage {
  padding: 100px 0 0;
}
div.ceyFlixSubscriptionPage div.subscriptionFeatures {
  padding: 10px 100px 100px;
  color: var(--text-color);
}
div.ceyFlixSubscriptionPage div.subscriptionFeatures h3, div.ceyFlixSubscriptionPage div.subscriptionFeatures .h3 {
  width: 100%;
  margin: 0 0 30px;
  text-align: center;
  color: var(--inner-text-color);
}
div.ceyFlixSubscriptionPage div.subscriptionFeatures div.subscriptionConditionList ul.conditionList li {
  color: var(--inner-text-color);
  margin: 13px 0;
  list-style: decimal;
}
@media all and (max-width: 1024px) {
  div.ceyFlixSubscriptionPage div.subscriptionFeatures {
    padding: 10px 0 80px;
    margin: 0 15px 0 15px;
  }
}

div.ceyFlixHelpCenter {
  padding: 100px 0 80px;
  color: var(--text-color);
}
div.ceyFlixHelpCenter p {
  color: var(--inner-text-color);
  margin-left: 20px;
  margin-top: 20px;
}
div.ceyFlixHelpCenter h2, div.ceyFlixHelpCenter .h2 {
  text-align: center;
  padding-bottom: 60px;
}
div.ceyFlixHelpCenter div.helpPagePoints {
  background: rgba(55, 55, 55, 0.16);
  border-radius: 15px;
  padding: 30px 0;
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint {
  padding: 40px 100px;
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint h3, div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint .h3 {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--inner-text-color);
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ul {
  padding: 20px;
  margin-left: 70px;
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ul li {
  list-style: square;
  padding: 10px;
  color: var(--text-hover);
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ul li:last-child {
  padding-bottom: 0px;
}
@media all and (max-width: 1024px) {
  div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ul {
    margin-left: 0px;
  }
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ol {
  padding: 20px;
  margin-left: 70px;
}
div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ol li {
  list-style: decimal;
  padding: 10px;
  color: var(--text-hover);
}
@media all and (max-width: 1024px) {
  div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint ol {
    margin-left: 0px;
  }
}
@media all and (max-width: 1024px) {
  div.ceyFlixHelpCenter div.helpPagePoints div.helpPoint {
    padding: 40px 20px;
  }
}
div.ceyFlixHelpCenter .paddingContainer {
  padding: 20px 100px;
}
@media all and (max-width: 1024px) {
  div.ceyFlixHelpCenter .paddingContainer {
    padding: 10px 20px;
  }
}
div.ceyFlixHelpCenter p.note {
  color: var(--inner-text-color);
  margin-left: 20px;
  margin-top: 0px;
}

/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::-moz-placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-appearance: none;
          appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  -moz-appearance: none;
       appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder, .form-floating > .form-control-plaintext::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown), .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
          mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
          mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
     object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
     object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
       column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: 0.25rem !important;
       column-gap: 0.25rem !important;
}

.column-gap-2 {
  -moz-column-gap: 0.5rem !important;
       column-gap: 0.5rem !important;
}

.column-gap-3 {
  -moz-column-gap: 1rem !important;
       column-gap: 1rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
       column-gap: 1.5rem !important;
}

.column-gap-5 {
  -moz-column-gap: 3rem !important;
       column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-sm-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-sm-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-md-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-md-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-md-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-md-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-lg-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-lg-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.general-zindex {
  z-index: 900;
}

:focus {
  outline: none;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: var(--bs-heading-font-family);
}

h1, .h1 {
  font-size: var(--bs-heading-1, 2.5rem);
}

h2, .h2 {
  font-size: var(--bs-heading-2, 2rem);
}

h3, .h3 {
  font-size: var(--bs-heading-3, 1.75rem);
}

h4, .h4 {
  font-size: var(--bs-heading-4, 1.5rem);
}

h5, .h5 {
  font-size: var(--bs-heading-5, 1.25rem);
}

h6, .h6 {
  font-size: var(--bs-heading-6, 1rem);
}

a {
  transition: color 400ms ease, background 400ms ease;
  color: var(--bs-primary);
  text-decoration: none;
}

h1 a, .h1 a, h2 a, .h2 a, h3 a, .h3 a, h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
  color: inherit;
}

img {
  max-width: 100%;
}

.iq-scroller-effect {
  /* Scroll Track */
  /* Scroll Handle */
  /* Scroll Handle on hover */
}
.iq-scroller-effect ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 20px;
}
.iq-scroller-effect ::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 20px;
}
.iq-scroller-effect ::-webkit-scrollbar-thumb {
  background: #dee2e6;
  border-radius: 20px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  cursor: pointer;
}
.iq-scroller-effect ::-webkit-scrollbar-thumb:hover {
  background: #e1e5e9;
  border-radius: 20px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

@media (max-width: 767px) {
  h5, .h5 {
    font-size: 1rem;
  }
}
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

[data-bs-theme=dark] {
  --bs-gray-900: #141314;
  --bs-gray-900-rgb: 20, 19, 20;
  --bs-gray-800: #343a40;
}

[data-bs-theme=hotstar] {
  --bs-primary: #0959E4;
  --bs-primary-rgb: 22, 121, 231;
  --bs-secondary-color: #fff;
  --bs-secondary-color-rgb: 255, 255, 255;
  --bs-body-color: #fff;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-heading-color: #fff;
  --bs-body-bg: #0F1014;
  --bs-body-bg-rgb: 15, 16, 20;
  --bs-gray-900: #191923;
  --bs-gray-900-rgb: 25, 25, 35;
  --bs-gray-800: #252833;
  --bs-gray-800-rgb: 37, 40, 51;
}

[data-bs-theme=amazonprime] {
  --bs-primary: #1A98FF;
  --bs-primary-rgb: 26, 152, 255;
  --bs-secondary-color: #fff;
  --bs-secondary-color-rgb: 255, 255, 255;
  --bs-body-color: #fff;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-heading-color: #fff;
  --bs-body-bg: #00050D;
  --bs-body-bg-rgb: 0, 5, 13;
  --bs-gray-900: #1D2127;
  --bs-gray-900-rgb: 29, 33, 39;
  --bs-gray-800: #191E25;
  --bs-gray-800-rgb: 25, 30, 37;
}

[data-bs-theme=hulu] {
  --bs-primary: #3ee783;
  --bs-primary-rgb: 62, 231, 131;
  --bs-secondary-color: #fff;
  --bs-secondary-color-rgb: 255, 255, 255;
  --bs-body-color: #fff;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-heading-color: #fff;
  --bs-body-bg:#0B0C0F;
  --bs-body-bg-rgb: 11, 12, 15;
  --bs-gray-900: #272C35;
  --bs-gray-900-rgb: 39, 44, 53;
  --bs-gray-800: #242830;
  --bs-gray-800-rgb: 36, 40, 48;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: Roboto, sans-serif, Liberation Mono, Courier New, monospace;
  font-weight: 500;
  line-height: 1.2;
  color: #fff;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #fff;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 0;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::-moz-placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-appearance: none;
          appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  -moz-appearance: none;
       appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder, .form-floating > .form-control-plaintext::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown), .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

div.dropdowns {
  position: absolute;
  top: 91px;
  right: 40px;
  border-radius: 5px;
  z-index: 99999;
  display: none;
  margin: 0;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #000;
  background-clip: padding-box;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  border-radius: 0px;
  border-bottom: 1px solid #343a40;
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: #fff;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
          mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
          mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
     object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
     object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
       column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: 0.25rem !important;
       column-gap: 0.25rem !important;
}

.column-gap-2 {
  -moz-column-gap: 0.5rem !important;
       column-gap: 0.5rem !important;
}

.column-gap-3 {
  -moz-column-gap: 1rem !important;
       column-gap: 1rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
       column-gap: 1.5rem !important;
}

.column-gap-5 {
  -moz-column-gap: 3rem !important;
       column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-sm-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-sm-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-md-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-md-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-md-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-md-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-lg-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-lg-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.iq-accordian .iq-accordian-block {
  margin-bottom: 1.875rem;
  padding: 0;
  overflow: hidden;
  background: var(--bs-gray-900);
  border-radius: 0.3125rem;
}
.iq-accordian .iq-accordian-block .iq-accordian-title {
  border-radius: 0.25rem;
  position: relative;
  padding: 1.875rem 4.688rem 1.875rem 1.875rem;
  line-height: normal;
  cursor: pointer;
  display: flex;
  text-align: left;
  color: var(--bs-white);
  font-weight: 600;
  -webkit-text-decoration: 600;
          text-decoration: 600;
}
.iq-accordian .iq-accordian-block .iq-accordian-title .iq-icon-right {
  position: absolute;
  right: 1.875rem;
  top: 1.563rem;
  text-align: center;
  background: var(--bs-primary);
  height: 2.188rem;
  width: 2.188rem;
}
.iq-accordian .iq-accordian-block .iq-accordian-title .iq-icon-right i {
  color: var(--bs-white);
  vertical-align: middle;
  line-height: 2.188rem;
}
.iq-accordian .iq-accordian-block .iq-accordian-title .iq-icon-right i.active {
  display: none;
}
.iq-accordian .iq-accordian-block .iq-accordian-details {
  padding: 1.875rem;
  border: 0.0625rem solid;
  border-width: 0.0625rem 0 0 0;
  border-image-source: linear-gradient(270deg, rgba(38, 37, 37, 0) -1.25%, #6c757d 43.69%, rgba(38, 37, 37, 0) 99.08%);
  border-image-slice: 1;
}
.iq-accordian .iq-accordian-block.iq-active .iq-accordian-title .iq-icon-right i.active {
  display: block;
}
.iq-accordian .iq-accordian-block.iq-active .iq-accordian-title .iq-icon-right i.inactive {
  display: none;
}

.alert .bi {
  width: 1rem;
  height: 1rem;
}

:root {
  --animate-duration: 1s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.animate__fadeOut {
  animation-name: fadeOut;
}

.animate__animated {
  animation-duration: 1s;
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.iq-custom-badge {
  position: relative;
  padding: 0 1rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  margin-right: 1rem;
}
.iq-custom-badge:last-child {
  margin-right: 0;
}
.iq-custom-badge .iq-cancel-btn {
  position: absolute;
  top: auto;
  right: -0.5rem;
  padding: 0;
  line-height: 0;
}

.badge {
  line-height: normal;
}

.btn-icon {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.btn-icon.btn .btn-inner {
  display: inline-flex;
}
.btn-icon.btn .btn-inner svg {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  height: 1.875rem;
  width: 1.875rem;
  padding: 0;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.btn-icon.btn-sm .btn-inner svg, .btn-group-sm > .btn-icon.btn .btn-inner svg {
  width: 1rem;
  height: 1rem;
}
.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  height: 3rem;
  width: 3rem;
  padding: 0;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.btn-icon.btn-lg .btn-inner svg, .btn-group-lg > .btn-icon.btn .btn-inner svg {
  width: 2.5rem;
  height: 2.5rem;
}

.btn-setting {
  padding: 0.5rem;
  z-index: 1000;
  top: 50%;
}

.customizer-btn {
  transform: rotate(-45deg);
}

.iq-qty-btn .btn:focus {
  box-shadow: unset;
}
.iq-qty-btn .input-display {
  width: 3.5rem;
  cursor: text;
}
.iq-qty-btn .input-display:hover {
  color: var(--bs-body-color) !important;
  background: transparent !important;
}

.iq-qty-btn .input-display.btn-outline-primary:focus {
  border-color: var(--bs-primary);
}
.iq-qty-btn .input-display.btn-outline-primary:hover {
  color: var(--bs-primary) !important;
  background: transparent !important;
}

.iq-qty-btn .input-display.btn-outline-secondary:focus {
  border-color: var(--bs-secondary);
}
.iq-qty-btn .input-display.btn-outline-secondary:hover {
  color: var(--bs-secondary) !important;
  background: transparent !important;
}

.iq-status-switch .form-check-input:checked {
  border-color: var(--bs-info);
  background-color: var(--bs-info);
}
.iq-status-switch .form-check-input:not(:checked) {
  border-color: var(--bs-danger);
  background-color: var(--bs-danger);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input {
  width: 0.875em;
  height: 0.875em;
}
.form-check-input:focus {
  box-shadow: none;
}

.form-range::-webkit-slider-thumb {
  cursor: pointer;
}

.custom-form-field input {
  padding: 0 1rem;
  height: 54px;
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  border-radius: 0;
  color: inherit;
}
.custom-form-field input:focus {
  border-color: var(--bs-primary);
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  color: inherit;
}
.custom-form-field textarea {
  padding: 1rem 1rem 0;
  height: 54px;
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  border-radius: 0;
  min-height: 9.375em;
  color: inherit;
}
.custom-form-field textarea:focus {
  border-color: var(--bs-primary);
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  color: inherit;
}
.custom-form-field input[type=checkbox] {
  width: auto;
  margin-right: 10px;
  height: auto;
}
.custom-form-field .mail-box input {
  border: 1px solid #dbdfe7;
}
.custom-form-field .mail-box input:focus {
  border-color: var(--bs-primary);
}
.custom-form-field .mail-box.style-bordered input {
  background-color: transparent;
  border-color: var(--bs-body-color);
  color: var(--bs-body-color);
}
.custom-form-field .mail-box.style-bordered input:focus {
  border-color: var(--bs-primary);
}
.custom-form-field .select2-container .select2-selection--single {
  border-color: transparent;
}
.custom-form-field .select2-container.select2-container--open .select2-selection--single {
  border-color: var(--bs-primary);
}

.form-control:focus {
  color: var(--bs-heading-color);
  border-color: var(--bs-primary);
}

.form-group {
  margin-bottom: 1rem;
}
.form-group .input-group-text {
  color: #adb5bd;
}

.form-group-alt.input-group {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
}
.form-group-alt.input-group .input-group-text {
  color: #adb5bd;
}
.form-group-alt.input-group > :not(:first-child):not(.dropdown-menu) {
  box-shadow: unset;
}
.form-group-alt.input-group > :not(:first-child):not(.dropdown-menu).input-group-text {
  border: 0;
  padding-left: 0;
}
.form-group-alt.input-group > :not(:last-child):not(.dropdown-menu) {
  box-shadow: unset;
  padding-right: 0;
}
.form-group-alt.input-group > :not(:last-child):not(.dropdown-menu).input-group-text {
  border: 0;
}
.form-group-alt.input-group:focus-within {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.form-group-alt .form-control {
  border: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.form-group-alt .form-control.is-valid {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.form-group-alt .form-control.is-valid:focus {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.form-group-alt .form-control.is-invalid:focus {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.form-group-alt .form-control:focus {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.form-select {
  box-shadow: none;
}
.form-select:focus {
  box-shadow: none;
}

.form-control[readonly] {
  background-color: var(--bs-body-bg);
}

form textarea.form-control {
  resize: none;
  padding: 1rem;
  height: 150px;
}

.form-collapse .edit-date-icon {
  color: #6c757d;
}
.form-collapse .edit-date-icon.collapsed {
  color: var(--bs-primary);
}

.cursor-pointer {
  cursor: pointer;
}

.form-group-filled .input-group-text,
.form-group-filled .form-control {
  background-color: var(--bs-body-bg);
}

#top-tab-list {
  margin-bottom: 60px;
}
#top-tab-list li a {
  color: var(--bs-primary);
  background: var(--bs-primary-tint-80);
  display: block;
  padding: 0.938rem;
  text-decoration: none;
  font-size: 1.125rem;
  border-radius: 0.375rem;
}
#top-tab-list li a .iq-icon {
  display: inline-block;
  text-align: center;
  height: 3.125rem;
  width: 3.125rem;
  line-height: 3.125rem;
  font-size: 1.25rem;
  border-radius: 50rem;
  background: var(--bs-primary);
  color: #fff;
}
#top-tab-list li.active a {
  background: var(--bs-primary);
  color: #fff;
}
#top-tab-list li.active a .iq-icon {
  background: #fff;
  color: var(--bs-primary);
}
#top-tab-list li.active.done a {
  background: #198754;
}
#top-tab-list li.active.done a i {
  color: #198754;
}
#top-tab-list li#confirm.active a {
  background: #198754;
}
#top-tab-list li#confirm.active a i {
  color: #198754;
}

#form-wizard1 fieldset:not(:first-of-type) {
  display: none;
}

.table {
  overflow: hidden;
}
.table thead {
  white-space: nowrap;
}
.table thead tr {
  background-color: #fff;
}
.table thead tr th {
  text-transform: capitalize;
}
.table tbody tr td {
  vertical-align: middle;
}
.table tbody tr td .iq-sub-label {
  margin-bottom: 0.125rem;
}
.table.table-dark tbody tr td {
  color: #fff;
  vertical-align: middle;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: transparent;
}

.table-primary {
  --bs-table-bg: var(--bs-primary-tint-80);
}

.table-responsive {
  /* Scroll Track */
  /* Scroll Handle */
  /* Scroll Handle on hover */
}
.table-responsive::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 20px;
}
.table-responsive::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 20px;
}
.table-responsive::-webkit-scrollbar-thumb {
  background: rgba(33, 37, 41, 0.2);
  border-radius: 20px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  cursor: pointer;
}
.table-responsive::-webkit-scrollbar-thumb:hover {
  background: rgba(33, 37, 41, 0.2);
  border-radius: 20px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  cursor: pointer;
}
.table-responsive .dataTables_wrapper .row .dataTables_length {
  padding-left: 1.5rem;
}
.table-responsive .dataTables_wrapper .row .dataTables_filter,
.table-responsive .dataTables_wrapper .row .dataTables_paginate {
  padding-right: 1.5rem;
}
.table-responsive .dataTables_wrapper .row .dataTables_info {
  padding-left: 1.5rem;
  padding-top: unset;
}

.table-dark td {
  color: #fff !important;
  vertical-align: middle;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  white-space: nowrap;
}

table.custom-table thead {
  vertical-align: middle;
}
table.custom-table thead th {
  border: none;
  padding: 1.5em 1.25em;
  text-transform: capitalize;
  color: var(--bs-white);
  background: rgba(16, 16, 16, 0.9);
}
table.custom-table tbody td {
  border: none;
  padding: 1.5em 1.25em;
  text-transform: capitalize;
  color: var(--bs-white);
  background: var(--bs-gray-900);
  border-bottom: 0.625em solid var(--bs-body-bg);
}
table.table-border thead tr {
  background: var(--bs-gray-900);
}
table.table-border thead tr th {
  background: transparent;
  padding: 1rem;
}
table.table-border tbody tr td {
  padding: 1rem;
}
table.table-border tfoot tr td {
  padding: 1rem;
  border-top: 1px solid rgba(var(--bs-black-rgb), 0.1);
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-primary);
}

.nav-tabs .nav-link.active {
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.nav-pills .nav-link.active {
  color: var(--bs-white);
  background-color: var(--bs-primary);
}

.nav-slider {
  position: relative;
}
.nav-slider .nav-link.active {
  color: var(--bs-white);
  background-color: var(--bs-primary);
}
.nav-slider .nav-item {
  z-index: 3;
}
.nav-slider .nav-slider-thumb {
  z-index: 1 !important;
  width: 100%;
  color: var(--bs-primary);
  background: var(--bs-primary);
}

.nav-underline.nav-pills .nav-link {
  color: var(--bs-body-color);
  font-weight: 500;
  border-radius: 0;
  padding-bottom: 1.375rem;
}
.nav-underline.nav-pills .nav-link.active, .nav-underline.nav-pills .nav-link:hover {
  background-color: transparent;
  color: var(--bs-primary);
}

.navbar-brand img.logo {
  height: 2.375rem;
  max-height: 2.375rem;
}
.navbar-brand svg {
  width: 10rem;
}

header.header-sticky,
header .header-sticky {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  -webkit-backdrop-filter: blur(0.085em);
          backdrop-filter: blur(0.085em);
  width: 100%;
  display: inline-block;
  transition: all 0.3s ease-out 0s;
  background: rgba(0, 0, 0, 0.1607843137);
}
header.header-sticky.sticky,
header .header-sticky.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  animation: header-slide-down 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-fill-mode: initial;
  transition: all 0.3s ease-out 0s;
}
header .navbar-toggler-icon {
  background-image: none;
  position: relative;
  background: none;
  text-align: center;
  background: var(--bs-primary);
}
header .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
  width: 1360px;
  padding: 2rem 1rem;
}
header .mega-menu-content .iq-nav-menu li ul.mega-menu-item li {
  width: 16.4%;
  display: inline-block;
  border-bottom: 0;
}
header .mega-menu-content .iq-nav-menu li ul.mega-menu-item li .nav-link span {
  font-size: 1rem;
  font-weight: 400;
  padding: 1.375rem 0.9375rem;
  transition: color 400ms ease, background 400ms ease;
}
header .mega-menu-content .iq-nav-menu li ul.mega-menu-item li .nav-link:hover span {
  color: var(--bs-primary);
}
header.header-center-home .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
  left: 30%;
  transform: translateX(-30%);
}
header .navbar .sub-nav.mega-menu-item li:hover > a::before,
header .navbar .sub-nav.mega-menu-item li > a.active::before {
  content: none;
}

/*===================
Merchandise Header
========================*/
.header-merchandise .navbar .horizontal-nav {
  padding-left: 0;
}
.header-merchandise .navbar > .container-fluid, .header-merchandise .navbar > .container-sm, .header-merchandise .navbar > .container-md, .header-merchandise .navbar > .container-lg, .header-merchandise .navbar > .container-xl, .header-merchandise .navbar > .container-xxl,
.header-merchandise .navbar > .container {
  justify-content: start;
}
.header-merchandise .header-top {
  padding: 1rem 0;
  background-color: #141414;
}
.header-merchandise .header-bottom {
  position: relative;
  background: rgba(var(--bs-black-rgb), 0.5);
  -webkit-backdrop-filter: blur(0.625em);
          backdrop-filter: blur(0.625em);
  display: inline-block;
  width: 100%;
  z-index: 9999;
  transition: all 0.3s ease-out 0s;
}
.header-merchandise .iq-nav-menu li:first-child .nav-link {
  padding-left: 0;
}
.header-merchandise .iq-nav-menu li .sub-nav li .nav-link {
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.iq-meta-menu {
  gap: 1.5625rem;
}
.iq-meta-menu li a {
  color: var(--bs-white);
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.0625rem;
}
.iq-meta-menu li a :hover {
  color: var(--bs-primary);
}

.search-box-2 {
  position: relative;
}
.search-box-2 input {
  height: auto;
  background-color: var(--bs-body-bg);
  padding: 0.5rem 1rem 0.5rem 2.5rem;
}
.search-box-2 .search-btn {
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  top: 48%;
  left: 1rem;
  transform: translateY(-50%);
}

/*==========
sidebar cart
===============*/
.sidebar-cart .cart-items-list {
  height: 29rem;
  overflow-y: auto;
}
.sidebar-cart .offcanvas-body {
  background-color: var(--bs-gray-900);
}

/*====================
Slide Down Animation
=========================*/
@keyframes header-slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (max-width: 1800px) {
  header .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
    width: 1190px;
  }
}
@media (max-width: 1600px) {
  header .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
    width: 1024px;
  }
}
@media (max-width: 1280px) {
  header .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
    width: 980px;
  }
  header.header-center-home .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
    transform: translateX(-25%);
  }
  header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav.mega-menu-item {
    max-width: 980px;
  }
}
@media (max-width: 1199px) {
  header .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
    width: auto;
  }
  header .mega-menu-content .iq-nav-menu li ul.mega-menu-item li {
    width: 100%;
    display: inline-block;
  }
  header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav.mega-menu-item {
    max-width: inherit;
    box-shadow: none;
    padding: 0;
    max-width: 100%;
    margin-left: 0;
  }
  header.header-center-home .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
    left: 0;
    transform: none;
  }
}
@media (min-width: 1200px) {
  header .mega-menu-content .iq-nav-menu li ul.mega-menu-item li .nav-link span {
    padding-bottom: 0;
  }
  header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav.mega-menu-item {
    max-width: 1360px;
  }
  header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav.mega-menu-item li a.nav-link {
    text-align: center;
    font-weight: 500;
  }
  header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav.mega-menu-item li a.nav-link::after {
    display: none;
  }
  header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav.mega-menu-item li a.nav-link img {
    box-shadow: 0px 0px 87.3px 2.7px rgba(var(--bs-black-rgb), 0.06);
    transition: all 0.3s ease-in-out;
  }
  header ul.sub-nav.mega-menu-item {
    overflow: auto;
  }
  header.header-center .iq-nav-menu li > ul.mega-menu-item {
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
  }
  header.header-center .iq-nav-menu > li:first-child {
    position: static;
  }
}
.iq-search-bar.dropdown-menu {
  border: none;
  min-width: 25em;
  left: auto;
  right: 0;
}
.iq-search-bar .search-input {
  height: 4em;
  padding: 0 1em;
  border: none;
  border-radius: 0;
  color: var(--bs-white);
}
.iq-search-bar .input-group-text {
  padding: 0;
  background-color: transparent;
}
.iq-search-bar .search-submit {
  color: var(--bs-white);
  cursor: pointer;
  background: var(--bs-primary);
  border: none;
  outline: none;
  box-shadow: none;
  height: 3em;
  width: 3em;
  position: absolute;
  background: transparent;
  top: 50%;
  transform: translateY(-50%);
}

.iq-responsive-menu {
  padding: 1.0625rem 0;
}

.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  justify-content: center;
}

.horizontal-nav .offcanvas-header {
  display: none;
  pointer-events: none;
}

.logoutAndLogin {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 10px 0;
  border-top: 1px solid white;
}

header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav li a.nav-link .menu-icon {
  position: absolute;
  left: auto;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav li a.nav-link .menu-icon .toggle-menu {
  top: 50%;
  transform: translateY(-50%);
}
header .navbar .sub-nav li {
  padding: 0 0.9375em;
  margin-right: 0;
}
header .navbar .sub-nav li:hover > a,
header .navbar .sub-nav li > a.active {
  color: var(--bs-white) !important;
}
header .navbar .sub-nav li > a::before {
  position: absolute;
  content: "";
  background: var(--bs-primary);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0.25em;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
  transition: all 0.5s ease-in-out;
}
header .navbar .sub-nav li > a::after {
  position: absolute;
  content: "";
  background: transparent -webkit-gradient(linear, right top, left top, from(rgba(var(--bs-primary-rgb), 0)), to(rgba(var(--bs-primary-rgb), 0.3)));
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
  transition: all 0.5s ease-in-out;
  z-index: -1;
}
header .navbar .sub-nav li:hover > a::before,
header .navbar .sub-nav li > a.active::before {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 0.5s ease-in-out;
}
header .navbar .sub-nav li:hover > a::after,
header .navbar .sub-nav li > a.active::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 0.5s ease-in-out;
}
header .navbar .right-panel .navbar-nav .nav-item .nav-link {
  color: #fff;
}
header .navbar .right-panel div.dropdowns {
  position: absolute;
  top: 91px;
  right: 40px;
  border-radius: 5px;
  z-index: 99999;
  display: none;
  margin: 0;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #000;
  background-clip: padding-box;
}
header .user-icons {
  height: 2.5rem;
  width: 2.5rem;
  background: #000;
}
header .dropdown-user .user-info {
  border-bottom: 1px solid var(--bs-border-color);
  padding: 1rem 1.25rem;
}
header .dropdown-user .user-info img {
  width: 2.5rem;
  height: 2.5rem;
  -o-object-fit: cover;
     object-fit: cover;
}
header .dropdown-user .iq-sub-card {
  padding: 0.9375rem 1.25rem;
}
header .dropdown-user .iq-sub-card svg,
header .dropdown-user .iq-sub-card i {
  color: var(--bs-white);
  stroke: var(--bs-white);
}
header .dropdown-user .iq-sub-card.iq-logout-2 {
  background: var(--bs-gray-900);
}
header .dropdown-user .iq-sub-card:hover h6, header .dropdown-user .iq-sub-card:hover .h6 {
  color: var(--bs-primary);
}
header .dropdown-search.dropdown-menu[data-bs-popper] {
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  min-width: 20rem;
}
header .search-box .form-group {
  animation: scale-out-hor-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
header .search-box .iq-search-bar .form-control {
  padding-left: 2.8em;
  padding-right: 1.5em;
  background: rgb(41, 39, 39);
  z-index: 0;
}
header .search-box a.show + ul li .form-group {
  animation: scale-in-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.nav .navbar-toggler {
  border: none;
  padding: 0;
  outline: none;
  vertical-align: middle;
}
.nav .navbar-toggler .navbar-toggler-btn {
  width: 30px;
  height: 30px;
  display: block;
  position: relative;
}
.nav .navbar-toggler .navbar-toggler-btn .navbar-toggler-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.7rem;
  height: 2px;
  background-color: var(--bs-white);
  background-image: none;
  display: inline-block;
}
.nav .navbar-toggler .navbar-toggler-btn .navbar-toggler-icon::after, .nav .navbar-toggler .navbar-toggler-btn .navbar-toggler-icon::before {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
  width: 1.7rem;
  height: 2px;
  background-color: var(--bs-white);
  display: inline-block;
}
.nav .navbar-toggler .navbar-toggler-btn .navbar-toggler-icon::after {
  top: 0.6rem;
}
.nav .navbar-toggler .navbar-toggler-btn .navbar-toggler-icon::before {
  top: -0.6rem;
}
.nav .navbar-toggler[aria-expanded=true] .navbar-toggler-btn .navbar-toggler-icon {
  background-color: transparent;
}
.nav .navbar-toggler[aria-expanded=true] .navbar-toggler-btn .navbar-toggler-icon::before {
  top: 0;
  transform: rotate(135deg);
}
.nav .navbar-toggler[aria-expanded=true] .navbar-toggler-btn .navbar-toggler-icon::after {
  top: 0;
  transform: rotate(-135deg);
}

.iq-nav-menu {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1599px) {
  header .navbar .horizontal-nav {
    padding-left: 3rem;
  }
}
@media (max-width: 1499px) {
  header .navbar .horizontal-nav {
    padding-left: 2rem;
  }
  header .navbar .right-panel .navbar-nav .iq-button {
    padding: 1em 1.5em;
  }
}
@media (min-width: 1200px) {
  .iq-nav-menu {
    flex-direction: row;
    margin-bottom: 0;
    display: inline-flex;
    flex-wrap: wrap;
  }
  .iq-nav-menu li {
    position: relative;
    flex-direction: column;
  }
  .iq-nav-menu li > ul {
    position: absolute;
    height: auto !important;
    top: 100%;
    width: 100%;
    min-width: 17.75rem;
    z-index: 999;
    left: auto;
    right: auto;
    padding: 0.5rem 0;
    display: none;
    margin: 0;
    background: #fff;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .iq-nav-menu li > ul ul {
    left: 100%;
    top: 0;
    box-shadow: 0rem 0.25rem 2rem 0rem rgba(0, 0, 0, 0.1);
  }
  .iq-nav-menu li > ul li {
    padding: 0;
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.03);
  }
  .iq-nav-menu li > ul li a {
    transition: var(--sidebar-transition);
    transition-duration: var(--sidebar-transition-duration);
    transition-timing-function: var(--sidebar-transition-function-ease-in-out);
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  }
  .iq-nav-menu li > ul li:last-child {
    border-bottom: none;
  }
  .iq-nav-menu li .iq-header-sub-menu .nav-item > .nav-link:hover {
    padding-left: 1.5rem;
    font-weight: 500;
    transition: var(--sidebar-transition);
    transition-duration: var(--sidebar-transition-duration);
    transition-timing-function: var(--sidebar-transition-function-ease-in-out);
  }
  .iq-nav-menu li:hover > ul {
    display: block;
  }
  .iq-nav-menu li .nav-link.active {
    color: var(--bs-primary);
  }
  .iq-nav-menu li.active > a {
    color: var(--bs-primary);
  }
  .iq-nav-menu > li {
    flex: 0 0 auto;
    position: relative;
    flex: 0 0 auto;
    position: relative;
  }
  .iq-nav-menu > li > a {
    padding: 0.5rem 1.25rem;
    margin: 0;
    border-radius: 0.5rem;
  }
  .iq-nav-menu > li:hover {
    border-color: transparent;
    padding: 0;
  }
  .iq-nav-menu ul {
    border-left: 0;
  }
  .iq-nav-menu > li.iq-ecomm-active {
    padding: 0;
  }
  header .navbar .right-panel .navbar-nav .nav-item .nav-link {
    padding: 1.0625rem var(--bs-navbar-nav-link-padding-x);
  }
  header .navbar .right-panel div.dropdowns {
    position: absolute;
    top: 91px;
    right: 40px;
    border-radius: 5px;
    z-index: 99999;
    display: none;
    margin: 0;
    color: #000;
    text-align: left;
    list-style: none;
    background-color: #000;
    background-clip: padding-box;
  }
  .dropdown-user {
    background: #000;
    width: 17.5rem;
  }
}
@media (max-width: 1199.98px) {
  header .navbar .horizontal-nav {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  header .search-box {
    position: static;
    text-align: end;
  }
  header .search-box .form-group {
    animation: none;
  }
  header .search-box .dropdown-search.dropdown-menu[data-bs-popper] {
    top: 100%;
    transform: translateY(0);
  }
  header .search-box .dropdown-search .form-group {
    width: 260px;
    margin: 0 0 0 auto;
    background: var(--bs-gray-900);
  }
  .navbar-toggler {
    display: block;
  }
  .iq-nav-menu li.active {
    color: var(--bs-primary);
  }
  .iq-nav-menu li a {
    padding-left: 1rem;
  }
  .iq-nav-menu li a .right-icon {
    transition: all 100ms ease-in-out;
    display: none;
  }
  .iq-nav-menu li a:not(.collapsed)[aria-expanded=true] {
    color: var(--bs-primary);
  }
  .iq-nav-menu li a:not(.collapsed)[aria-expanded=true] .right-icon {
    transition: all 100ms ease-in-out;
    transform: rotate(90deg);
  }
  .horizontal-nav .offcanvas-header {
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: auto;
  }
  .horizontal-nav.mobile-offcanvas {
    background: #000000;
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 80%;
    z-index: 1200;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  .horizontal-nav.mobile-offcanvas .sub-nav li {
    padding: 0;
  }
  .horizontal-nav.mobile-offcanvas .sub-nav li > a::before,
  .horizontal-nav.mobile-offcanvas .sub-nav li > a::after {
    content: none;
  }
  .horizontal-nav.mobile-offcanvas .iq-nav-menu li .sub-nav a {
    padding: 1.375rem 0.9375rem;
    color: var(--bs-white);
    border: solid;
    border-width: 0 0 0.0625rem;
    border-image-source: linear-gradient(90deg, rgb(31, 31, 31), rgba(31, 31, 31, 0.6), rgba(31, 31, 31, 0));
    border-image-slice: 1;
    font-weight: 400;
    font-size: 0.875rem;
  }
  .horizontal-nav.mobile-offcanvas .iq-nav-menu li .sub-nav a:hover, .horizontal-nav.mobile-offcanvas .iq-nav-menu li .sub-nav a.active {
    color: var(--bs-primary) !important;
  }
  .horizontal-nav.mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }
  .horizontal-nav.mobile-offcanvas .container, .horizontal-nav.mobile-offcanvas .container-fluid, .horizontal-nav.mobile-offcanvas .container-sm, .horizontal-nav.mobile-offcanvas .container-md, .horizontal-nav.mobile-offcanvas .container-lg, .horizontal-nav.mobile-offcanvas .container-xl, .horizontal-nav.mobile-offcanvas .container-xxl {
    display: block;
  }
  .horizontal-nav .offcanvas-header {
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: auto;
    padding-bottom: 3.125em;
  }
  .iq-navbar .navbar-collapse:not(.offcanvas-collapse) {
    position: absolute;
    top: 96%;
    left: 0;
    width: 100%;
    flex-grow: unset;
    background: #000;
    z-index: 1081;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    transition: all 400ms ease;
  }
  .iq-navbar .navbar-collapse:not(.offcanvas-collapse) .navbar-nav {
    flex-direction: row;
    float: right;
    padding-right: 1rem;
    align-items: center;
  }
}
@media (max-width: 991.98px) {
  .iq-navbar .navbar-collapse:not(.offcanvas-collapse) {
    top: 100%;
  }
  .iq-navbar .navbar-collapse .navbar-nav.navbar-list:not(.offcanvas-collapse) {
    flex-direction: row;
    float: right;
  }
  header .navbar .horizontal-nav {
    padding-left: 0;
    padding-right: 0;
  }
  header .navbar .horizontal-nav .container-fluid, header .navbar .horizontal-nav .container-sm, header .navbar .horizontal-nav .container-md, header .navbar .horizontal-nav .container-lg, header .navbar .horizontal-nav .container-xl, header .navbar .horizontal-nav .container-xxl {
    padding: 0 1rem;
  }
}
.navbar ul li .iq-header-sub-menu li {
  padding: 0 0.9375em;
  margin-right: 0;
}
.navbar ul li .iq-header-sub-menu li:hover > a::before {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 0.5s ease-in-out;
}
.navbar ul li .iq-header-sub-menu li > a::before {
  position: absolute;
  content: "";
  background: var(--bs-primary);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0.25em;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
  transition: all 0.5s ease-in-out;
}
.navbar ul li .iq-header-sub-menu li:hover > a::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 0.5s ease-in-out;
}
.navbar ul li .iq-header-sub-menu li > a::after {
  position: absolute;
  content: "";
  background: transparent -webkit-gradient(linear, right top, left top, from(rgba(var(--bs-primary-rgb), 0)), to(rgba(var(--bs-primary-rgb), 0.3)));
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
  transition: all 0.5s ease-in-out;
  z-index: -1;
}

.nav-item span.dots {
  height: 0.625em;
  width: 0.625em;
  font-size: 0;
  text-align: center;
  padding: 0;
  position: absolute;
  top: 0.188em;
  right: 0.75em;
  border-radius: 50%;
}

.menu-right .nav-item span.dots {
  right: 0;
}
.menu-right li .iq-sub-dropdown .iq-sub-card {
  position: relative;
  font-size: inherit;
  padding: 0.938em 0.938em;
  line-height: normal;
  color: inherit;
  text-align: left;
  display: inline-block;
  width: 100%;
  border: 0.063em solid;
  border-image-slice: 0.063em;
  border-width: 0 0 0.063em 0;
  border-image-source: linear-gradient(to right, rgba(209, 208, 207, 0.6), rgba(209, 208, 207, 0.3), rgba(209, 208, 207, 0));
}

.iq-nav-menu li {
  position: relative;
  margin-right: 2.8125em;
}
.iq-nav-menu li:last-child {
  margin-right: 0;
}
.iq-nav-menu li a[aria-expanded=true] .arrow-active {
  opacity: 0;
  display: none;
}
.iq-nav-menu li a[aria-expanded=true] .arrow-hover {
  opacity: 1;
  display: block;
  margin: 6px;
  font-size: 0.75em;
  transition: all 0.6s ease-in-out;
}
.iq-nav-menu li a .iq-arrow-right {
  margin-right: 0;
  margin-left: auto;
  font-size: 15px;
  width: 15px;
  transition: all 0.6s ease-in-out;
}
.iq-nav-menu li a .arrow-active {
  font-size: 0.75em;
}

.iq-nav-menu li a .arrow-hover,
.iq-nav-menu li a[aria-expanded=true] .arrow-active {
  opacity: 0;
  display: none;
  font-size: 0.75em;
}

.toggle-menu {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  background: var(--bs-primary);
  border-radius: 0;
  text-align: center;
  display: block;
  height: 1.5em;
  width: 1.5em;
  line-height: 1.5em;
  font-size: 1em;
  z-index: 9;
  color: var(--bs-white);
  transition: all 0.3s ease-in-out;
  display: none;
}

@media (max-width: 1199px) {
  .toggle-menu {
    display: block;
  }
  .iq-nav-menu li {
    margin-right: 0;
  }
  .iq-nav-menu li .nav-link.active {
    color: var(--bs-primary);
    font-weight: 500;
  }
  .iq-navbar {
    z-index: 889;
  }
  .iq-nav-menu > li {
    margin: 0 1rem 1.5rem;
  }
  .iq-nav-menu > li:last-child {
    margin: 0 1rem 1.5rem;
  }
  .iq-nav-menu > li > a {
    padding: 0 0 1.5rem;
    color: var(--bs-white);
    border: solid;
    border-width: 0 0 0.0625rem;
    border-image-source: linear-gradient(90deg, rgb(31, 31, 31), rgba(31, 31, 31, 0.6), rgba(31, 31, 31, 0));
    border-image-slice: 1;
  }
}
.iq-nav-menu .nav-item > .nav-link:hover,
.iq-nav-menu .nav-item > .nav-link:hover i {
  color: var(--bs-primary);
}

.iq-nav-menu li.active > a {
  color: var(--bs-primary);
}
.iq-nav-menu li > ul li {
  padding: 0;
  margin: 0;
}
.iq-nav-menu > li > a {
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
}
.iq-nav-menu a {
  position: relative;
  display: block;
  padding: 0.625rem 1.875rem;
  outline-width: 0;
  color: var(--bs-body-color);
  text-decoration: none;
}
.iq-nav-menu .menu-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bs-primary);
}
.nav-link:hover:focus-visible,
.nav-link:focus:focus-visible {
  box-shadow: none;
}

header .navbar ul li i {
  display: inline-block;
  font-weight: 700;
  font-size: 1em;
  transition: all 0.3s ease-in-out;
}
header .navbar-right.menu-right ul li {
  margin-right: 2.3em;
}
header .navbar-right.menu-right ul li:last-child {
  margin-right: 0;
}

@media (min-width: 1200px) {
  .navbar ul li:hover > .sub-menu {
    display: block !important;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .iq-nav-menu li > ul {
    position: absolute;
    height: auto !important;
    top: 100%;
    width: 100%;
    min-width: 16rem;
    z-index: 999;
    left: auto;
    right: auto;
    padding: 0.9375rem 0;
    display: none;
    margin: 0;
    background: var(--bs-gray-900);
    box-shadow: 0 0.625rem 1.875rem rgba(0, 0, 0, 0.1);
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .iq-nav-menu li > ul li {
    padding: 0;
    padding: 0 0.9375em;
  }
  .iq-nav-menu li > ul li a {
    transition: all 0.4s ease-in-out;
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    transition: all 0.4s ease-in-out;
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    overflow: hidden;
    font-size: 0.875em;
    border-radius: 0;
    display: inline-block;
    width: 100%;
    line-height: 1em;
    padding: 0.938em;
    color: #fff;
    position: relative;
  }
  .iq-nav-menu li > ul ul {
    box-shadow: 0rem 0.25rem 2rem 0rem rgba(0, 0, 0, 0.1);
    left: auto;
    right: 100%;
    top: 0;
    box-shadow: 0rem 0.25rem 2rem 0rem rgba(0, 0, 0, 0.1);
  }
  .iq-nav-menu li:hover > ul {
    display: block;
  }
  .iq-nav-menu li:hover > ul.mega-menu-item {
    display: flex;
  }
  .iq-nav-menu li.active > a > i {
    color: var(--bs-primary);
  }
  .iq-nav-menu .menu-image-box a {
    padding: 0em 1em;
  }
  .iq-nav-menu ul {
    border-left: 0;
  }
  .iq-nav-menu > li > a {
    font-size: 0.875rem;
    padding: 1.0625rem var(--bs-navbar-nav-link-padding-x);
    line-height: 1.5rem;
    z-index: 9;
    text-decoration: none;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 0.0625em;
  }
  header .navbar ul li a:hover {
    color: var(--bs-primary);
  }
  .iq-product-menu-responsive.offcanvas-end {
    width: auto;
  }
  .product-offcanvas .modal-backdrop.show {
    opacity: 0;
  }
}
@media (min-width: 1400px) {
  .iq-nav-menu li > ul ul {
    left: 100%;
    right: auto;
  }
}
@media (max-width: 767px) {
  .horizontal-nav.mobile-offcanvas {
    width: 90%;
  }
}
.scale-in-hor-right {
  animation: scale-in-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes scale-in-hor-right {
  0% {
    transform: scaleX(0);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
.scale-out-hor-right {
  animation: scale-out-hor-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@keyframes scale-out-hor-right {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
.onhover:hover {
  color: var(--bs-primary);
}

.btn-color {
  background: var(--bs-primary);
  color: white;
}
.btn-color:hover {
  background: rgb(var(--bs-primary-rgb), 2);
  color: white;
}

.nav-tabs {
  border-radius: var(--bs-border-radius);
  margin-bottom: 0.5rem;
}
.nav-tabs .nav-link {
  border-radius: var(--bs-border-radius);
}

.nav-pills {
  border-radius: var(--bs-border-radius);
  margin-bottom: 0.5rem;
}

.nav-tunnel {
  padding: 0.25rem;
  border: 1px solid var(--bs-primary);
}
.nav-tunnel .nav-link {
  padding: 0.25rem 1rem;
  border-radius: 0;
}

.tab-bottom-bordered {
  border-bottom: 4px solid rgba(var(--bs-primary-rgb), 0.1);
}
.tab-bottom-bordered .nav-tabs .nav-link {
  color: white;
  position: relative;
  border: none;
}
.tab-bottom-bordered .nav-tabs .nav-link::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 3px;
  border-radius: 0.375rem;
  background: var(--bs-primary);
  bottom: -3px;
  left: 50%;
  top: unset;
  z-index: 1;
  transition: all 400ms ease;
}
.tab-bottom-bordered .nav-tabs .nav-link::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 3px;
  border-radius: 0.375rem;
  background: var(--bs-primary);
  bottom: -3px;
  right: 50%;
  top: unset;
  z-index: 1;
  transition: all 400ms ease;
}
.tab-bottom-bordered .nav-tabs .nav-link.active {
  background-color: unset;
  color: var(--bs-primary);
  box-shadow: unset;
}
.tab-bottom-bordered .nav-tabs .nav-link.active::before {
  width: 60%;
  right: 0;
  transition: all 400ms ease;
}
.tab-bottom-bordered .nav-tabs .nav-link.active::after {
  width: 60%;
  left: 0;
  transition: all 400ms ease;
}
.tab-bottom-bordered.iq-custom-tab-border .nav-tabs .nav-link:nth-child(1) {
  padding-left: unset;
}
.tab-bottom-bordered.tab-border-botton-style-2 .nav-tabs {
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--bs-border-color);
}
.tab-bottom-bordered.tab-border-botton-style-2 .nav-tabs .nav-link {
  color: #212529;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0;
  letter-spacing: 0;
}
.tab-bottom-bordered.tab-border-botton-style-2 .nav-tabs .nav-link.active {
  color: var(--bs-primary);
}
.tab-bottom-bordered.tab-border-botton-style-2 .nav-tabs .nav-link::after {
  bottom: -12px;
}
.tab-bottom-bordered.tab-border-botton-style-2 .nav-tabs .nav-link::before {
  bottom: -12px;
}

.nav-iconly .nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 0;
  padding: 1rem;
  background: var(--bs-gray-900);
  color: inherit;
}

.tab-content.iq-tab-fade-up .tab-pane.fade {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.tab-content.iq-tab-fade-up .tab-pane.fade.active.show {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 991px) {
  .tab-bottom-bordered.tab-border-botton-style-2 .nav-tabs {
    padding-bottom: 0;
    border-bottom: none;
  }
  .tab-bottom-bordered.tab-border-botton-style-2 .nav-tabs .nav-link::after {
    bottom: -5px;
  }
  .tab-bottom-bordered.tab-border-botton-style-2 .nav-tabs .nav-link::before {
    bottom: -5px;
  }
}
.nav-slider {
  position: relative;
  border-radius: 0;
}
.nav-slider .nav-link.active {
  color: var(--bs-white);
  background-color: var(--bs-primary);
}
.nav-slider .nav-item {
  z-index: 3;
}
.nav-slider .nav-slider-thumb {
  z-index: 1 !important;
  width: 100%;
  color: var(--bs-primary);
  background: var(--bs-primary);
}

.iq-custom-tab.nav-pills .nav-item .nav-link {
  background: none;
  font-weight: 400;
  text-transform: capitalize;
  padding: 1em 1em;
  margin: 0 1em 0 0;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  color: var(--bs-white);
}
.iq-custom-tab.nav-pills .nav-item .nav-link::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(var(--bs-primary-rgb), 0) 0, rgba(var(--bs-primary-rgb), 0.3) 85%);
  opacity: 0;
  filter: alpha(opacity=0);
  transform: translate3d(0, -50%, 0);
  transition: all 0.5s ease-in-out;
}
.iq-custom-tab.nav-pills .nav-item .nav-link::after {
  content: "";
  position: absolute;
  height: 0.1875em;
  top: 0;
  bottom: auto;
  left: 0;
  width: 0;
  background: var(--bs-primary);
  opacity: 0;
  transition: all 0.8s linear;
}
.iq-custom-tab.nav-pills .nav-item .nav-link.active {
  color: var(--bs-primary);
}
.iq-custom-tab.nav-pills .nav-item .nav-link.active::before {
  opacity: 1;
  filter: alpha(opacity=100);
  transform: translateZ(0);
  transition: all 0.5s ease-in-out;
}
.iq-custom-tab.nav-pills .nav-item .nav-link.active::after {
  opacity: 1;
  width: 100%;
}
.iq-custom-tab.tab-bg-gredient-center {
  background: var(--bs-gray-900);
}
.iq-custom-tab.tab-bg-fill {
  background: var(--bs-gray-900);
}

@media (max-width: 479px) {
  .iq-custom-tab {
    flex-direction: column;
  }
  .iq-custom-tab.nav-pills .nav-item .nav-link {
    margin: 0;
  }
}
.iq-custom-tab-style-two .tab-header .nav-link {
  color: var(--bs-body-color);
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding: 0.5rem 1.25rem 0 1.25rem;
}
.iq-custom-tab-style-two .tab-header .nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  bottom: 0;
  background: var(--bs-primary);
  transition: width 0.4s ease-in;
}
.iq-custom-tab-style-two .tab-header .nav-link:hover::after {
  width: 100%;
}
.iq-custom-tab-style-two .tab-header .nav-link.active {
  background-color: transparent;
}
.iq-custom-tab-style-two .tab-header .nav-link.active::after {
  width: 100%;
}
.iq-custom-tab-style-two .tab-content {
  padding: 0 6.25rem;
}

.select2-container .select2-selection--single {
  height: 2.7em;
  padding: 0.4375em 1em 0.5em 1em;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0;
  background: var(--bs-gray-900);
  color: var(--bs-body-color);
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  color: var(--bs-body-color);
}
.select2-container.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
}
.select2-container.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: var(--bs-body-color) transparent transparent transparent;
}
.select2-container.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--bs-primary);
}
.select2-container.select2-container--default .select2-results__option--selected {
  background: var(--bs-gray-900);
}
.select2-container.select2-container--open .select2-selection--single {
  border-color: var(--bs-primary);
}
.select2-container.select2-container--open .select2-selection__rendered {
  color: var(--bs-body-color);
}
.select2-container.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent var(--bs-body-color) transparent;
}
.select2-container .select2-dropdown {
  border-color: var(--bs-primary);
  border-radius: 0;
  background: var(--bs-gray-900);
  color: var(--bs-body-color);
}
.select2-container .select2-search--dropdown {
  padding: 0.5rem 0.75rem;
}
.select2-container .select2-search--dropdown .select2-search__field {
  border-color: var(--bs-border-color);
}
.select2-container .select2-results__option {
  padding: 0.5rem 1rem;
}

footer .footer-menu > li {
  position: relative;
}
footer .footer-menu > li a {
  color: var(--bs-body-color);
  font-size: 0.875rem;
  transition: all 0.5s ease-in-out;
  text-transform: capitalize;
}
footer .footer-menu > li a:hover {
  color: var(--bs-primary);
}
footer .footer-menu > li::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--bs-primary);
  font-size: 0.625rem;
}
footer .menu {
  margin-bottom: 0.9375rem;
}
footer .menu li {
  margin-right: 1.875rem;
}
footer .menu li a {
  color: var(--bs-body-color);
  font-size: 0.875rem;
}
footer .menu li a:hover {
  color: var(--bs-primary);
}
footer .menu li:last-child {
  margin-right: 0;
}

.footer-default {
  background: var(--bs-gray-900);
}
.footer-default .footer-logo {
  margin-bottom: 2.5rem;
}
.footer-default .footer-link-title {
  font-size: 1.125rem;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--bs-heading-color);
  margin-bottom: 1.1875rem;
}
.footer-default .footer-top {
  padding: 5.625rem 0;
}
.footer-default .footer-top .contact {
  font-size: 1.25rem;
  font-weight: 600;
}
.footer-default .footer-bottom {
  padding: 3.125em 0em 3.125em 0em;
}

.mailchimp .form-control {
  background: var(--bs-gray-900);
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}
.mailchimp.mailchimp-dark .form-control {
  background: var(--bs-body-bg);
}
.mailchimp .iq-button .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
}

.footer-merchandise {
  background: var(--bs-gray-900);
}
.footer-merchandise .footer-inner {
  padding: 7.375rem 0 6.5rem 0;
  height: 100%;
}

@media (max-width: 991.98px) {
  .footer-merchandise .footer-top {
    padding: 4.375rem 0;
  }
  .footer-merchandise .footer-inner {
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .footer-default .footer-top,
  .footer-default .footer-bottom {
    padding: 2rem 0;
  }
}
.loader.simple-loader {
  background-color: var(--bs-body-bg);
  height: 100%;
  width: 100%;
  position: fixed;
  place-content: center;
  overflow: hidden !important;
  right: 0px;
  z-index: 1250;
}
.loader.simple-loader .loader-body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.swal2-styled.swal2-confirm {
  background: var(--bs-primary) !important;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: none;
}
.swal2-styled.swal2-cancel {
  background: var(--bs-gray-900) !important;
}
.swal2-styled.swal2-cancel:focus {
  box-shadow: none;
}

/*!
* Version: 2.0.0
* Template: Streamit - Responsive Bootstrap 5.3.2 Template
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for Template.
*
*/
:focus {
  outline: none;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: var(--bs-heading-font-family);
}

h1, .h1 {
  font-size: var(--bs-heading-1, 2.5rem);
}

h2, .h2 {
  font-size: var(--bs-heading-2, 2rem);
}

h3, .h3 {
  font-size: var(--bs-heading-3, 1.75rem);
}

h4, .h4 {
  font-size: var(--bs-heading-4, 1.5rem);
}

h5, .h5 {
  font-size: var(--bs-heading-5, 1.25rem);
}

h6, .h6 {
  font-size: var(--bs-heading-6, 1rem);
}

a {
  transition: color 400ms ease, background 400ms ease;
  color: var(--bs-primary);
  text-decoration: none;
}

h1 a, .h1 a, h2 a, .h2 a, h3 a, .h3 a, h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
  color: inherit;
}

img {
  max-width: 100%;
}

.iq-scroller-effect {
  /* Scroll Track */
  /* Scroll Handle */
  /* Scroll Handle on hover */
}
.iq-scroller-effect ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 20px;
}
.iq-scroller-effect ::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 20px;
}
.iq-scroller-effect ::-webkit-scrollbar-thumb {
  background: #dee2e6;
  border-radius: 20px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  cursor: pointer;
}
.iq-scroller-effect ::-webkit-scrollbar-thumb:hover {
  background: #e1e5e9;
  border-radius: 20px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

@media (max-width: 767px) {
  h5, .h5 {
    font-size: 1rem;
  }
}
:root {
  --prefix: bs-;
  --bs-heading-font-family: Roboto, sans-serif, Liberation Mono, Courier New, monospace;
}
@media (max-width: 767px) {
  :root {
    --bs-heading-1: 3.809rem;
    --bs-heading-2: 2.5rem;
    --bs-heading-3: 1.875rem;
    --bs-heading-4: 1.25rem;
  }
}

.container {
  max-width: 1400px;
}

.body-class-1 {
  scroll-behavior: smooth;
}

.main-img .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.main-img .container h1, .main-img .container .h1 {
  margin: 0;
  font-size: 4.375rem;
  color: #fff;
}
.main-img .container h1 span, .main-img .container .h1 span {
  padding: 0.375rem 0.875rem;
  display: inline-block;
}

.bd-heading-1 .bd-example {
  margin-top: 1rem;
}
.bd-heading-1 .bd-example:first-child {
  margin-top: 0rem;
}

.bd-cheatsheet section article .card.iq-document-card {
  padding: 1.5rem;
}
.bd-cheatsheet section article .card.iq-document-card .tab-content pre.language-markup {
  margin: unset;
}

.bd-aside.card.iq-document-card {
  padding: 1.5rem;
}

/* Table of contents */
.bd-aside a {
  padding: 0.25rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 0.25rem;
  color: rgba(33, 37, 41, 0.95);
  text-decoration: none;
}
.bd-aside a:hover, .bd-aside a:focus {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
}
.bd-aside .active {
  font-weight: 600;
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  color: var(--bs-primary);
}
.bd-aside .btn {
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  border: 0;
}
.bd-aside .btn .right-icon {
  transition: transform 0.35s ease;
}
.bd-aside .btn:hover, .bd-aside .btn:focus {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  color: var(--bs-primary);
}
.bd-aside .btn[aria-expanded=true] .right-icon {
  transform: rotate(90deg);
}

.scrollspy-example {
  position: relative;
  height: 12.5rem;
  margin-top: 0.5rem;
  overflow: auto;
}

[id=modal] .bd-example .btn,
[id=buttons] .bd-example .btn,
[id=tooltips] .bd-example .btn,
[id=popovers] .bd-example .btn,
[id=dropdowns] .bd-example .btn-group,
[id=dropdowns] .bd-example .dropdown,
[id=dropdowns] .bd-example .dropup,
[id=dropdowns] .bd-example .dropend,
[id=dropdowns] .bd-example .dropstart {
  margin-top: 0.5rem;
}

/* Layout */
@media (min-width: 1200px) {
  .body-class-1 {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: auto;
  }
  .bd-aside.sticky-xl-top {
    top: 2rem !important;
  }
  .bd-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    grid-column: 1/span 3;
  }
  .sticky-xl-top {
    z-index: 2;
  }
  .bd-cheatsheet,
  .bd-cheatsheet section,
  .bd-cheatsheet article {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 3fr 1fr;
    grid-column: span 2/span 2;
    grid-template-rows: auto;
  }
  .bd-aside {
    grid-area: 1/1;
    scroll-margin-top: 4rem;
  }
  .bd-cheatsheet section,
  .bd-cheatsheet section > .iq-side-content {
    top: 2rem;
    scroll-margin-top: 0;
  }
  .bd-cheatsheet article,
  .bd-cheatsheet .bd-heading {
    top: calc(7.2rem + 1.5rem);
    scroll-margin-top: calc(7.8rem + 1.5rem);
  }
  .bd-cheatsheet .bd-heading {
    z-index: 1;
    order: 2;
  }
  .bd-cheatsheet .bd-heading .card {
    margin-bottom: 0;
  }
  .bd-cheatsheet article .iq-document-card {
    margin-bottom: unset;
  }
  .bd-cheatsheet article .iq-document-card.iq-doc-head {
    margin-top: -8.8rem;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .mobile-offcanvas {
    width: 40% !important;
  }
}
@media (max-width: 1199px) {
  .header .container {
    max-width: unset !important;
  }
  aside.mobile-offcanvas {
    margin-top: unset !important;
  }
  aside.mobile-offcanvas .offcanvas-header {
    display: block;
  }
  .body-class-1 {
    padding: unset;
  }
  .body-class-1.container {
    max-width: unset;
  }
  #content .iq-side-content .card {
    margin-top: 1.25rem;
  }
  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1200;
    width: 75%;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
    background: rgba(255, 255, 255, 0.8392156863);
    -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
  }
  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateY(0);
  }
  .mobile-offcanvas .container,
  .mobile-offcanvas .container-fluid,
  .mobile-offcanvas .container-sm,
  .mobile-offcanvas .container-md,
  .mobile-offcanvas .container-lg,
  .mobile-offcanvas .container-xl,
  .mobile-offcanvas .container-xxl {
    display: block;
  }
}
@media (min-width: 1200px) {
  aside.mobile-offcanvas .offcanvas-header {
    display: none;
  }
  .bd-cheatsheet section .iq-side-content {
    grid-column-end: 3;
  }
  .bd-cheatsheet section .iq-side-content .card {
    margin-bottom: 0;
  }
}
body.offcanvas-active {
  overflow: hidden;
}

body.uikit .btn-download {
  display: none;
}

.uisheet.screen-darken {
  height: 100%;
  width: 0%;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(33, 37, 41, 0.6);
  transition: opacity 0.2s linear, visibility 0.2s, width 2s ease-in;
}
.uisheet.screen-darken.active {
  z-index: 10;
  transition: opacity 0.3s ease, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
}

.middle {
  display: inline !important;
}
.middle .mid-menu {
  position: fixed;
  top: 9.375rem;
  left: 0rem;
  z-index: 999;
  text-align: center;
  cursor: pointer;
  background: var(--bs-primary);
  color: #fff;
  padding: 0.2rem;
  border-radius: 0 1rem 1rem 0;
}

.top-1 {
  top: 1rem;
}

.elem-list {
  max-height: calc(80vh + 4rem);
  overflow: auto;
  /* Scroll Track */
  /* Scroll Handle */
  /* Scroll Handle on hover */
}
.elem-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 10px;
}
.elem-list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}
.elem-list::-webkit-scrollbar-thumb {
  background: rgba(33, 37, 41, 0.2);
  border-radius: 10px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  cursor: pointer;
}
.elem-list::-webkit-scrollbar-thumb:hover {
  background: rgba(33, 37, 41, 0.2);
  border-radius: 10px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  cursor: pointer;
}

.card .card-body .iq-media-group-1 .iq-media-1 {
  margin-left: -1.25rem;
  position: relative;
  z-index: 0;
}
.card .card-body .iq-media-group-1 .iq-media-1:hover {
  z-index: 9;
}
.card .card-body .iq-media-group-1 .iq-media-1 img {
  border: 2px solid #dee2e6;
}
.card .card-body .iq-media-group-1 .iq-media-1:first-child {
  margin-left: 0;
}
.card .card-body .iq-media-1 {
  display: inline-flex;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.25rem;
}
.card .card-body .iq-media-1:hover {
  text-decoration: none;
}

.iq-banner-thumb-slider .slider .slider-bg {
  height: 100vh;
}
.iq-banner-thumb-slider .slider .slider-bg .swiper-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.iq-banner-thumb-slider .slider .slider-bg .swiper-container .swiper-wrapper {
  margin: 0;
}
.iq-banner-thumb-slider .slider .slider-bg .slider-images {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.iq-banner-thumb-slider .slider .slider-bg .slider-images .slider--image {
  height: 100%;
  overflow: hidden;
  background: linear-gradient(90.3deg, #181616 9.88%, rgba(19, 18, 18, 0) 31.52%), linear-gradient(266.54deg, #181616 13.29%, rgba(19, 18, 18, 0) 98.41%);
}
.iq-banner-thumb-slider .slider .slider-bg .slider-images .slider--image img {
  transition: 3s;
  mix-blend-mode: overlay;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
}
.iq-banner-thumb-slider .slider .slider-bg .slider-images .description {
  position: absolute;
  top: 50%;
  left: 6.25rem;
  z-index: 99;
  transform: translateY(-50%);
  width: 100%;
}
.iq-banner-thumb-slider .slider .slider-bg .slider-images .description > .row {
  padding-top: var(--header-height);
}
.iq-banner-thumb-slider .slider .slider-bg .slider-images .description p {
  margin-top: 0.625rem;
  margin-bottom: 2rem;
  width: 37.5em;
  max-width: 100%;
}
.iq-banner-thumb-slider .slider .slider-bg .slider-images .description .slider-content a {
  color: #fff;
  transition: all 0.5s ease-in-out;
}
.iq-banner-thumb-slider .slider .slider-bg .slider-images .description .slider-content a:hover {
  color: var(--bs-primary);
}
.iq-banner-thumb-slider .slider .slider-bg .slider-images .description .iq-button {
  margin-top: 2.8125rem;
}
.iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider {
  position: relative;
  margin-top: var(--header-height);
  z-index: 99;
  width: 38em;
  height: 100%;
  background: linear-gradient(-270deg, #141414 0%, rgba(20, 20, 20, 0) 100%);
  border-radius: 3px;
  padding: 2.1875rem 0 2.1875rem 2.1875rem;
  right: 0;
}
.iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider .swiper-wrapper .swiper-slide {
  padding: 0;
}
.iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider .block-images .img-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 3px;
  background: linear-gradient(31deg, #341214 -1.66%, rgba(0, 0, 0, 0) 62.6%);
}
.iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider .block-images .img-box img {
  height: 20.625rem;
  mix-blend-mode: overlay;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  border-radius: 3px;
  mix-blend-mode: overlay;
}
.iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider .block-images .img-box .block-description {
  position: absolute;
  bottom: 1rem;
  top: auto;
  right: auto;
  left: 1rem;
}
.iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider .block-images .img-box .block-description .fs-12 {
  display: inline-block;
  direction: ltr;
  font-size: 0.75rem;
}
.iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider .banner-thumb-slider-nav {
  position: relative;
  padding-bottom: 3.4375rem;
}
.iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider .banner-thumb-slider-nav .swiper-button i {
  font-size: 0.75rem;
}
.iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider .banner-thumb-slider-nav .slider-prev {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 0;
}
.iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider .banner-thumb-slider-nav .slider-next {
  position: absolute;
  z-index: 10;
  left: 2.8125em;
  bottom: 0;
}

.big-font {
  font-size: 4.375rem;
  font-weight: 700;
}

@media (max-width: 1399.98px) {
  .iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider {
    width: 30em;
  }
}
@media (max-width: 1200px) {
  .iq-banner-thumb-slider .slider .slider-bg .slider-images .description {
    left: 4rem;
  }
  .iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider {
    width: 27em;
  }
}
@media (max-width: 991px) {
  .horizontal_thumb_slider {
    display: none;
  }
}
@media (max-width: 767px) {
  .iq-banner-thumb-slider .slider .slider-bg {
    height: 32.125rem;
  }
  .iq-banner-thumb-slider .slider .slider-bg .slider-images .description {
    left: 0;
    padding: 0 1rem;
  }
}
@media (max-width: 980px) {
  .iq-banner-thumb-slider .slider .slider-bg {
    height: 42.125rem;
  }
}
.movie-tag li {
  padding-right: 1.725em;
  font-weight: 600;
  position: relative;
  white-space: nowrap;
}
.movie-tag li::before {
  content: "";
  height: 0.375em;
  width: 0.375em;
  background: var(--bs-primary);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5625em;
  border-radius: 50%;
}
.movie-tag li:last-child {
  padding-right: 0;
}
.movie-tag li:last-child::before {
  content: none;
}

.swiper-slide-active .RightAnimate {
  animation: RightAnimate 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s both;
}

.swiper-slide-active .RightAnimate-two {
  animation: RightAnimate 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.4s both;
}

.swiper-slide-active .RightAnimate-three {
  animation: RightAnimate 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.6s both;
}

.swiper-slide-active .RightAnimate-four {
  animation: RightAnimate 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s both;
}
@keyframes RightAnimate {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.swiper-nav {
  --active: var(--bs-white);
  --border: rgba(var(--bs-white-rgb), 0.12);
  width: 2.75rem;
  height: 2.75rem;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}
.swiper-nav::before, .swiper-nav::after {
  content: "";
  display: block;
  position: absolute;
  left: 0.063rem;
  right: 0.063rem;
  top: 0.063rem;
  bottom: 0.063rem;
  border-radius: 50%;
  border: 0.063rem solid var(--bs-body-color);
}
.swiper-nav svg {
  width: 2.75rem;
  height: 2.75rem;
  display: block;
  position: relative;
  z-index: 1;
  color: var(--active);
  stroke-width: 0.125rem;
  stroke-dashoffset: 126;
  stroke-dasharray: 126 126 0;
  transform: rotate(0deg);
}
.swiper-nav i {
  display: block;
  position: absolute;
  margin: -0.625rem 0 0 -0.625rem;
  width: 1.25rem;
  height: 1.25rem;
  left: 50%;
  top: 50%;
}
.swiper-nav i::before, .swiper-nav i::after {
  content: "";
  width: 0.625rem;
  height: 0.125rem;
  border-radius: 0.063rem;
  position: absolute;
  left: 50%;
  top: 50%;
  background: var(--active);
  margin: -0.063rem 0 0 -0.313rem;
  display: block;
  transform-origin: 0.563rem 50%;
}
.swiper-nav i::before {
  transform: rotate(-40deg);
}
.swiper-nav i::after {
  transform: rotate(40deg);
}
.swiper-nav.animate i {
  animation: arrow 1.6s ease forwards;
}
.swiper-nav.animate i::before {
  animation: arrowUp 1.6s ease forwards;
}
.swiper-nav.animate i::after {
  animation: arrowDown 1.6s ease forwards;
}
.swiper-nav.animate svg {
  animation: stroke 1s ease forwards 0.3s;
}

.swiper-banner-button-prev.swiper-nav {
  left: 2%;
  transform: scaleX(-1) translateY(-50%);
}

.swiper-banner-button-next.swiper-nav {
  left: auto;
  right: 2%;
}

.slider-ratting ul li {
  margin-right: 0.313rem;
}

.banner-home-swiper .banner-home-swiper-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.banner-home-swiper .banner-home-swiper-image img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.banner-home-swiper .banner-home-swiper-image:before {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(20, 20, 20, 0.9) 15%, rgba(83, 100, 141, 0) 100%);
  width: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.banner-home-swiper p {
  width: 37.5rem;
  max-width: 100%;
  margin: 2rem 0;
}
.banner-home-swiper .trending-list {
  margin-bottom: 1.875rem;
}

#home-banner-slider {
  height: 100vh;
}

@media (max-width: 991px) {
  .home-slider .swiper-bg {
    padding: 3.125rem 0;
  }
}
@media (max-width: 767px) {
  #home-banner-slider {
    height: 28.125rem;
  }
}
@keyframes stroke {
  52% {
    transform: rotate(-180deg);
    stroke-dashoffset: 0;
  }
  52.1% {
    transform: rotate(-360deg);
    stroke-dashoffset: 0;
  }
  100% {
    transform: rotate(-180deg);
    stroke-dashoffset: 126;
  }
}
@keyframes arrow {
  0%, 100% {
    transform: translateX(0);
    opacity: 1;
  }
  23% {
    transform: translateX(17px);
    opacity: 1;
  }
  24%, 80% {
    transform: translateX(-22px);
    opacity: 0;
  }
  81% {
    opacity: 1;
    transform: translateX(-22px);
  }
}
@keyframes arrowUp {
  0%, 100% {
    transform: rotate(-40deg) scaleX(1);
  }
  20%, 80% {
    transform: rotate(0deg) scaleX(0.1);
  }
}
@keyframes arrowDown {
  0%, 100% {
    transform: rotate(40deg) scaleX(1);
  }
  20%, 80% {
    transform: rotate(0deg) scaleX(0.1);
  }
}
@media (max-width: 991px) {
  .swiper-nav,
  .swiper-nav svg {
    width: 2rem;
    height: 2rem;
  }
  .swiper-banner-button-prev .swiper-nav {
    left: 1%;
  }
}
.movie-banner {
  overflow: hidden;
  position: relative;
}
.movie-banner .swiper-banner-container {
  height: 70vh;
}
.movie-banner .swiper-banner-container .swiper-slide-prev,
.movie-banner .swiper-banner-container .swiper-slide-next {
  filter: blur(0.1875em);
}
.movie-banner .movie-banner-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.movie-banner .movie-banner-image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.movie-banner .movie-banner-image:before {
  width: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(20, 20, 20) 35%, rgba(83, 100, 141, 0) 100%);
}
.movie-banner .shows-content {
  padding: 0;
  position: relative;
}
.movie-banner .shows-content > div {
  padding-left: 1.875em;
}
.movie-banner .shows-content .slider-ratting {
  margin-top: 1.25em;
}
.movie-banner .shows-content .movie-banner-time {
  margin: 0.625em 0 1.25em;
}
.movie-banner .shows-content .movie-banner-text {
  margin: 1.875em 0;
  max-width: 37.5em;
}
.movie-banner .swiper-banner-button-next,
.movie-banner .swiper-banner-button-prev {
  position: absolute;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 999;
  background: transparent;
  border: none;
  color: rgba(var(--bs-white-rgb), 0.9);
  z-index: 1;
  height: 4.063rem;
}
.movie-banner .swiper-banner-button-next .arrow-icon,
.movie-banner .swiper-banner-button-prev .arrow-icon {
  font-size: 3rem;
  color: var(--bs-white);
}
.movie-banner .swiper-banner-button-prev {
  left: 0;
}
.movie-banner .swiper-banner-button-next {
  right: 0;
}

@media (max-width: 767px) {
  .movie-banner .swiper-banner-container {
    height: 28.125rem !important;
  }
  .movie-banner .shows-content .movie-banner-text {
    display: none;
  }
}
@media (max-width: 479px) {
  .movie-banner .swiper-banner-container {
    height: 21.25rem !important;
  }
}
.verticle-slider .slider .swiper-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.verticle-slider .slider .swiper-container .swiper-wrapper {
  margin: 0;
}
.verticle-slider .slider .swiper-container .swiper-wrapper .swiper-slide {
  padding: 0;
}
.verticle-slider .slider-flex {
  display: flex;
  align-items: flex-start;
}
.verticle-slider .slider--col {
  margin: 4.06rem 2em 4.06rem 6.06rem;
}
.verticle-slider .vertical-slider-prev {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -40px;
}
.verticle-slider .vertical-slider-prev:focus {
  outline: none;
}
.verticle-slider .vertical-slider-next {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -40px;
}
.verticle-slider .vertical-slider-next:focus {
  outline: none;
}
.verticle-slider i.vertical-aerrow {
  background: rgba(var(--bs-black-rgb), 0.5);
  width: 4.375em;
  text-align: center;
}
.verticle-slider .slider-thumbs {
  height: 40em;
  width: 21.875em;
}
.verticle-slider .slider-thumbs .swiper-bg::before {
  display: none;
}
.verticle-slider .slider-thumbs .slider--image {
  transition: 0.25s;
}
.verticle-slider .slider-thumbs .slider--image:hover {
  opacity: 1;
}
.verticle-slider .slider-thumbs .swiper-slide.swiper-slide-active .hover-buttons {
  opacity: 1;
}
.verticle-slider .slider-thumbs .swiper-slide.swiper-slide-active .block-images::before {
  opacity: 1;
  transition: all 0.45s ease 0s;
}
.verticle-slider .slider-thumbs .swiper-slide .block-images {
  background: linear-gradient(0deg, var(--bs-black) 0%, rgba(var(--bs-black-rgb), 0) 51.04%);
  border-bottom: 2px solid transparent;
  border-radius: 0.375rem;
  transition: all 0.45s ease 0s;
}
.verticle-slider .slider-thumbs .swiper-slide .block-images img {
  mix-blend-mode: overlay;
}
.verticle-slider .slider-thumbs .swiper-slide .block-images .block-description {
  position: absolute;
  top: auto;
  bottom: 1.25rem;
  padding: 0 1.25rem;
}
.verticle-slider .slider-thumbs .swiper-slide .block-images .block-description .iq-title {
  color: var(--bs-white);
  font-size: 1.33125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.9375rem;
  text-transform: capitalize;
}
.verticle-slider .slider-thumbs .swiper-slide .block-images .block-description .iq-title a {
  text-decoration: none;
  color: var(--bs-white);
}
.verticle-slider .slider-thumbs .swiper-slide.swiper-slide-active .block-images {
  border-color: var(--bs-primary);
}
.verticle-slider .slider-images {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.verticle-slider .slider-images .slider--image {
  height: 100%;
  overflow: hidden;
  background: linear-gradient(83deg, var(--bs-black) 31.49%, rgba(var(--bs-black-rgb), 0) 82.3%);
}
.verticle-slider .slider-images .slider--image img {
  transition: 3s;
  mix-blend-mode: overlay;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
}
.verticle-slider .slider-images .description {
  position: absolute;
  top: 50%;
  left: 33rem;
  width: 30%;
  z-index: 99;
  transform: translateY(-50%);
}
.verticle-slider .slider-images .description .iq-title a {
  text-decoration: none;
  color: var(--bs-white);
  text-transform: capitalize;
}

.tab-slider-banner {
  position: relative;
}
.tab-slider-banner .tab-slider-banner-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tab-slider-banner .tab-slider-banner-images img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.tab-slider-banner .tab-slider-banner-images::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0.1875rem;
  background: linear-gradient(90deg, rgba(var(--bs-black-rgb), 0.9) 0%, rgba(20, 20, 20, 0.9) 15%, rgba(83, 100, 141, 0) 100%);
}

.tab-block {
  border-radius: 0.1875rem;
  background: var(--bs-gray-900);
  padding: 2.19rem 0;
}
.tab-block .tab-title {
  padding: 0 2.19rem;
  margin-bottom: 0.94rem;
}
.tab-block .nav-tabs {
  padding: 0 2.19rem;
  gap: 2.81rem;
}
.tab-block .nav-tabs::-webkit-scrollbar {
  display: none;
}
.tab-block .nav-tabs .nav-link {
  padding: 1rem 0;
}
.tab-block .tab-content {
  padding: 0 2.19rem;
}
.tab-block .tab-content .tab-pane li {
  margin-bottom: 1.25rem;
}
.tab-block .tab-content .tab-pane li:last-child {
  margin-bottom: 0;
}
.tab-block .tab-content .tab-pane .image-box img {
  width: 7.5rem;
  height: 5.3125rem;
  -o-object-fit: cover;
     object-fit: cover;
}

.tab-slider .tab-block .tab-pane {
  max-height: 25rem;
  overflow-y: auto;
}
.tab-slider .tab-block .tab-pane::-webkit-scrollbar {
  display: none;
}

@media (max-width: 767px) {
  .tab-block .tab-content .tab-pane .image-box img {
    width: 5rem;
  }
}
.playbtn {
  display: block;
  transition: all 0.5s ease;
  text-align: center;
}
.playbtn .triangle {
  transition: all 0.7s ease-in-out;
  stroke-dasharray: 240;
  stroke-dashoffset: 480;
  stroke: var(--bs-white);
  transform: translateY(0);
}
.playbtn .circle {
  stroke: var(--bs-white);
  stroke-dasharray: 650;
  stroke-dashoffset: 1300;
  transition: all 0.5s ease-in-out;
}
.playbtn span {
  color: var(--bs-white);
}
.playbtn:hover .triangle {
  stroke-dashoffset: 0;
  opacity: 1;
  stroke: var(--bs-primary);
  animation: trailorPlay 0.7s ease-in-out;
}
.playbtn:hover .circle {
  stroke-dashoffset: 0;
  stroke: var(--bs-primary);
}
.playbtn:hover span {
  color: var(--bs-primary);
}

.w-trailor {
  font-size: 1.25em;
  letter-spacing: 0.21875em;
  margin-left: 0.9375em;
  vertical-align: middle;
}

.logo-hotstar,
.logo-prime,
.logo-hulu {
  display: none;
}

.logo-default {
  display: block;
}

[data-bs-theme=hotstar] .logo-hotstar {
  display: block;
}
[data-bs-theme=hotstar] .logo-default,
[data-bs-theme=hotstar] .logo-prime,
[data-bs-theme=hotstar] .logo-hulu {
  display: none;
}

[data-bs-theme=amazonprime] .logo-prime {
  display: block;
}
[data-bs-theme=amazonprime] .logo-hotstar,
[data-bs-theme=amazonprime] .logo-default,
[data-bs-theme=amazonprime] .logo-hulu {
  display: none;
}

[data-bs-theme=hulu] .logo-hulu {
  display: block;
}
[data-bs-theme=hulu] .logo-hotstar,
[data-bs-theme=hulu] .logo-prime,
[data-bs-theme=hulu] .logo-default {
  display: none;
}

.iq-breadcrumb {
  display: block;
  padding: 11.375em 0 7em;
  z-index: 9;
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  background-color: var(--bs-gray-900) !important;
}
.iq-breadcrumb ol {
  padding: 0;
  margin-bottom: 0;
  margin-top: 0.938em;
}
.iq-breadcrumb ol li {
  display: inline-block;
  text-transform: capitalize;
}
.iq-breadcrumb ol li a {
  color: var(--bs-primary);
  text-decoration: none;
  font-weight: 700;
}
.iq-breadcrumb ol li.active {
  color: var(--bs-primary);
}

.iq-breadcrumb_noImg {
  padding: 40px 0px 20px 0px;
  background-color: transparent;
}

@media (max-width: 991px) {
  .title {
    font-size: 2.7rem;
  }
}
@media (max-width: 767px) {
  .iq-breadcrumb {
    padding: 5rem 0;
  }
  .iq-breadcrumb .title {
    font-size: 1.9rem;
  }
}
.iq-card .block-images {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition: all 0.6s ease 0s;
  overflow: hidden;
}
.iq-card .block-images .iq-image-box a {
  z-index: 1;
}
.iq-card .block-images .img-box {
  border-radius: 0.1875rem;
  overflow: hidden;
}
.iq-card .block-images .img-box::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, #0c0b11 8.71%, rgba(12, 11, 17, 0) 72.11%);
  border-radius: 0.1875rem;
  width: 100%;
  height: 100%;
  bottom: 0;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}
.iq-card .block-images .img-box a {
  z-index: 1;
}
.iq-card .block-images .img-box img {
  mix-blend-mode: overlay;
  aspect-ratio: 2/3;
}
.iq-card .block-images .card-description {
  z-index: 9;
  transition: all 0.4s ease-in-out;
  padding: 1.25rem 1.25rem 0.625rem;
  transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: antialiased;
}
.iq-card .block-images .card-description .cart-content a {
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}
.iq-card .block-images .card-description .cart-content a.watch-list-not {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--bs-body-color);
}
.iq-card .block-images .card-description.with-transition {
  bottom: -4.375rem;
  opacity: 0;
  padding: 1.25rem 0 0;
  visibility: hidden;
  transition: all 0.1s ease;
}
.iq-card .block-images::after {
  position: absolute;
  content: "";
  border: 1.25rem solid var(--bs-gray-900);
  bottom: -5rem;
  left: -1.25rem;
  right: -1.25rem;
  top: -1.25rem;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  visibility: hidden;
  background: transparent;
  border-radius: 0.187rem;
}
.iq-card .movie-time-text {
  color: var(--bs-body-color);
  font-size: 0.75rem;
}
.iq-card .card-description {
  position: absolute;
  left: 0;
  bottom: 0;
  width: auto;
  right: 0;
  transition: all 0.4s ease-in-out;
  padding: 1.25rem 1.25rem 0.625rem;
  transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: antialiased;
}
.iq-card .block-social-info {
  position: absolute;
  left: 1.25rem;
  right: 1.25rem;
  bottom: 2.2rem;
  z-index: 999;
  display: flex;
  opacity: 0;
  justify-content: space-between;
  top: inherit;
  transition: opacity 0.2s ease-in-out;
}
.iq-card .block-social-info .share-wrapper {
  position: absolute;
  top: inherit;
  right: 0;
  left: -0.375rem;
  bottom: 80%;
  min-width: inherit;
  height: auto;
  width: 2.375rem;
}
.iq-card .block-social-info .share .share-wrapper {
  display: none;
}
.iq-card .block-social-info .share:hover .share-wrapper {
  display: inline-block;
}
.iq-card .block-social-info .music-play-lists li {
  width: 1.625rem;
  height: 1.625rem;
  margin-bottom: 0;
  border-radius: 50%;
  line-height: 2.1875rem;
  margin: 0 auto 0.4375rem;
  background-color: transparent;
  border: 0.0625rem solid var(--bs-white);
  transition: all 0.45s ease 0s;
}
.iq-card .block-social-info .music-play-lists li:hover {
  border-color: var(--bs-primary);
  background: var(--bs-primary);
}
.iq-card .block-social-info .music-play-lists .share > span {
  width: 100%;
  height: 100%;
  display: inline-block;
  line-height: 0;
  font-size: 1rem;
}
.iq-card .block-social-info .music-play-lists span i {
  color: var(--bs-white);
  font-size: 0.625em;
  line-height: 2.5em;
  cursor: pointer;
}
.iq-card .share-boxs .share-shape {
  position: absolute;
  left: 0.75rem;
  top: inherit;
  bottom: -0.875rem;
  transform: rotate(90deg);
  right: 0;
  width: 0.875rem;
  height: 2.5rem;
}
.iq-card .share-boxs .share-shape path {
  fill: var(--bs-gray-900);
}
.iq-card .share-boxs .overflow-hidden {
  background: var(--bs-gray-900);
}
.iq-card .share-boxs .overflow-hidden a,
.iq-card .share-boxs .overflow-hidden span {
  display: inline-block;
  width: 2.375rem;
  background: var(--bs-gray-900);
  color: var(--bs-white);
}
.iq-card .share-boxs a:hover {
  color: var(--bs-primary);
}
.iq-card .iq-button .btn {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0;
  overflow: hidden;
}
.iq-card:hover .block-images .img-box {
  background: transparent;
}
.iq-card:hover .block-images .img-box img {
  mix-blend-mode: inherit;
}
.iq-card:hover .block-images .img-box::before {
  opacity: 1;
}
.iq-card:hover .block-images .card-description {
  background: var(--bs-gray-900);
}
.iq-card:hover .block-images .card-description.with-transition {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.2s;
}
.iq-card:hover .block-images .card-description.with-transition .cart-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.iq-card:hover .block-images .card-description.with-transition .watchlist {
  transition-delay: 0s;
}
.iq-card:hover .block-images::after {
  opacity: 1;
  transition: all 0.2s ease-in-out;
  visibility: visible;
  transition-delay: 0.2s;
}
.iq-card:hover .block-social-info {
  opacity: 1;
  transition-delay: 0.3s;
}

.card-style-slider {
  padding-left: 10px;
}
.card-style-slider li.swiper-slide {
  padding: 0 0.9375rem;
}

.card-hover:hover .block-images {
  z-index: 99;
  transform: scale3d(0.9, 0.9, 0.9) translate3d(0, 0, 0) perspective(31.25rem);
  transform-origin: 50% 50%;
  transition: all 0.6s ease 0s;
  box-shadow: none;
  transition-delay: 0.2s;
  overflow: visible;
  margin-top: -1.875rem;
}

.card-style-slider li.swiper-slide.swiper-slide-active .card-hover:hover .block-images {
  transform: scale3d(0.9, 0.9, 0.9) translate3d(4%, 0, 0) perspective(31.25rem);
}
.card-style-slider li.swiper-slide.swiper-active.last .card-hover:hover .block-images {
  transform: scale3d(0.9, 0.9, 0.9) translate3d(-12%, 0, 0) perspective(31.25rem);
}

@media (max-width: 1024px) {
  .iq-card:hover .block-images {
    margin-top: 0;
  }
  .card-hover:hover .block-images,
  .card-style-slider li.swiper-slide.swiper-slide-active .card-hover:hover .block-images {
    transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(31.25em);
  }
  .iq-card:hover .block-social-info {
    opacity: 0;
  }
  .iq-card:hover .block-images::after {
    display: none;
  }
  .iq-card:hover .block-images .card-description.with-transition {
    opacity: 0;
  }
}
@media (max-width: 767px) {
  .card-style-slider li.swiper-slide {
    padding: 0 0.4687rem;
    margin-bottom: 0;
  }
}
.iq-card-style-2 .block-images .img-box img {
  height: 10.125rem;
  aspect-ratio: 3/2;
}

.card-style-grid .slide-items {
  padding: 0 0.9375em;
  margin-bottom: 1.875em;
}
.card-style-grid:hover .block-images {
  margin-top: unset;
}

.card-hover-style-two .block-images .img-box {
  width: 100%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: all 0.6s ease 0s;
}
.card-hover-style-two .block-images:hover .img-box {
  z-index: 99;
  transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(31.25em);
  transform-origin: 50% 50%;
  transition: all 0.6s ease 0s;
}

li.swiper-slide-active .card-hover-style-two .block-images:hover .img-box {
  transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0) perspective(31.25em);
}

li.swiper-active.last .card-hover-style-two .block-images:hover .img-box {
  transform: scale3d(1.1, 1.1, 1) translate3d(-6%, 0, 0) perspective(31.25em);
}

@media (max-width: 767px) {
  .card-hover-style-two .block-images:hover .img-box {
    transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0) perspective(31.25em);
  }
}
.free-icon-img {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  width: 36px;
}

.lock-icon-img {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 3px;
  width: 17px;
  margin-left: 10px;
}

.iq-button .btn {
  color: var(--bs-white);
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 500;
  border-radius: 0.1875rem;
  background: rgba(var(--bs-primary-rgb), 0.8);
  border: 0.063em solid var(--bs-primary);
  transition: color 0.3s ease;
  transform: perspective(0.063em) translateZ(0);
}
.iq-button .btn:focus, .iq-button .btn:active {
  border-color: var(--bs-primary);
  background: var(--bs-primary);
}
.iq-button .btn i {
  font-size: 0.75rem;
  margin-left: 0.75rem;
}
.iq-button .btn .button-text,
.iq-button .btn i {
  vertical-align: middle;
}
.iq-button .btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--bs-primary);
  border: 0.063em solid var(--bs-primary);
  transform: scaleX(1);
  transform-origin: 50%;
  transition: transform 0.3s ease-out;
}
.iq-button .btn:hover::before {
  transform: scaleX(0);
}
.iq-button.link-button .btn {
  padding: 0;
  color: var(--bs-primary);
  border: 0;
  background: transparent;
}
.iq-button.link-button .btn::before {
  content: none;
}
.iq-button.link-button .btn:hover {
  color: var(--bs-primary);
}

@media (max-width: 767px) {
  .iq-button .btn {
    padding: 0.75rem 1.5rem;
  }
}
.button-text, .fa-play {
  color: var(--text-color, defaultColor);
}

.title-box {
  margin-bottom: 3.125rem;
}

@media (max-width: 767px) {
  .title-box {
    margin-bottom: 1.875rem;
  }
}
.iq-top-ten-block-slider {
  margin: 0 -0.9375em;
}
.iq-top-ten-block-slider .swiper-wrapper {
  margin: 0;
}
.iq-top-ten-block-slider .swiper-button.swiper-button-next {
  right: 2rem;
}
.iq-top-ten-block-slider .swiper-button.swiper-button-prev {
  left: 2rem;
}

.iq-top-ten-block .block-image {
  padding: 0;
}
.iq-top-ten-block .block-image .overly-images {
  position: relative;
  z-index: 1;
  display: block;
}
.iq-top-ten-block .block-image .overly-images img {
  width: 100%;
  border-radius: 0.375rem;
  transition: all 0.6s ease 0s;
}
.iq-top-ten-block .block-image .top-ten-numbers {
  position: absolute;
  bottom: -65px;
  right: 0;
  font-weight: 800;
  font-size: 6em;
  transition: all 0.4s ease-out;
  z-index: 2;
}
.iq-top-ten-block:hover .block-image .top-ten-numbers {
  bottom: -50px;
}

@media (max-width: 991px) {
  .iq-top-ten-block .block-image .top-ten-numbers {
    font-size: 6.5rem;
  }
}
@media (max-width: 767px) {
  .iq-top-ten-block .block-image {
    padding: 0 0.4687rem;
  }
  .iq-top-ten-block-slider .swiper-button.swiper-button-next {
    right: 1rem;
  }
  .iq-top-ten-block-slider .swiper-button.swiper-button-prev {
    left: 1rem;
  }
}
@media (max-width: 991px) {
  .iq-top-ten-block .block-image .top-ten-numbers {
    font-size: 4.5rem !important;
    bottom: -40px;
  }
}
@media (max-width: 640px) {
  .iq-top-ten-block .block-image .landscape {
    font-size: 3.5rem !important;
    bottom: -40px;
  }
}
@media (max-width: 360px) {
  .iq-top-ten-block .block-image .landscape {
    font-size: 3rem !important;
  }
}
.iq-watching-block {
  position: relative;
}
.iq-watching-block .block-images .iq-image-box {
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 0.375rem;
  background: linear-gradient(180deg, rgba(12, 11, 17, 0) 50%, var(--bs-black) 100%);
}
.iq-watching-block .block-images .iq-image-box img {
  border-radius: 0.375rem;
  height: 180px;
  mix-blend-mode: overlay;
}
.iq-watching-block .block-images .iq-preogress {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  bottom: 1rem;
}
.iq-watching-block .block-images .iq-preogress .data-left-timing {
  margin-bottom: 0.5rem;
  padding-left: 20px;
  display: block;
}

@media (max-width: 767px) {
  .iq-watching-block .block-images .iq-image-box img {
    height: 100px;
  }
  .iq-watching-block .block-images .iq-preogress .data-left-timing {
    display: none;
  }
}
.personality-card li.swiper-slide {
  padding: 0 0.9375rem;
}
.personality-card .cast-title {
  color: var(--bs-heading-color);
  font-weight: 500;
}
.personality-card .cast-title:hover {
  color: var(--bs-primary);
}

.profile-social-lists {
  display: flex;
}
.profile-social-lists li a {
  font-size: 2em;
}

.person-details .single-person__sidebar-title {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0.313em;
}

.trending-dec {
  padding: 0 0 3.125em;
  margin-bottom: 3.125em;
  margin-top: 1.875em;
}

.iq-personality-card li:first-child:hover .block-images {
  transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0) perspective(31.25em);
}
.iq-personality-card li:last-child:hover .block-images {
  transform: scale3d(1.1, 1.1, 1) translate3d(-7%, 0, 0) perspective(31.25em);
}
.iq-personality-card li:hover .block-images {
  z-index: 99;
  transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(31.25em);
  transform-origin: 50% 50%;
  transition: all 0.6s ease 0s;
  overflow: visible;
}
.iq-personality-card li:hover .block-images::before {
  opacity: 1;
}
.iq-personality-card li:hover .block-images .img-box {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.iq-personality-card li .block-images {
  position: relative;
  width: 100%;
  margin-bottom: 1.875em;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition: all 0.6s ease 0s;
}
.iq-personality-card li .block-images::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-left: 0.25em solid var(--bs-primary);
  background: rgba(var(--bs-black), 0.8);
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  transition: all 0.5s ease-in-out;
}
.iq-personality-card li .block-images .img-box {
  position: relative;
  background: rgba(var(--bs-black), 0.8);
  transition: all 0.5s ease-in-out;
}
.iq-personality-card li .block-description {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  position: absolute;
  left: 1.563em;
  top: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.iq-personality-card li .block-description .iq-title {
  padding-right: 3.938em;
  font-size: 1.125em;
  color: var(--bs-white);
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 767px) {
  .personality-card li.swiper-slide {
    padding: 0 0.4687rem;
  }
}
.iq-card-geners .block-images {
  backface-visibility: hidden;
}
.iq-card-geners .block-images .img-box {
  background: linear-gradient(0deg, rgba(12, 11, 17, 0.25) 0%, rgba(12, 11, 17, 0.25) 100%), linear-gradient(180deg, rgba(12, 11, 17, 0) 56%, rgba(12, 11, 17, 0.2) 100%);
}
.iq-card-geners .block-images .img-box::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 0.09375em solid var(--bs-primary);
  background: rgba(var(--bs-black), 0.8);
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  transition: all 0.5s ease-in-out;
  border-radius: 3px;
}
.iq-card-geners .block-images .img-box img {
  mix-blend-mode: overlay;
}
.iq-card-geners .block-images:hover .img-box::before {
  opacity: 1;
}
.iq-card-geners .blog-description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.iq-card-geners .blog-description .iq-title a {
  font-size: 1.125rem;
  color: var(--bs-white);
}

.geners-style-grid .slide-items {
  margin-bottom: 1.875em;
}

@media (max-width: 767px) {
  .geners-card li.swiper-slide {
    padding: 0 0.4687rem;
  }
}
.iq-cast {
  position: relative;
  margin-bottom: 2rem;
}
.iq-cast .iq-cast-body {
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 1.25em;
  background: linear-gradient(24.12deg, var(--bs-black) 0.77%, transparent 96.59%);
}

.cast-images::before {
  position: absolute;
  content: "";
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  background: var(--bs-gray-900);
}
.cast-images .img-box {
  height: 8rem;
  position: relative;
}
.cast-images .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.cast-images .block-description {
  position: relative;
}
.cast-images .block-description .iq-title {
  font-size: 1.125em;
  color: var(--bs-white);
  text-transform: capitalize;
}
.cast-images .block-description .iq-title a {
  font-size: inherit;
  color: inherit;
}

.episode-block {
  position: relative;
  margin-bottom: 1.875em;
}
.episode-block .episode-number {
  background: var(--bs-primary);
  position: absolute;
  top: 0.9375em;
  left: 0.9375em;
  color: var(--bs-white);
  padding: 0 0.3125em;
}
.episode-block .episode-play {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 2.8125em;
  width: 2.8125em;
  line-height: 2.8125em;
  text-align: center;
  background: rgba(var(--bs-white-rgb), 0.25);
  border-radius: 50%;
  margin: 0 auto;
  transition: all 0.5s ease-in-out;
}
.episode-block .episode-play a {
  position: relative;
  display: block;
  height: 2em;
  width: 2em;
  line-height: 2em;
  font-size: 1rem;
  text-align: center;
  background: var(--bs-white);
  color: var(--bs-primary);
  border-radius: 50%;
  margin: 0 auto;
  transition: all 0.45s ease 0s;
}
.episode-block .epi-desc {
  background: var(--bs-gray-900);
}
.episode-block .epi-desc .rel-date {
  padding: 0.3125em 0.8em;
  position: relative;
  overflow: hidden;
  display: inline-block;
  font-style: italic;
}

.category-inner {
  padding: 0 1rem;
}
.category-inner .category_image .category-details {
  position: absolute;
  bottom: 1.375em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}
.category-inner .category_image .category-details .category-title {
  letter-spacing: 0.0625em;
  font-weight: 500;
}

.product-box {
  position: relative;
  height: 31.25em;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 3.125em 3.125em 3.125em;
}
.product-box.left-1-productbox {
  height: 23.43em;
}
.product-box.left-2-productbox {
  height: 23.43em;
}
.product-box.right-1-productbox {
  height: 23.43em;
}
.product-box.right-2-productbox {
  height: 23.43em;
}
.product-box.center-productbox {
  height: 46.875em;
}
.product-box .card-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.product-box .product-card-img-overlay {
  position: absolute;
  inset: 0;
  background-repeat: no-repeat;
  z-index: -1;
  background-position: center center;
  background-size: cover;
  height: 100%;
  transform: scale(1);
  transform-origin: top right;
  transition: all 1s ease-in-out;
  border-radius: 0;
}
.product-box .card-body .card-title {
  margin-bottom: 0.5em;
}
.product-box .card-body .parent-heading {
  margin: 0.5em 0 1.5em;
}
.product-box:hover .product-card-img-overlay {
  transform: scale(1.1);
}

.categorie-about-left {
  margin: 8.938em 0em 0em 0em;
}

@media (max-width: 767px) {
  .categorie-about-left {
    margin: 0;
  }
}
.product-block {
  position: relative;
  margin: 0 0 2em;
  transition: all 0.45s ease-in-out;
}
.product-block .onsale {
  position: absolute;
  text-align: center;
  top: 1em;
  left: 1em;
  margin: 0;
  font-size: 1em;
  font-weight: 500;
  padding: 0.3125em 0.5em;
  min-height: auto;
  min-width: auto;
  line-height: normal;
  color: var(--bs-white);
  border-radius: 0;
  z-index: 2;
}
.product-block .product-image {
  position: relative;
}
.product-block .product-image::after {
  position: absolute;
  content: "";
  background: rgba(var(--bs-black-rgb), 0.1);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  opacity: 0;
  transition: all 0.45s ease-in-out;
}
.product-block .image-wrap {
  position: relative;
}
.product-block .image-wrap .buttons-holder {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: auto;
  transform: translate3d(-50%, -50%, 0) scale(0) translateZ(0);
  background: var(--bs-primary);
  padding: 1em 1.625em;
  margin: 0;
  opacity: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.7s ease-in-out;
}
.product-block .image-wrap .buttons-holder ul {
  position: relative;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-block .image-wrap .buttons-holder ul li {
  display: inline-block;
  transform: rotateY(180deg);
  transition: all 0.5s ease-in-out;
  margin: 0 0.75em;
}
.product-block .image-wrap .buttons-holder ul li a {
  line-height: normal;
  display: block;
  color: var(--bs-white);
}
.product-block .product-caption {
  margin-top: 1rem;
  text-align: center;
}
.product-block .product-caption .product__title {
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1.75em;
  margin-bottom: 0;
}
.product-block .product-caption .price-detail {
  margin-bottom: 0.5em;
}
.product-block .product-caption .price-detail del {
  color: var(--bs-body-text);
  opacity: 0.4;
  margin-right: 0.3125em;
  opacity: 0.5;
}
.product-block:hover .image-wrap .buttons-holder {
  opacity: 1;
  transform: translate3d(-50%, -50%, 0) scale(1) translateZ(0);
}
.product-block.product-list {
  margin: 0 0 2em;
  padding: 0 1em;
}
.product-block.product-list .image-wrap .product-image img {
  height: 18.625em;
  -o-object-fit: cover;
     object-fit: cover;
}
.product-block.product-list .buttons-holder {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  padding: 0;
}
.product-block.product-list .buttons-holder ul {
  height: 100%;
}
.product-block.product-list .product-caption {
  margin: 0;
  text-align: left;
}
.product-block.product-list .product-caption .iq-button-holder {
  margin-top: 2em;
}
.product-block.product-list .product-caption .iq-button-holder .add_to_wishlist {
  border: 0.0625em solid var(--bs-white);
  color: var(--bs-white);
  width: 2.75em;
  height: 2.75em;
  line-height: 2.75em;
  text-align: center;
  display: block;
}
.product-block.product-list .product-caption .iq-button-holder .add_to_wishlist:hover {
  color: var(--bs-primary);
}
.product-block.product-list .product-caption p.blog-desc {
  margin-top: 2em;
  padding-top: 2em;
  border: solid;
  border-width: 0.0625em 0 0;
  border-image-source: linear-gradient(90deg, rgba(var(--bs-secondary-rgb), 1), rgba(var(--bs-secondary-rgb), 0.6), rgba(var(--bs-secondary-rgb), 0));
  border-image-slice: 1;
}

.product-view-button {
  padding: 0.5em 0.8em;
  margin-right: 1em;
  background-color: var(--bs-gray-900);
}
.product-view-button ul li {
  display: inline-block;
}
.product-view-button ul li .nav-link {
  background: transparent !important;
  padding: 0;
}
.product-view-button ul li .nav-link:hover {
  color: var(--bs-primary);
}
.product-view-button ul li .nav-link.active {
  color: var(--bs-primary);
}
.product-view-button ul li .nav-link.active svg.hover_effect {
  fill: var(--bs-primary);
}
.product-view-button ul li svg {
  width: 1.125em;
  height: 1.125em;
}
.product-view-button ul li svg.hover_effect {
  fill: var(--bs-white);
}

@media (max-width: 991px) {
  .product-view-button {
    display: none;
  }
}
.iq-blog-box {
  margin-bottom: 3.125em;
  padding-bottom: 2.5em;
  border: solid;
  border-width: 0 0 0.063em;
  border-image-source: linear-gradient(270deg, rgba(38, 37, 37, 0) -1.25%, #6c757d 43.69%, rgba(38, 37, 37, 0) 99.08%);
  border-image-slice: 1;
}
.iq-blog-box .iq-blog-image {
  position: relative;
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-bottom: 1.875em;
}
.iq-blog-box .iq-blog-meta ul {
  margin: 0;
  padding: 0;
  line-height: 1em;
}
.iq-blog-box .iq-blog-meta ul li {
  text-transform: uppercase;
  font-style: italic;
  margin: 0 0.625em 0.625em 0;
  font-size: 0.875em;
  padding: 0.625em 0.625em 0.625em 0.938em;
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.iq-blog-box .iq-blog-meta ul li a {
  color: var(--bs-white);
}
.iq-blog-box .iq-blog-meta-cat-tag {
  padding: 0;
  display: inline-block;
  width: 100%;
  position: static;
  margin: 0;
}
.iq-blog-box .iq-blog-meta-cat-tag li {
  list-style: none;
  float: left;
  margin: 0 0.625em 0.625em 0;
}
.iq-blog-box .iq-blog-meta-cat-tag li a {
  background: var(--bs-gray-900);
  color: var(--bs-body-text);
  padding: 0.313em 0.625em;
  text-transform: uppercase;
  font-size: 0.875em;
}
.iq-blog-box .iq-blog-meta-cat-tag li a:hover {
  background-color: var(--bs-primary);
  color: var(--bs-white);
}

.blog-single .iq-blog-box {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
}

.iq-blog-category-2 li a {
  color: var(--bs-white);
}
.iq-blog-category-2 li a:hover {
  color: var(--bs-primary);
  transition: all 0.4s ease 0s;
}

.iq-published-date a {
  color: var(--bs-white);
}
.iq-published-date a:hover {
  color: var(--bs-primary);
  transition: all 0.4s ease 0s;
}

.iq-author-details {
  margin-top: 2.1875em;
}
.iq-author-details .iq-author-image {
  height: 2rem;
  width: 2rem;
  line-height: 2.5rem;
}
.iq-author-details .iq-author-image a {
  color: var(--bs-white);
}
.iq-author-details .iq-author-image a:hover {
  color: var(--bs-primary);
  transition: all 0.4s ease 0s;
}

.blockquote {
  padding: 1.875rem 1.875rem 0.9375rem 1.875rem;
  border-left: 2px solid var(--bs-primary);
}
.blockquote p {
  color: var(--bs-body-color);
  font-style: italic;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 2.5rem;
}
.blockquote .blockquote-icon i {
  font-size: 60px;
  color: var(--bs-gray-900);
}

.iq-author-meta-details {
  grid-gap: 2.1875em;
  gap: 2.1875rem;
}
.iq-author-meta-details .iq-author-image {
  width: 9.6875em;
  height: 9.6875em;
}

.blog-nav {
  padding-top: 3em;
  padding-bottom: 2.8125em;
  position: relative;
}
.blog-nav:after {
  position: absolute;
  content: "";
  background: var(--bs-gray-900);
  width: 1px;
  height: 65px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blog-nav .blog-next-post .next {
  margin-right: 0.625em;
}
.blog-nav .blog-prev-post .previous {
  margin-left: 0.625em;
}
.blog-nav .blog-prev-post a .blog-post,
.blog-nav .blog-next-post a .blog-post {
  color: var(--bs-body-color);
}
.blog-nav .blog-prev-post a .blog-arrow,
.blog-nav .blog-next-post a .blog-arrow {
  color: var(--bs-body-color);
}
.blog-nav .blog-prev-post a:hover .blog-arrow,
.blog-nav .blog-next-post a:hover .blog-arrow {
  color: var(--bs-primary);
  transition: all 0.4s ease 0s;
}

.blog-box {
  margin-bottom: 3.125rem;
  padding-bottom: 2.5rem;
  border: solid;
  border-width: 0 0 0.063em;
  border-image-source: linear-gradient(270deg, rgba(38, 37, 37, 0) -1.25%, #6c757d 43.69%, rgba(38, 37, 37, 0) 99.08%);
  border-image-slice: 1;
}

blockquote,
.block-quote.is-style-large {
  background: rgba(83, 81, 79, 0.4);
  padding: 0.938em 1.875em;
  border-left: 0.25em solid var(--bs-primary);
  margin-bottom: 1.875em;
  border-radius: 0.5em;
}

blockquote cite {
  font-family: "Heebo", sans-serif;
  font-weight: bold;
}

.blog-video div#my-video {
  height: 70vh;
}

.blog-single-icon i {
  width: 1em;
  height: 1em;
  position: relative;
  display: block;
  font-size: 3.75rem;
}
.blog-single-icon i::before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

a:hover .blog-heading {
  transition: all 0.5s ease-in-out;
}
a:hover .blog-heading {
  color: var(--bs-primary);
}

@media (max-width: 991px) {
  .blog-nav::after {
    content: none;
  }
}
@media (max-width: 767px) {
  .blockquote {
    padding: 0.9375rem;
  }
  .blog-box {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
  .blog-nav {
    padding-top: 0;
  }
}
.quote-icon {
  font-size: 50px;
}

.blog-quotes .block-quote {
  display: none;
}

.widget {
  margin-bottom: 1.875em;
  padding: 1.875em;
  display: inline-block;
  width: 100%;
  float: left;
  position: relative;
  background: var(--bs-gray-900);
  border-radius: 0.1875em;
}
.widget .search-form {
  position: relative;
}
.widget .search-form .form-control {
  border: 0.0625em solid var(--bs-border-color);
  padding-right: 3.5em;
  background: var(--bs-body-bg);
}
.widget .search-form .form-control:focus {
  border-color: var(--bs-primary);
}
.widget .search-form .block-search_button {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--bs-white);
  cursor: pointer;
  background: var(--bs-primary);
  border: none;
  outline: none;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  height: 100%;
  width: 3em;
  border-radius: 0 3px 3px 0;
}
.widget .widget-title {
  font-size: 1.375em;
  position: relative;
  margin-bottom: 1.364em;
  margin-top: 0;
  font-weight: 500;
}
.widget.widget_categories li {
  color: var(--bs-body-text);
  margin-bottom: 0.9375em;
  border-bottom: 0.0625em solid var(--bs-border-color);
  padding-bottom: 0.9375em;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease-in-out;
}
.widget.widget_categories li a {
  color: var(--bs-body-color);
}
.widget.widget_categories li .post_count {
  opacity: 0;
  font-weight: 500;
  border-radius: 3.125em;
  color: var(--bs-primary);
  width: 1.25em;
  height: 1.25em;
  text-align: center;
  line-height: 1.25em;
  font-size: 0.875em;
  transition: opacity 0.3s ease-in-out;
}
.widget.widget_categories li::before {
  content: "";
  left: 0;
  top: 4px;
  width: 1px;
  height: 15px;
  position: absolute;
  background: var(--bs-primary);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.widget.widget_categories li:hover {
  padding-left: 8px;
}
.widget.widget_categories li:hover .post_count {
  opacity: 1;
}
.widget.widget_categories li:hover::before {
  opacity: 1;
}

.widget_tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.widget_tags li {
  padding: 0;
}
.widget_tags li a {
  background: var(--bs-body-bg);
  display: inline-block;
  color: var(--bs-body-color);
  padding: 0.3125em 0.625em;
  font-size: 0.875em;
  border-radius: 0.1875em;
  transition: all 0.3s ease-in-out;
}
.widget_tags li a:hover {
  background: var(--bs-primary);
  transition: all 0.3s ease-in-out;
}

.iq-blog-tag {
  margin-top: 3.75em;
  padding-top: 3.125em;
  border: solid;
  border-width: 0.063em 0 0;
  border-image-source: linear-gradient(270deg, rgba(var(--bs-secondary-rgb), 0) -1.25%, var(--bs-secondary) 43.69%, rgba(var(--bs-secondary-rgb), 0) 99.08%);
  border-image-slice: 1;
  margin-bottom: 2.5em;
}

.widget_social_media li {
  margin-right: 0.75rem;
  display: inline-block;
}
.widget_social_media li:last-child {
  margin-right: 0;
}
.widget_social_media li a {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  line-height: 2.5rem;
  margin-right: 0;
  color: var(--bs-white);
  font-size: 1rem;
  border-radius: 50%;
  background: var(--bs-gray-800);
  transform: translateY(0);
  transition: all 0.4s ease 0s;
}
.widget_social_media li a:hover {
  background: var(--bs-primary);
  transform: translateY(-0.3125em);
  color: var(--bs-white);
}
.widget_social_media li a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.9);
  z-index: -1;
  border-radius: 50%;
  transition: all 0.4s ease 0s;
}

.iq-widget-blog li {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: none;
}
.iq-widget-blog .post-title {
  margin-bottom: 0.5rem;
}
.iq-widget-blog .img-holder {
  width: 3.75em;
  border-radius: 3px;
}
.iq-widget-blog .img-holder a {
  display: block;
  width: 100%;
  height: 3.75em;
  width: 3.75em;
  border-radius: 0;
  overflow: hidden;
  border-radius: 3px;
}
.iq-widget-blog .blog-data {
  font-size: 0.75em;
  text-transform: uppercase;
  letter-spacing: 0.0625em;
  color: var(--bs-body-color);
}
.iq-widget-blog .blog-data i {
  color: var(--bs-primary);
}

@media (max-width: 767px) {
  .iq-blog-tag {
    margin-top: 2rem;
    padding-top: 2rem;
    margin-bottom: 0;
  }
}
.iq-tag-box {
  background: linear-gradient(184deg, #090809 3.36%, #040304 96.91%);
  margin-bottom: 1.875em;
  text-align: center;
  height: 9.375em;
  padding: 0.9375em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition: all 0.6s ease 0s;
}
.iq-tag-box::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 0.25em solid var(--bs-primary);
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}
.iq-tag-box:hover {
  transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(31.25em);
  transform-origin: 50% 50%;
  transition: all 0.6s ease 0s;
}
.iq-tag-box .iq-tag {
  color: var(--bs-primary);
  font-size: 1rem;
  line-height: 1.75em;
  letter-spacing: 1px;
  font-weight: 500;
  word-break: break-word;
  text-transform: capitalize;
}

.shop-box {
  margin-bottom: 2em;
  display: inline-block;
  width: 100%;
  position: relative;
  background: transparent;
  padding: 0 0 2em;
  border-top: none;
  border-bottom: 0.0625em solid var(--bs-gray-900);
}

.shop-list-checkbox li {
  margin: 0 0 1.875em 0.5rem !important;
  padding: 0 !important;
  position: relative;
}
.shop-list-checkbox li .checkbox {
  position: relative;
}
.shop-list-checkbox li .checkbox::before {
  content: "\f0d7";
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  font-family: Font Awesome\ 5 Free;
  color: var(--bs-primary);
  font-weight: 900;
}
.shop-list-checkbox li:last-child {
  margin: 0 0 0 0.5rem !important;
  padding: 0 !important;
}

.product-range .noUi-horizontal {
  height: 0.3rem;
}

.product-range .noUi-horizontal .noUi-handle {
  background: var(--bs-primary);
  box-shadow: unset;
  top: -0.1975em;
  width: 0.625em;
  height: 0.75em;
  border-radius: 0;
  right: -0.5em;
  border: 0em;
}

.product-range .noUi-connect {
  background: var(--bs-primary);
}

.product-range .noUi-handle:after,
.product-range .noUi-handle:before {
  display: none;
}

.product-range .noUi-target {
  border-radius: 0;
  border: 0em;
  box-shadow: none;
}

.top-product-img {
  height: 5em;
  width: 5em;
  min-width: 5em;
  background-color: var(--bs-body-bg);
}

.shop-product li a {
  color: var(--bs-white);
}
.shop-product li a:hover {
  color: var(--bs-primary);
  transition: all 0.4s ease 0s;
}

ul.shop_list_checkbox li {
  display: inline-block;
}

.shop_list_checkbox li .shop_checkbox_label {
  position: relative;
}

.shop_list_checkbox li .shop_checkbox_label:before {
  content: "";
  height: 100%;
  width: 100%;
  border: 2px solid var(--bs-gray-900);
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.shop_list_checkbox li {
  margin: 0 1em 0.5em 0 !important;
}

.shop_list_checkbox li .shop_checkbox_label {
  font-size: 14px;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 2.5em;
  min-width: 2.5em;
  line-height: 2.5em;
  margin: 0 auto;
  text-align: center;
  color: var(--bs-white);
  padding: 0 0.5em;
}

@media (max-width: 479px) {
  .shop-box {
    padding: 0 0 1em;
    margin-bottom: 1em;
  }
}
.pricing-plan-wrapper {
  position: relative;
  background-color: var(--bs-gray-900);
}
.pricing-plan-wrapper .pricing-plan-header {
  padding: 3.75rem 2.8125rem;
  background: var(--bs-body-bg);
}
.pricing-plan-wrapper .pricing-plan-header .plan-name {
  font-size: 1.25em;
  margin-bottom: 1.875rem;
}
.pricing-plan-wrapper .pricing-plan-header .sale-price {
  font-size: 2.437rem;
}
.pricing-plan-wrapper .pricing-plan-header .main-price {
  font-size: 2.375em;
}
.pricing-plan-wrapper .pricing-details {
  padding: 2.8125rem;
}
.pricing-plan-wrapper .pricing-details .pricing-plan-description ul {
  margin: 0 0 1.875rem;
}
.pricing-plan-wrapper .pricing-details .pricing-plan-description ul li {
  margin-bottom: 2.5rem;
  padding-left: 1.625rem;
  position: relative;
}
.pricing-plan-wrapper .pricing-details .pricing-plan-description ul li i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.pricing-plan-wrapper .pricing-plan-footer .btn {
  width: 100%;
  font-weight: 400;
  letter-spacing: 0.0625rem;
}
.pricing-plan-wrapper::after {
  content: "";
  background: linear-gradient(180deg, var(--bs-primary) 0, rgba(var(--bs-primary-rgb), 0) 100%);
  position: absolute;
  top: -0.125em;
  bottom: -0.125em;
  left: -0.125em;
  right: -0.125em;
  z-index: -1;
}

@media (max-width: 480px) {
  .pricing-plan-wrapper .pricing-plan-header {
    padding: 2.5rem 1.875rem;
  }
  .pricing-plan-wrapper .pricing-details {
    padding: 1.875rem;
  }
}
.countdown li {
  margin: 0 2.8125rem;
  text-align: center;
}
.countdown li .numberDisplay {
  font-size: 3.0625rem;
  font-weight: 600;
  color: var(--bs-white);
}
.countdown li::after {
  content: ":";
  position: absolute;
  right: -2.8125rem;
  top: 15%;
  color: var(--bs-primary);
  font-size: 3.125rem;
  font-weight: 700;
}
.countdown li:first-child {
  margin-left: 0;
}
.countdown li:last-child {
  margin-right: 0;
}
.countdown li:last-child::after {
  content: none;
}

@media (max-width: 767px) {
  .count-down {
    flex-direction: column;
  }
  .count-down li {
    margin: 0 0 0.625rem;
  }
  .count-down li .numberDisplay {
    font-size: 2.5rem;
  }
  .count-down li::after {
    content: none;
  }
}
.watchlist-warpper .block-images {
  position: relative;
  overflow: hidden;
}
.watchlist-warpper .block-images .img-box img {
  height: 12.5rem;
}
.watchlist-warpper .block-images .card-description {
  padding: 1.5rem 0;
}
.watchlist-warpper .block-images:hover .card-description {
  opacity: 1;
}
.watchlist-warpper.card-style-two .block-images {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
.watchlist-warpper.card-style-two .block-images .img-box {
  transform: none;
  width: auto;
}
.watchlist-warpper.card-style-two .block-images .img-box img {
  width: 150px;
  height: 90px;
  -o-object-fit: cover;
     object-fit: cover;
}
.watchlist-warpper.card-style-two .block-images:hover {
  background: var(--bs-gray-900);
}
.watchlist-warpper.card-style-two .block-images:hover .img-box {
  transform: none;
}

.playlist-main-banner .img-detail {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 1rem;
  z-index: 0;
}

@media (max-width: 375px) {
  .watchlist-warpper .block-images .card-description {
    padding: 1rem 0;
  }
  .playlist-main-banner .img-detail {
    padding: 0.5rem;
  }
}
.iq-popup-video .iq-video-icon {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.iq-popup-video .iq-video {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 9;
}
.iq-popup-video .iq-video i {
  line-height: 50px;
  font-size: 0.75rem;
}
.iq-popup-video .iq-fslightbox-img a img.img-fluid {
  max-width: 100%;
}

.padding-200 {
  padding: 9.375rem 0;
}

.waves {
  position: absolute;
  top: 50%;
  background: transparent;
  left: 50%;
  width: 25px;
  height: 25px;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 50%;
  backface-visibility: hidden;
  background: var(--bs-primary);
}
.waves::before {
  content: "";
  position: absolute;
  background-color: var(--bs-white);
  margin-left: -12px;
  margin-top: -12px;
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 50%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  animation: wave-animate 3s infinite ease-out;
}
.waves::after {
  content: "";
  position: absolute;
  background-color: var(--bs-white);
  margin-left: -12px;
  margin-top: -12px;
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 50%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  opacity: 0;
  animation: wave-animate 3s 1.5s infinite ease-out;
}

@keyframes wave-animate {
  0% {
    transform: scale(0);
    opacity: 1;
    transform-origin: center;
  }
  100% {
    transform: scale(3);
    opacity: 0;
    transform-origin: center;
  }
}
.product-tab-slider .product-tab-slider-thumb {
  margin-bottom: 30px;
}
.product-tab-slider .product-tab-slider-images {
  margin-bottom: 0;
}

/*---------------------------------------------------------------------
                      Pages Content
-----------------------------------------------------------------------*/
.custom-header-relative .main-content {
  padding-top: var(--header-height);
}

.iq-view-all {
  font-weight: 500;
  font-size: 0.875rem;
}

body {
  overflow-x: hidden;
}

.iq-main-slider #my-video {
  height: 70vh;
}

.details-part {
  padding-top: 1.875em;
}
.details-part .trending-info {
  position: relative;
  padding: 1rem 0 3.75em;
}

/* ------------------------
Rtl Box & Color Customizer
----------------------------- */
.rtl-box {
  position: fixed;
  max-width: 250px;
  top: 8rem;
  right: 0;
  background-color: var(--bs-gray-900);
  z-index: 1000;
}
.rtl-box .live-customizer {
  background: var(--bs-white);
  height: auto;
  top: 8rem;
  bottom: auto;
  width: 20rem;
}
.rtl-box .rtl-btn:focus {
  outline: none;
  box-shadow: none;
}
.rtl-box .modes {
  margin: 0;
}
.rtl-box .modes .cutomizer-button {
  cursor: pointer;
  display: block;
  border: 0.063em solid var(--bs-body-bg);
  color: var(--bs-dark);
  text-align: center;
  padding: 0.3em 1.8em;
  transition: all 400ms ease;
}
.rtl-box .modes .cutomizer-button.active, .rtl-box .modes .cutomizer-button:hover {
  background: var(--bs-gray-900);
  color: var(--bs-white);
}
.rtl-box .modes .btn-check:checked + .cutomizer-button {
  background: var(--bs-gray-900);
  color: var(--bs-white);
}
.rtl-box .title-customizer {
  color: var(--bs-dark);
}

.reset-tooltip {
  --bs-tooltip-bg: var(--bs-primary);
  --bs-tooltip-color: var(--bs-white);
}

/* ------------------------
Rtl Box & Color Customizer
----------------------------- */
.share-icons.music-play-lists li {
  position: relative;
  display: inline-flex;
  margin-right: 0.438em;
  width: 2.813em;
  height: 2.813em;
  line-height: 2.188em;
  text-align: center;
  background: rgba(var(--bs-white-rgb), 0.3);
  border-radius: 50%;
  margin: 0 0.5rem 0.438em;
  align-items: center;
}
.share-icons.music-play-lists li span {
  position: relative;
  display: block;
  width: 2em;
  height: 2em;
  line-height: 2.1rem;
  border-radius: 50%;
  font-size: 1em;
  text-align: center;
  background: var(--bs-white);
  color: var(--bs-primary);
  margin: 0 auto;
  transition: all 0.45s ease 0s;
  cursor: pointer;
}
.share-icons.music-play-lists li span i {
  font-size: 0.938em;
}
.share-icons.music-play-lists li .share-box {
  display: none;
  position: absolute;
  top: 0.2em;
  right: -4.1em;
  background-color: var(--bs-gray-900);
  padding: 0 0 0 0.4375em;
  border-radius: 0em;
  text-align: center;
  z-index: 2;
  animation: side-in 0.5s forwards;
  margin-bottom: 0.625em;
  transition: all 0.45s ease 0s;
  height: 2.5em;
}
.share-icons.music-play-lists li .share-box .share-shape {
  position: absolute;
  transform: rotate(-180deg);
  left: -0.9em;
  right: auto;
  top: 0px;
  width: 0.9375em;
}
.share-icons.music-play-lists li .share-box a {
  background: transparent !important;
  color: var(--bs-body-text) !important;
  margin-right: 0.938em;
}
.share-icons.music-play-lists li.share:hover .share-box {
  display: inline-block;
}

.movie-detail-select {
  background: rgba(var(--bs-white-rgb), 0.3);
  padding: 7px;
  border-radius: 8px;
  width: 150px;
}
.movie-detail-select select {
  background: var(--bs-white);
  color: var(--bs-primary);
  height: 2em;
  padding: 0.2rem 1rem;
  border-radius: 5px;
  border: none;
}
.movie-detail-select select:focus {
  background: var(--bs-white);
  color: var(--bs-primary);
}
.movie-detail-select .select2-container .select2-selection--single,
.movie-detail-select .select2-container .select2-selection--multiple {
  background: var(--bs-white);
  color: var(--bs-primary);
  height: 2em;
  padding: 0.2rem 1rem;
  border-radius: 5px;
  border: none;
}
.movie-detail-select .select2-container .select2-selection--single .select2-selection__rendered,
.movie-detail-select .select2-container .select2-selection--multiple .select2-selection__rendered {
  color: var(--bs-primary);
}
.movie-detail-select .select2-container.select2-container--default .select2-selection--single .select2-selection__arrow b,
.movie-detail-select .select2-container.select2-container--default .select2-selection--multiple .select2-selection__arrow b {
  border-color: var(--bs-primary) transparent transparent transparent;
}
.movie-detail-select .select2-container.select2-container--open .select2-selection--single .select2-selection__arrow b,
.movie-detail-select .select2-container.select2-container--open .select2-selection--multiple .select2-selection__arrow b {
  border-color: transparent transparent var(--bs-primary) transparent;
}

.iq-blogtag .title {
  color: var(--bs-body-color);
  font-size: var(--bs-body-font-size);
  transition: all 0.3s;
  text-decoration: none;
}
.iq-blogtag .title:hover {
  color: var(--bs-primary);
}

.trailor-video .block-images {
  width: auto;
  border-radius: 0.625em;
  overflow: hidden;
  vertical-align: middle;
}
.trailor-video .block-images::before {
  background: rgba(var(--bs-black-rgb), 0.5);
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
}
.trailor-video .btn-transparant.iq-button {
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  right: 0;
  transform: translateY(-50%);
}
.trailor-video .btn-transparant.iq-button span {
  display: block;
  font-size: 1em;
  text-transform: capitalize;
  color: var(--bs-white);
}

.tv-show-detail .iq-main-slider {
  min-height: 80vh;
  padding: 3.75rem 0 3.75rem 5em;
  z-index: 1;
  width: 100%;
}
.tv-show-detail .iq-main-slider::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(51, 51, 51, 0), rgba(var(--bs-black-rgb), 0.8) 70%, var(--bs-black));
}

.play-button {
  position: relative;
  width: 3.125rem;
  height: 3.125rem;
  min-width: 3.125rem;
  line-height: 3.125rem;
  border-radius: 50%;
  text-align: center;
  background: var(--bs-primary);
  color: var(--bs-white);
  z-index: 1;
}
.play-button::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 3.125em;
  min-width: 3.125em;
  height: 3.125em;
  border-radius: 50%;
  z-index: -1;
  background: var(--bs-primary);
  animation: pulse-border 1.5s ease-out infinite;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.shop-contact-one {
  padding: 6.25em 6.25em 6.25em 6.25em;
  background-color: var(bs-gray-900);
}
.shop-contact-one .channel-logo {
  padding: 0.8em 0.625em 0.625em 0.938em;
  width: 15.938em;
}

.logo-width {
  width: 10rem;
}

.iq-restriction_box {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--bs-gray-900);
  padding: 1rem;
  margin-top: calc(var(--header-height) + 2.5rem);
}
.iq-restriction_box .iq-button {
  margin: 0.625em 0;
}

@media (max-width: 1200px) {
  .iq-main-slider #my-video {
    height: 60vh;
  }
  .tv-show-detail .iq-main-slider {
    min-height: 70vh;
    padding: 2rem 1rem;
  }
  .iq-restriction_box {
    height: 60vh;
  }
}
@media (max-width: 1024px) {
  .iq-main-slider #my-video {
    height: 50vh;
  }
  .iq-restriction_box {
    height: 50vh;
  }
}
@media (max-width: 767px) {
  .iq-main-slider #my-video {
    height: 30vh;
  }
  .shop-contact-one {
    padding: 2em;
  }
  .iq-restriction_box {
    height: 30vh;
  }
  .iq-restriction_box {
    margin-top: calc(var(--header-height) + 2rem);
  }
}
.image-box {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.image-box img {
  transition: transform 2s ease-in-out;
  transform-origin: center center;
}
.image-box img:hover {
  transform: scale(1.3);
}

.box-content .desiganation {
  padding: 0.313em;
  position: relative;
  overflow: hidden;
  display: inline-block;
  font-style: italic;
}
.box-content h4, .box-content .h4 {
  line-height: 0;
}

.image-box-1 {
  font-size: 9.375rem;
}
.image-box-1 svg {
  width: 1em;
  height: 1em;
}

.counter {
  color: var(--bs-primary);
  font-size: 2.5em;
  font-weight: 600;
  text-align: center;
}

.counter-1::after {
  content: "+";
  color: var(--bs-primary);
  font-weight: 600;
}

.counter-title {
  color: var(--bs-body-text);
  font-weight: 300;
  text-align: center;
  font-size: 1.1875rem;
  line-height: 2.5;
}

.iq-contact-list {
  border: 0.063rem solid;
  border-image-slice: 1;
  border-width: 0 0 0 0.063rem;
  border-image-source: linear-gradient(to top, rgba(var(--bs-secondary-rgb), 0), rgba(var(--bs-secondary-rgb), 0.6), rgba(var(--bs-secondary-rgb), 0));
}

.contact-box {
  background-color: var(--bs-gray-900);
  padding: 2.1875rem;
  position: relative;
}
.contact-box img {
  z-index: 0;
}
.contact-box .icon-wrapper {
  background-color: var(--bs-body-bg);
  height: 3.75rem;
  width: 3.75rem;
  line-height: 3.75rem;
  text-align: center;
  flex: none;
}

@media (max-width: 1024px) {
  .contact-box {
    padding: 1rem;
  }
}
.mail-box-width {
  width: 50%;
}

@media (max-width: 1024px) {
  .mail-box-width {
    width: 100%;
  }
}
.profile-box {
  background-color: var(--bs-gray-900);
  padding: 2rem 0;
  margin-bottom: 1rem;
}
.profile-box .account-logo img {
  width: 5rem;
  height: 5rem;
}
.profile-box .account-logo i {
  width: 1.125rem;
  height: 1.125rem;
  line-height: 1.125rem;
  background-color: var(--bs-body-bg);
  color: var(--bs-white);
  font-size: 0.75rem;
  text-align: center;
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
}

.user-login-card {
  padding: 3.125em;
  margin: 0 auto;
  -webkit-backdrop-filter: blur(1.5625em);
          backdrop-filter: blur(1.5625em);
}
.user-login-card .navbar-brand img {
  margin-bottom: 3rem;
}
.user-login-card .navbar-brand svg {
  height: 40px;
}
.user-login-card .full-button .btn {
  width: 100%;
}
.user-login-card .full-button .btn i {
  display: none;
}
.user-login-card .seperator {
  margin: 1.5rem 0 0.875rem;
}
.user-login-card .line {
  flex: auto;
  display: inline-block;
  border-bottom: 0.0625em solid var(--bs-border-color);
  vertical-align: middle;
  min-width: 50px;
  line-height: 0;
}
.user-login-card .widget_social_media li a {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  background: var(--bs-gray-900);
}

.seperator .circle {
  background: var(--bs-body-color);
  vertical-align: middle;
  height: 0.25rem;
  width: 0.25rem;
  margin: 0 0.625rem;
  border-radius: 50%;
}
.seperator .circle.circle-small {
  height: 0.18rem;
  width: 0.18rem;
}

.awards-box {
  padding: 2.5rem 0;
  margin-bottom: 2.5rem;
}

.cast-box img {
  height: 34.375rem;
}
.cast-box .widget_social_media {
  position: absolute;
  bottom: 1.25rem;
  left: 50%;
  transform: translateX(-50%);
}
.cast-box .widget_social_media li a {
  background: var(--bs-body-bg);
}
.cast-box .widget_social_media li a:hover {
  background: var(--bs-primary);
}

.person-img {
  width: 6.875rem;
  height: 7.5rem;
  min-width: 6.875rem;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

#woosq-popup .modal-content {
  background: var(--bs-gray-900);
}
#woosq-popup .review span {
  font-size: 0.875rem;
}
#woosq-popup .entry-summary {
  overflow: auto;
  height: 28.75rem;
  padding: 2rem 2rem 2rem 3rem;
}
#woosq-popup .modal-dialog {
  max-width: 57.5em;
  max-height: 28.75em;
}
#woosq-popup .modal-dialog img {
  max-height: 28.75em;
  width: 28.75em;
}
#woosq-popup .modal-dialog .btn-close {
  height: 30px;
  width: 30px;
  font-size: 12px;
}
#woosq-popup .modal-dialog .row > div {
  max-height: 28.75em;
}

.modal-content {
  background: var(--bs-gray-900);
}
.modal-content .modal-header {
  padding: 3.125rem 3.125rem 1rem;
}
.modal-content .modal-body {
  padding: 0 3.125rem 3.125rem;
}
.modal-content .modal-body .form-control {
  background-color: var(--bs-body-bg);
}
.modal-content .btn-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.modal-content .btn-close:focus {
  box-shadow: none;
}

@media (max-width: 767px) {
  .iq-contact-list {
    border: 0;
  }
  .image-box-1 {
    font-size: 6.375rem;
  }
  #woosq-popup .modal-dialog img {
    width: 100%;
  }
  #woosq-popup .entry-summary {
    padding: 1rem;
  }
  .modal-content .modal-header {
    padding: 1rem;
  }
  .modal-content .modal-body {
    padding: 0 1rem 1rem;
  }
}
.height-auto-100 {
  height: 100vh;
}

[data-bs-theme=hulu] .iq-button .btn {
  color: var(--bs-black);
}
[data-bs-theme=hulu] #back-to-top .top {
  color: var(--bs-black);
}

@media (max-width: 991px) {
  .height-auto-100 {
    height: auto;
  }
}
@media (min-width: 1025px) and (max-width: 1768px) {
  .containerHeight {
    height: 420px;
  }
}

.continueWatching {
  height: 320px;
}

.section-padding-top {
  padding-top: 30px;
}

.swiper-button {
  width: 1.875rem;
  height: 1.875rem;
  text-align: center;
  line-height: 1.875rem;
  color: var(--bs-white);
  background: transparent;
  border-radius: 50%;
  border: 0.0625em solid var(--bs-white);
  z-index: 1;
  transition: all 0.3s ease-in-out;
  font-size: 1em;
}
.swiper-button i {
  vertical-align: middle;
}
.swiper-button::after {
  font-size: 0.625rem;
  color: var(--bs-white);
}
.swiper-button.swiper-button-prev {
  left: 1em;
}
.swiper-button.swiper-button-next {
  right: 1em;
}
.swiper-button:hover {
  background: var(--bs-primary);
  border: 0.0625em solid var(--bs-primary);
}

.swiper-button-next,
.swiper-button-prev {
  top: 50%;
}

.swiper {
  margin-bottom: 5.8rem;
}

.card-style-slider .swiper {
  overflow: visible;
  margin-bottom: 4rem;
}

.joint-arrows {
  position: absolute;
  top: auto;
  bottom: 5rem;
  left: 5.25em;
  z-index: 9;
  width: 7rem;
}

@media (max-width: 1024px) {
  .swiper {
    margin-bottom: 20px;
  }
}
@media (max-width: 1367px) {
  .joint-arrows {
    left: 1rem;
  }
}
@media (max-width: 767px) {
  .swiper {
    margin-bottom: 30px;
  }
  .joint-arrows {
    bottom: auto;
    top: 2rem;
    left: auto;
    right: 0;
  }
  .swiper-button {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
  .swiper-button.swiper-button-next {
    right: 0.4687rem;
  }
  .swiper-button.swiper-button-prev {
    left: 0.4687rem;
  }
  .swiper-button::after {
    font-size: 0.6875rem;
  }
}
.object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  padding: 0 4em;
}

.section-padding {
  padding: 6.25rem 0;
}

.section-padding-noimg {
  padding: 0;
}

.section-padding-top {
  padding: 2.25rem 0 0 0;
  margin-bottom: -15px;
}

.section-padding-bottom {
  padding: 0 0 2rem 0;
}

.font-size-6 {
  font-size: 0.375rem;
}

.font-size-12 {
  font-size: 0.75rem;
}

.font-size-14 {
  font-size: 0.875rem;
}

.font-size-18 {
  font-size: 1.125rem;
}

.font-size-20 {
  font-size: 1.25rem;
}

.line-count-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-count-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-count-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-count-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.line-count-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.letter-spacing-1 {
  letter-spacing: 0.0625rem;
}

.letter-spacing-2 {
  letter-spacing: 0.125rem;
}

.fw-500 {
  font-weight: 500;
}

.mb-40 {
  margin-bottom: 2.5rem;
}

.overflow-x-scroll {
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow-x: auto !important;
  overflow-y: hidden;
}

.overflow-y-scroll {
  flex-wrap: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
}

.border-gredient-left {
  border-left: 0.25em solid var(--bs-primary);
  background: rgba(0, 0, 0, 0) linear-gradient(270deg, rgba(var(--bs-primary-rgb), 0), rgba(var(--bs-primary-rgb), 0.3));
}

.border-gredient-center {
  border: solid;
  border-width: 0 0 0.063em;
  border-image-source: linear-gradient(270deg, rgba(var(--bs-secondary-rgb), 0), rgba(var(--bs-secondary-rgb), 0.6), rgba(var(--bs-secondary-rgb), 0));
  border-image-slice: 1;
}

.swiper-wrapper {
  margin: 0 -0.9375rem;
}

.swiper-wrapper .swiper-slide {
  padding: 0 0.5rem !important;
}

.swiper-slide-space-0 .swiper-wrapper .swiper-slide {
  padding: 0;
}

.texture-text {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  line-height: normal;
  background-image: url("/src/assets/imagesnew/pages/texure.webp");
}

.big-font-5 {
  font-size: 5rem;
  font-weight: 800;
}

@media (max-width: 1367px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    padding: 0 1rem;
  }
}
@media (max-width: 1024px) {
  .section-padding {
    padding: 3.125rem 0;
  }
  .section-padding-top {
    padding: 2.125rem 0 0 0;
  }
  .section-padding-bottom {
    padding: 0 0 2.125rem 0;
  }
}
@media (max-width: 991px) {
  .texture-text {
    font-size: 3.4375rem !important;
  }
}
@media (max-width: 767px) {
  .texture-text {
    font-size: 1.5615rem !important;
  }
  .section-padding {
    padding: 1.875rem 0;
  }
  .section-padding-top {
    padding: 1.875rem 0 0;
  }
  .section-padding-bottom {
    padding: 0 0 1.875rem;
  }
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    padding: 0 0.938rem;
  }
  .section-padding {
    padding-right: 0;
    padding-left: 0;
  }
  .swiper-wrapper .swiper-slide {
    padding: 0 0.4687rem;
  }
}
#woosq-popup img {
  max-height: 28.75em;
  width: 28.75em;
}

@media (max-width: 767px) {
  .bottom_margin_space {
    margin-bottom: -110px !important;
  }
}

@media (min-width: 1025px) {
  .bottom_space {
    margin-bottom: -80px !important;
  }
}

.parallax-window {
  height: 100%;
  padding: 6.25em 0;
  position: relative;
  background-size: cover;
  background-attachment: fixed;
}
.parallax-window::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(var(--bs-black-rgb), 0.8);
}
.parallax-window .parallaxt-details {
  z-index: 9;
  position: relative;
}

#back-to-top {
  display: inline !important;
}
#back-to-top .top {
  z-index: 999;
  bottom: 1.875em;
  right: 1.5625em;
  width: 3.125em;
  height: 3.125em;
  text-align: center;
  line-height: 3.125em;
  transition: all 0.3s ease-in-out;
}
#back-to-top .top i {
  vertical-align: middle;
}

.gallery-thumbs {
  padding: 2.5rem 0;
  overflow: visible;
}

.trending-slider-nav .movie-swiper {
  border: 0.75rem solid rgba(var(--bs-black-rgb), 0);
  transition: all 0.4s ease;
  z-index: -1;
}
.trending-slider-nav .swiper-slide.swiper-slide-active {
  transform: scale3d(1.3, 1.3, 1) translate3d(0, 0, 0) perspective(500px);
  transform-origin: 50% 50%;
  transition: all 0.6s ease 0s;
  box-shadow: 0rem 0rem 0.75rem rgba(var(--bs-black-rgb), 0.9);
}
.trending-slider-nav .swiper-slide.swiper-slide-active .movie-swiper {
  transition: all 0.4s ease;
  padding: 0.625rem;
  background: rgba(var(--bs-black-rgb), 0.5) !important;
  -webkit-backdrop-filter: blur(0.063rem);
          backdrop-filter: blur(0.063rem);
  border: 0.063rem solid var(--bs-border-color);
}

.trending-slider .tranding-block {
  background-size: cover;
  background-position: top right;
  position: relative;
  min-height: 40rem;
}

.trending-contens .trending-tab-slider .trending-tab-slider-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.trending-contens .trending-tab-slider .trending-tab-slider-image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.trending-contens .trending-tab-slider .trending-tab-slider-image::before {
  background: linear-gradient(90deg, rgba(var(--bs-black-rgb), 0.9) 0%, rgba(20, 20, 20, 0.9) 15%, rgba(83, 100, 141, 0) 100%);
  width: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.trending-contens .episodes-contens {
  margin-top: 1.875rem;
}
.trending-contens .swiper {
  margin-bottom: 0;
}
.trending-contens .swiper .swiper-wrapper .swiper-slide {
  padding: 0;
}
.trending-contens .swiper .swiper {
  margin: 0 -0.9375rem;
}
.trending-contens .swiper .swiper .swiper-wrapper .swiper-slide {
  padding: 0 0.9375rem;
}
.trending-contens .swiper-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 60px;
  text-align: center;
  opacity: 1;
  z-index: 9;
  background: rgba(var(--bs-black-rgb), 0.5);
  margin: 0;
  line-height: 0.3125rem;
  box-shadow: 0 0.5625rem 1.1875rem rgba(1, 4, 27, 0.0509803922);
  font-size: 16px;
  color: var(--active);
  transition: all 0.4s ease-in-out 0s;
}
.trending-contens .swiper-arrow::after {
  content: none;
}
.trending-contens .swiper-arrow.swiper-button-next {
  right: 0;
}
.trending-contens .swiper-arrow.swiper-button-prev {
  left: 0;
}

.tranding-block,
.banner-wrapper {
  background-size: cover;
  background-position: top right;
  position: relative;
}

.tranding-block .tab-title-info {
  z-index: 99;
}
.tranding-block .trending-pills {
  background: rgb(var(--bs-black-rgb), 0.25);
}
.tranding-block .trending-content {
  padding: 0rem 3.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 46.25rem;
}
.tranding-block .trending-content::-webkit-scrollbar {
  display: none;
}
.tranding-block .trending-content .tab-pane {
  width: 100%;
  height: 100%;
}
.tranding-block .trending-content .tab-pane.fade {
  display: none;
  visibility: hidden;
  opacity: 0;
}
.tranding-block .trending-content .tab-pane.fade.active.show {
  display: block;
  visibility: visible;
  opacity: 1;
}
.tranding-block .trending-pills.nav-pills .nav-item a {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  text-transform: uppercase;
  margin-left: 0.938rem;
  margin-right: 0.938rem;
}
.tranding-block .trending-pills.nav-pills .nav-item a:hover {
  color: var(--bs-primary);
}
.tranding-block .trending-pills .nav-link {
  padding: 1rem;
}
.tranding-block .trending-content .p-btns {
  display: flex;
  align-items: center;
}
.tranding-block .trending-content .trending-info {
  margin: 0.42rem 0;
  padding: 3.75rem 0;
  position: relative;
  z-index: 9;
}
.tranding-block .trending-content .trending-info .trending-box {
  margin: 0.42rem 0;
}
.tranding-block .trending-content .trending-info .text-detail {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.044rem;
  margin-bottom: 1.875rem;
}
.tranding-block .trending-content .trending-info .text-detail span {
  position: relative;
  margin-right: 0.9375rem;
  padding-right: 0.9375rem;
}
.tranding-block .trending-content .trending-info .text-detail span::before {
  content: "";
  height: 0.375rem;
  width: 0.375rem;
  background: var(--bs-white);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  border-radius: 50%;
}
.tranding-block .trending-content .trending-info .text-detail span:last-child {
  margin-right: 0;
  padding-right: 0;
}
.tranding-block .trending-content .trending-info .text-detail span:last-child::before {
  display: none;
}
.tranding-block .trending-content .trending-info .trending-dec {
  margin-bottom: 1.875rem;
  padding: 0;
  width: 35%;
}
.tranding-block .trending-content .trending-info .block-social {
  height: 2.813rem;
  width: 2.813rem;
  line-height: 2.813rem;
  text-align: center;
  background: rgba(var(--bs-white-rgb), 0.15);
  border-radius: 50%;
  margin-left: 0.938rem;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -o-display: flex;
  align-items: center;
}
.tranding-block .trending-content .trending-info .block-social a {
  position: relative;
  display: block;
  height: 2rem;
  width: 2rem;
  line-height: 1.625rem;
  font-size: 1rem;
  text-align: center;
  background: var(--bs-white);
  color: var(--bs-primary);
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.45s ease 0s;
}
.tranding-block .trending-content .trending-info .block-social.social1 a {
  background: var(--bs-primary);
  color: var(--bs-white);
}
.tranding-block .trending-content .trending-info .iq-dropdown .form-control {
  background: rgb(var(--bs-black-rgb), 0.2);
  border-radius: 0.313rem;
  color: var(--bs-white);
}
.tranding-block .trending-content .trending-list .title {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.313rem;
}
.tranding-block .trending-content .trending-list .title span {
  font-size: 0.875rem;
  font-weight: 400;
}
.tranding-block .trending-content .swiper .swiper-slide {
  pointer-events: auto;
}
.tranding-block .trending-content .c-logo {
  width: 155px;
}
.tranding-block .trending-content .big-title {
  margin: 1.25rem 0;
  font-weight: 800;
}

.tab-watch-trailer-container {
  padding: 0.625rem;
  background: rgba(var(--bs-black-rgb), 0.5) !important;
  -webkit-backdrop-filter: blur(0.625rem);
  backdrop-filter: blur(0.063rem);
  border: 0.063rem solid var(--bs-border-color);
}
.tab-watch-trailer-container .tab-watch-trailer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(20, 20, 20, 0.9) 15%, rgba(83, 100, 141, 0) 100%);
  opacity: 0.5;
}
.tab-watch-trailer-container .trailer-image {
  height: 450px;
  -o-object-fit: cover;
     object-fit: cover;
}
.tab-watch-trailer-container .playbtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.badge.badge-trend {
  background: rgb(var(--bs-black-rgb), 0.42);
}

.swiper-bg:before {
  background: linear-gradient(90deg, rgba(var(--bs-black-rgb), 0.9) 0%, rgba(20, 20, 20, 0.9) 15%, rgba(83, 100, 141, 0) 100%);
  width: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

@media (max-width: 1199.98px) {
  .tranding-block .trending-content .trending-info .trending-dec {
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .tab-watch-trailer-container .trailer-image {
    height: 400px;
  }
}
@media (max-width: 767.98px) {
  .tab-watch-trailer-container .trailer-image {
    height: 250px;
  }
  .trending-slider-nav .swiper-slide.swiper-slide-active {
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0) perspective(500px);
  }
}
@media (max-width: 575.98px) {
  .tranding-block .trending-content {
    padding: 0rem 1rem;
  }
  .tab-watch-trailer-container .playbtn svg {
    width: 50px;
  }
  .tab-watch-trailer-container .playbtn .w-trailor {
    font-size: 0.875rem;
    margin-left: 0.5rem;
  }
}
@media (max-width: 479.98px) {
  .tab-watch-trailer-container .playbtn {
    text-align: center;
  }
  .tab-watch-trailer-container .playbtn .w-trailor {
    display: inline-block;
    width: 100%;
    margin: 0.5rem 0 0;
  }
}
table.cart-table thead tr th {
  background: var(--bs-gray-900);
  color: var(--bs-white);
  padding: 1em;
}
table.cart-table tbody tr td {
  padding: 1.313rem 1rem;
  color: var(--bs-body-color);
}
table.cart-table .product-thumbnail img {
  height: 5em;
  width: 5em;
  min-width: 5em;
  -o-object-fit: cover;
     object-fit: cover;
}

.product-social-share ul li a {
  height: 30px;
  width: 30px;
  line-height: 30px;
}

.cart-page-items li .cart-pre-number {
  padding: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  font-size: 0.75em;
  background: var(--bs-gray-900);
}

.cart_totals {
  background: var(--bs-gray-900);
}
.cart_totals .btn {
  width: 100%;
}
.cart_totals table th {
  background: transparent;
}
.cart_totals table td {
  background: transparent;
}

.acc-left-menu {
  background: var(--bs-gray-900);
}
.acc-left-menu .nav-tabs .nav-link {
  color: var(--bs-white);
  border: none;
}
.acc-left-menu .nav-tabs .nav-link:hover {
  color: var(--bs-primary);
}
.acc-left-menu .nav-tabs .nav-link.active {
  color: var(--bs-primary);
}

#product-menu-content {
  background: var(--bs-gray-900);
}
#product-menu-content .form-control {
  background: var(--bs-body-bg);
}

.orders-table table td .iq-button .btn {
  padding: 0.5rem 1rem;
}
.orders-table table td .iq-button .btn i {
  display: none;
}

.woocommerce-info {
  background-color: var(--bs-gray-900);
  color: var(--bs-body-color);
  display: inline-block;
  padding: 1em 2.5em;
}

.checkout-coupon {
  background-color: var(--bs-gray-900);
  border: 0;
  text-align: center;
  padding: 2em;
  max-width: 43.75em;
  margin: 0 auto 3em;
}
.checkout-coupon .form-control {
  background: var(--bs-body-bg);
  padding-right: 11rem;
}
.checkout-coupon .iq-checkout-coupon {
  position: relative;
}
.checkout-coupon .iq-checkout-coupon .iq-button {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  bottom: 0;
}
.checkout-coupon .iq-checkout-coupon .iq-button .btn {
  height: 54px;
  line-height: 54px;
  padding: 0 2rem;
}
.checkout-coupon .iq-checkout-coupon .iq-button .btn i {
  display: none;
}

.cart_item td {
  border: none;
  padding: 1.5em 1em 1.5em 0;
}
.cart_item .product-name {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.cart_item .product-image img {
  height: 5rem;
  width: 5rem;
  min-width: 5rem;
  -o-object-fit: cover;
     object-fit: cover;
}

.payment-box .accordion .accordion-item-payment {
  margin-bottom: 1rem;
}
.payment-box .accordion .accordion-item-payment .accordion-button-payment {
  color: var(--bs-white);
}
.payment-box .accordion .accordion-item-payment .accordion-button-payment .form-check {
  margin-bottom: 1rem;
}
.payment-box .accordion .accordion-item-payment .accordion-body {
  position: relative;
  background: var(--bs-gray-900);
}
.payment-box .accordion .accordion-item-payment .accordion-body::before {
  content: "";
  display: block;
  border: 1em solid var(--bs-gray-900);
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  top: -0.75em;
  left: 0;
  margin: -1em 0 0 2em;
}

.order {
  background: var(--bs-gray-900);
  padding: 2em;
  text-align: center;
  margin-bottom: 2em;
}
.order .thank {
  text-align: center;
  margin: 0 0 1.5em;
  font-size: 1.5em;
  line-height: normal;
}
.order .details {
  display: flex;
}
.order .details .detail {
  width: 20%;
  font-size: 0.715em;
  border-right: 1px dashed var(--bs-border-color);
  list-style: none;
  text-align: center;
}
.order .details .detail:last-child {
  border-right: 0;
}
.order .details .detail strong {
  font-size: 1.4em;
  color: var(--bs-white);
  display: block;
}

.bill_section {
  background: var(--bs-gray-900);
  padding: 1rem;
}
.bill_section table {
  background: transparent;
}
.bill_section table tr {
  background: transparent;
}
.bill_section table tr td {
  background: transparent;
}

.iq-qty-btn button {
  padding: 0.75rem 0.9375rem;
}
.iq-qty-btn button.btn:hover {
  background-color: transparent;
}

.add-product-wrapper .wishlist-btn {
  padding: 0.75rem;
  line-height: 0;
  color: var(--bs-white);
}
.add-product-wrapper .wishlist-btn:hover {
  color: var(--bs-primary);
}

.additional-info-table table.table-border th {
  width: 10%;
  padding: 1rem;
}

.product-review-list .user-image img {
  width: 3.75rem;
  height: 3.75rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.product-review-list .published-date {
  letter-spacing: 0.1875rem;
  font-size: 0.83rem;
}
.product-review-list .ratting i {
  font-size: 0.875rem;
}

.iq-on-sale, .iq-on-new {
  font-size: 1rem;
  font-weight: 500;
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: auto;
}

.price {
  font-weight: 700;
}

.sale-product .price {
  text-decoration: line-through;
  color: var(--bs-body-color);
  display: inline-block;
  font-weight: 400;
}
.sale-product .sale-price {
  font-weight: 700;
  display: inline-block;
}

.checkout-payment .btn {
  width: 100%;
}

.shop-filter .iq-custom-select {
  width: 250px;
}

.accordion-button-payment .form-check-label {
  vertical-align: middle;
}

.product-detail .product-detail-image {
  height: 650px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 1024px) {
  .product-detail {
    padding-top: 70px;
  }
}
@media (max-width: 767px) {
  .order .details {
    display: block;
    padding-left: 0;
  }
  .order .details .detail {
    width: 100%;
    margin-bottom: 1.25em;
    border-right: none;
    border-bottom: 0.0625em dashed var(--bs-border-color);
    padding: 0 0 1em;
    text-align: left;
  }
}
@media (max-width: 575.98px) {
  .product-detail .product-detail-image {
    height: 550px;
  }
}
@media (max-width: 479.98px) {
  .product-detail .product-detail-image {
    height: 400px;
  }
}
/* PrismJS 1.23.0
https://prismjs.com/download.html#themes=prism-okaidia&languages=markup+css+clike+javascript */
/**
 * okaidia theme for JavaScript, CSS and HTML
 * Loosely based on Monokai textmate theme by http://www.monokai.nl/
 * @author ocodia
 */
code[class*=language-],
pre[class*=language-] {
  color: #f8f8f2;
  background: none;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
}

/* Code blocks */
pre[class*=language-] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  border-radius: 0.3em;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: #272822;
}

/* Inline code */
:not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #8292a2;
}

.token.punctuation {
  color: #f8f8f2;
}

.token.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
  color: #f92672;
}

.token.boolean,
.token.number {
  color: #ae81ff;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #a6e22e;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  color: #f8f8f2;
}

.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
  color: #e6db74;
}

.token.keyword {
  color: #66d9ef;
}

.token.regex,
.token.important {
  color: #fd971f;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

/*!
* Version: 2.0.0
* Template: Streamit - Responsive Bootstrap 5.3.2 Template
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for Template.
*
*/
:focus {
  outline: none;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: var(--bs-heading-font-family);
}

h1, .h1 {
  font-size: var(--bs-heading-1, 2.5rem);
}

h2, .h2 {
  font-size: var(--bs-heading-2, 2rem);
}

h3, .h3 {
  font-size: var(--bs-heading-3, 1.75rem);
}

h4, .h4 {
  font-size: var(--bs-heading-4, 1.5rem);
}

h5, .h5 {
  font-size: var(--bs-heading-5, 1.25rem);
}

h6, .h6 {
  font-size: var(--bs-heading-6, 1rem);
}

a {
  transition: color 400ms ease, background 400ms ease;
  color: var(--bs-primary);
  text-decoration: none;
}

h1 a, .h1 a, h2 a, .h2 a, h3 a, .h3 a, h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
  color: inherit;
}

img {
  max-width: 100%;
}

.iq-scroller-effect {
  /* Scroll Track */
  /* Scroll Handle */
  /* Scroll Handle on hover */
}
.iq-scroller-effect ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 20px;
}
.iq-scroller-effect ::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 20px;
}
.iq-scroller-effect ::-webkit-scrollbar-thumb {
  background: #dee2e6;
  border-radius: 20px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  cursor: pointer;
}
.iq-scroller-effect ::-webkit-scrollbar-thumb:hover {
  background: #e1e5e9;
  border-radius: 20px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

@media (max-width: 767px) {
  h5, .h5 {
    font-size: 1rem;
  }
}
:root {
  --prefix: bs-;
  --bs-heading-font-family: Roboto, sans-serif, Liberation Mono, Courier New, monospace;
}
@media (max-width: 767px) {
  :root {
    --bs-heading-1: 3.809rem;
    --bs-heading-2: 2.5rem;
    --bs-heading-3: 1.875rem;
    --bs-heading-4: 1.25rem;
  }
}

.container {
  max-width: 1400px;
}

[dir=rtl] {
  direction: rtl;
}
[dir=rtl] ol,
[dir=rtl] ul {
  padding-left: 0;
  padding-right: 2rem;
}
[dir=rtl] .list-inline {
  padding-right: 0;
}
[dir=rtl] .text-start {
  text-align: right !important;
}
[dir=rtl] .text-end {
  text-align: left !important;
}
[dir=rtl] .pe-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
[dir=rtl] .pe-1 {
  padding-left: 0.25rem !important;
  padding-right: 0 !important;
}
[dir=rtl] .pe-2 {
  padding-left: 0.5rem !important;
  padding-right: 0 !important;
}
[dir=rtl] .pe-3 {
  padding-left: 1rem !important;
  padding-right: 0 !important;
}
[dir=rtl] .pe-4 {
  padding-left: 1.5rem !important;
  padding-right: 0 !important;
}
[dir=rtl] .pe-5 {
  padding-left: 3rem !important;
  padding-right: 0 !important;
}
[dir=rtl] .ps-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
[dir=rtl] .ps-1 {
  padding-right: 0.25rem !important;
  padding-left: 0 !important;
}
[dir=rtl] .ps-2 {
  padding-right: 0.5rem !important;
  padding-left: 0 !important;
}
[dir=rtl] .ps-3 {
  padding-right: 1rem !important;
  padding-left: 0 !important;
}
[dir=rtl] .ps-4 {
  padding-right: 1.5rem !important;
  padding-left: 0 !important;
}
[dir=rtl] .ps-5 {
  padding-right: 3rem !important;
  padding-left: 0 !important;
}
[dir=rtl] .me-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
[dir=rtl] .me-1 {
  margin-left: 0.25rem !important;
  margin-right: 0 !important;
}
[dir=rtl] .me-2 {
  margin-left: 0.5rem !important;
  margin-right: 0 !important;
}
[dir=rtl] .me-3 {
  margin-left: 1rem !important;
  margin-right: 0 !important;
}
[dir=rtl] .me-4 {
  margin-left: 1.5rem !important;
  margin-right: 0 !important;
}
[dir=rtl] .me-5 {
  margin-left: 3rem !important;
  margin-right: 0 !important;
}
[dir=rtl] .ms-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
[dir=rtl] .ms-1 {
  margin-right: 0.25rem !important;
  margin-left: 0 !important;
}
[dir=rtl] .ms-2 {
  margin-right: 0.5rem !important;
  margin-left: 0 !important;
}
[dir=rtl] .ms-3 {
  margin-right: 1rem !important;
  margin-left: 0 !important;
}
[dir=rtl] .ms-4 {
  margin-right: 1.5rem !important;
  margin-left: 0 !important;
}
[dir=rtl] .ms-5 {
  margin-right: 3rem !important;
  margin-left: 0 !important;
}
[dir=rtl] .start-0 {
  left: auto !important;
  right: 0 !important;
}
[dir=rtl] .start-50 {
  left: auto !important;
  right: 50% !important;
}
[dir=rtl] .end-0 {
  right: auto !important;
  left: 0 !important;
}
[dir=rtl] .end-50 {
  right: auto I !important;
  left: 50% !important;
}
[dir=rtl] .border-end {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  border-right: 0 !important;
}
[dir=rtl] .border-start {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  border-left: 0 !important;
}
[dir=rtl] .list-unstyled {
  padding-right: 0;
}
[dir=rtl] .dropdown-menu[data-bs-popper] {
  top: 100%;
  right: 0;
  margin-top: var(--bs-dropdown-spacer);
}
[dir=rtl] .dropdown-menu-end[data-bs-popper] {
  left: 0;
  right: auto;
}
[dir=rtl] .dropdown-menu-start[data-bs-popper] {
  left: auto;
  right: 0;
}
@media (min-width: 576px) {
  [dir=rtl] .float-sm-start {
    float: right !important;
  }
  [dir=rtl] .float-sm-end {
    float: left !important;
  }
  [dir=rtl] .float-sm-none {
    float: none !important;
  }
  [dir=rtl] .pe-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-sm-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-sm-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .ps-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-sm-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-sm-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .text-sm-start {
    text-align: right !important;
  }
  [dir=rtl] .text-sm-end {
    text-align: left !important;
  }
}
@media (min-width: 768px) {
  [dir=rtl] .float-md-start {
    float: right !important;
  }
  [dir=rtl] .float-md-end {
    float: left !important;
  }
  [dir=rtl] .me-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-md-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-md-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .ms-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-md-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-md-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .pe-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-md-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-md-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .ps-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-md-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-md-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .text-md-start {
    text-align: right !important;
  }
  [dir=rtl] .text-md-end {
    text-align: left !important;
  }
}
@media (min-width: 992px) {
  [dir=rtl] .float-lg-start {
    float: right !important;
  }
  [dir=rtl] .float-lg-end {
    float: left !important;
  }
  [dir=rtl] .ms-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-lg-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-lg-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .pe-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-lg-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-lg-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .ps-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-lg-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-lg-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .text-lg-start {
    text-align: right !important;
  }
  [dir=rtl] .text-lg-end {
    text-align: left !important;
  }
}
@media (min-width: 1200px) {
  [dir=rtl] .float-xl-start {
    float: right !important;
  }
  [dir=rtl] .float-xl-end {
    float: left !important;
  }
  [dir=rtl] .me-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-xl-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-xl-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .ms-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-xl-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-xl-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .pe-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-xl-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-xl-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .ps-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-xl-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .ps-xl-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .text-xl-start {
    text-align: right !important;
  }
  [dir=rtl] .text-xl-end {
    text-align: left !important;
  }
}
@media (min-width: 1400px) {
  [dir=rtl] .float-xxl-start {
    float: right !important;
  }
  [dir=rtl] .float-xxl-end {
    float: left !important;
  }
  [dir=rtl] .me-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-xxl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-xxl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .me-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .ms-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .ms-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .pe-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-xxl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-xxl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .pe-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important;
  }
  [dir=rtl] .text-xxl-start {
    text-align: right !important;
  }
  [dir=rtl] .text-xxl-end {
    text-align: left !important;
  }
}
[dir=rtl] .toggle-menu {
  right: auto;
  left: 0;
}
[dir=rtl] header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav li a.nav-link .menu-icon {
  right: auto;
  left: 15px;
}
[dir=rtl] header .navbar .iq-nav-menu.navbar-nav li ul.sub-nav li a.nav-link .menu-icon i {
  transform: scalex(-1);
}
[dir=rtl] header .navbar .sub-nav li > a::after {
  background: rgba(0, 0, 0, 0) -webkit-gradient(linear, left top, right top, from(rgba(var(--bs-primary-rgb), 0)), to(rgba(var(--bs-primary-rgb), 0.3)));
  transform: translate3d(100%, 0, 0);
}
[dir=rtl] header .navbar .sub-nav li > a.active::after {
  transform: translate3d(0, 0, 0);
}
[dir=rtl] header .navbar .sub-nav li:hover > a::after {
  transform: translate3d(0, 0, 0);
}
[dir=rtl] header .dropdown-search.dropdown-menu[data-bs-popper] {
  left: 0;
  right: auto;
}
[dir=rtl] header .iq-search-bar .search-submit {
  left: 0;
  right: auto;
}
[dir=rtl] .iq-nav-menu li {
  margin-right: 0;
  margin-left: 2.8125em;
}
[dir=rtl] .iq-nav-menu li:last-child {
  margin-left: 0;
}
[dir=rtl] .iq-nav-menu li > ul ul {
  left: auto;
  right: 0;
}
[dir=rtl] .iq-nav-menu li > ul li {
  margin: 0;
}
[dir=rtl] .iq-nav-menu li > ul li:last-child {
  margin: 0;
}
@media (min-width: 1200px) {
  [dir=rtl] .iq-nav-menu li > ul ul {
    left: 100%;
    right: auto;
  }
}
@media (min-width: 1400px) {
  [dir=rtl] .iq-nav-menu li > ul ul {
    left: auto;
    right: 100%;
  }
}
@media (max-width: 1199.98px) {
  [dir=rtl] header .search-box .dropdown-search .form-group {
    margin: 0 auto 0 0;
  }
  [dir=rtl] .horizontal-nav.mobile-offcanvas {
    left: auto;
    right: 0;
    transform: translateX(100%);
  }
  [dir=rtl] .horizontal-nav.mobile-offcanvas.show {
    transform: translateX(0);
  }
  [dir=rtl] .horizontal-nav.mobile-offcanvas .iq-nav-menu li .sub-nav a {
    border-image-source: linear-gradient(-90deg, rgb(31, 31, 31), rgba(31, 31, 31, 0.6), rgba(31, 31, 31, 0));
  }
  [dir=rtl] .iq-nav-menu > li > a {
    border-image-source: linear-gradient(-90deg, rgb(31, 31, 31), rgba(31, 31, 31, 0.6), rgba(31, 31, 31, 0));
  }
  [dir=rtl] .iq-navbar .navbar-collapse:not(.offcanvas-collapse) .navbar-nav {
    float: left;
    padding-right: 0;
    padding-left: 1rem;
  }
}
@media (max-width: 1199px) {
  [dir=rtl] .iq-nav-menu li {
    margin: 0 1rem 1.5rem;
  }
  [dir=rtl] .iq-nav-menu li:last-child {
    margin: 0 1rem 1.5rem;
  }
}
[dir=rtl] .scrollhidden::-webkit-scrollbar {
  display: none;
}
[dir=rtl] header.header-center-home .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
  left: auto;
  right: 30%;
  transform: translateX(30%);
}
[dir=rtl] header .search-box .iq-search-bar .form-control {
  padding-left: 2.8em;
  padding-right: 1.5em;
}
[dir=rtl] header .search-box a.show + ul li .form-group {
  animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes scale-in-hor-left {
  0% {
    transform: scaleX(0);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@media (min-width: 1200px) {
  [dir=rtl] header .iq-search-bar.dropdown-menu {
    left: 0;
    right: auto;
  }
}
@media (max-width: 1199.98px) {
  [dir=rtl] header.header-center-home .mega-menu-content .iq-nav-menu li ul.mega-menu-item {
    right: 0;
    transform: none;
  }
}
[dir=rtl] .iq-custom-tab.nav-pills .nav-item .nav-link {
  margin: 0 0 0 1em;
}
@media (max-width: 479px) {
  [dir=rtl] .iq-custom-tab.nav-pills .nav-item .nav-link {
    margin: 0;
  }
}
[dir=rtl] footer .footer-menu > li::before {
  left: auto;
  right: 0;
  transform: translateY(-50%) scale(-1);
}
[dir=rtl] footer .menu li {
  margin-right: 0;
  margin-left: 1.875em;
}
[dir=rtl] footer .menu li:last-child {
  margin-left: 0;
}
[dir=rtl] .mailchimp .iq-button .btn {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[dir=rtl] .select2-container {
  text-align: right;
}
[dir=rtl] .select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 20px;
  padding-right: 0;
}
[dir=rtl] .select2-container.select2-container--default .select2-selection--single .select2-selection__arrow {
  left: 17px;
  right: auto;
}
[dir=rtl] .select2-container.select2-container--default .select2-selection--single .select2-selection__arrow b {
  right: 50%;
  left: auto;
  margin-right: -4px;
  margin-left: 0;
}
[dir=rtl] .select2-container .select2-results__options {
  padding: 0.5rem 0;
}
[dir=rtl] .iq-accordian .iq-accordian-block .iq-accordian-title {
  padding: 1.875rem 1.875rem 1.875rem 4.688rem;
}
[dir=rtl] .iq-accordian .iq-accordian-block .iq-accordian-title .iq-icon-right {
  left: 1.875rem;
  right: auto;
}
[dir=rtl] .iq-card .block-social-info .share-wrapper {
  right: -0.375rem;
  left: 0;
}
[dir=rtl] .iq-card .share-boxs .share-shape {
  right: 0.75rem;
  left: auto;
}
[dir=rtl] .card-style-slider li.swiper-slide {
  padding: 5px !important;
}
[dir=rtl] .card-style-slider li.swiper-slide.swiper-slide-active .card-hover:hover .block-images {
  transform: scale3d(1.1, 1.1, 1) translate3d(-12%, 0, 0) perspective(31.25rem);
}
[dir=rtl] .card-style-slider li.swiper-slide.swiper-active.last .card-hover:hover .block-images {
  transform: scale3d(1.1, 1.1, 1) translate3d(4%, 0, 0) perspective(31.25rem);
}
[dir=rtl] .iq-watching-block .block-images .iq-preogress .progress-bar-height {
  height: 1px;
}
[dir=rtl] .iq-watching-block .block-images .iq-preogress .data-left-timing {
  padding-left: 0;
  padding-right: 20px;
  text-align: right;
}
[dir=rtl] .product-box {
  text-align: left;
}
[dir=rtl] .product-block .onsale {
  left: auto;
  right: 1em;
}
[dir=rtl] .product-block.product-list .product-caption {
  text-align: right;
}
[dir=rtl] .product-block.product-list .product-caption p.blog-desc {
  border-image-source: linear-gradient(-90deg, rgba(var(--bs-secondary-rgb), 1), rgba(var(--bs-secondary-rgb), 0.6), rgba(var(--bs-secondary-rgb), 0));
}
[dir=rtl] .product-block .image-wrap .buttons-holder {
  left: 0;
  right: 50%;
  transform: translate(50%, -50%) scale(0);
}
[dir=rtl] .product-block:hover .image-wrap .buttons-holder {
  transform: translate(50%, -50%) scale(1);
}
[dir=rtl] .product-view-button {
  margin-right: 0;
  margin-left: 1em;
}
[dir=rtl] .iq-blog-box .iq-blog-meta-cat-tag li {
  float: right;
}
[dir=rtl] blockquote,
[dir=rtl] .block-quote.is-style-large {
  border-left: 0;
  border-right: 0.25em solid var(--bs-primary);
}
[dir=rtl] .blockquote {
  border-left: none;
  border-right: 2px solid var(--bs-primary);
}
[dir=rtl] .widget .search-form .form-control {
  padding-left: 3.5rem;
  padding-right: 1.5rem;
}
[dir=rtl] .widget .search-form .block-search_button {
  left: 0;
  right: auto;
  border-radius: 3px 0 0 3px;
}
[dir=rtl] .widget.widget_categories li::before {
  left: auto;
  right: 0;
}
[dir=rtl] .widget.widget_categories li:hover {
  padding-left: 0;
  padding-right: 8px;
}
[dir=rtl] .widget_social_media li {
  margin-left: 0.75rem;
  margin-right: 0;
}
[dir=rtl] .widget_social_media li:last-child {
  margin-left: 0;
}
[dir=rtl] .pricing-plan-wrapper .pricing-details .pricing-plan-description ul li {
  padding-left: 0;
  padding-right: 1.625rem;
}
[dir=rtl] .pricing-plan-wrapper .pricing-details .pricing-plan-description ul li i {
  left: auto;
  right: 0;
}
[dir=rtl] .iq-button .btn i {
  margin-left: 0;
  margin-right: 0.5rem;
}
[dir=rtl] .iq-top-ten-block .block-image .top-ten-numbers {
  right: auto;
  left: 0;
}
[dir=rtl] .countdown li:first-child {
  margin-left: 2.8125rem;
  margin-right: 0;
}
[dir=rtl] .countdown li:last-child {
  margin-right: 2.8125rem;
  margin-left: 0;
}
[dir=rtl] .countdown li::after {
  left: -2.8125rem;
  right: auto;
}
@media (max-width: 767px) {
  [dir=rtl] .count-down li {
    margin: 0 0 0.625rem;
  }
  [dir=rtl] .count-down li:first-child {
    margin-left: 0;
  }
  [dir=rtl] .count-down li:last-child {
    margin-right: 0;
  }
  [dir=rtl] .count-down li::after {
    content: none;
  }
}
[dir=rtl] .breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
  padding-right: var(--bs-breadcrumb-item-padding-x);
}
[dir=rtl] .breadcrumb-item + .breadcrumb-item::before {
  float: right;
  padding-right: 0;
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
[dir=rtl] .iq-breadcrumb ol {
  padding-right: 0;
}
[dir=rtl] .waves::before, [dir=rtl] .waves::after {
  margin-right: -12px;
  margin-left: 0;
}
[dir=rtl] .tv-show-detail .iq-main-slider {
  padding: 3.75rem 5em 3.75rem 0;
}
[dir=rtl] .tv-show-detail .iq-main-slider:before {
  background-image: linear-gradient(-180deg, rgba(51, 51, 51, 0), rgba(var(--bs-black-rgb), 0.8) 70%, var(--bs-black));
}
[dir=rtl] .share-icons.music-play-lists li .share-box {
  left: -6.2em;
  right: auto;
}
[dir=rtl] .iq-contact-list {
  border-width: 0 0.063rem 0 0;
}
[dir=rtl] #back-to-top .top {
  left: 1.5625em;
  right: auto;
}
[dir=rtl] .rtl-box {
  right: auto;
  left: 0;
}
[dir=rtl] .rtl-box.show {
  transform: translateX(0);
}
[dir=rtl] .rtl-btn {
  left: auto;
  right: -2.5em;
}
[dir=rtl] .checkout-coupon .iq-checkout-coupon .iq-button {
  left: 0;
  right: auto;
}
[dir=rtl] .order .details .detail {
  border-right: 0;
  border-left: 1px dashed var(--bs-border-color);
}
[dir=rtl] .order .details .detail:last-child {
  border-left: 0;
}
@media (max-width: 767px) {
  [dir=rtl] .order .details .detail {
    border-left: none;
    text-align: right;
  }
}
[dir=rtl] .trending-contens .trending-tab-slider .trending-tab-slider-image::before {
  background: linear-gradient(-90deg, rgba(var(--bs-black-rgb), 0.9) 0%, rgba(20, 20, 20, 0.9) 15%, rgba(83, 100, 141, 0) 100%);
}
[dir=rtl] .trending-contens .swiper-arrow.swiper-button-prev {
  left: auto;
  right: 0;
}
[dir=rtl] .trending-contens .swiper-arrow.swiper-button-prev i {
  display: inline-block;
  transform: scaleX(-1);
}
[dir=rtl] .trending-contens .swiper-arrow.swiper-button-next {
  left: 0;
  right: auto;
}
[dir=rtl] .trending-contens .swiper-arrow.swiper-button-next i {
  display: inline-block;
  transform: scaleX(-1);
}
[dir=rtl] .swiper-bg:before {
  background: linear-gradient(-90deg, rgba(var(--bs-black-rgb), 0.9) 0%, rgba(20, 20, 20, 0.9) 15%, rgba(83, 100, 141, 0) 100%);
}
[dir=rtl] .border-gredient-left {
  border-left: none;
  border-right: 0.25em solid var(--bs-primary);
  background: rgba(0, 0, 0, 0) linear-gradient(-270deg, rgba(var(--bs-primary-rgb), 0), rgba(var(--bs-primary-rgb), 0.3));
}
[dir=rtl] .image-flip-rtl {
  transform: scaleX(-1);
}
[dir=rtl] .image-flip-rtl > div {
  transform: scaleX(-1);
}
[dir=rtl] .swiper-button.swiper-button-prev {
  left: auto;
  right: 1em;
}
[dir=rtl] .swiper-button.swiper-button-next {
  right: auto;
  left: 1em;
}
@media (max-width: 1367px) {
  [dir=rtl] .joint-arrows {
    left: auto;
    right: 1em;
  }
}
@media (max-width: 767px) {
  [dir=rtl] .joint-arrows {
    right: auto;
    left: 0;
  }
}
[dir=rtl] .banner-home-swiper .time {
  direction: ltr;
}
[dir=rtl] .banner-home-swiper .banner-home-swiper-image::before {
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.9) 0%, rgba(20, 20, 20, 0.9) 15%, rgba(83, 100, 141, 0) 100%);
}
[dir=rtl] .slider-ratting ul {
  direction: ltr;
}
[dir=rtl] .slider-ratting ul li {
  margin-right: 0;
  margin-left: 0.313em;
}
[dir=rtl] .iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider {
  direction: ltr;
  left: 0;
  right: auto;
  background: linear-gradient(270deg, #141414 0%, rgba(20, 20, 20, 0) 100%);
  padding: 2.1875rem 2.1875rem 2.1875rem 0;
}
[dir=rtl] .iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider .swiper-wrapper .swiper-slide {
  direction: rtl;
}
[dir=rtl] .iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider .block-images .img-box .block-description {
  left: auto;
  right: 1rem;
}
[dir=rtl] .iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider .banner-thumb-slider-nav .slider-prev {
  left: auto;
  right: 0;
  transform: scale(-1);
}
[dir=rtl] .iq-banner-thumb-slider .slider .slider-bg .horizontal_thumb_slider .banner-thumb-slider-nav .slider-next {
  left: auto;
  right: 2.8125em;
  transform: scale(-1);
}
[dir=rtl] .iq-banner-thumb-slider .slider .slider-bg .slider-images .slider--image {
  background: linear-gradient(-90.3deg, #181616 9.88%, rgba(19, 18, 18, 0) 31.52%), linear-gradient(-266.54deg, #181616 13.29%, rgba(19, 18, 18, 0) 98.41%);
}
[dir=rtl] .iq-banner-thumb-slider .slider .slider-bg .slider-images .description {
  left: auto;
  right: 6.25rem;
}
[dir=rtl] .movie-tag li {
  padding-right: 0;
  padding-left: 1.725em;
}
[dir=rtl] .movie-tag li:last-child {
  padding-left: 0;
}
[dir=rtl] .movie-tag li::before {
  right: auto;
  left: 0.5625em;
}
@media (max-width: 767px) {
  [dir=rtl] .iq-banner-thumb-slider .slider .slider-bg .slider-images .description {
    right: 0;
  }
}
[dir=rtl] .movie-banner .movie-banner-image:before {
  background: linear-gradient(-90deg, rgb(0, 0, 0) 0%, rgb(20, 20, 20) 35%, rgba(83, 100, 141, 0) 100%);
}
[dir=rtl] .movie-banner .shows-content > div {
  padding-left: 0;
  padding-right: 1.875em;
}
[dir=rtl] .movie-banner .swiper-banner-button-prev {
  right: 0;
  left: auto;
}
[dir=rtl] .movie-banner .swiper-banner-button-prev i {
  display: inline-block;
  transform: scalex(-1);
}
[dir=rtl] .movie-banner .swiper-banner-button-next {
  left: 0;
  right: auto;
}
[dir=rtl] .movie-banner .swiper-banner-button-next i {
  display: inline-block;
  transform: scalex(-1);
}
[dir=rtl] .tab-slider-banner .tab-slider-banner-images::before {
  background: linear-gradient(-90deg, rgba(var(--bs-black-rgb), 0.9) 0%, rgba(20, 20, 20, 0.9) 15%, rgba(83, 100, 141, 0) 100%);
}
[dir=rtl] .verticle-slider .slider--col {
  margin: 4.06rem 6.06em 4.06rem 2rem;
}
[dir=rtl] .verticle-slider .slider-images .slider--image {
  background: linear-gradient(-83deg, var(--bs-black) 31.49%, rgba(var(--bs-black-rgb), 0) 82.3%);
}
[dir=rtl] .verticle-slider .slider-images .description {
  left: auto;
  right: 33rem;
}
@media (max-width: 991px) {
  [dir=rtl] .verticle-slider .slider-images .description {
    left: auto;
    right: 0;
  }
}
[dir=rtl] .w-trailor {
  margin-left: 0;
  margin-right: 0.9375em;
}

div.trailerModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.trailerModal div.head {
  margin-top: 50px;
  position: fixed;
  z-index: 9999;
  color: var(--white-color);
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 20px;
}
div.trailerModal div.head div.left h4.heading, div.trailerModal div.head div.left .heading.h4 {
  font-size: 13px;
}
div.trailerModal div.head div.left h1.title, div.trailerModal div.head div.left .title.h1 {
  font-size: 22px;
  text-transform: uppercase;
}
div.trailerModal div.head div.left span.director {
  font-weight: 700;
  font-size: 14px;
  margin-right: 5px;
}
div.trailerModal div.head div.left span.year {
  font-weight: normal;
  font-size: 13px;
}
div.trailerModal div.head div.right div.closeIcon {
  cursor: pointer;
}
div.trailerModal div.head div.right div.closeIcon svg {
  stroke: var(--white-color) !important;
}
div.trailerModal div.trailerPlayer {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin: 0 auto;
}
@media all and (max-width: 1400px) {
  div.trailerModal div.trailerPlayer {
    top: 50%;
    width: 60%;
  }
}
@media all and (max-width: 1200px) {
  div.trailerModal div.trailerPlayer {
    top: 50%;
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.trailerModal div.trailerPlayer {
    top: 45%;
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.trailerModal div.trailerPlayer {
    width: 95%;
  }
}
div.trailerModal div.trailerPlayer video {
  width: 100%;
  display: block;
}

div.episodeListPage {
  padding: 100px 0 0;
}
div.episodeListPage h2.title, div.episodeListPage .title.h2 {
  text-align: center;
}

div.noscheduleContainer div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.noscheduleContainer div.closeIconContainer {
  position: fixed;
  top: 80px !important;
  right: 30px;
  z-index: 999;
  cursor: pointer;
}
div.noscheduleContainer div.closeIconContainer svg {
  width: 25px !important;
  height: 25px !important;
  stroke: white !important;
}
div.noscheduleContainer div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.noscheduleContainer div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.noscheduleContainer div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.noscheduleContainer div.contents {
    width: 90%;
  }
}
div.noscheduleContainer div.contents h1, div.noscheduleContainer div.contents .h1 {
  color: var(--inner-text-color);
  font-size: 22px;
}

div.squarepaymentProcessingScreen {
  padding: 100px 0;
  background-color: var(--sub-bg-color);
}
div.squarepaymentProcessingScreen span.success {
  color: var(--success-color);
}
@media all and (max-width: 640px) {
  div.squarepaymentProcessingScreen div.wrapper {
    width: 90%;
  }
}
div.squarepaymentProcessingScreen div.paymentContainer {
  width: 90%;
  height: 50rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

div.socialLoginModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.socialLoginModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 9999999;
}
div.socialLoginModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.socialLoginModal div.contents {
  z-index: 9999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.socialLoginModal div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.socialLoginModal div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.socialLoginModal div.contents {
    width: 90%;
  }
}
div.socialLoginModal div.contents h1.error, div.socialLoginModal div.contents .error.h1 {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.socialLoginModal div.contents p {
  color: var(--white-color);
  font-size: 16px;
}
div.socialLoginModal div.contents h1, div.socialLoginModal div.contents .h1 {
  color: var(--white-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.socialLoginModal div.contents h1, div.socialLoginModal div.contents .h1 {
    font-size: 16px;
  }
}
div.socialLoginModal div.contents div.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
div.socialLoginModal div.contents div.buttonContainer button {
  background-color: var(--button-color);
  padding: 10px 12px;
  color: var(--text-color);
  font-size: 13px;
  cursor: pointer;
  width: 10rem;
  border-radius: 5px;
}
div.socialLoginModal div.contents div.buttonContainer button:first-child {
  margin-right: 6px;
}

div.shorts {
  height: 100vh;
  overflow: hidden;
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
}
@media (max-width: 480px) {
  div.shorts {
    margin-top: 0px;
  }
}
div.shorts__videos {
  height: 87vh;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 400px;
  aspect-ratio: 9/16;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
div.shorts__videos::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1200px) {
  div.shorts__videos {
    width: 47%;
  }
}
@media (max-width: 1024px) {
  div.shorts__videos {
    width: 50%;
  }
}
@media (max-width: 768px) {
  div.shorts__videos {
    width: 80%;
  }
}
@media (max-width: 480px) {
  div.shorts__videos {
    width: 100%;
    height: 87vh;
  }
}
@media (max-width: 360px) {
  div.shorts__videos {
    width: 100%;
    height: 89vh;
  }
}
div.shorts div.loder-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.shorts div.loder-div .loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
div.shorts div.video-container {
  display: flex;
}
div.shorts div.video-container div.video-shorts {
  position: relative;
  width: 100%;
  height: 87.5vh;
  margin-top: 10px;
  margin-bottom: 10px;
  scroll-snap-align: start;
  overflow: hidden;
  background-color: #000;
  /* Mobile-specific styles */
}
div.shorts div.video-container div.video-shorts__player {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  aspect-ratio: 9/16;
}
div.shorts div.video-container div.video-shorts .video-js {
  pointer-events: auto; /* Ensure touch events are enabled */
}
div.shorts div.video-container div.video-shorts .video-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  pointer-events: auto; /* Ensure touch events are enabled */
  transition: opacity 0.3s ease-in-out;
}
div.shorts div.video-container div.video-shorts .hidden {
  opacity: 0;
  visibility: hidden; /* Ensure the element is not clickable */
  pointer-events: none;
}
div.shorts div.video-container div.video-shorts .show {
  opacity: 1;
  visibility: visible;
}
div.shorts div.video-container div.video-shorts .pause-icon, div.shorts div.video-container div.video-shorts .play-icon {
  width: 60px;
  height: 60px;
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
div.shorts div.video-container div.video-shorts .pause-icon::before, div.shorts div.video-container div.video-shorts .play-icon::before {
  content: "▶"; /* Unicode for play triangle */
  font-size: 30px;
  color: white;
}
div.shorts div.video-container div.video-shorts .pause-icon::before {
  content: "❚❚"; /* Unicode for pause bars */
}
@media (max-width: 768px) {
  div.shorts div.video-container div.video-shorts .pause-icon, div.shorts div.video-container div.video-shorts .play-icon {
    width: 60px;
    height: 60px;
  }
  div.shorts div.video-container div.video-shorts .pause-icon::before, div.shorts div.video-container div.video-shorts .play-icon::before {
    font-size: 25px;
  }
}
@media (max-width: 640px) {
  div.shorts div.video-container div.video-shorts .pause-icon, div.shorts div.video-container div.video-shorts .play-icon {
    width: 40px;
    height: 40px;
  }
  div.shorts div.video-container div.video-shorts .pause-icon::before, div.shorts div.video-container div.video-shorts .play-icon::before {
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  div.shorts div.video-container div.video-shorts .pause-icon, div.shorts div.video-container div.video-shorts .play-icon {
    width: 30px;
    height: 30px;
  }
  div.shorts div.video-container div.video-shorts .pause-icon::before, div.shorts div.video-container div.video-shorts .play-icon::before {
    font-size: 10px;
  }
}
div.shorts div.video-container div.video-shorts .shortsVideoTop {
  position: absolute;
  min-width: -moz-fit-content;
  min-width: fit-content;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
div.shorts div.video-container div.video-shorts .shortsVideoTopIcon {
  display: flex;
  width: 40%;
  justify-content: space-evenly;
}
div.shorts div.video-container div.video-shorts .shortsVideoTopIcon > .MuiSvgIcon-root {
  font-size: 32px;
  padding: 10px;
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcons {
  position: absolute;
  right: 8px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcon > .MuiSvgIcon-root {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  padding: 11px;
  font-size: 43px;
  cursor: pointer;
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcon > p {
  color: white;
  font-size: 14px;
  margin-top: 5px;
}
div.shorts div.video-container div.video-shorts .shortDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
  position: relative;
}
div.shorts div.video-container div.video-shorts .shortDetails p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.shorts div.video-container div.video-shorts .show-more-button {
  color: #00f; /* Adjust color as needed */
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
}
div.shorts div.video-container div.video-shorts .shortsBottom {
  display: flex;
  position: absolute;
  flex-direction: column;
  bottom: 10px;
  width: 84%;
  padding: 0 10px;
}
div.shorts div.video-container div.video-shorts .shortsDesc {
  display: flex;
  padding: 10px;
}
div.shorts div.video-container div.video-shorts .shortsDesc > p {
  color: white;
  font-size: 14px;
}
div.shorts div.video-container div.video-shorts .MuiSvgIcon-root {
  cursor: pointer;
  color: white;
}
div.shorts div.video-container div.video-shorts .shortDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 58px;
  position: relative;
}
div.shorts div.video-container div.video-shorts .shortDetails p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
}
div.shorts div.video-container div.video-shorts .show-more-button {
  color: #00f; /* Adjust color as needed */
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
}
div.shorts div.video-container div.video-shorts .shortDetails > button {
  padding: 7px;
  background-color: rgba(177, 177, 177, 0.609);
  border: none;
  outline: none;
  color: white;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
}
div.shorts div.video-container div.video-shorts .shortDetails > button:hover {
  background-color: rgb(255, 0, 0);
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcon > .MuiSvgIcon-root:hover {
  color: blue;
}
div.shorts div.video-container div.video-shorts .shortsDesc > .ticker {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  max-width: 250px;
}
div.shorts div.video-container div.video-shorts .description {
  color: white;
}
div.shorts div.video-container div.share-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
div.shorts div.video-container div.share-modal-overlay .share-modal-content {
  background: #202020;
  border-radius: 12px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  top: 260px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  color: white;
}
div.shorts div.video-container div.share-modal-overlay .share-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}
div.shorts div.video-container div.share-modal-overlay .share-options {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
div.shorts div.video-container div.share-modal-overlay .share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin: 0 10px;
  transition: opacity 0.3s;
  border: none;
  cursor: pointer;
}
div.shorts div.video-container div.share-modal-overlay .share-button:hover {
  opacity: 0.8;
}
div.shorts div.video-container div.share-modal-overlay .facebook {
  background: #3b5998;
}
div.shorts div.video-container div.share-modal-overlay .twitter {
  background: #1da1f2;
}
div.shorts div.video-container div.share-modal-overlay .share-url-container {
  display: flex;
  margin-top: 15px;
}
div.shorts div.video-container div.share-modal-overlay .share-url-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 4px 0 0 4px;
  background: #333;
  color: white;
}
div.shorts div.video-container div.share-modal-overlay .copy-button {
  padding: 10px 15px;
  background: #1da1f2;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background 0.3s;
}
div.shorts div.video-container div.share-modal-overlay .copy-button:hover {
  background: #0c8de4;
}
div.shorts div.video-container div.comment-modal {
  position: fixed;
  top: 76px;
  width: 28%;
  right: 8%;
  height: 88vh;
  background-color: #212121;
  z-index: 1000;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1400px) {
  div.shorts div.video-container div.comment-modal {
    width: 28%;
    right: 9%;
  }
}
@media all and (max-width: 1200px) {
  div.shorts div.video-container div.comment-modal {
    width: 60%;
  }
}
@media all and (max-width: 768px) {
  div.shorts div.video-container div.comment-modal {
    width: 80%;
  }
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal {
    width: 100%;
    right: 0;
    top: 58px;
    height: 88vh;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions {
  display: flex;
  align-items: center;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button {
  position: relative;
  margin-right: 15px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button button {
  background: transparent;
  border: none;
  color: var(--white-color);
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button button .sort-icon {
  margin-left: 5px;
  font-size: 10px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button .sort-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #282828;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button .sort-dropdown div {
  padding: 10px 15px;
  color: var(--white-color);
  cursor: pointer;
  white-space: nowrap;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button .sort-dropdown div:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
@media all and (max-width: 1200px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header {
    width: 100%;
  }
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header {
    width: 100%;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header h2, div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .close-modal {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.loder-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.loder-div .loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers {
  padding: 10px 0 25px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-right: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name, div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right .name.h1 {
  font-size: 16px;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 20px;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 1.5;
  max-width: 100%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container {
  margin-top: 10px;
  margin-bottom: 30px;
  width: 30%;
  height: auto;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-right: 0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-right: 5px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer {
  width: 70%;
  margin: 0 auto;
  padding: 60px 0;
}
@media all and (max-width: 1200px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer {
    width: 100%;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.top div.head {
  display: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.top div.textContainer {
  display: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers {
  padding: 40px 0 25px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-right: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name, div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right .name.h1 {
  font-size: 16px;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 20px;
  margin-bottom: 20px;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 1.5;
  max-width: 100%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-right: 0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-right: 5px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-item {
  display: flex;
  margin-bottom: 16px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-body {
  flex: 1;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-author {
  font-weight: 500;
  margin-bottom: 4px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-text {
  margin-bottom: 4px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-actions {
  display: flex;
  align-items: center;
  color: #606060;
  font-size: 14px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-like {
  margin-right: 8px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-replies {
  color: #065fd4;
  cursor: pointer;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer {
  padding: 16px;
  border-top: 1px solid var(--border-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper {
  display: flex;
  align-items: flex-start;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper span.nameAvatar {
  width: 10%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container {
  flex-grow: 1;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #e1e8ed;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage {
  width: 55%;
  height: 30%;
  margin-bottom: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage img {
  max-width: 55%;
  border-radius: 5px;
  height: auto;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage button.removeButton {
  position: absolute;
  background: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--button-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage button.removeButton:hover {
  background-color: #f2f2f2;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage button.removeButton svg {
  width: 15px;
  height: 15px;
  fill: var(--button-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer label.customFileUpload {
  background-color: #1da1f2;
  color: white;
  margin-right: 10px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer label.customFileUpload:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer input[type=file] {
  display: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer button.giphyButton {
  background-color: #1da1f2;
  color: white;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions button.submit {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions button.submit:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .add-comment {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  background-color: var(--input-bg-color);
  color: var(--text-color);
  margin-bottom: 8px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .add-comment:focus {
  outline: none;
  border-color: var(--focus-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions label.customFileUpload {
  width: 50%;
  text-align: center;
  border-radius: 50%;
  transition: background-color 0.2s;
  border: 1px solid #1da1f2;
  padding: 10px;
  margin: 10px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .action-button {
  background: none;
  cursor: pointer;
  color: var(--icon-color);
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.2s;
  border: 1px solid #1da1f2;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .action-button:hover {
  background-color: var(--hover-bg-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .submit-button {
  background: none;
  color: var(--button-text-color);
  border: none;
  padding: 8px 16px;
  border-radius: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .submit-button:not(:disabled):hover {
  background-color: var(--button-hover-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .image-preview {
  margin-top: 12px;
  position: relative;
  display: inline-block;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .image-preview img {
  max-width: 100%;
  max-height: 200px;
  border-radius: 4px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .image-preview .remove-preview {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .add-comment {
  width: 100%;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
div.shorts div.video-container div.customModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
div.shorts div.video-container div.customModal .modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
}
div.shorts div.video-container div.customModal .modalContent .closeButton {
  position: absolute;
  top: -6px;
  right: 2px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.video-js {
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
  word-break: initial;
  height: 100% !important;
  width: 100% !important;
}

div.replyFieldShorts {
  display: flex;
  width: 100%;
  padding-top: 30px;
  animation: fadeIn 0.4s ease-in-out;
}
div.replyFieldShorts.child {
  width: 92%;
  margin: 0 0 0 auto;
}
div.replyFieldShorts div.left {
  margin-right: 20px;
}
div.replyFieldShorts div.left div.imageContainer {
  width: 45px;
  height: 45px;
}
div.replyFieldShorts div.left div.imageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.replyFieldShorts div.left div.imageContainer span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.replyFieldShorts div.right {
  width: 100%;
}
div.replyFieldShorts div.right div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 15px !important;
  border: 1px solid #e1e8ed;
}
div.replyFieldShorts div.right div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply {
  width: 55%;
  height: 30%;
  position: relative;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply img {
  max-width: 100%;
  border-radius: 5px;
  height: auto;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton {
  position: absolute;
  background: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--button-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton:hover {
  background-color: #f2f2f2;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton svg {
  width: 15px;
  height: 15px;
  fill: var(--button-color);
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer {
  display: flex;
  align-items: center;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer label.customFileUploadReply {
  background: none;
  padding: 5px;
  border: 1px solid #1da1f2;
  color: white;
  margin-right: 10px;
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer label.customFileUploadReply:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.cancel {
  background: none;
  border: none !important;
  margin-right: 10px;
  color: var(--white-color);
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.cancel:hover {
  background-color: transparent;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer input[type=file] {
  display: none;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.giphyButton {
  background: none;
  color: white;
  border: 1px solid #1da1f2;
  margin-right: 10px;
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  padding: 5px;
  margin-right: 10px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions button.submit {
  background: none;
  font-weight: 600;
  border: none !important;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions button.submit:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyFieldShorts div.right div.inputContainer div.actions {
  display: flex;
  justify-content: flex-end;
}
div.replyFieldShorts div.right div.inputContainer div.actions p {
  font-size: 14px;
  color: var(--text-color);
}
div.replyFieldShorts div.right div.inputContainer div.actions p span {
  color: var(--grey-color);
  cursor: pointer;
}
div.replyFieldShorts div.right div.inputContainer div.actions p span:hover {
  text-decoration: underline;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer {
  display: flex;
  align-items: center;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer label.customFileUploadReply {
  background: none;
  border: 1px solid #1da1f2;
  color: white;
  margin-right: 10px;
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer label.customFileUploadReply:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer button.cancel {
  background: none;
  border: none !important;
  margin-right: 10px;
  color: var(--white-color);
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer button.cancel:hover {
  background-color: transparent;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer input[type=file] {
  display: none;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer button.giphyButton {
  background: none;
  color: white;
  border: 1px solid #1da1f2;
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  margin-right: 10px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}

div.b2bdetailsScreen {
  padding: 100px 0 0;
  position: relative;
}
div.b2bdetailsScreen div.expired-b2b {
  padding: 120px !important;
  background: var(--default-bg-color);
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
div.b2bdetailsScreen div.expired-b2b span {
  color: red;
}
div.b2bdetailsScreen div.expired-b2b div.warningText {
  padding-bottom: 20px !important;
}
div.b2bdetailsScreen div.expired-b2b div.warningIcon {
  line-height: 0;
  padding-right: 10px;
}
div.b2bdetailsScreen div.expired-b2b div.warningIcon svg {
  width: 30px !important;
  height: 30px !important;
  fill: white;
}
div.b2bdetailsScreen .swiper-button-next {
  right: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.b2bdetailsScreen .swiper-button-next {
    right: 0 !important;
  }
}
div.b2bdetailsScreen .swiper-button-prev {
  left: -9px !important;
  top: 45%;
}
@media all and (min-width: 1200px) {
  div.b2bdetailsScreen .swiper-button-prev {
    left: 0 !important;
  }
}
div.b2bdetailsScreen div.wrapper {
  position: relative;
  z-index: 8;
}
div.b2bdetailsScreen div.bgImageContainer {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 7;
  width: 100%;
}
div.b2bdetailsScreen div.bgImageContainer div.gradient {
  position: absolute;
  bottom: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, #10141f, rgba(16, 20, 31, 0) 260.46%), linear-gradient(1turn, #10141f, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4)), rgba(16, 20, 31, 0.4);
}
div.b2bdetailsScreen div.bgImageContainer img {
  width: 100%;
  display: block;
}
div.b2bdetailsScreen div.trailer {
  width: 90%;
  margin: 0 auto;
}
div.b2bdetailsScreen div.trailer div.trailerVideoPlayer {
  margin-bottom: 40px;
}
div.b2bdetailsScreen div.trailer div.videoTitle {
  padding: 0 0 30px;
}
div.b2bdetailsScreen div.trailer div.videoTitle h1.title, div.b2bdetailsScreen div.trailer div.videoTitle .title.h1 {
  color: white;
}
div.b2bdetailsScreen div.trailer div.videoTitle div.trailerDownload {
  width: 15%;
  margin-top: 15px;
}
div.b2bdetailsScreen div.trailer div.videoTitle div.trailerDownload button {
  width: 100%;
  display: block;
  background-color: rgb(255, 255, 255);
  padding: 15px 10px;
  font-size: 15px;
  color: black;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 980px) {
  div.b2bdetailsScreen div.trailer div.videoTitle div.trailerDownload button {
    width: 88px;
    height: 39px;
    padding: 0;
  }
}
@media screen and (max-width: 640px) {
  div.b2bdetailsScreen div.trailer div.videoTitle h1.title, div.b2bdetailsScreen div.trailer div.videoTitle .title.h1 {
    font-size: 0.9rem;
  }
}
div.b2bdetailsScreen div.videoContainer {
  background-color: #000;
  width: 100%;
}
div.b2bdetailsScreen div.videoContainer.heightIncrease {
  height: 38em;
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.videoContainer.heightIncrease {
    height: 25em;
  }
}
div.b2bdetailsScreen div.videoContainer div.gradientTop {
  position: absolute;
  top: 0;
  z-index: 8;
  width: 100%;
  height: 20%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(0deg, rgba(16, 20, 31, 0), rgba(16, 20, 31, 0.2) 19%, rgba(16, 20, 31, 0.4) 39%, rgba(16, 20, 31, 0.8));
}
div.b2bdetailsScreen div.videoContainer div.player {
  position: relative;
  z-index: 9;
  width: 100%;
  display: block;
}
div.b2bdetailsScreen div.videoContainer div.player div.closeIcon {
  display: none;
}
div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 7;
}
div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying h1, div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying .h1 {
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying h1, div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying .h1 {
    font-size: 23px;
  }
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying h1, div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying .h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 768px) {
  div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying h1, div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying .h1 {
    font-size: 18px;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying h1, div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying .h1 {
    font-size: 16px;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying h1, div.b2bdetailsScreen div.videoContainer div.player div.nowPlaying .h1 {
    font-size: 14px;
  }
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode {
  position: fixed;
  top: 101.5px;
  width: 20%;
  right: 50px;
  z-index: 999999999;
  height: 160px;
}
@media all and (max-width: 768px) {
  div.b2bdetailsScreen div.videoContainer div.player.pipMode {
    width: 40%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.videoContainer div.player.pipMode {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.videoContainer div.player.pipMode {
    width: 68%;
  }
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode video {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode .video-js {
  padding: 0;
  height: 100%;
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode div.gradientTop {
  display: none;
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode div.nowPlaying {
  display: none;
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode:hover div.closeIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  background-color: var(--bg-color);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode:hover div.closeIcon svg {
  color: var(--text-color);
}
div.b2bdetailsScreen div.videoContainer div.player.pipMode:hover div.gradientTop {
  display: block;
}
div.b2bdetailsScreen div.videoContainer video {
  display: block;
  width: 100%;
}
div.b2bdetailsScreen div.details {
  position: relative;
  z-index: 8;
  display: flex;
  padding: 30px 0 130px;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details {
    flex-direction: column;
  }
}
div.b2bdetailsScreen div.details div.reportProblemContainer {
  position: absolute;
  bottom: -99px;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 100%;
  border-radius: 8px;
  padding: 15px;
  display: none;
  cursor: pointer;
}
div.b2bdetailsScreen div.details div.reportProblemContainer.active {
  display: block;
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.details div.reportProblemContainer {
    width: 100%;
  }
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.reportProblemContainer {
    width: 100%;
    position: relative;
    bottom: 0;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
div.b2bdetailsScreen div.details div.reportProblemContainer div.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
div.b2bdetailsScreen div.details div.reportProblemContainer div.closeIcon {
  position: absolute;
  top: 4px;
  right: 3px;
  z-index: 9;
  background-color: var(--bg-color);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.b2bdetailsScreen div.details div.reportProblemContainer div.closeIcon svg {
  color: var(--text-color);
}
div.b2bdetailsScreen div.details div.reportProblemContainer span {
  color: var(--text-color);
  margin: 3px;
}
div.b2bdetailsScreen div.details div.reportProblemContainer span:hover {
  color: #a9a9a9;
}
div.b2bdetailsScreen div.details div.reportProblemContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  right: 46%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.details div.reportProblemContainer::after {
    right: 43%;
  }
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.reportProblemContainer::after {
    right: 49%;
  }
}
div.b2bdetailsScreen div.details div.watchNow {
  width: 100%;
  margin-top: 15px;
}
div.b2bdetailsScreen div.details div.watchNow button {
  width: 100%;
  display: block;
  background: var(--button-color);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.watchNow button:hover {
  background: var(--button-hover);
}
div.b2bdetailsScreen div.details div.trailerWatchNowButton {
  color: #10141f;
  background-color: #10141f;
  width: 100%;
  margin-top: 15px;
}
div.b2bdetailsScreen div.details div.trailerWatchNowButton button {
  width: 100%;
  display: block;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.trailerWatchNowButton button:hover {
  background: var(--button-hover);
}
div.b2bdetailsScreen div.details div.addtoList {
  width: 100%;
  margin-top: 15px;
}
div.b2bdetailsScreen div.details div.addtoList button {
  width: 100%;
  display: block;
  background-color: rgb(255, 255, 255);
  padding: 15px 10px;
  font-size: 15px;
  color: black;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons {
  margin-top: 15px;
  display: flex;
  width: 100%;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne {
  width: 70%;
  position: relative;
  margin-right: 10px;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne {
    width: 75%;
  }
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
  position: absolute;
  bottom: -126px;
  left: -20px;
  z-index: 9;
  background-color: #1c1f29;
  border: 1px solid var(--border-color);
  width: 232px;
  border-radius: 8px;
  display: none;
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 200px;
  }
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 232px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer {
    width: 100%;
  }
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer.active {
  display: block;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
  content: "";
  top: -9px;
  width: 0;
  height: 0;
  border-color: transparent transparent rgba(240, 241, 245, 0.16);
  border-style: solid;
  border-width: 0 8px 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
    left: 40%;
  }
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer::after {
    left: 50%;
  }
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  border-bottom: 1px solid var(--border-color) !important;
  width: 100% !important;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon {
  width: 10%;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer button.facebook div.icon svg:hover {
  color: var(--text-color);
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.left {
  width: 10%;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.facebookContainer div.right svg:hover {
  color: var(--text-color);
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  width: 100% !important;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon {
  width: 10%;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer button.twitter div.icon svg:hover {
  color: var(--text-color);
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.left {
  width: 10%;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.right svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne div.shareContainer div.twitterContainer div.right svg:hover {
  color: var(--text-color);
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne button.share {
  display: block;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerOne button.share:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo {
  width: 30%;
  position: relative;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 10%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 18%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo {
    width: 25%;
  }
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo button.more {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(150, 153, 163, 0.16);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo button.more svg {
  width: 20px !important;
  height: 20px !important;
}
div.b2bdetailsScreen div.details div.buttons div.buttonContainerTwo button.more:hover {
  background-color: rgba(203, 203, 208, 0.16);
}
div.b2bdetailsScreen div.details div.subscriptions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
div.b2bdetailsScreen div.details div.subscriptions div.items {
  cursor: pointer;
  margin-right: 10px;
  background-color: var(--yellow-color);
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 20px;
  border-radius: 30px;
}
div.b2bdetailsScreen div.details div.subscriptions div.items.free {
  background-color: var(--success-color);
}
div.b2bdetailsScreen div.details div.leftContainer {
  width: 27%;
  margin-right: 40px;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.leftContainer {
    display: none;
  }
}
div.b2bdetailsScreen div.details div.leftContainer div.showCardDetails {
  width: 100%;
  position: sticky;
  top: 0;
}
div.b2bdetailsScreen div.details div.leftContainer div.showCardDetails div.imageContainer {
  width: 100%;
}
div.b2bdetailsScreen div.details div.leftContainer div.showCardDetails div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
div.b2bdetailsScreen div.details div.rightContainer {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.b2bdetailsScreen div.details div.rightContainer::-webkit-scrollbar {
  display: none;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 40px;
  width: 100%;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata h1.name, div.b2bdetailsScreen div.details div.rightContainer div.showMetadata .name.h1 {
  font-size: 58px;
  font-weight: 700;
  color: var(--text-color);
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata h1.name, div.b2bdetailsScreen div.details div.rightContainer div.showMetadata .name.h1 {
    font-size: 48px;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata h1.name, div.b2bdetailsScreen div.details div.rightContainer div.showMetadata .name.h1 {
    font-size: 35px;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data {
  display: flex;
  margin-bottom: 6px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span {
  font-size: 18px;
  color: var(--inner-text-color);
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span {
    font-size: 14px;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span.year {
  margin-right: 6px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span.duration {
  margin-right: 8px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span.duration.showDot::before {
  content: "·";
  margin-right: 6px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span.certificate {
  display: block;
  padding: 4px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 11px;
  font-weight: 700;
  color: var(--text-color);
  border-radius: 4px;
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.data span.certificate {
    font-size: 10px;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.genres {
  margin-bottom: 10px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.genres span {
  font-size: 18px;
  color: var(--inner-text-color);
  margin-right: 6px;
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.genres span {
    font-size: 14px;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.genres span::before {
  content: "·";
  margin-right: 6px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.genres span:first-child::before {
  display: none;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.genres span:last-child {
  margin-right: 0;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.watchNow {
  display: none;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.watchNow {
    display: block;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.trailerWatchNowButton {
  display: none;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.trailerWatchNowButton {
    display: block;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.addtoList {
  display: none;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.addtoList {
    display: block;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.buttons {
  display: none;
  margin-bottom: 20px;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.buttons {
    display: flex;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata p.description {
  font-size: 15px;
  color: var(--text-color);
  line-height: 1.4em;
  letter-spacing: 0.3px;
  max-width: 100%;
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata p.description {
    max-width: 100%;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info {
  margin-top: 20px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left {
    width: 33%;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left h6, div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.left .h6 {
  font-size: 16px;
  font-weight: 400;
  color: var(--inner-text-color);
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right {
    width: 55%;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names {
  display: flex;
  flex-wrap: wrap;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names li {
  font-size: 16px;
  font-weight: 600;
  margin-right: 8px;
  color: var(--text-color);
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names li:last-child {
  margin-right: 0;
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.starring div.right ul.names li {
    font-size: 14px;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director {
  display: flex;
  justify-content: space-between;
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
  width: 15%;
}
@media all and (max-width: 768px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 20%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 30%;
  }
}
@media all and (max-width: 360px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left {
    width: 33%;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left h6, div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.left .h6 {
  font-size: 16px;
  font-weight: 400;
  color: var(--inner-text-color);
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
  width: 85%;
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 65%;
  }
}
@media all and (max-width: 360px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right {
    width: 55%;
  }
}
div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right h6, div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right .h6 {
  font-size: 16px;
  font-weight: 600;
  margin-right: 8px;
  color: var(--text-color);
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right h6, div.b2bdetailsScreen div.details div.rightContainer div.showMetadata div.info div.director div.right .h6 {
    font-size: 14px;
  }
}
div.b2bdetailsScreen div.downloadPlaylist {
  width: 18%;
  z-index: 15;
  top: 630px;
  right: 40px;
  position: absolute;
}
div.b2bdetailsScreen div.downloadPlaylist button {
  width: 80%;
  display: block;
  background-color: var(--button-color);
  border: 1px solid var(--button-color);
  padding: 15px 10px;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.b2bdetailsScreen div.downloadPlaylist button:hover {
  background-color: transparent;
}
@media (max-width: 2440px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 10%;
    top: 679px;
    left: 127px;
  }
}
@media (max-width: 1280px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 13%;
    left: 104px;
  }
}
@media (max-width: 980px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 14%;
    top: 485px;
    left: 577px;
  }
}
@media (max-width: 768px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 17%;
    left: 93px;
    top: 752px;
  }
  div.b2bdetailsScreen div.downloadPlaylist button {
    width: 68%;
    font-size: 12px;
    font-weight: 300;
  }
}
@media (max-width: 640px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 21%;
    right: 104px;
  }
}
@media (max-width: 480px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 26%;
    top: 620px;
    left: 59px;
  }
}
@media (max-width: 360px) {
  div.b2bdetailsScreen div.downloadPlaylist {
    width: 64%;
    left: 100px;
    top: 586px;
  }
}
div.b2bdetailsScreen div.progressBarVideo {
  width: 39px;
  height: 52px;
  left: 214px;
  z-index: 10px;
  position: absolute;
  top: 249px;
}
@media (max-width: 2440px) {
  div.b2bdetailsScreen div.progressBarVideo {
    top: 236px;
    width: 37px;
    left: 115px;
  }
}
@media (max-width: 1280px) {
  div.b2bdetailsScreen div.progressBarVideo {
    top: 203px;
    left: 79px;
  }
}
@media (max-width: 980px) {
  div.b2bdetailsScreen div.progressBarVideo {
    display: block;
    width: 100px;
  }
}
@media (max-width: 768px) {
  div.b2bdetailsScreen div.progressBarVideo {
    top: 757px;
    left: 94px;
  }
}
@media (max-width: 640px) {
  div.b2bdetailsScreen div.progressBarVideo {
    top: 731px;
  }
}
@media (max-width: 480px) {
  div.b2bdetailsScreen div.progressBarVideo {
    top: 628px;
    left: 53px;
    width: 24px;
  }
}
@media (max-width: 360px) {
  div.b2bdetailsScreen div.progressBarVideo {
    top: 600px;
    width: 20px;
  }
}
div.b2bdetailsScreen div.downloadButton {
  width: 6%;
  padding: 6px;
  margin-left: 263px;
}
@media (max-width: 2440px) {
  div.b2bdetailsScreen div.downloadButton {
    margin-left: 0px;
    width: 6%;
  }
}
@media (max-width: 1280px) {
  div.b2bdetailsScreen div.downloadButton {
    top: 240px;
    left: 65px;
  }
}
@media (max-width: 980px) {
  div.b2bdetailsScreen div.downloadButton {
    width: 100px;
    margin-left: 320px;
  }
}
@media (max-width: 768px) {
  div.b2bdetailsScreen div.downloadButton {
    margin-left: 200px;
  }
}
@media (max-width: 640px) {
  div.b2bdetailsScreen div.downloadButton {
    width: 70px;
  }
}
@media (max-width: 480px) {
  div.b2bdetailsScreen div.downloadButton {
    top: 628px;
    left: 53px;
    width: 24px;
  }
}
@media (max-width: 360px) {
  div.b2bdetailsScreen div.downloadButton {
    top: 600px;
    width: 20px;
  }
}
div.b2bdetailsScreen div.videoContainerPlaylist {
  background-color: #000;
  width: 35%;
  z-index: 10;
  top: 230px;
  right: 100px;
  position: absolute;
}
@media (max-width: 2440px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 32%;
    top: 398px;
    right: 118px;
  }
}
@media (max-width: 1280px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 40%;
    top: 366px;
    right: 67px;
  }
}
@media (max-width: 980px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 45%;
    top: 247px;
    right: 254px;
  }
}
@media (max-width: 768px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 70%;
    top: 254px;
    left: 89px;
  }
}
@media (max-width: 640px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 73%;
    top: 258px;
    left: 80px;
  }
}
@media (max-width: 480px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 75%;
    top: 239px;
    left: 59px;
  }
}
@media (max-width: 360px) {
  div.b2bdetailsScreen div.videoContainerPlaylist {
    width: 78%;
    left: 35px;
  }
}
div.b2bdetailsScreen div.videoContainerPlaylist.heightIncrease {
  height: 28em;
}
@media all and (max-width: 1200px) {
  div.b2bdetailsScreen div.videoContainerPlaylist.heightIncrease {
    height: 25em;
  }
}
div.b2bdetailsScreen div.videoContainerPlaylist div.gradientTop {
  position: absolute;
  top: 0;
  z-index: 8;
  width: 100%;
  height: 20%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(0deg, rgba(16, 20, 31, 0), rgba(16, 20, 31, 0.2) 19%, rgba(16, 20, 31, 0.4) 39%, rgba(16, 20, 31, 0.8));
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer {
  position: relative;
  z-index: 9;
  width: 100%;
  display: block;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.closeIcon {
  display: none;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 7;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1, div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst .h1 {
  color: var(--text-color);
}
@media (max-width: 2440px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1, div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst .h1 {
    font-size: 14px;
  }
}
@media all and (max-width: 1280px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1, div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst .h1 {
    font-size: 12px;
  }
}
@media all and (max-width: 980px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1, div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst .h1 {
    font-size: 10px;
  }
}
@media all and (max-width: 768px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1, div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst .h1 {
    font-size: 10px;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1, div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst .h1 {
    font-size: 8px;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst h1, div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer div.nowPlayingPlaylilst .h1 {
    font-size: 6px;
  }
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode {
  position: fixed;
  top: 110px;
  width: 22%;
  right: 15px;
  z-index: 999999999;
  height: 200px;
}
@media all and (max-width: 768px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode {
    width: 40%;
  }
}
@media all and (max-width: 640px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode {
    width: 68%;
  }
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode video {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode .video-js {
  padding: 0;
  height: 100%;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode div.gradientTop {
  display: none;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode div.nowPlaying {
  display: none;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode:hover div.closeIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  background-color: var(--bg-color);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode:hover div.closeIcon svg {
  color: var(--text-color);
}
div.b2bdetailsScreen div.videoContainerPlaylist div.playListplayer.pipMode:hover div.gradientTop {
  display: block;
}
div.b2bdetailsScreen div.videoContainerPlaylist video {
  display: block;
  width: 100%;
}
div.playListPage {
  padding: 130px 0 40px;
}
div.playListPage div.headingSection {
  padding-bottom: 60px;
  position: absolute;
  top: 84px;
  z-index: 8;
  left: 60px;
}
@media all and (max-width: 640px) {
  div.playListPage div.headingSection {
    top: 50px;
    z-index: 8;
    left: 20px;
  }
}
div.playListPage div.headingSection h1.heading, div.playListPage div.headingSection .heading.h1 {
  font-size: 40px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--text-color);
  overflow: hidden;
  text-overflow: ellipsis;
}
@media all and (max-width: 640px) {
  div.playListPage div.headingSection h1.heading, div.playListPage div.headingSection .heading.h1 {
    font-size: 22px;
    margin-top: 50px;
  }
}
div.playListPage div.headingSection p.desc {
  font-size: 18px;
  color: var(--inner-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
}
@media all and (max-width: 640px) {
  div.playListPage div.headingSection p.desc {
    font-size: 14px;
  }
}
div.playListPage div.headingSection-wrap {
  padding-bottom: 60px;
  width: 55%;
  position: absolute;
  top: 84px;
  z-index: 8;
  left: 60px;
}
@media (max-width: 1280px) {
  div.playListPage div.headingSection-wrap {
    width: 40%;
    right: 100px;
    top: 63px;
  }
}
@media (max-width: 980px) {
  div.playListPage div.headingSection-wrap {
    width: 80%;
    top: 231px;
    right: 107px;
  }
}
@media (max-width: 768px) {
  div.playListPage div.headingSection-wrap {
    padding-bottom: 60px;
    position: absolute;
    top: 298px;
    z-index: 8;
    left: 39px;
    width: 82%;
  }
}
@media (max-width: 640px) {
  div.playListPage div.headingSection-wrap {
    top: 250px;
    left: 39px;
  }
}
@media (max-width: 480px) {
  div.playListPage div.headingSection-wrap {
    top: 200px;
    left: 22px;
  }
}
@media (max-width: 360px) {
  div.playListPage div.headingSection-wrap {
    top: 163px;
    left: 22px;
  }
}
div.playListPage div.headingSection-wrap h1.heading, div.playListPage div.headingSection-wrap .heading.h1 {
  font-size: 40px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--text-color);
  overflow: hidden;
  text-overflow: ellipsis;
}
@media all and (max-width: 640px) {
  div.playListPage div.headingSection-wrap h1.heading, div.playListPage div.headingSection-wrap .heading.h1 {
    font-size: 18px;
    margin-top: 50px;
  }
}
div.playListPage div.headingSection-wrap p.desc {
  font-size: 16px;
  color: var(--inner-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 2440px) {
  div.playListPage div.headingSection-wrap p.desc {
    font-size: 16px;
  }
}
@media (max-width: 1280px) {
  div.playListPage div.headingSection-wrap p.desc {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  div.playListPage div.headingSection-wrap p.desc {
    font-size: 9px;
  }
}
@media all and (max-width: 640px) {
  div.playListPage div.headingSection-wrap p.desc {
    font-size: 8px;
  }
}
@media all and (max-width: 480px) {
  div.playListPage div.headingSection-wrap p.desc {
    font-size: 6px;
  }
}
@media all and (max-width: 360px) {
  div.playListPage div.headingSection-wrap p.desc {
    font-size: 4px;
  }
}
div.playListPage div.backgroundContainer {
  width: 100%;
  display: block;
  height: 508px;
  position: relative;
}
@media all and (max-width: 768px) {
  div.playListPage div.backgroundContainer {
    height: 556px;
  }
}
div.playListPage div.backgroundContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  div.playListPage div.backgroundContainer img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.playListPage div.bottomGradient {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}
div.playListPage div.playlistBody div.playlistContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
  width: 30%;
  margin-bottom: 30px;
  margin-right: 20px;
}
div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
  margin-right: 0px;
}
@media all and (max-width: 2600px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    margin-right: 30px;
    width: 15.5%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-right: 30px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(6n) {
    margin-right: 0px;
  }
}
@media all and (max-width: 2500px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 15.8%;
    margin-right: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-right: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(6n) {
    margin-right: 0px;
  }
}
@media all and (max-width: 2200px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 19%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(5n) {
    margin-right: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(6n) {
    margin-right: 20px;
  }
}
@media all and (max-width: 1980px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 23.7%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(4n) {
    margin-right: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(5n) {
    margin-right: 20px;
  }
}
@media all and (max-width: 1768px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 23.8%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(4n) {
    margin-right: 0px;
  }
}
@media all and (max-width: 1600px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 23.8%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(4n) {
    margin-right: 0px;
  }
}
@media all and (max-width: 1400px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 31.5%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-right: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(4n) {
    margin-right: 20px;
  }
}
@media all and (max-width: 1200px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 31.3%;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-right: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(4n) {
    margin-right: 20px;
  }
}
@media all and (max-width: 1080px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 31%;
    margin-right: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(5n) {
    margin-right: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-right: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(2n) {
    margin-right: 20px;
  }
}
@media all and (max-width: 980px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 47.8%;
    margin-right: 25px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(5n) {
    margin-right: 25px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-right: 25px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(2n) {
    margin-right: 0px;
  }
}
@media all and (max-width: 768px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 47.8%;
    margin-right: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(5n) {
    margin-right: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-right: 20px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(2n) {
    margin-right: 0px;
  }
}
@media all and (max-width: 640px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems {
    width: 99%;
    margin-bottom: 10px;
    margin-right: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(3n) {
    margin-right: 0px;
  }
  div.playListPage div.playlistBody div.playlistContainer div.playlistItems:nth-child(5n) {
    margin-right: 0px;
  }
}
div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
  width: 15%;
  margin-bottom: 30px;
  margin-right: 20px;
  margin-left: 20px;
}
@media all and (max-width: 2600px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 14.9%;
  }
}
@media all and (max-width: 2500px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 14.6%;
  }
}
@media all and (max-width: 2200px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 17.8%;
  }
}
@media all and (max-width: 1980px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 17.5%;
  }
}
@media all and (max-width: 1768px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 17.5%;
  }
}
@media all and (max-width: 1600px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 16.8%;
  }
}
@media all and (max-width: 1400px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 16.3%;
  }
}
@media all and (max-width: 1200px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 20.9%;
  }
}
@media all and (max-width: 1080px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 20.5%;
  }
}
@media all and (max-width: 980px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 27.8%;
  }
}
@media all and (max-width: 768px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 26.8%;
  }
}
@media all and (max-width: 640px) {
  div.playListPage div.playlistBody div.playlistContainer div.playlistItemsPortrait {
    width: 90%;
    margin: 0 auto 10px auto;
  }
}

div.playlistCardContainer {
  width: 100%;
  position: relative;
  margin-top: 20px;
  margin-right: 10px;
}
div.playlistCardContainer:nth-child(3n) {
  margin-right: 0px;
}
div.playlistCardContainer div.showsSubscriptions {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 1200px) {
  div.playlistCardContainer div.showsSubscriptions {
    display: none;
  }
}
div.playlistCardContainer div.showsSubscriptions div.items {
  width: 5rem;
  margin-right: 10px;
  background-color: var(--yellow-color);
  padding: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 10px;
  border-radius: 30px;
}
div.playlistCardContainer div.showsSubscriptions div.items.free {
  background-color: var(--success-color);
}
@media all and (max-width: 1200px) {
  div.playlistCardContainer div.showsSubscriptions div.items {
    font-size: 8px;
    width: 4rem;
  }
}
div.playlistCardContainer div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.playlistCardContainer div.premiumStatus div.premium {
    width: 26px;
  }
}
div.playlistCardContainer div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.playlistCardContainer div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.playlistCardContainer div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 50px;
}
@media all and (max-width: 1200px) {
  div.playlistCardContainer div.premiumStatus div.free {
    width: 30px;
  }
}
div.playlistCardContainer div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.playlistCardContainer.showContents {
  cursor: pointer;
}
div.playlistCardContainer.showContents:hover div.imageContainer div.extraActions {
  visibility: visible;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.playlistCardContainer.showContents:hover div.imageContainer img {
  transform: scale(1.1);
}
div.playlistCardContainer.showContents:hover div.metaData h1.title, div.playlistCardContainer.showContents:hover div.metaData .title.h1 {
  color: var(--text-hover);
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
div.playlistCardContainer div.imageContainer {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
div.playlistCardContainer div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}
div.playlistCardContainer div.imageContainer img.landscape {
  min-height: 130px;
  background: black;
}
div.playlistCardContainer div.imageContainer img.portrait {
  min-height: 420px;
  background: black;
}
div.playlistCardContainer div.imageContainer div.continueWatchingBar {
  position: absolute;
  bottom: 0;
  height: 6px;
  background-color: var(--text-color);
  width: 100%;
}
div.playlistCardContainer div.imageContainer div.continueWatchingBar div.line {
  height: 100%;
  background-color: var(--button-hover);
}
div.playlistCardContainer div.imageContainer div.extraActions {
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.playlistCardContainer div.imageContainer div.extraActions div.showCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
@media all and (min-width: 320px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer {
    width: 30px;
    height: 30px;
  }
  div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 13px !important;
    height: 25px !important;
  }
}
@media all and (min-width: 320px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer {
    width: 50px;
    height: 50px;
  }
}
div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer:hover {
  border-color: var(--text-hover);
}
div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer:hover svg {
  color: var(--text-hover);
}
div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
  width: 13px !important;
  height: 13px !important;
  color: var(--text-color);
  transition: 0.3s ease-in-out;
}
@media all and (min-width: 320px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 13px !important;
    height: 13px !important;
  }
}
@media all and (min-width: 480px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 25px !important;
    height: 18px !important;
  }
}
div.playlistCardContainer div.imageContainer div.extraActions div.addToList {
  position: absolute;
  bottom: 3px;
  width: 96%;
  z-index: 9;
  left: 50%;
  border-radius: 8px;
  transform: translateX(-50%);
  padding: 6px;
  text-align: center;
  box-shadow: rgba(62, 1, 1, 0.03) 0px 16px 24px;
  background-color: rgba(49, 46, 46, 0.3);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}
@media all and (min-width: 320px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.addToList {
    padding: 0;
    border-radius: 2px;
    width: 97%;
  }
  div.playlistCardContainer div.imageContainer div.extraActions div.addToList span {
    font-size: 8px;
  }
}
@media all and (min-width: 480px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.addToList {
    padding: 3px;
  }
  div.playlistCardContainer div.imageContainer div.extraActions div.addToList span {
    font-size: 10px;
  }
}
div.playlistCardContainer div.imageContainer div.extraActions div.addToList:hover span {
  color: var(--text-hover);
}
div.playlistCardContainer div.imageContainer div.extraActions div.addToList span {
  color: var(--text-color);
  font-size: 10px;
  transition: 0.3s ease-in-out;
}
@media all and (min-width: 480px) {
  div.playlistCardContainer div.imageContainer div.extraActions div.addToList span {
    font-size: 11px;
  }
}
div.playlistCardContainer div.metaData {
  color: var(--text-color);
  background-color: #2e2e2e;
  padding: 20px;
  border-color: #2e2e2e;
}
@media all and (min-width: 980px) {
  div.playlistCardContainer div.metaData {
    padding: 10px;
  }
}
div.playlistCardContainer div.metaData h1.title, div.playlistCardContainer div.metaData .title.h1 {
  color: var(--text-color);
  font-size: 25px;
  transition: 0.3s ease-in-out;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}
@media all and (max-width: 1200px) {
  div.playlistCardContainer div.metaData h1.title, div.playlistCardContainer div.metaData .title.h1 {
    font-size: 20px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
@media all and (max-width: 980px) {
  div.playlistCardContainer div.metaData h1.title, div.playlistCardContainer div.metaData .title.h1 {
    font-size: 20px;
  }
}
@media all and (min-width: 640px) {
  div.playlistCardContainer div.metaData h1.title, div.playlistCardContainer div.metaData .title.h1 {
    font-size: 25px;
  }
}
div.playlistCardContainer div.metaData div.datas {
  display: flex;
  justify-content: space-between;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
div.playlistCardContainer div.metaData div.datas div.left span {
  font-size: 12px;
  margin-right: 6px;
}
div.playlistCardContainer div.metaData div.datas div.left span.duration::before {
  margin-right: 6px;
}
div.playlistCardContainer div.metaData div.datas div.left span:last-child {
  margin-right: 0;
}
div.playlistCardContainer div.metaData div.datas div.right span {
  font-size: 14px;
  display: inline-block;
  padding: 1px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 12px;
  font-weight: 700;
  color: var(--text-color);
  border-radius: 4px;
}
@media all and (min-width: 320px) {
  div.playlistCardContainer div.metaData div.datas div.right span.certificate {
    font-size: 9px;
  }
}
div.playlistCardContainer div.metaData div.genres {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}
@media all and (min-width: 320px) {
  div.playlistCardContainer div.metaData div.genres {
    display: none;
  }
}
@media all and (min-width: 768px) {
  div.playlistCardContainer div.metaData div.genres {
    display: flex;
  }
}
div.playlistCardContainer div.metaData div.genres span {
  margin-right: 6px;
}
div.playlistCardContainer div.metaData div.genres span:last-child {
  margin-right: 0;
}
div.playlistCardContainer div.metaData div.genres span:first-child::before {
  display: none;
}
div.playlistCardContainer div.metaData div.genres span::before {
  content: "·";
  margin-right: 6px;
}
div.playlistCardContainer div.metaData p.description {
  font-size: 15px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media all and (max-width: 980px) {
  div.playlistCardContainer div.metaData p.description {
    font-size: 13px;
  }
}

div.progressBar {
  position: relative;
  z-index: 9;
  width: 98%;
  padding: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 2px solid rgba(150, 153, 163, 0.16);
  border-radius: 8px; /* Rounded corners for the modal */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Box shadow for a subtle lift effect */
  text-align: center;
  animation: fadeInOut 1.5s ease-in-out infinite;
}
div.progressBar p.downloadText {
  color: white;
  font-size: 16px;
}
@keyframes fadeInOut {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@media (max-width: 2600px) {
  div.progressBar {
    width: 98%;
    left: 10px;
    top: 0px;
  }
  div.progressBar p.downloadText {
    font-size: 16px;
  }
}
@media (max-width: 2500px) {
  div.progressBar {
    width: 98%;
    left: 10px;
    top: 0px;
  }
  div.progressBar p.downloadText {
    font-size: 15px;
  }
}
@media (max-width: 2200px) {
  div.progressBar {
    width: 98%;
    left: 10px;
    top: 0px;
  }
  div.progressBar p.downloadText {
    font-size: 14px;
  }
}
@media (max-width: 1980px) {
  div.progressBar {
    top: 410px;
  }
  div.progressBar p.downloadText {
    font-size: 13px;
  }
}
@media (max-width: 1768px) {
  div.progressBar {
    width: 98%;
    left: 10px;
    top: 0px;
  }
  div.progressBar p.downloadText {
    font-size: 12px;
  }
}
@media (max-width: 1600px) {
  div.progressBar p.downloadText {
    font-size: 11px;
  }
}
@media (max-width: 1400px) {
  div.progressBar p.downloadText {
    font-size: 10px;
  }
}
@media (max-width: 1200px) {
  div.progressBar p.downloadText {
    font-size: 9px;
  }
}
@media (max-width: 1280px) {
  div.progressBar p.downloadText {
    font-size: 10px;
  }
}
@media (max-width: 980px) {
  div.progressBar p.downloadText {
    font-size: 7px;
  }
}
@media (max-width: 768px) {
  div.progressBar p.downloadText {
    font-size: 7px;
  }
}
@media (max-width: 640px) {
  div.progressBar p.downloadText {
    font-size: 7px;
  }
}
@media (max-width: 480px) {
  div.progressBar p.downloadText {
    font-size: 6px;
  }
}
@media (max-width: 360px) {
  div.progressBar p.downloadText {
    font-size: 6px;
  }
}

div.livePlayerContainer {
  background-color: var(--bg-color);
  position: relative;
}
div.livePlayerContainer div.topplayerCenter {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 97vh;
}
@media all and (max-width: 480px) {
  div.livePlayerContainer div.topplayerCenter {
    min-height: 508px;
  }
}
@media all and (max-width: 360px) {
  div.livePlayerContainer div.topplayerCenter {
    height: 300px;
    margin-top: 15%;
  }
}
div.livePlayerContainer div.topplayerCenter div.wrapper {
  display: flex;
  justify-content: center;
  padding-top: 5%;
}
div.livePlayerContainer div.topplayerCenter div.bgImage {
  padding: 0px 0;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100%;
  max-height: 800px;
  position: absolute;
  z-index: 3;
  top: 0;
}
div.livePlayerContainer div.topplayerCenter div.gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  z-index: 4;
  height: 500px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 94.15%);
}
div.livePlayerContainer div.topplayerCenter div.videoContainer {
  z-index: 9;
  width: 55%;
  top: 90px;
  height: 80%;
}
@media all and (max-width: 980px) {
  div.livePlayerContainer div.topplayerCenter div.videoContainer {
    width: 100%;
  }
}
@media all and (max-width: 360px) {
  div.livePlayerContainer div.topplayerCenter div.videoContainer {
    width: 100%;
    height: 52%;
    margin-top: 4%;
  }
}
div.livePlayerContainer div.topplayerCenter div.videoContainer div.video-js {
  border-radius: 15px;
  height: 408px;
}
@media all and (max-width: 980px) {
  div.livePlayerContainer div.topplayerCenter div.videoContainer div.video-js {
    height: 300px;
  }
}
@media all and (max-width: 480px) {
  div.livePlayerContainer div.topplayerCenter div.videoContainer div.video-js {
    height: 200px;
  }
}
div.livePlayerContainer div.topplayerCenter div.videoContainer video {
  border-radius: 15px;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer {
  position: relative;
  width: 100%;
  margin-top: 1%;
  margin-left: 3.5%;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info {
  display: flex;
  color: var(--text-color);
  position: relative;
  z-index: 9;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.channelLogo {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.channelLogo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer {
  padding: 1%;
  width: 100%;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer div.liveLabel {
  background-color: #f52d2d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 3px;
  width: 3.5rem;
  border-radius: 3px;
  margin-bottom: 10px;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer div.liveLabel span {
  font-weight: 600;
  font-size: 11px;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer div.liveLabel svg {
  width: 16px !important;
  height: 16px !important;
  margin-right: 6px;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer h1.title, div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer .title.h1 {
  font-size: 30px;
  margin-bottom: 15px;
}
@media all and (max-width: 480px) {
  div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer h1.title, div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer .title.h1 {
    font-size: 26px;
  }
}
@media all and (max-width: 360px) {
  div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer h1.title, div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer .title.h1 {
    font-size: 22px;
  }
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer div.additionalInfo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer div.additionalInfo h6.timeLeft, div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer div.additionalInfo .timeLeft.h6 {
  color: var(--white-color);
  font-size: 15px;
  margin-right: 15px;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer div.additionalInfo span.caption {
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 10px;
  padding: 2px 4px;
  font-weight: 700;
  border-radius: 3px;
  outline: none;
  border: none;
  margin-right: 15px;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer div.additionalInfo span.rating {
  background-color: hsla(226, 7%, 61%, 0.32);
  border-radius: 4px;
  color: var(--white-color);
  color: var(--text-color);
  font-size: 12px;
  font-weight: 700;
  padding: 1px 8px;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer p.description {
  max-width: 100%;
  font-size: 15px;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer p.description span.readMore, div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer p.description span.readLess {
  color: var(--button-color);
  margin-left: 6px;
  cursor: pointer;
}
div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer p.description span.readMore:hover, div.livePlayerContainer div.topplayerCenter div.bannerContainer div.info div.infoContainer p.description span.readLess:hover {
  text-decoration: underline;
}
div.livePlayerContainer div.wrapper {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1%;
}
div.livePlayerContainer div.wrapper div.categories {
  margin-bottom: 30px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
div.livePlayerContainer div.wrapper div.categories::-webkit-scrollbar {
  display: none;
}
div.livePlayerContainer div.wrapper div.categories div.buttons {
  width: 1200px;
}
div.livePlayerContainer div.wrapper div.categories div.buttons button {
  background-color: var(--black-color);
  padding: 5px 10px;
  color: var(--white-color);
  margin-right: 10px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 30px;
  cursor: pointer;
}
div.livePlayerContainer div.wrapper div.categories div.buttons button.active {
  background-color: var(--white-color);
  color: var(--black-color);
}
div.livePlayerContainer div.wrapper div.categories div.buttons button:last-child {
  margin-right: 0;
}
div.livePlayerContainer div.wrapper div.scheduleTime {
  width: 90%;
  margin: 0 0 6px auto;
  display: flex;
  align-items: center;
  position: relative;
}
div.livePlayerContainer div.wrapper div.scheduleTime div.time {
  border-left: 2px solid var(--border-color);
  padding-left: 10px;
  width: 100%;
}
div.livePlayerContainer div.wrapper div.scheduleTime div.time span {
  color: var(--inner-text-color);
  font-size: 10px;
  font-weight: 700;
}
div.livePlayerContainer div.wrapper div.scheduleContainer {
  position: relative;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.rightGradient {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 10rem;
  z-index: 9;
  background: linear-gradient(95deg, rgba(0, 0, 0, 0), #000 101.15%);
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10rem;
  z-index: 9;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 62.15%);
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel {
  display: flex;
  align-items: center;
  width: 3000px;
  height: 75px;
  margin-bottom: 4px;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime {
  position: absolute;
  top: -26px;
  right: 0;
  background-color: #f52d2d;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 3px 3px;
  width: 5rem;
  border-radius: 3px;
  color: var(--white-color);
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime span {
  font-weight: 600;
  font-size: 11px;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime div.icon {
  line-height: 0;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime div.icon svg {
  width: 16px !important;
  height: 16px !important;
  margin-right: 6px;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.left {
  width: 5%;
  margin-right: 4px;
  height: 100%;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard {
  width: 100%;
  height: 100%;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard div.imageContainer {
  width: 100%;
  height: 100%;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 4px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.right {
  display: flex;
  align-items: center;
  width: 95%;
  height: 100%;
  position: relative;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem {
  background-color: rgb(25, 25, 25);
  padding: 25px 20px;
  border-radius: 4px;
  margin-right: 4px;
  height: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active {
  border: 2px solid var(--text-hover);
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active div.progress {
  display: block;
  position: absolute;
  background: linear-gradient(95deg, rgba(25, 25, 25, 0), #363434 101.15%);
  width: 10%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active div.progress div.currentTime {
  display: none;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem:hover div.metaData {
  display: flex;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.progress {
  display: none;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem h1, div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem .h1 {
  font-size: 16px;
  color: var(--white-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 9;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData {
  display: none;
  justify-content: space-between;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData p.description {
  position: absolute;
  z-index: 99;
  color: var(--inner-text-color);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData div.infoIcon {
  text-align: right;
  position: absolute;
  z-index: 9999;
  right: 10px;
  top: 10px;
}
div.livePlayerContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData div.infoIcon svg {
  color: var(--inner-text-color);
  width: 20px !important;
  height: 20px !important;
}

div.languageContainer {
  align-items: center;
  padding-top: 5px;
  background-color: var(--bg-color);
}
div.languageContainer label {
  font-size: 15px;
  color: var(--inner-text-color);
}
@media all and (max-width: 480px) {
  div.languageContainer label {
    font-size: 13px;
  }
}
div.languageContainer select {
  margin-top: 3px;
  cursor: pointer;
  width: 168px;
  height: 25px;
  border-radius: 3px;
  margin-bottom: 10px;
  background: transparent;
  color: #fff;
}
@media all and (max-width: 360px) {
  div.languageContainer select {
    width: 125px;
  }
}
@media all and (max-width: 320px) {
  div.languageContainer select {
    width: 100px;
  }
}
div.languageContainer select option {
  background-color: #1c1f29;
  color: white;
  font-size: 12px;
}

:root {
  --primary-color:#141414;
  --secondary-color:#e50914;
  --secondary-hover-color:rgba(229,9,20,0.8);
  --grey-color:#777;
  --text-hover:#b3b3b3;
  --bg-color:#000;
  --secondary-bg-color:#313131;
  --overlay:rgba(16,20,31,.64);
  --white-color:#fff;
  --border-color:hsl(0deg 0% 100% / 15%);
  --account-settings:#1d1c1c;
  --text-color:#fff;
  --success-color:#4BB543;
  --error-color:#E72A31;
  --yellow-color:#f8d406;
  --black-color:#000;
  --button-color:#e50914;
  --primary-btn-color:#fff;
  --secondary-btn-color:rgba(109, 109, 110, 0.7);
  --primary-btn-hover:rgba(255, 255, 255, 0.75);
  --secondary-btn-hover:rgba(109, 109, 110, 0.4);
  --heading-color:#fff;
  --modal-bg-color:#ffffff;
  --sub-bg-color:#141414;
  --sub-heading-color:#e50914;
  --default-bg-color:#141414;
  --default-border-color:rgba(182, 183, 190, 0.16);
  --default-text-hover-color:#e50914;
  --transparent-color:rgba(0, 0, 0, 0.9);
  --duration-color:#46d369;
  --freeBtn-color:#2b9947;
  --freeBtn-hover:#46d369;
  --inner-text-color:#9699a3;
  --bs-primary-rgb: 229, 9, 20;
  --bs-primary: #e50914;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}
body .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
body .MuiPaper-root {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  max-width: 779px !important;
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}
@media all and (max-width: 980px) {
  body .MuiPaper-root {
    max-width: 100% !important;
  }
}
body .css-1k2f74c-MuiPaper-root-MuiDialog-paper {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}
body.hideScroll .MuiDialog-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body.hideScroll .MuiDialog-container::-webkit-scrollbar {
  display: none;
}
body.showScrollbar .MuiDialog-container {
  -ms-overflow-style: unset !important; /* IE and Edge */
  scrollbar-width: unset !important; /* Firefox */
}
body.showScrollbar .MuiDialog-container::-webkit-scrollbar {
  display: block !important;
}

.wrapper {
  width: 92%;
  margin: 0 auto;
}

.swiper-button-next.swiper-button-disabled {
  display: none !important;
}

.swiper-button-prev.swiper-button-disabled {
  display: none !important;
}

.swiper-button-next::after {
  font-size: 1rem !important;
  color: var(--white-color);
}

.swiper-button-prev::after {
  font-size: 1rem !important;
  color: var(--white-color);
  position: absolute;
}

.swiper-button {
  width: 1.875rem !important;
  height: 1.875rem !important;
  text-align: center;
  line-height: 1.875rem;
  color: var(--bs-white);
  background: transparent;
  border-radius: 50%;
  border: 0.0625em solid var(--bs-white);
  z-index: 1;
  transition: all 0.3s ease-in-out;
  font-size: 1em;
}

span {
  color: #fff;
}

.nav-link {
  color: #fff;
}

span.error {
  font-size: 14px;
  color: #E72A31;
  display: block;
  margin-top: 10px;
  font-weight: normal !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#progressBarContainer {
  position: fixed;
  z-index: 999999999;
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  top: 0;
  right: 0;
}

#progressBar {
  background: linear-gradient(to left, var(--button-color), var(--button-color));
  transform-origin: top left;
  transform: scale(0, 0);
  opacity: 1;
  transition: 0.3s ease-in-out;
}

#progressBarContainer,
#progressBar {
  height: 100vh;
  width: 8px;
}

div.faqs {
  padding: 100px 0;
}
div.faqs h1, div.faqs .h1 {
  text-align: center;
  color: var(--white-color);
  margin-bottom: 20px;
}
div.faqs p {
  color: var(--text-color) !important;
}
div.faqs p.nb {
  margin-top: 20px;
}
div.faqs .accordion__button {
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
}
div.faqs .accordion__panel {
  background-color: #1d1c1c;
}/*# sourceMappingURL=app.css.map */