div.categoriesContainer {
  position: relative;
  z-index: 9;
  &.landscape {
    &.rowCount3{
      margin-bottom: 325px;
      margin-top: -161px;
      @media all and (max-width: 2600px) {
        margin-bottom: 653px;
        &.lastChild{
          margin-bottom: 576px !important;
        }
      }
      @media all and (max-width: 2500px) {
        margin-bottom: 589px;
        &.lastChild{
          margin-bottom: 503px !important;
        }
      }
      @media all and (max-width: 2200px) {
        &.lastChild{
          margin-bottom: 461px !important;
        }
      }
      @media all and (max-width: 1980px) {
        margin-bottom: 520px;
        &.lastChild{
          margin-bottom: 417px !important;
        }
      }
      @media all and (max-width: 1768px) {
        margin-bottom: 488px;
        &.lastChild{
          margin-bottom: 378px !important;
        }
      }
      @media all and (max-width: 1600px) {
        margin-bottom: 461px;
        &.lastChild{
          margin-bottom: 330px !important;
        }
      }
      @media all and (max-width: 1400px) {
        margin-bottom: 423px;
        &.lastChild{
          margin-bottom: 286px !important;
        }
      }
      @media all and (max-width: 1200px) {
        margin-bottom: 375px;
        &.lastChild{
          margin-bottom: 251px !important;
        }
      }

      .swiper-button-next {
        right: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 29rem;
          width: 6rem;
          right: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 29rem;
    
          // &::after{
          //   position: absolute;
          //   right: 62px;
          // }
        }
        @media all and (max-width: 2200px) {
          height: 23rem;
        }
        @media all and (max-width: 1980px) {
          height: 21rem;
          &::after {
            position: absolute;
            right: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 19rem;
          &::after {
            font-size: 18px !important;
            right: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 16.9rem;
          &::after {
            font-size: 15px !important;
            right: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 15.8rem;
        }
        @media all and (max-width: 1200px) {
          height: 10.9rem;
          &::after {
            font-size: 14px !important;
            right: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
      }
      .swiper-button-prev {
        left: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 29rem;
          width: 6rem;
          left: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 29rem;
          // &::after{
          //   position: absolute;
          //   left: 62px;
          // }
        }
    
        @media all and (max-width: 2200px) {
          height: 23rem;
        }
        @media all and (max-width: 1980px) {
          height: 21rem;
          &::after {
            position: absolute;
            left: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 19rem;
          &::after {
            font-size: 18px !important;
            left: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 16.9rem;
          &::after {
            font-size: 15px !important;
            left: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 15.8rem;
        }
        @media all and (max-width: 1200px) {
          height: 10.9rem;
          &::after {
            font-size: 14px !important;
            left: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
        //     @media all and (min-width:320px){
        //         display: flex;
        //         left: -26px;
        //         width: 9%;
        //         top: 11%;
    
        //     }
        //     @media all and (min-width:360px){
        //         top: 9%;
        //         left: -42px;
    
        //     }
        //     @media all and (min-width:480px){
        //         top: 7%;
        //         width: 5%;
        //         left: -51px;
        //     }
        //     @media all and (min-width:640px){
        //         top: 5%;
        //         left: -60px;
        //     }
        //     @media all and (min-width:768px){
        //         width: 4%;
        //         left: -80px;
        //     }
        //     @media all and (min-width:980px){
        //         width: 4%;
        //         left: -47px;
        //     }
        //     @media all and (min-width:1200px){
        //       left: -57px;
        //   }
        //   @media all and (min-width:1400px){
        //     left: -67px;
        // }
        // @media all and (min-width:1600px){
        //   left: -70px;
        // }
        // @media all and (min-width:1768px){
        //   left: -79px;
        // }
        // @media all and (min-width:768px){
        //     width: 7%;
        //  }
      }
      

    }
    &.rowCount4{
      margin-bottom: 325px;
      margin-top: -161px;
      @media all and (max-width: 2600px) {
        margin-bottom: 550px;
        &.lastChild{
          margin-bottom: 449px !important;
        }
      }
      @media all and (max-width: 2500px) {
        margin-bottom: 499px;
        &.lastChild{
          margin-bottom: 398px !important;
        }
      }
      @media all and (max-width: 2200px) {
        &.lastChild{
          margin-bottom: 363px !important;
        }
      }
      @media all and (max-width: 1980px) {
        margin-bottom: 440px;
        &.lastChild{
          margin-bottom: 342px !important;
        }
      }
      @media all and (max-width: 1768px) {
        margin-bottom: 407px;
        &.lastChild{
          margin-bottom: 301px !important;
        }
      }
      @media all and (max-width: 1600px) {
        margin-bottom: 386px;
        &.lastChild{
          margin-bottom: 268px !important;
        }
      }
      @media all and (max-width: 1400px) {
        margin-bottom: 360px;
        &.lastChild{
          margin-bottom: 243px !important;
        }
      }
      @media all and (max-width: 1200px) {
        margin-bottom: 338px;
        &.lastChild{
          margin-bottom: 194px !important;
        }
      }

      .swiper-button-next {
        right: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 23rem;
          width: 6rem;
          right: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 20rem;
    
          // &::after{
          //   position: absolute;
          //   right: 62px;
          // }
        }
        @media all and (max-width: 2200px) {
          height: 18rem;
        }
        @media all and (max-width: 1980px) {
          height: 16rem;
          &::after {
            position: absolute;
            right: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 14rem;
          &::after {
            font-size: 18px !important;
            right: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 11.9rem;
          &::after {
            font-size: 15px !important;
            right: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height:11.5rem;
        }
        @media all and (max-width: 1200px) {
          height: 8.9rem;
          &::after {
            font-size: 14px !important;
            right: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
      }
      .swiper-button-prev {
        left: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 23rem;
          width: 6rem;
          left: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 20rem;
          // &::after{
          //   position: absolute;
          //   left: 62px;
          // }
        }
    
        @media all and (max-width: 2200px) {
          height: 18rem;
        }
        @media all and (max-width: 1980px) {
          height: 16rem;
          &::after {
            position: absolute;
            left: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 14rem;
          &::after {
            font-size: 18px !important;
            left: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height:11.9rem;
          &::after {
            font-size: 15px !important;
            left: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 11.5rem;
        }
        @media all and (max-width: 1200px) {
          height: 8.9rem;
          &::after {
            font-size: 14px !important;
            left: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
        //     @media all and (min-width:320px){
        //         display: flex;
        //         left: -26px;
        //         width: 9%;
        //         top: 11%;
    
        //     }
        //     @media all and (min-width:360px){
        //         top: 9%;
        //         left: -42px;
    
        //     }
        //     @media all and (min-width:480px){
        //         top: 7%;
        //         width: 5%;
        //         left: -51px;
        //     }
        //     @media all and (min-width:640px){
        //         top: 5%;
        //         left: -60px;
        //     }
        //     @media all and (min-width:768px){
        //         width: 4%;
        //         left: -80px;
        //     }
        //     @media all and (min-width:980px){
        //         width: 4%;
        //         left: -47px;
        //     }
        //     @media all and (min-width:1200px){
        //       left: -57px;
        //   }
        //   @media all and (min-width:1400px){
        //     left: -67px;
        // }
        // @media all and (min-width:1600px){
        //   left: -70px;
        // }
        // @media all and (min-width:1768px){
        //   left: -79px;
        // }
        // @media all and (min-width:768px){
        //     width: 7%;
        //  }
      }
      

    }
    &.rowCount5{
      margin-bottom: 325px;
      margin-top: -161px;
      @media all and (max-width: 2600px) {
        margin-bottom: 498px;
        &.lastChild{
          margin-bottom: 366px !important;
        }
      }
      @media all and (max-width: 2500px) {
        margin-bottom: 447px;
        &.lastChild{
          margin-bottom: 329px !important;
        }
      }
      @media all and (max-width: 2200px) {
        &.lastChild{
          margin-bottom: 300px !important;
        }
      }
      @media all and (max-width: 1980px) {
        margin-bottom: 397px;
        &.lastChild{
          margin-bottom: 270px !important;
        }
      }
      @media all and (max-width: 1768px) {
        margin-bottom: 373px;
        &.lastChild{
          margin-bottom: 244px !important;
        }
      }
      @media all and (max-width: 1600px) {
        margin-bottom: 365px;
        &.lastChild{
          margin-bottom: 218px !important;
        }
      }
      @media all and (max-width: 1400px) {
        margin-bottom: 337px;
        &.lastChild{
          margin-bottom: 186px !important;
        }
      }
      @media all and (max-width: 1200px) {
        margin-bottom: 301px;
        &.lastChild{
          margin-bottom: 162px !important;
        }
      }

      .swiper-button-next {
        right: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 18rem;
          width: 6rem;
          right: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 16rem;
    
          // &::after{
          //   position: absolute;
          //   right: 62px;
          // }
        }
        @media all and (max-width: 2200px) {
          height: 14rem;
        }
        @media all and (max-width: 1980px) {
          height: 12rem;
          &::after {
            position: absolute;
            right: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 11rem;
          &::after {
            font-size: 18px !important;
            right: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 9.9rem;
          &::after {
            font-size: 15px !important;
            right: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height:9.5rem;
        }
        @media all and (max-width: 1200px) {
          height: 7.9rem;
          &::after {
            font-size: 14px !important;
            right: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
      }
      .swiper-button-prev {
        left: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 18rem;
          width: 6rem;
          left: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 16rem;
          // &::after{
          //   position: absolute;
          //   left: 62px;
          // }
        }
    
        @media all and (max-width: 2200px) {
          height: 14rem;
        }
        @media all and (max-width: 1980px) {
          height: 12rem;
          &::after {
            position: absolute;
            left: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 11rem;
          &::after {
            font-size: 18px !important;
            left: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height:9.9rem;
          &::after {
            font-size: 15px !important;
            left: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 9.5rem;
        }
        @media all and (max-width: 1200px) {
          height: 7.9rem;
          &::after {
            font-size: 14px !important;
            left: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
        //     @media all and (min-width:320px){
        //         display: flex;
        //         left: -26px;
        //         width: 9%;
        //         top: 11%;
    
        //     }
        //     @media all and (min-width:360px){
        //         top: 9%;
        //         left: -42px;
    
        //     }
        //     @media all and (min-width:480px){
        //         top: 7%;
        //         width: 5%;
        //         left: -51px;
        //     }
        //     @media all and (min-width:640px){
        //         top: 5%;
        //         left: -60px;
        //     }
        //     @media all and (min-width:768px){
        //         width: 4%;
        //         left: -80px;
        //     }
        //     @media all and (min-width:980px){
        //         width: 4%;
        //         left: -47px;
        //     }
        //     @media all and (min-width:1200px){
        //       left: -57px;
        //   }
        //   @media all and (min-width:1400px){
        //     left: -67px;
        // }
        // @media all and (min-width:1600px){
        //   left: -70px;
        // }
        // @media all and (min-width:1768px){
        //   left: -79px;
        // }
        // @media all and (min-width:768px){
        //     width: 7%;
        //  }
      }
      

    }
    &.rowCount6{
      margin-bottom: 325px;
      margin-top: -161px;
      @media all and (max-width: 2600px) {
        margin-bottom: 435px;
        &.lastChild{
          margin-bottom: 321px !important;
        }
      }
      @media all and (max-width: 2500px) {
        margin-bottom: 411px;
        &.lastChild{
          margin-bottom: 283px !important;
        }
      }
      @media all and (max-width: 2200px) {
        &.lastChild{
          margin-bottom: 268px !important;
        }
      }
      @media all and (max-width: 1980px) {
        margin-bottom: 361px;
        &.lastChild{
          margin-bottom: 231px !important;
        }

      }
      @media all and (max-width: 1768px) {
        margin-bottom: 339px;
        &.lastChild{
          margin-bottom: 211px !important;
        }
      }
      @media all and (max-width: 1600px) {
        margin-bottom: 330px;
        &.lastChild{
          margin-bottom: 191px !important;
        }
      }
      @media all and (max-width: 1400px) {
        margin-bottom: 311px;
        &.lastChild{
          margin-bottom: 170px !important;
        }
      }
      @media all and (max-width: 1200px) {
        margin-bottom: 290px;
        &.lastChild{
          margin-bottom: 147px !important;
        }
      }

      .swiper-button-next {
        right: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 15rem;
          width: 6rem;
          right: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 13rem;
    
          // &::after{
          //   position: absolute;
          //   right: 62px;
          // }
        }
        @media all and (max-width: 2200px) {
          height: 12rem;
        }
        @media all and (max-width: 1980px) {
          height: 11rem;
          &::after {
            position: absolute;
            right: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 10rem;
          &::after {
            font-size: 18px !important;
            right: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 8.9rem;
          &::after {
            font-size: 15px !important;
            right: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height:7.5rem;
        }
        @media all and (max-width: 1200px) {
          height: 6.9rem;
          &::after {
            font-size: 14px !important;
            right: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
      }
      .swiper-button-prev {
        left: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 15rem;
          width: 6rem;
          left: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 13rem;
          // &::after{
          //   position: absolute;
          //   left: 62px;
          // }
        }
    
        @media all and (max-width: 2200px) {
          height: 12rem;
        }
        @media all and (max-width: 1980px) {
          height: 11rem;
          &::after {
            position: absolute;
            left: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 10rem;
          &::after {
            font-size: 18px !important;
            left: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height:8.9rem;
          &::after {
            font-size: 15px !important;
            left: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 7.5rem;
        }
        @media all and (max-width: 1200px) {
          height: 6.9rem;
          &::after {
            font-size: 14px !important;
            left: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
        //     @media all and (min-width:320px){
        //         display: flex;
        //         left: -26px;
        //         width: 9%;
        //         top: 11%;
    
        //     }
        //     @media all and (min-width:360px){
        //         top: 9%;
        //         left: -42px;
    
        //     }
        //     @media all and (min-width:480px){
        //         top: 7%;
        //         width: 5%;
        //         left: -51px;
        //     }
        //     @media all and (min-width:640px){
        //         top: 5%;
        //         left: -60px;
        //     }
        //     @media all and (min-width:768px){
        //         width: 4%;
        //         left: -80px;
        //     }
        //     @media all and (min-width:980px){
        //         width: 4%;
        //         left: -47px;
        //     }
        //     @media all and (min-width:1200px){
        //       left: -57px;
        //   }
        //   @media all and (min-width:1400px){
        //     left: -67px;
        // }
        // @media all and (min-width:1600px){
        //   left: -70px;
        // }
        // @media all and (min-width:1768px){
        //   left: -79px;
        // }
        // @media all and (min-width:768px){
        //     width: 7%;
        //  }
      }
      

    }
    &.rowCount7{
      margin-bottom: 325px;
      margin-top: -161px;
      @media all and (max-width: 2600px) {
        margin-bottom: 386px;
        &.lastChild{
          margin-bottom: 321px !important;
        }
      }
      @media all and (max-width: 2500px) {
        margin-bottom: 365px;
        &.lastChild{
          margin-bottom: 283px !important;
        }
      }
      @media all and (max-width: 2200px) {
        &.lastChild{
          margin-bottom: 268px !important;
        }
      }
      @media all and (max-width: 1980px) {
        margin-bottom: 325px;
        &.lastChild{
          margin-bottom: 231px !important;
        }

      }
      @media all and (max-width: 1768px) {
        margin-bottom: 339px;
        &.lastChild{
          margin-bottom: 211px !important;
        }
      }
      @media all and (max-width: 1600px) {
        margin-bottom: 300px;
        &.lastChild{
          margin-bottom: 191px !important;
        }
      }
      @media all and (max-width: 1400px) {
        margin-bottom: 280px;
        &.lastChild{
          margin-bottom: 170px !important;
        }
      }
      @media all and (max-width: 1200px) {
        margin-bottom: 290px;
        &.lastChild{
          margin-bottom: 147px !important;
        }
      }

      .swiper-button-next {
        right: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 15rem;
          width: 6rem;
          right: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 13rem;
    
          // &::after{
          //   position: absolute;
          //   right: 62px;
          // }
        }
        @media all and (max-width: 2200px) {
          height: 12rem;
        }
        @media all and (max-width: 1980px) {
          height: 11rem;
          &::after {
            position: absolute;
            right: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 9rem;
          &::after {
            font-size: 18px !important;
            right: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 8rem;
          &::after {
            font-size: 15px !important;
            right: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height:7.5rem;
        }
        @media all and (max-width: 1200px) {
          height: 6rem;
          &::after {
            font-size: 14px !important;
            right: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
      }
      .swiper-button-prev {
        left: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 15rem;
          width: 6rem;
          left: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 13rem;
          // &::after{
          //   position: absolute;
          //   left: 62px;
          // }
        }
    
        @media all and (max-width: 2200px) {
          height: 12rem;
        }
        @media all and (max-width: 1980px) {
          height: 11rem;
          &::after {
            position: absolute;
            left: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 9rem;
          &::after {
            font-size: 18px !important;
            left: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height:8rem;
          &::after {
            font-size: 15px !important;
            left: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 7.5rem;
        }
        @media all and (max-width: 1200px) {
          height: 6rem;
          &::after {
            font-size: 14px !important;
            left: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
        //     @media all and (min-width:320px){
        //         display: flex;
        //         left: -26px;
        //         width: 9%;
        //         top: 11%;
    
        //     }
        //     @media all and (min-width:360px){
        //         top: 9%;
        //         left: -42px;
    
        //     }
        //     @media all and (min-width:480px){
        //         top: 7%;
        //         width: 5%;
        //         left: -51px;
        //     }
        //     @media all and (min-width:640px){
        //         top: 5%;
        //         left: -60px;
        //     }
        //     @media all and (min-width:768px){
        //         width: 4%;
        //         left: -80px;
        //     }
        //     @media all and (min-width:980px){
        //         width: 4%;
        //         left: -47px;
        //     }
        //     @media all and (min-width:1200px){
        //       left: -57px;
        //   }
        //   @media all and (min-width:1400px){
        //     left: -67px;
        // }
        // @media all and (min-width:1600px){
        //   left: -70px;
        // }
        // @media all and (min-width:1768px){
        //   left: -79px;
        // }
        // @media all and (min-width:768px){
        //     width: 7%;
        //  }
      }
      

    }
    &.rowCount8{
      margin-bottom: 280px;
      margin-top: -161px;
      @media all and (max-width: 2600px) {
        margin-bottom: 360px;
        &.lastChild{
          margin-bottom: 321px !important;
        }
      }
      @media all and (max-width: 2500px) {
        margin-bottom: 355px;
        &.lastChild{
          margin-bottom: 283px !important;
        }
      }
      @media all and (max-width: 2200px) {
        margin-bottom: 320px;
        &.lastChild{
          margin-bottom: 268px !important;
        }
      }
      @media all and (max-width: 1980px) {
        margin-bottom: 300px;
        &.lastChild{
          margin-bottom: 231px !important;
        }

      }
      @media all and (max-width: 1768px) {
        margin-bottom: 315px;
        &.lastChild{
          margin-bottom: 211px !important;
        }
      }
      @media all and (max-width: 1600px) {
        margin-bottom: 300px;
        &.lastChild{
          margin-bottom: 191px !important;
        }
      }
      @media all and (max-width: 1400px) {
        margin-bottom: 285px;
        &.lastChild{
          margin-bottom: 170px !important;
        }
      }
      @media all and (max-width: 1200px) {
        margin-bottom: 290px;
        &.lastChild{
          margin-bottom: 147px !important;
        }
      }

      .swiper-button-next {
        right: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 11rem;
          width: 6rem;
          right: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 11rem;
    
          // &::after{
          //   position: absolute;
          //   right: 62px;
          // }
        }
        @media all and (max-width: 2200px) {
          height: 8rem;
        }
        @media all and (max-width: 1980px) {
          height: 7rem;
          &::after {
            position: absolute;
            right: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 8rem;
          &::after {
            font-size: 18px !important;
            right: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 7rem;
          &::after {
            font-size: 15px !important;
            right: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height:6rem;
        }
        @media all and (max-width: 1200px) {
          height: 5rem;
          &::after {
            font-size: 14px !important;
            right: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
      }
      .swiper-button-prev {
        left: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 11rem;
          width: 6rem;
          left: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 11rem;
          // &::after{
          //   position: absolute;
          //   left: 62px;
          // }
        }
    
        @media all and (max-width: 2200px) {
          height: 8rem;
        }
        @media all and (max-width: 1980px) {
          height: 7rem;
          &::after {
            position: absolute;
            left: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 8rem;
          &::after {
            font-size: 18px !important;
            left: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height:7rem;
          &::after {
            font-size: 15px !important;
            left: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 6rem;
        }
        @media all and (max-width: 1200px) {
          height: 5rem;
          &::after {
            font-size: 14px !important;
            left: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }


        //     @media all and (min-width:320px){
        //         display: flex;
        //         left: -26px;
        //         width: 9%;
        //         top: 11%;
    
        //     }
        //     @media all and (min-width:360px){
        //         top: 9%;
        //         left: -42px;
    
        //     }
        //     @media all and (min-width:480px){
        //         top: 7%;
        //         width: 5%;
        //         left: -51px;
        //     }
        //     @media all and (min-width:640px){
        //         top: 5%;
        //         left: -60px;
        //     }
        //     @media all and (min-width:768px){
        //         width: 4%;
        //         left: -80px;
        //     }
        //     @media all and (min-width:980px){
        //         width: 4%;
        //         left: -47px;
        //     }
        //     @media all and (min-width:1200px){
        //       left: -57px;
        //   }
        //   @media all and (min-width:1400px){
        //     left: -67px;
        // }
        // @media all and (min-width:1600px){
        //   left: -70px;
        // }
        // @media all and (min-width:1768px){
        //   left: -79px;
        // }
        // @media all and (min-width:768px){
        //     width: 7%;
        //  }
      }
      

    }
    .swiper-button-next,.swiper-button-prev {
      @media all and (max-width: 980px) {
        display: none;
      }
    }
    @media all and (max-width: 980px) {
      margin-top: 0;
      margin-bottom: 24px;
    }


    
  }

  &.portrait {
   
    &.rowCount3{
      margin-bottom: 325px;
      margin-top: -161px;
       &.lastChild{
      margin-bottom: 1000px !important;
    }
      @media all and (max-width: 2600px) {
        margin-bottom: 1387px;
        &.lastChild{
          margin-bottom: 1375px !important;
        }
      }
      @media all and (max-width: 2500px) {
        margin-bottom: 1248px;
        &.lastChild{
          margin-bottom: 1214px !important;
        }
      }
      @media all and (max-width: 2200px) {
        margin-bottom: 1228px;
        &.lastChild{
          margin-bottom: 1144px !important;
        }
      }
      @media all and (max-width: 2080px) {
        margin-bottom: 1138px;
        &.lastChild{
          margin-bottom: 1100px !important;
        }
      }
      @media all and (max-width: 1980px) {
        margin-bottom: 1116px;
        &.lastChild{
          margin-bottom: 1054px !important;
        }
      }
      @media all and (max-width: 1860px) {
        margin-bottom: 1033px;
        &.lastChild{
          margin-bottom: 995px !important;
        }
      }
      @media all and (max-width: 1768px) {
        margin-bottom: 1024px;
        &.lastChild{
          margin-bottom: 931px !important;
        }
      }
      @media all and (max-width: 1680px) {
        margin-bottom: 956px;
        &.lastChild{
          margin-bottom: 892px !important;
        }
      }
      @media all and (max-width: 1600px) {
        margin-bottom: 920px;
        &.lastChild{
          margin-bottom: 822px !important;
        }
      }
      @media all and (max-width: 1480px) {
        margin-bottom: 864px;
        &.lastChild{
          margin-bottom: 782px !important;
        }
      }
      @media all and (max-width: 1400px) {
        margin-bottom: 836px;
        &.lastChild{
          margin-bottom: 738px !important;
        }
      }
      @media all and (max-width: 1300px) {
        margin-bottom: 775px;
        &.lastChild{
          margin-bottom: 681px !important;
        }
      }
      @media all and (max-width: 1200px) {
        margin-bottom: 730px;
        &.lastChild{
          margin-bottom: 625px !important;
        }
      }
      @media all and (max-width: 1080px) {
        margin-bottom: 672px;
        &.lastChild{
          margin-bottom: 572px !important;
        }
      }

      .swiper-button-next {
        right: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 77rem;
          width: 6rem;
          right: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 67rem;
    
          // &::after{
          //   position: absolute;
          //   right: 62px;
          // }
        }
        @media all and (max-width: 2200px) {
          height: 61rem;
        }
        @media all and (max-width: 1980px) {
          height: 55rem;
          &::after {
            position: absolute;
            right: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 50rem;
          &::after {
            font-size: 18px !important;
            right: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 43.9rem;
          &::after {
            font-size: 15px !important;
            right: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 36.8rem;
        }
        @media all and (max-width: 1200px) {
          height: 31.9rem;
          &::after {
            font-size: 14px !important;
            right: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
      }
      .swiper-button-prev {
        left: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 77rem;
          width: 6rem;
          left: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 67rem;
          // &::after{
          //   position: absolute;
          //   left: 62px;
          // }
        }
    
        @media all and (max-width: 2200px) {
          height: 61rem;
        }
        @media all and (max-width: 1980px) {
          height: 55rem;
          &::after {
            position: absolute;
            left: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 50rem;
          &::after {
            font-size: 18px !important;
            left: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 43.9rem;
          &::after {
            font-size: 15px !important;
            left: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 36.8rem;
        }
        @media all and (max-width: 1200px) {
          height: 31.9rem;
          &::after {
            font-size: 14px !important;
            left: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
        //     @media all and (min-width:320px){
        //         display: flex;
        //         left: -26px;
        //         width: 9%;
        //         top: 11%;
    
        //     }
        //     @media all and (min-width:360px){
        //         top: 9%;
        //         left: -42px;
    
        //     }
        //     @media all and (min-width:480px){
        //         top: 7%;
        //         width: 5%;
        //         left: -51px;
        //     }
        //     @media all and (min-width:640px){
        //         top: 5%;
        //         left: -60px;
        //     }
        //     @media all and (min-width:768px){
        //         width: 4%;
        //         left: -80px;
        //     }
        //     @media all and (min-width:980px){
        //         width: 4%;
        //         left: -47px;
        //     }
        //     @media all and (min-width:1200px){
        //       left: -57px;
        //   }
        //   @media all and (min-width:1400px){
        //     left: -67px;
        // }
        // @media all and (min-width:1600px){
        //   left: -70px;
        // }
        // @media all and (min-width:1768px){
        //   left: -79px;
        // }
        // @media all and (min-width:768px){
        //     width: 7%;
        //  }
      }
      

    }
    &.rowCount4{
      margin-bottom: 325px;
      margin-top: -161px;
      @media all and (max-width: 2600px) {
        margin-bottom: 1125px;
        &.lastChild{
          margin-bottom: 1037px !important;
        }
      }
      @media all and (max-width: 2500px) {
        margin-bottom: 1018px;
        &.lastChild{
          margin-bottom: 915px !important;
        }
      }
      @media all and (max-width: 2200px) {
        margin-bottom: 931px;
        &.lastChild{
          margin-bottom: 829px !important;
        }
      }
      @media all and (max-width: 1980px) {
        margin-bottom: 840px;
        &.lastChild{
          margin-bottom: 751px !important;
        }
      }
      @media all and (max-width: 1768px) {
        margin-bottom: 787px;
        &.lastChild{
          margin-bottom: 690px !important;
        }
      }
      @media all and (max-width: 1600px) {
        margin-bottom: 709px;
        &.lastChild{
          margin-bottom: 598px !important;
        }
      }
      @media all and (max-width: 1400px) {
        margin-bottom: 658px;
        &.lastChild{
          margin-bottom: 521px !important;
        }
      }
      @media all and (max-width: 1200px) {
        margin-bottom: 559px;
        &.lastChild{
          margin-bottom: 433px !important;
        }
      }

      .swiper-button-next {
        right: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 59rem;
          width: 6rem;
          right: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 51rem;
    
          // &::after{
          //   position: absolute;
          //   right: 62px;
          // }
        }
        @media all and (max-width: 2200px) {
          height: 46rem;
        }
        @media all and (max-width: 1980px) {
          height: 41rem;
          &::after {
            position: absolute;
            right: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 37rem;
          &::after {
            font-size: 18px !important;
            right: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 32.9rem;
          &::after {
            font-size: 15px !important;
            right: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 28.8rem;
        }
        @media all and (max-width: 1200px) {
          height: 23.9rem;
          &::after {
            font-size: 14px !important;
            right: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
      }
      .swiper-button-prev {
        left: -54px;
        height: 124px;
        width: 4%;
        top: 5%;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 59rem;
          width: 6rem;
          left: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 51rem;
          // &::after{
          //   position: absolute;
          //   left: 62px;
          // }
        }
    
        @media all and (max-width: 2200px) {
          height: 46rem;
        }
        @media all and (max-width: 1980px) {
          height: 41rem;
          &::after {
            position: absolute;
            left: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 37rem;
          &::after {
            font-size: 18px !important;
            left: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 32.9rem;
          &::after {
            font-size: 15px !important;
            left: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 28.8rem;
        }
        @media all and (max-width: 1200px) {
          height: 23.9rem;
          &::after {
            font-size: 14px !important;
            left: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
        //     @media all and (min-width:320px){
        //         display: flex;
        //         left: -26px;
        //         width: 9%;
        //         top: 11%;
    
        //     }
        //     @media all and (min-width:360px){
        //         top: 9%;
        //         left: -42px;
    
        //     }
        //     @media all and (min-width:480px){
        //         top: 7%;
        //         width: 5%;
        //         left: -51px;
        //     }
        //     @media all and (min-width:640px){
        //         top: 5%;
        //         left: -60px;
        //     }
        //     @media all and (min-width:768px){
        //         width: 4%;
        //         left: -80px;
        //     }
        //     @media all and (min-width:980px){
        //         width: 4%;
        //         left: -47px;
        //     }
        //     @media all and (min-width:1200px){
        //       left: -57px;
        //   }
        //   @media all and (min-width:1400px){
        //     left: -67px;
        // }
        // @media all and (min-width:1600px){
        //   left: -70px;
        // }
        // @media all and (min-width:1768px){
        //   left: -79px;
        // }
        // @media all and (min-width:768px){
        //     width: 7%;
        //  }
      }
      

    }
    &.rowCount5{
      margin-bottom: 593px;
      margin-top: -161px;
      @media all and (max-width: 2600px) {
        margin-bottom: 955px;
        &.lastChild{
          margin-bottom: 833px !important;
        }
      }
      @media all and (max-width: 2500px) {
        margin-bottom: 851px;
        &.lastChild{
          margin-bottom: 733px !important;
        }
      }
      @media all and (max-width: 2200px) {
        margin-bottom: 798px;
        &.lastChild{
          margin-bottom: 678px !important;
        }
      }
      @media all and (max-width: 1980px) {
        margin-bottom: 735px;
        &.lastChild{
          margin-bottom: 606px !important;
        }
      }
      @media all and (max-width: 1768px) {
        margin-bottom: 678px;
        &.lastChild{
          margin-bottom: 559px !important;
        }
      }
      @media all and (max-width: 1600px) {
        margin-bottom: 615px;
        &.lastChild{
          margin-bottom: 496px !important;
        }
      }
      @media all and (max-width: 1400px) {
        margin-bottom: 564px;
        &.lastChild{
          margin-bottom: 422px !important;
        }
      }
      @media all and (max-width: 1200px) {
        margin-bottom: 502px;
        &.lastChild{
          margin-bottom: 345px !important;
        }
      }

      .swiper-button-next {
        right: -54px;
        height: 425px;
        width: 4%;
        // top: 5%;
        margin-top: -22px;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 46rem;
          width: 6rem;
          right: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 41rem;
    
          // &::after{
          //   position: absolute;
          //   right: 62px;
          // }
        }
        @media all and (max-width: 2200px) {
          height: 37rem;
        }
        @media all and (max-width: 1980px) {
          height: 34rem;
          &::after {
            position: absolute;
            right: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 31rem;
          &::after {
            font-size: 18px !important;
            right: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 26.9rem;
          &::after {
            font-size: 15px !important;
            right: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 24.8rem;
        }
        @media all and (max-width: 1200px) {
          height: 18.9rem;
          &::after {
            font-size: 14px !important;
            right: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
      }
      .swiper-button-prev {
        left: -54px;
        height: 422px;
        width: 4%;
        top: 5%;
        margin-top: -21px;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 46rem;
          width: 6rem;
          left: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 41rem;
          // &::after{
          //   position: absolute;
          //   left: 62px;
          // }
        }
    
        @media all and (max-width: 2200px) {
          height: 37rem;
        }
        @media all and (max-width: 1980px) {
          height: 34rem;
          &::after {
            position: absolute;
            left: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 31rem;
          &::after {
            font-size: 18px !important;
            left: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 26.9rem;
          &::after {
            font-size: 15px !important;
            left: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 24.8rem;
        }
        @media all and (max-width: 1200px) {
          height: 18.9rem;
          &::after {
            font-size: 14px !important;
            left: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
        //     @media all and (min-width:320px){
        //         display: flex;
        //         left: -26px;
        //         width: 9%;
        //         top: 11%;
    
        //     }
        //     @media all and (min-width:360px){
        //         top: 9%;
        //         left: -42px;
    
        //     }
        //     @media all and (min-width:480px){
        //         top: 7%;
        //         width: 5%;
        //         left: -51px;
        //     }
        //     @media all and (min-width:640px){
        //         top: 5%;
        //         left: -60px;
        //     }
        //     @media all and (min-width:768px){
        //         width: 4%;
        //         left: -80px;
        //     }
        //     @media all and (min-width:980px){
        //         width: 4%;
        //         left: -47px;
        //     }
        //     @media all and (min-width:1200px){
        //       left: -57px;
        //   }
        //   @media all and (min-width:1400px){
        //     left: -67px;
        // }
        // @media all and (min-width:1600px){
        //   left: -70px;
        // }
        // @media all and (min-width:1768px){
        //   left: -79px;
        // }
        // @media all and (min-width:768px){
        //     width: 7%;
        //  }
      }
      

    }
    &.rowCount6{
      margin-bottom: 325px;
      margin-top: -161px;
      
      @media all and (max-width: 2600px) {
        margin-bottom: 820px;
        &.lastChild{
          margin-bottom: 700px !important;
        }
      }
      @media all and (max-width: 2500px) {
        margin-bottom: 731px;
        &.lastChild{
          margin-bottom: 640px !important;
        }
      }
      @media all and (max-width: 2200px) {
        margin-bottom: 672px;
        &.lastChild{
          margin-bottom: 600px !important;
        }
      }
      @media all and (max-width: 1980px) {
        margin-bottom: 635px;
        &.lastChild{
          margin-bottom: 570px !important;
        }
      }
      @media all and (max-width: 1768px) {
        margin-bottom: 575px;
        &.lastChild{
          margin-bottom: 500px !important;
        }
      }
      @media all and (max-width: 1600px) {
        margin-bottom: 537px;
        &.lastChild{
          margin-bottom: 450px !important;
        }
      }
      @media all and (max-width: 1400px) {
        margin-bottom: 520px;
        &.lastChild{
          margin-bottom: 400px !important;
        }
       
      }
      @media all and (max-width: 1200px) {
        margin-bottom: 439px;
        &.lastChild{
          margin-bottom: 320px !important;
        }
      }

      .swiper-button-next {
        right: -54px;
        height: 351px;
        width: 4%;
        top: 5%;
        margin-top: -22px;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 38rem;
          width: 6rem;
          right: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 36rem;
    
          // &::after{
          //   position: absolute;
          //   right: 62px;
          // }
        }
        @media all and (max-width: 2200px) {
          height: 31rem;
        }
        @media all and (max-width: 1980px) {
          height: 29rem;
          &::after {
            position: absolute;
            right: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 25rem;
          &::after {
            font-size: 18px !important;
            right: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 22rem;
          &::after {
            font-size: 15px !important;
            right: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 20.8rem;
        }
        @media all and (max-width: 1200px) {
          height: 17rem;
          &::after {
            font-size: 14px !important;
            right: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
      }
      .swiper-button-prev {
        left: -54px;
        height: 352px;
        width: 4%;
        top: 5%;
        margin-top: -24px;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 38rem;
          width: 6rem;
          left: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 36rem;
          // &::after{
          //   position: absolute;
          //   left: 62px;
          // }
        }
    
        @media all and (max-width: 2200px) {
          height: 31rem;
        }
        @media all and (max-width: 1980px) {
          height: 29rem;
          &::after {
            position: absolute;
            left: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 25rem;
          &::after {
            font-size: 18px !important;
            left: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 22rem;
          &::after {
            font-size: 15px !important;
            left: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 20.8rem;
        }
        @media all and (max-width: 1200px) {
          height: 17rem;
          &::after {
            font-size: 14px !important;
            left: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
        //     @media all and (min-width:320px){
        //         display: flex;
        //         left: -26px;
        //         width: 9%;
        //         top: 11%;
    
        //     }
        //     @media all and (min-width:360px){
        //         top: 9%;
        //         left: -42px;
    
        //     }
        //     @media all and (min-width:480px){
        //         top: 7%;
        //         width: 5%;
        //         left: -51px;
        //     }
        //     @media all and (min-width:640px){
        //         top: 5%;
        //         left: -60px;
        //     }
        //     @media all and (min-width:768px){
        //         width: 4%;
        //         left: -80px;
        //     }
        //     @media all and (min-width:980px){
        //         width: 4%;
        //         left: -47px;
        //     }
        //     @media all and (min-width:1200px){
        //       left: -57px;
        //   }
        //   @media all and (min-width:1400px){
        //     left: -67px;
        // }
        // @media all and (min-width:1600px){
        //   left: -70px;
        // }
        // @media all and (min-width:1768px){
        //   left: -79px;
        // }
        // @media all and (min-width:768px){
        //     width: 7%;
        //  }
      }
      

    }
    &.rowCount7{
      margin-bottom: 325px;
      margin-top: 150px;
      @media all and (max-width: 2600px) {
        margin-bottom: 525px;
      }
      @media all and (max-width: 2500px) {
        margin-bottom: 498px;
      }
      @media all and (max-width:2200px) {
        margin-bottom: 435px;
      }
      @media all and (max-width:1980px) {
        margin-bottom: 395px;
      }
      @media all and (max-width:1768px) {
        margin-bottom: 350px;
      }
      @media all and (max-width:1600px) {
        margin-bottom: 315px;
      }
      @media all and (max-width:1400px) {
        margin-bottom: 275px;
      }
      @media all and (max-width:1200px) {
        margin-bottom: 235px;
      }
      @media all and (max-width: 980px) {
        margin-top: 0;
        margin-bottom: 24px;
      }

      .swiper-button-next {
        right: -54px;
        height: 300px;
        width: 4%;
        margin-top: 0px;
        top: 43% !important;
        background: hsla(0, 0%, 8%, 0.5);
    
        @media all and (max-width: 2600px) {
          height: 32rem;
          width: 6rem;
          right: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 31rem;
        }
        @media all and (max-width: 2200px) {
          height: 27rem;
        }
        @media all and (max-width: 1980px) {
          height: 25rem;
          &::after {
            position: absolute;
            right: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 22rem;
          &::after {
            font-size: 18px !important;
            right: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 20rem;
          &::after {
            font-size: 15px !important;
            right: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 17rem;
        }
        @media all and (max-width: 1200px) {
          height: 14.9rem;
          &::after {
            font-size: 14px !important;
            right: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
      }
      .swiper-button-prev {
        left: -54px;
        height: 300px;
        width: 4%;
        top: 5%;
        margin-top: -23px;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 32rem;
          width: 6rem;
          left: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 31rem;
        }
    
        @media all and (max-width: 2200px) {
          height: 27rem;
        }
        @media all and (max-width: 1980px) {
          height: 25rem;
          &::after {
            position: absolute;
            left: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 22rem;
          &::after {
            font-size: 18px !important;
            left: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 20rem;
          &::after {
            font-size: 15px !important;
            left: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 17rem;
        }
        @media all and (max-width: 1200px) {
          height: 14.9rem;
          &::after {
            font-size: 14px !important;
            left: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
        
      }
      
    }
    &.rowCount8{
      margin-bottom: 356px;
      margin-top: 130px;
      @media all and (max-width: 2600px) {
        margin-bottom: 465px;
      }
      @media all and (max-width: 2500px) {
        margin-bottom: 430px;
      }
      @media all and (max-width:2200px) {
        margin-bottom: 390px;
      }
      @media all and (max-width:1980px) {
        margin-bottom: 360px;
      }
      @media all and (max-width:1768px) {
        margin-bottom: 320px;
      }
      @media all and (max-width:1600px) {
        margin-bottom: 285px;
      }
      @media all and (max-width:1400px) {
        margin-bottom: 240px;
      }
      @media all and (max-width:1200px) {
        margin-bottom: 232px;
      }
      @media all and (max-width: 980px) {
        margin-top: 0;
        margin-bottom: 24px;
      }

      .swiper-button-next {
        right: -54px;
        height: 261px;
        width: 4%;
        top: 4%;
        margin-top: -22px;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 28rem;
          width: 6rem;
          right: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 27rem;
        }
        @media all and (max-width: 2200px) {
          height: 24rem;
        }
        @media all and (max-width: 1980px) {
          height: 21rem;
          &::after {
            position: absolute;
            right: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 19rem;
          &::after {
            font-size: 18px !important;
            right: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 17rem;
          &::after {
            font-size: 15px !important;
            right: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 15rem;
        }
        @media all and (max-width: 1200px) {
          height: 13rem;
          &::after {
            font-size: 14px !important;
            right: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
      }
      .swiper-button-prev {
        left: -54px;
        height: 260px;
        width: 4%;
        top: 5%;
        margin-top: -22px;
        background: hsla(0, 0%, 8%, 0.5);
        @media all and (max-width: 2600px) {
          height: 28rem;
          width: 6rem;
          left: -90px;
        }
        @media all and (max-width: 2500px) {
          width: 5.5rem;
          height: 27rem;
        }
    
        @media all and (max-width: 2200px) {
          height: 24rem;
        }
        @media all and (max-width: 1980px) {
          height: 21rem;
          &::after {
            position: absolute;
            left: 48px;
          }
        }
        @media all and (max-width: 1768px) {
          height: 19rem;
          &::after {
            font-size: 18px !important;
            left: 53px;
          }
        }
        @media all and (max-width: 1600px) {
          height: 17rem;
          &::after {
            font-size: 15px !important;
            left: 60px;
          }
        }
        @media all and (max-width: 1400px) {
          height: 15rem;
        }
        @media all and (max-width: 1200px) {
          height: 13rem;
          &::after {
            font-size: 14px !important;
            left: 69px;
          }
        }
        @media all and (max-width: 980px) {
          display: none;
        }
        
      }
    }
    
    .swiper-button-next,.swiper-button-prev {
      right: 20px;
      margin-top: 50px;
      @media all and (max-width: 980px) {
        display: none;
      }
    }
    @media all and (max-width: 980px) {
      margin-top: 0;
      margin-bottom: 24px;
    }
  }
  
  
  &:hover {
    z-index: 99;
  }
  &.hideArrow {
    div.itemsContainer {
      div.swiper {
        div.swiper-wrapper {
          z-index: 99;
        }
      }
    }
  }

  .swiper-slide {
    // transition: 0.2s ease-in-out;
    z-index: 9;
    //    &.hover{
    //     z-index: 99999 !important;
    //    }
    @media all and (min-width: 981px) {
      &.landscape {
        &.hover {
          &:hover {
            z-index: 99 !important;
            // &:first-child{
            //   div.show {
            //     div.showCardContainer {
            //         div.square {
            //           border-radius: 10px;
            //           box-shadow: 0 0 2px #000a;
            //           transform: translateY(-60px) scale(1.2);
            //           @media all and (max-width:1200px){

            //           }
            //           div.metaData{
            //             display: block;
            //             visibility: visible !important;
            //             animation: fadeIn 0.3s ease-in-out;
            //           }
            //           div.imageContainer {
            //           img {
            //             border-bottom-left-radius: 0px;
            //             border-bottom-right-radius: 0px;
            //           }

            //         }
            //         div.continueWatchingBar{
            //           display: none;
            //         }
            //         div.continueWatchingContainer{
            //           display: flex;
            //         }
            //       }
            //     }
            //   }
            // }
            // &.swiper-6{
            //   div.show {
            //     div.showCardContainer {
            //       div.square{

            //           transform: translateY(-60px) scale(1.2);
            //       }
            //     }
            //   }
            // }
            div.show {
              div.showCardContainer {
                div.square {
                  border-radius: 10px;
                  box-shadow: 0 0 2px #000a;
                  transform: translateY(-60px) scale(1.4);
                  div.metaData {
                    display: block;
                    visibility: visible !important;
                    animation: fadeIn 0.3s ease-in-out;
                  }
                  div.imageContainer {
                    img {
                      border-bottom-left-radius: 0px;
                      border-bottom-right-radius: 0px;
                    }
                  }
                  div.continueWatchingBar {
                    display: none;
                  }
                  div.continueWatchingContainer {
                    display: flex;
                  }
                }
              }
            }
            &:first-child {
              div.show {
                div.showCardContainer {
                  div.square {
                    transform: translateY(-60px) scale(1.4) !important;
                  }
                }
              }
            }
            // &:nth-child(6n) {
            //   div.show {
            //     div.showCardContainer {
            //       div.square{

            //           transform: translateY(-60px) scale(1.4);
            //       }
            //     }
            //   }
            // }
            &:last-child {
              div.show {
                div.showCardContainer {
                  div.square {
                    transform: translateX(-60px) translateY(-60px) scale(1.4);
                  }
                }
              }
            }
            // &:first-child{
            //   div.show {
            //     div.showCardContainer {
            //         div.square {
            //           transform: translateX(60px) translateY(-60px) scale(1.4);
            //         div.imageContainer {
            //           img {
            //             border-bottom-left-radius: 0px;
            //             border-bottom-right-radius: 0px;
            //           }
            //         }
            //       }
            //     }
            //   }
            // }
          }
        }
      }
      &.portrait {
       
        &.hover {
          &:hover {
            z-index: 99 !important;

            div.show {
              div.showCardContainer {
                div.square {
                  border-radius: 10px;
                  box-shadow: 0 0 2px #000a;
                  transform: translateY(-60px) scale(1.2);
                  div.metaData {
                    display: block;
                    visibility: visible !important;
                    animation: fadeIn 0.3s ease-in-out;
                  }
                  div.imageContainer {
                    img {
                      border-bottom-left-radius: 0px;
                      border-bottom-right-radius: 0px;
                    }
                  }
                  div.continueWatchingBar {
                    display: none;
                  }
                  div.continueWatchingContainer {
                    display: flex;
                  }
                }
              }
            }
            &:first-child {
              div.show {
                div.showCardContainer {
                  div.square {
                    transform: translateY(-60px) scale(1.2) !important;
                  }
                }
              }
            }
            // &:nth-child(6n) {
            //   div.show {
            //     div.showCardContainer {
            //       div.square{

            //           transform: translateY(-60px) scale(1.2);
            //       }
            //     }
            //   }
            // }
            &:last-child {
              div.show {
                div.showCardContainer {
                  div.square {
                    transform: translateX(-60px) translateY(-60px) scale(1.2);
                  }
                }
              }
            }
            // &:first-child{
            //   div.show {
            //     div.showCardContainer {
            //         div.square {
            //           transform: translateX(60px) translateY(-60px) scale(1.2);
            //         div.imageContainer {
            //           img {
            //             border-bottom-left-radius: 0px;
            //             border-bottom-right-radius: 0px;
            //           }
            //         }
            //       }
            //     }
            //   }
            // }
          }
        }
      }
    }
  }
  //   .swiper-button-next{
  //     &::after{
  //         transition: 0.3s ease-in-out;
  //         opacity:0;
  //     }
  // }
  // .swiper-button-prev{

  //     &::after{
  //         transition: 0.3s ease-in-out;
  //         opacity:0;
  //     }
  // }

  &:hover {
    .swiper-button-next {
      &::after {
        opacity: 1;
      }
    }
    .swiper-button-prev {
      &::after {
        opacity: 1;
      }
    }
  }
  .swiper-button-next {
    &::after {
      opacity: 0;
    }
  }
  .swiper-button-prev {
    &::after {
      opacity: 0;
    }
    // &::after{
    //     background: rgb(16, 20, 31);
    //     padding: 6px;
    //     border-radius: 50%;
    //     font-size: 18px;
    //     width: 20px;
    //     height: 20px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     color: var(--text-color);
    //     opacity: 0;
    //     transition: 0.3s ease-in-out;
    //     @media all and (min-width:320px){

    //         font-size: 9px;
    //         width: 10px;
    //         height: 10px;

    //     }
    //     @media all and (min-width:980px){

    //         font-size: 18px;
    //         width: 20px;
    //         height: 20px;

    //      }
    // }
  }

  div.wrapper {
    &:hover {
      div.itemsContainer {
        div.categoryNameContainer {
          span.seeAll {
            visibility: visible;
          }
        }
      }
    }
  }

  div.itemsContainer {
    position: relative;
    width: 93%;
    margin: 0 auto;
    visibility: hidden;
    &.showSlider {
      visibility: visible;
      animation: fadeIn 1.5s ease-in-out;
    }
    // @media all and (max-width: 2500px) {
    //   width: 96%;
    // }
   
    &:hover {
      div.categoryNameContainer {
        div.exploreAll {
          div.rightArrow {
            opacity: 1;
          }
        }
      }
    }
    div.categoryNameContainer {
      cursor: pointer;
      display: inline-block;
      align-items: center;
      h1 {
        font-size: 19px;
        color: var(--text-color);
        max-width: 99%;
        font-weight: 500;
        margin-bottom: 10px;
        @media all and (max-width: 980px) {
          font-size: 16px;
        }
      }
      &:hover {
        div.exploreAll {
          div.seeAll {
            opacity: 1;
            max-width: 100%;
            transform: translateX(10px);
          }
          div.rightArrow {
            svg {
              width: 10px !important;
              height: 10px !important;
              transform: translateX(10px) rotate(-90deg);
            }
          }
        }
      }
      div.content {
        display: flex;
      }
      div.exploreAll {
        color: #54b9c5;
        display: flex;
        align-items: center;
        margin-top: -6px;
        div.seeAll {
          cursor: pointer;
          display: inline-block;
          font-size: 0.9vw;
          line-height: 0.8vw;
          margin-right: 4px;
          max-width: 0;
          opacity: 0;
          //  margin-left: 10px;
          font-weight: 500;
          transition: max-width 1s, opacity 1s, transform 0.75s;
          vertical-align: bottom;
          white-space: nowrap;
          transform: translateX(-10px);
          @media all and (max-width: 980px) {
            font-size: 1vw;
          }
        }
        div.rightArrow {
          // &::before{
          //     content: "\e658";
          //     color: #54b9c5;
          //     display: block;
          // }
          opacity: 0;
          transition: 0.3s ease-in-out;
          line-height: 0;
          svg {
            transform: rotate(-90deg);
            transition: 0.2s ease-in-out;
            width: 20px !important;
            height: 20px !important;
          }
        }
      }
    }

    div.show {
      transition: 0.4s ease-in-out;
      position: absolute;

      @media all and (max-width: 980px) {
        position: unset;
      }
      @media all and (min-width: 320px) {
        width: 100%;
      }
      // @media all and (min-width:360px){
      //     width: 11rem;
      // }
      // @media all and (min-width:640px){
      //     width: 13rem;
      // }
      // @media all and (min-width:768px){
      //     width: 13rem;
      // }
      // @media all and (min-width:980px){
      //     width: 13rem;
      // }
    }
    div.showMoreCard{
      background-color: var(--bg-color);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      padding: 20px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      cursor: pointer;
      @media all and (max-width:2600px){
        width: 250px;
        height: 259px;
      }
      @media all and (max-width:2500px){
        height: 225px;
      }
      @media all and (max-width:2200px){
        height: 200px;
      }
      @media all and (max-width:1980px){
        height: 180px;
      }
      @media all and (max-width:1768px){
        height: 162px;
      }
      @media all and (max-width:1600px){
        height: 142px;
      }
      @media all and (max-width:1400px){
        height: 120px;
      }
      @media all and (max-width:1200px){
        height: 100px;
        width: 150px;
      }
      @media all and (max-width:980px){
       transform: translateY(50%);
       height: 100px;
      }
      @media all and (max-width:640px){
        transform: none;
        min-height: 208px;
        width: 200px;
      }
      &:hover{
        h2{
          text-decoration: underline;
        }
      }
      h2{
        color: var(--white-color);
        font-size: 18px;
        letter-spacing: .3px;
        @media all and (max-width:1200px){
          font-size: 15px;
        }
      }
    }
    .swiper{
      .swiper-wrapper{
        .swiper-slide{
          &.hover{
            &:last-child{
              div.show{
                &.visibleShowAllButton{
                  div.showCardContainer{
                    div.square{
                      transform: none !important;
                      div.metaData{
                        display: none !important;
                        @media all and (max-width:980px){
                          display: block !important;
                        }
                      }
                    }
                  }

                }
              }
            }

          }
          &:last-child{
            div.show{
              &.visibleShowAllButton{
                div.showCardContainer{
                 div.showMoreOverlay{
                  background-color: var(--bg-color);
                  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                  position: absolute;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                  width: 100%;
                  z-index: 99;
                  &:hover{
                    h2{
                      text-decoration: underline;
                    }
                  }
                  h2{
                    color: var(--white-color);
                    font-size: 20px;
                    letter-spacing: 1px;
                    @media all and (max-width:1400px){
                      font-size: 18px;
                    }
                    @media all and (max-width:1200px){
                      font-size: 15px;
                    }
                    @media all and (max-width:360px){
                      font-size: 18px;
                    }
                  }
                 }
  
                }

              }
            }
          }
        }
      }
    }
  }
}

.margincontainer{
  @media all and (min-width:1025px){
    margin-bottom:-60px;
  }
  @media all and (max-width:980px){
    margin-bottom:-70px;
  }
}
.minimumHeightContainer{
  // @media all and (min-width:1600px){
  //   min-height: 220px !important;
  // }

  @media all and (min-width:1025px){
    min-height: 202px;
  }
  @media all and (min-width:1400px){
    min-height: 220px;
  }
  @media all and (min-width:1600px){
    min-height: 240px !important;
  }
  @media all and (min-width:1768px){
    min-height: 289px !important;
  }
  @media all and (min-width:1980px){
    min-height: 326px !important;
  }
  @media all and (min-width:2500px){
    min-height: 387px !important;
  }
  @media all and (min-width:2600px){
    min-height: 420px !important;
  }
}
.marginLessContainer{
  margin-bottom:0
}