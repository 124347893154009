div.subscription-plan-containter{
    min-height: 300vh;
    margin-top: 0px;
    position: relative;
    @media all and (max-width:768px){
        min-height: 500vh;
    }

    #page-container {
        overflow-y: hidden;
    }
}