.widget {
  margin-bottom: 1.875em;
  padding: 1.875em;
  display: inline-block;
  width: 100%;
  float: left;
  position: relative;
  background: var(--bs-gray-900);
  border-radius: 0.1875em;

  .search-form {
    position: relative;

    .form-control {
      border: 0.0625em solid var(--bs-border-color);
      padding-right: 3.5em;
      background: var(--bs-body-bg);

      &:focus {
        border-color: var(--bs-primary);
      }
    }

    .block-search_button {
      position: absolute;
      right: 0;
      top: 0;
      color: var(--bs-white);
      cursor: pointer;
      background: var(--bs-primary);
      border: none;
      outline: none;
      box-shadow: none;
      transition: all 0.3s ease-in-out;
      height: 100%;
      width: 3em;
      border-radius: 0 3px 3px 0;
    }
  }

  .widget-title {
    font-size: 1.375em;
    position: relative;
    margin-bottom: 1.364em;
    margin-top: 0;
    font-weight: 500;
  }

  &.widget_categories {
    li {
      color: var(--bs-body-text);
      margin-bottom: 0.9375em;
      border-bottom: 0.0625em solid var(--bs-border-color);
      padding-bottom: 0.9375em;
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;
      transition: all 0.3s ease-in-out;

      a {
        color: var(--bs-body-color);
      }

      .post_count {
        opacity: 0;
        font-weight: 500;
        border-radius: 3.125em;
        color: var(--bs-primary);
        width: 1.25em;
        height: 1.25em;
        text-align: center;
        line-height: 1.25em;
        font-size: 0.875em;
        transition: opacity 0.3s ease-in-out;
      }

      &::before {
        content: "";
        left: 0;
        top: 4px;
        width: 1px;
        height: 15px;
        position: absolute;
        background: var(--bs-primary);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      &:hover {
        padding-left: 8px;

        .post_count {
          opacity: 1;
        }

        &::before {
          opacity: 1;
        }
      }
    }
  }

}

.widget_tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  li {
    padding: 0;

    a {
      background: var(--bs-body-bg);
      display: inline-block;
      color: var(--bs-body-color);
      padding: 0.3125em 0.625em;
      font-size: 0.875em;
      border-radius: 0.1875em;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: var(--bs-primary);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.iq-blog-tag {
  margin-top: 3.75em;
  padding-top: 3.125em;
  border: solid;
  border-width: 0.063em 0 0;
  border-image-source: linear-gradient(270deg, rgba(var(--bs-secondary-rgb), 0) -1.25%, var(--bs-secondary) 43.69%, rgba(var(--bs-secondary-rgb), 0) 99.08%);
  border-image-slice: 1;
  margin-bottom: 2.5em;

}

// social media
.widget_social_media {
  li {
    margin-right: .75rem;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }

    a {
      position: relative;
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      text-align: center;
      line-height: 2.5rem;
      margin-right: 0;
      color: var(--bs-white);
      font-size: 1rem;
      border-radius: 50%;
      background: var(--bs-gray-800);
      transform: translateY(0);
      transition: all 0.4s ease 0s;

      &:hover {
        background: var(--bs-primary);
        transform: translateY(-0.3125em);
        color: var(--bs-white);
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(0.9);
        z-index: -1;
        border-radius: 50%;
        transition: all 0.4s ease 0s;
      }
    }
  }
}

// recent blog
.iq-widget-blog {
  li {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: none;
  }

  .post-title {
    margin-bottom: 0.5rem;
  }

  .img-holder {
    width: 3.75em;
    border-radius: 3px;

    a {
      display: block;
      width: 100%;
      height: 3.75em;
      width: 3.75em;
      border-radius: 0;
      overflow: hidden;
      border-radius: 3px;
    }
  }

  .blog-data {
    font-size: 0.75em;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    color: var(--bs-body-color);

    i {
      color: var(--bs-primary);
    }
  }
}

@media (max-width:767px) {
  .iq-blog-tag {
    margin-top: 2rem;
    padding-top: 2rem;
    margin-bottom: 0;
  }
}