.widget {
    .search-form {
        .form-control{
            padding-left: 3.5rem;
            padding-right: 1.5rem;
        }        
        .block-search_button {
            left: 0;
            right: auto;
            border-radius: 3px 0 0 3px;
        }
    }
}  

.widget.widget_categories {
    li {
        &::before {
            left: auto;
            right: 0;
        }
        &:hover {
            padding-left: 0;
            padding-right: 8px;
        }
    }
}

.widget_social_media {
    li {
        margin-left: .75rem;
        margin-right: 0;
        &:last-child {
            margin-left:0;
        }
    }
}